import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import qs from 'qs'

export type CloudinaryVideoPlayerProps = {
  autoplay?: boolean
  bigPlayButton?: boolean
  cloudinaryPublicId: string
  controls?: boolean
  dataTestId?: string
  logoOnclickUrl: string
  loop?: boolean
  muted?: boolean
  quality?: 'good' | 'eco' | 'low'
  videoAlt: string
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  position: relative;
  width: 100%;
  max-height: 100%;
`

export const VideoArea = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`

const Video = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
`

export const CloudinaryVideoPlayer: FunctionComponent<
  CloudinaryVideoPlayerProps
> = ({
  autoplay = false,
  bigPlayButton = true,
  cloudinaryPublicId,
  controls = true,
  dataTestId = 'cloudinary-video-iframe',
  logoOnclickUrl,
  loop = false,
  muted = false,
  quality = 'eco',
  videoAlt,
}) => {
  const params = {
    cloud_name: 'tonies',
    player: {
      allowUsageReport: false, // disable Cloudinary's own Google Analytics
      autoplay,
      bigPlayButton,
      colors: {
        accent: '#d2000f',
        text: '#333333',
      },
      controls,
      fluid: true,
      fontFace: 'inherit',
      logoOnclickUrl,
      logoImageUrl:
        'https://images.ctfassets.net/l6vo76cztcax/6dgYMu94aQLToD8DAWgloK/cbbdd6025f8069f01efa33b7b128ccd7/Tonies_logo_cathead_red_rgb.svg',
      loop,
      muted: autoplay ?? muted,
      preload: 'none',
      skin: 'light',
      transformation: {
        fetchFormat: 'auto',
        quality: `auto:${quality}`,
      },
    },
    public_id: `${cloudinaryPublicId}`,
  }

  const query = qs.stringify(params)

  return (
    <Wrapper>
      <VideoArea data-testid={dataTestId}>
        <Video
          src={`https://player.cloudinary.com/embed/?${query}`}
          allow="fullscreen; picture-in-picture"
        >
          {videoAlt}
        </Video>
      </VideoArea>
    </Wrapper>
  )
}
