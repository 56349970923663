import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'

export type GenericSelectionType = {
  selection: string[]
  setSelection: (selectedItems: string[]) => void
  isSingleSelection?: boolean
  maxValue?: number
}

type SelectionProviderProps = GenericSelectionType & {
  isMaximumReached: boolean
  handleOnChange: (id: string | undefined) => void
}

export const selectionContext = createContext<SelectionProviderProps>({
  selection: [],
  setSelection: () => null,
  handleOnChange: () => null,
  isMaximumReached: false,
  isSingleSelection: false,
  maxValue: undefined,
})

export const SelectionProvider: FunctionComponent<
  Partial<GenericSelectionType> & PropsWithChildren
> = ({
  maxValue: maxValueProps,
  setSelection,
  isSingleSelection: isSingleSelectionProp,
  selection,
  children,
}) => {
  const isSingleSelection = isSingleSelectionProp ?? maxValueProps === 1
  const maxValue = isSingleSelection ? 1 : maxValueProps

  const isMaximumReached = useMemo(
    () =>
      Boolean(
        !isSingleSelection &&
          maxValue &&
          selection &&
          maxValue <= selection?.length
      ),
    [isSingleSelection, maxValue, selection]
  )

  const handleOnChange = useCallback(
    (id: string | undefined) => {
      if (selection && setSelection && id) {
        if (selection.includes(id)) {
          setSelection(selection.filter(curr => curr !== id))
        } else if (maxValue === 1) {
          setSelection([id])
        } else {
          setSelection([...selection, id])
        }
      }
    },
    [maxValue, selection, setSelection]
  )

  return (
    <selectionContext.Provider
      value={{
        selection: selection || [],
        setSelection: setSelection || (() => null),
        maxValue,
        isSingleSelection,
        handleOnChange,
        isMaximumReached,
      }}
    >
      {children}
    </selectionContext.Provider>
  )
}

export const useSelection = (id?: string) => {
  const context = useContext(selectionContext)
  const isSelected = Boolean(id && context.selection?.includes(id))

  return { ...context, isSelected }
}
