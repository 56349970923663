import React from 'react'
import Head from 'next/head'
import { useLocaleAltNotation } from '../../../../providers/locale/altNotation'
import { stripTags } from '../../../../utils/stripTags'

type OpenGraphProps = {
  description: string
  imageUrl: string
  pageUrl: string
  title: string
}

export const OpenGraph = ({
  description,
  imageUrl,
  pageUrl,
  title,
}: OpenGraphProps) => {
  const locale = useLocaleAltNotation('lc_CC')

  return (
    <Head>
      <meta property="og:title" content={stripTags(title)} />
      <meta property="og:site_name" content="tonies®" />
      <meta property="og:description" content={description} />
      <meta property="og:type" key="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:locale" content={locale} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Head>
  )
}
