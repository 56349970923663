import React from 'react'
import Head from 'next/head'

export const PreventCache = () => (
  <Head>
    <meta
      httpEquiv="Cache-Control"
      content="no-cache, no-store, must-revalidate"
    />
    <meta httpEquiv="Pragma" content="no-cache" />
    <meta httpEquiv="Expires" content="0" />
  </Head>
)
