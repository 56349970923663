import React from 'react'
import { GallerySchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { Gallery } from '../../../../../../components/organisms/Gallery2'

export const CM_GallerySchema = ({
  componentProps,
  entries,
  labels = [],
  ...rest
}: ContentModelType<unknown, GallerySchema>) => {
  return (
    // FE Migration
    <Gallery
      images={entries.map((entry, i) => {
        return {
          imageSrc: entry.image.file.url,
          link: entry.link,
          caption: entry.caption || labels[i],
        }
      })}
      variant="list"
      columnsDesktop={6}
      hasSocialLinks={false}
      {...rest}
    />
  )
}
