import { Button, CTARow, Paragraph, Spacing } from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import React, { FunctionComponent } from 'react'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { TooltipSchema } from 'lib/contentful/datamodel/schemas'
import { Image } from '@/tonies-ui/atoms/Image'

export const TooltipModal: FunctionComponent<
  Omit<TooltipSchema, 'contentTypeId' | 'referenceTitle'> & {
    onClose: () => void
  }
> = ({ headline, text, image, ctaUrl, ctaText, onClose }) => {
  return (
    <Modal
      isOpen
      headline={headline && <Accent text={headline} />}
      shouldFocusFirstElement
      size="large"
      onClose={onClose}
      data-testid="tooltip-modal"
      footer={
        <CTARow justifyContent="space-around" flexDirection="row-reverse">
          <Button
            data-testid="tooltip-modal-cta-button"
            variant="primary"
            as="a"
            href={ctaUrl}
          >
            {ctaText}
          </Button>
        </CTARow>
      }
    >
      <Paragraph>{text}</Paragraph>
      {image && (
        <Spacing mt="spacing-l">
          <Image src={image.file.url} alt={image.title} />
        </Spacing>
      )}
    </Modal>
  )
}
