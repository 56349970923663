import React, { ComponentProps } from 'react'
import { DownloadTeaserEntryListSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { MediaCopyCollection } from '../../../../../../components/organisms/MediaCopyCollection'
import { CM_MediaCopySchema } from '../molecules/MediaCopySchema'

export const CM_DownloadTeaserEntryListSchema = ({
  componentProps,
  entries,
  ...rest
}: ContentModelType<
  ComponentProps<typeof MediaCopyCollection>,
  DownloadTeaserEntryListSchema
>) => {
  return (
    <MediaCopyCollection
      {...rest}
      columnsDesktop={2}
      columnsMobile={1}
      orientation="horizontal"
      entries={entries.map(
        (props): ComponentProps<typeof MediaCopyCollection>['entries'][0] =>
          CM_MediaCopySchema(props)
      )}
    />
  )
}
