import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../util/fetch'

export type CompatibleToniesResponseType =
  | NormalizedProduct[]
  | 'request-failed'

export const getCompatibleContentTonieSalesIds = (
  lcCC: EcomLocale,
  tunesItemSalesId: string
) =>
  fetch<Record<string, unknown>>({
    query: `/tunesitems/${tunesItemSalesId}/compatible-content-tonie-item-sales-ids`,
    lcCC,
    method: 'get',
  })
