import React, { FormEvent, FunctionComponent, useCallback } from 'react'
import { useColorSelectorSortedByColor } from '../../../hooks/useSortedByColor'
import { Radio } from './components/Radio'
import { Selector } from './components/Selector'
import { ColorSelectorProps } from './types'

export const ColorSelector: FunctionComponent<ColorSelectorProps> = ({
  variants,
  activeVariantId,
  onChange,
  uniqueInputName,
}) => {
  const variantsSortedByColor = useColorSelectorSortedByColor(variants)

  const handleChange = useCallback(
    (e: FormEvent<HTMLDivElement>) => {
      const activeVariantId = (e.target as HTMLDivElement).id
      onChange(activeVariantId)
    },
    [onChange]
  )

  return (
    <Selector onChange={handleChange}>
      {variantsSortedByColor.map(variant => (
        <Radio color={variant.colorcode} key={variant.id}>
          <input
            type="radio"
            name={uniqueInputName}
            id={variant.id}
            defaultChecked={activeVariantId === variant.id}
          />
          <label htmlFor={variant.id}>{variant.name}</label>
        </Radio>
      ))}
    </Selector>
  )
}
