import React, { ComponentPropsWithoutRef } from 'react'
import {
  Button,
  CTARow,
  Paragraph,
  ParagraphProps,
  Spacing,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import { HeadlineWithAccent } from '../../../atoms/Headline'

export interface SectionHeadlineViewProps {
  headline?: ComponentPropsWithoutRef<typeof HeadlineWithAccent>
  subheadline?: ParagraphProps
  primaryCtaText?: string
  primaryCtaUrl?: string
  secondaryCtaText?: string
  secondaryCtaUrl?: string
}

export const SectionHeadlineView = ({
  headline,
  subheadline,
  primaryCtaText,
  primaryCtaUrl,
  secondaryCtaText,
  secondaryCtaUrl,
}: SectionHeadlineViewProps) => {
  const isMobile = useIsViewportMobile()
  const hasPrimaryCta = Boolean(primaryCtaText && primaryCtaUrl)
  const hasSecondaryCta = Boolean(secondaryCtaText && secondaryCtaUrl)

  return (
    <>
      {headline?.children && <HeadlineWithAccent {...headline} />}

      {subheadline?.children && <Paragraph align="center" {...subheadline} />}

      {(hasPrimaryCta || hasSecondaryCta) && (
        <Spacing mt={isMobile ? 'spacing-s' : 'spacing-m'}>
          <CTARow
            justifyContent="center"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {hasPrimaryCta && (
              <Button as="a" href={primaryCtaUrl}>
                {primaryCtaText}
              </Button>
            )}
            {hasSecondaryCta && (
              <Button as="a" variant="secondary" href={secondaryCtaUrl}>
                {secondaryCtaText}
              </Button>
            )}
          </CTARow>
        </Spacing>
      )}
    </>
  )
}
