import React, { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useSideCart } from '../../../../providers/sideCart'
import { ButtonLink } from '@boxine/tonies-ui'
import { useLocaleSelectorContext } from '../../../../providers/localeSelector'
import { useToniesCookies } from '../../../../hooks/useToniesCookies'
import { useGtmV4 } from '../../../../providers/gtmV4'
import { IconWithCount } from '@/tonies-ui/atoms/IconWithCountIndicator'
import styled from 'styled-components'

const StyledButtonLink = styled(ButtonLink)`
  display: inline-block;
`

export const CartLink: FunctionComponent = () => {
  const { pushGtmV4Event } = useGtmV4()
  const { t } = useTranslation()
  const { cartItemCount } = useToniesCookies()
  const [isOpen, setIsOpen] = useSideCart()
  const { setIsSelectorExpanded } = useLocaleSelectorContext()

  const handleToggleSideCart = useCallback(() => {
    // Toggle SideCart
    setIsOpen(!isOpen)

    // Hide Locale Selector, in case it was previously opened
    setIsSelectorExpanded(false)

    pushGtmV4Event({
      eventType: 'click',
      type: 'onClick',
      label: '[coded] Cart',
      action: isOpen ? 'side_cart_hide' : 'side_cart_show',
    })
  }, [setIsOpen, setIsSelectorExpanded, pushGtmV4Event, isOpen])

  return (
    <StyledButtonLink dataTestId="cart-link" onClick={handleToggleSideCart}>
      <IconWithCount
        dataTestIdCount="cart-count"
        count={cartItemCount}
        iconType="cart"
        isAnimated
        statusMessage={t('header:BasketStatus', { cartCount: cartItemCount })}
      />
    </StyledButtonLink>
  )
}
