import { useTranslation } from 'next-i18next'
import { useDateFormatter } from '../../../../hooks/useDateFormatter'
import { CardProps } from '@/tonies-ui/atoms/Card'
import { useTheme } from 'styled-components'

export const useCardNote = (
  availability?: NormalizedAvailability
): CardProps['note'] => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const df = useDateFormatter()

  if (availability?.state === 'sold-out') {
    return {
      text: t('products:soldOut'),
      fillColor: colors['anthracite-30'],
    }
  } else if (availability?.state === 'announced') {
    return {
      text: t('products:orderFromDate', {
        date: df(new Date(availability.orderFromDate)),
      }),
    }
  } else if (availability?.state === 'preorderable') {
    return {
      text: t('products:shippingFromDate', {
        date: df(new Date(availability.shippingFromDate)),
      }),
    }
  } else {
    return undefined
  }
}
