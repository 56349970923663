import { useInView } from 'framer-motion'
import { CarouselContext } from 'pure-react-carousel'
import { useContext, useEffect, RefObject } from 'react'

/**
 * This hook triggers two quick swipes (forward and then backward)
 * to emphasize that this area is swipeable. (A ticket which refines
 * this is currently in draft stage.)
 */
export const useIntroAnimation = (
  hasIntroAnimation: boolean,
  refCarousel: RefObject<HTMLElement>,
  refPrevButton: RefObject<HTMLButtonElement>,
  refNextButton: RefObject<HTMLButtonElement>
) => {
  const carouselContext = useContext(CarouselContext)
  const inViewport = useInView(refCarousel, { margin: '-200px' })

  useEffect(() => {
    if (inViewport && hasIntroAnimation) {
      let timeout: ReturnType<typeof setTimeout>

      if (refNextButton.current) {
        refNextButton.current.focus()
      }

      carouselContext.setStoreState({
        currentSlide: 1,
      })

      timeout = setTimeout(() => {
        if (refPrevButton.current) {
          refPrevButton.current.focus()
        }

        carouselContext.setStoreState({
          currentSlide: 0,
        })

        timeout = setTimeout(() => {
          if (refPrevButton.current) {
            refPrevButton.current.blur()
          }
        }, 800)
      }, 800)

      return () => clearTimeout(timeout)
    }

    return () => undefined
  }, [
    hasIntroAnimation,
    inViewport,
    refNextButton,
    refPrevButton,
    carouselContext,
  ])
}
