import React, { useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { EmptyStateView, EmptyStateViewProps } from '..'
import { useWishlist } from '../../../../providers/Wishlist'

export const EmptyCart = ({
  staticRecommendations = [],
  ...rest
}: Partial<EmptyStateViewProps>) => {
  const { t } = useTranslation()
  const { myDefaultWishlist = { products: [] } } = useWishlist()

  const combindedRecommendations =
    // use new Map() so avoid duplicates by sku
    new Map(
      [...myDefaultWishlist.products, ...staticRecommendations].map(item => [
        item.sku,
        item,
      ])
    ).values()

  // use a ref to avoid replacing the cards in the slider by adding and removing from the wishlist
  const recommendations = useRef([...combindedRecommendations])

  return (
    <EmptyStateView
      data-testid="cart-empty"
      headline={t('cart:empty:headline')}
      subheadline={t('cart:empty:subheadline')}
      staticRecommendations={recommendations.current}
      {...rest}
    />
  )
}
