import { ColorSelectorVariantProps } from '../components/atoms/ColorSelector/types'
import { LabelSelectorVariantProps } from '../components/atoms/LabelSelector/types'

export function useColorSelectorVariants(products: NormalizedProduct[]) {
  return products
    .filter(p => p.color)
    .map<ColorSelectorVariantProps>(product => ({
      name: product.name,
      id: product.id,
      colorcode: product.color || '#000000',
    }))
}

export function useLabelSelectorVariants(products: NormalizedProduct[]) {
  return products
    .filter(p => p.variantSelectValue)
    .map<LabelSelectorVariantProps>(pv => ({
      id: pv.id,
      name: pv.name,
      label: pv.variantSelectValue || '',
    }))
}
