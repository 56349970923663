import React from 'react'
import {
  Box,
  Button,
  CTARow,
  Headline2,
  Paragraph,
  Spacing,
  useCountryCodes,
  OmniButton,
} from '@boxine/tonies-ui'
import { IconButton } from '@/tonies-ui/atoms/IconButton'
import { useTranslation } from 'next-i18next'
import { useLocale } from '../../../providers/locale'
import { useTheme } from 'styled-components'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { InputField } from '../../../components/atoms/FormElements/Input'
import { Select } from '../../../components/atoms/FormElements/Select'
import { RichText } from '../../molecules/RichText'
import { Document } from '@contentful/rich-text-types'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { useBrazeFormik } from '../../../hooks/useBrazeFormik'
import { FormikProvider, FieldArray, Field, getIn } from 'formik'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { shopConfig } from '../../../config/shop'
import { E164Number } from 'libphonenumber-js'
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input/input-mobile'
import {
  Wrapper,
  FormWrapper,
  RepeaterWrapper,
  DataOfBirthParagraph,
  BirthdayWrapper,
} from './styles'

export type BrazeFormProps = {
  variant: 'AI Story Requests' | 'Kids Birthdays' | 'Newsletter' | 'Sweepstake'
  align?: 'center' | 'left'
  backgroundColor?: string
  ctaText?: string
  customUserAttributes?: {
    key: string
    value: boolean | string | number
  }[]
  eventLabel?: string
  eventName?: string
  formLocation: string
  hasAgeSelect?: boolean
  hasCountrySelect?: boolean
  hasFirstNameInput?: boolean
  hasLastNameInput?: boolean
  hasOptionalTonies?: boolean
  hasPhoneInput?: boolean
  headline?: string
  headlineAsHTMLTag?: SupportedHTMLTags
  optinEventName?: string
  ppPageUrl?: string
  richtext?: Document | string
  successHeadline?: string
  successRichtext: Document | string
  tcPageUrl?: string
  consentAppendedText?: string
}

export const BrazeFormContainer = ({
  variant,
  align = 'center',
  backgroundColor = 'transparent',
  ctaText,
  customUserAttributes,
  eventLabel,
  eventName,
  formLocation,
  hasAgeSelect,
  hasCountrySelect,
  hasFirstNameInput,
  hasLastNameInput,
  hasOptionalTonies,
  hasPhoneInput,
  headline,
  headlineAsHTMLTag = 'h2',
  optinEventName,
  ppPageUrl = shopConfig.legalLinks.privacyPolicy,
  richtext,
  successHeadline,
  successRichtext,
  tcPageUrl,
  consentAppendedText,
}: BrazeFormProps) => {
  const lcCC = useLocale()
  const { t } = useTranslation()
  const { colors } = useTheme()

  const formik = useBrazeFormik({
    customUserAttributes,
    eventLabel,
    eventName,
    formLocation,
    hasAgeSelect,
    optinEventName,
    variant,
  })

  const getFormikError = (fieldName: string): string => {
    const error = getIn(formik.errors, fieldName)
    const errorStr = typeof error === 'string' ? error : undefined
    const touched = getIn(formik.touched, fieldName) as boolean
    return touched && errorStr ? errorStr : ''
  }

  const countryOptions = useCountryCodes(lcCC).map(({ label, value }) => ({
    label,
    value: value as string,
  }))

  const ageOptions = [
    {
      label: '',
      value: '',
    },
  ]
  for (let i = 3; i <= 10; i++) {
    ageOptions.push({
      label: `${i}`,
      value: `${i}`,
    })
  }

  const dayOptions = [
    {
      label: '',
      value: '',
    },
  ]
  for (let i = 1; i <= 31; i++) {
    dayOptions.push({
      label: `${i < 10 ? 0 : ''}${i}`,
      value: `${i < 10 ? 0 : ''}${i}`,
    })
  }

  const monthOptions = [
    {
      label: '',
      value: '',
    },
  ]
  for (let i = 1; i <= 12; i++) {
    monthOptions.push({
      label: `${i < 10 ? 0 : ''}${i}`,
      value: `${i < 10 ? 0 : ''}${i}`,
    })
  }

  const currentYear = new Date().getFullYear()
  const yearOptions = [
    {
      label: '',
      value: '',
    },
  ]
  for (let i = currentYear; i >= currentYear - 20; i--) {
    yearOptions.push({
      label: `${i}`,
      value: `${i}`,
    })
  }

  const createPhoneInputMessage = (
    phone: string
  ): { text: string } | undefined => {
    if (phone) {
      if (isValidPhoneNumber(phone)) {
        return undefined
      }
      if (!isValidPhoneNumber(phone)) {
        return {
          text: t('brazeForm:message.phone'),
        }
      }
    }
    return {
      text: t('brazeForm:hint.phone'),
    }
  }

  return (
    <Wrapper backgroundColor={backgroundColor} data-testid="braze-form">
      {formik.status === 'SUCCESS' && (
        <>
          {successHeadline && (
            <Spacing mb="spacing-s">
              <Headline2 asHTMLTag="p" align={align}>
                <Accent text={successHeadline} />
              </Headline2>
            </Spacing>
          )}
          {typeof successRichtext === 'string' ? (
            <Paragraph size={1} align={align}>
              {successRichtext}
            </Paragraph>
          ) : (
            <RichText size={1} align={align} document={successRichtext} />
          )}
        </>
      )}

      {formik.status === 'IDLE' && (
        <>
          {headline && (
            <Spacing mb="spacing-s">
              <Headline2 asHTMLTag={headlineAsHTMLTag} align={align}>
                <Accent text={headline} />
              </Headline2>
            </Spacing>
          )}

          {richtext &&
            (typeof richtext === 'string' ? (
              <Paragraph size={1} align={align}>
                {richtext}
              </Paragraph>
            ) : (
              <RichText size={1} align={align} document={richtext} />
            ))}

          <FormikProvider value={formik}>
            <FormWrapper
              align={align}
              onSubmit={formik.handleSubmit}
              noValidate
              data-testid="braze-form-wrapper"
            >
              {variant === 'Sweepstake' && eventLabel && (
                <Field
                  as={InputField}
                  label={eventLabel}
                  message={
                    getFormikError('eventMessage')
                      ? {
                          text: getFormikError('eventMessage'),
                        }
                      : undefined
                  }
                  name="eventMessage"
                  status={getFormikError('eventMessage') ? 'error' : undefined}
                  value={formik.values.eventMessage}
                />
              )}

              {hasFirstNameInput && (
                <Field
                  as={InputField}
                  isOptional
                  label={t('brazeForm:label.firstName')}
                  name="firstName"
                  optionalHint={t('brazeForm:label.optional')}
                  value={formik.values.firstName}
                />
              )}

              {hasLastNameInput && (
                <Field
                  as={InputField}
                  isOptional
                  label={t('brazeForm:label.lastName')}
                  name="lastName"
                  optionalHint={t('brazeForm:label.optional')}
                  value={formik.values.lastName}
                />
              )}

              <Field
                as={InputField}
                label={t('brazeForm:label.email')}
                message={
                  getFormikError('email')
                    ? {
                        text: getFormikError('email'),
                      }
                    : undefined
                }
                name="email"
                status={getFormikError('email') ? 'error' : undefined}
                type="email"
                value={formik.values.email}
              />

              {hasPhoneInput && (
                <Field
                  as={PhoneInput}
                  inputComponent={InputField}
                  label={t('brazeForm:label.phone')}
                  message={createPhoneInputMessage(formik.values.phone)}
                  name="phone"
                  onChange={(value?: E164Number) =>
                    formik.setFieldValue('phone', value || '')
                  }
                  optionalHint={t('brazeForm:label.optional')}
                  value={formik.values.phone}
                />
              )}

              {hasCountrySelect && (
                <Spacing mt="spacing-xs">
                  <Field
                    as={Select}
                    label={t('brazeForm:label.country')}
                    name="country"
                    options={countryOptions}
                    value={formik.values.country}
                  />
                </Spacing>
              )}

              {variant === 'Kids Birthdays' && (
                <FieldArray
                  name="children"
                  validateOnChange={false}
                  render={arrayHelpers => (
                    <Spacing mt="spacing-s">
                      {formik.values.children.map((child, index) => (
                        <Spacing mb="spacing-s" key={index}>
                          <Box
                            borderRadius={['s']}
                            borderColor={colors['lightblue-30']}
                          >
                            <Spacing mx="spacing-s" mb="spacing-s">
                              <RepeaterWrapper>
                                <Field
                                  as={InputField}
                                  isOptional
                                  label={t('brazeForm:label.childName')}
                                  name={`children[${index}].name`}
                                  optionalHint={t('brazeForm:label.optional')}
                                  value={child.name}
                                />
                                <DataOfBirthParagraph size={2}>
                                  {t('brazeForm:label.dateOfBirth')}*
                                </DataOfBirthParagraph>
                                <BirthdayWrapper>
                                  <Field
                                    as={Select}
                                    label={t('brazeForm:label.day')}
                                    message={
                                      getFormikError(`children[${index}].day`)
                                        ? {
                                            text: getFormikError(
                                              `children[${index}].day`
                                            ),
                                          }
                                        : undefined
                                    }
                                    name={`children[${index}].day`}
                                    status={
                                      getFormikError(`children[${index}].day`)
                                        ? 'error'
                                        : undefined
                                    }
                                    value={child.day}
                                    options={dayOptions}
                                  />
                                  <Field
                                    as={Select}
                                    label={t('brazeForm:label.month')}
                                    message={
                                      getFormikError(`children[${index}].month`)
                                        ? {
                                            text: getFormikError(
                                              `children[${index}].month`
                                            ),
                                          }
                                        : undefined
                                    }
                                    name={`children[${index}].month`}
                                    status={
                                      getFormikError(`children[${index}].month`)
                                        ? 'error'
                                        : undefined
                                    }
                                    value={child.month}
                                    options={monthOptions}
                                  />
                                  <Field
                                    as={Select}
                                    label={t('brazeForm:label.year')}
                                    message={
                                      getFormikError(`children[${index}].year`)
                                        ? {
                                            text: getFormikError(
                                              `children[${index}].year`
                                            ),
                                          }
                                        : undefined
                                    }
                                    name={`children[${index}].year`}
                                    status={
                                      getFormikError(`children[${index}].year`)
                                        ? 'error'
                                        : undefined
                                    }
                                    value={child.year}
                                    options={yearOptions}
                                  />
                                </BirthdayWrapper>
                                <Paragraph size={4}>
                                  * {t('brazeForm:hint.ddmmyyyy')}
                                </Paragraph>
                                {formik.values.children.length > 1 && (
                                  <IconButton
                                    icon="delete1"
                                    fillColor="transparent"
                                    borderColor="transparent"
                                    iconColor={colors.primary}
                                    onClick={() => arrayHelpers.remove(index)}
                                    size="small"
                                  />
                                )}
                              </RepeaterWrapper>
                            </Spacing>
                          </Box>
                        </Spacing>
                      ))}
                      <Spacing mb="spacing-s">
                        <CTARow justifyContent="center">
                          {/* @TODO: third cta variant */}
                          <OmniButton
                            isCoupon
                            onClick={() =>
                              arrayHelpers.push({
                                name: '',
                                day: '',
                                month: '',
                                year: '',
                              })
                            }
                          >
                            {t('brazeForm:label.addChild')}
                          </OmniButton>
                        </CTARow>
                      </Spacing>
                    </Spacing>
                  )}
                />
              )}

              {variant === 'AI Story Requests' && (
                <FieldArray
                  name="aiStoryRequests"
                  validateOnChange={false}
                  render={arrayHelpers => (
                    <Spacing mt="spacing-s">
                      {formik.values.aiStoryRequests.map(
                        (aiStoryRequest, index) => (
                          <Spacing mb="spacing-s" key={index}>
                            <Box
                              borderRadius={['s']}
                              borderColor={colors['lightblue-30']}
                            >
                              <Spacing mx="spacing-s" mb="spacing-s">
                                <RepeaterWrapper>
                                  <Field
                                    as={InputField}
                                    label={t('brazeForm:label.firstName')}
                                    message={
                                      getFormikError(
                                        `aiStoryRequests[${index}].name`
                                      )
                                        ? {
                                            text: getFormikError(
                                              `aiStoryRequests[${index}].name`
                                            ),
                                          }
                                        : undefined
                                    }
                                    name={`aiStoryRequests[${index}].name`}
                                    status={
                                      getFormikError(
                                        `aiStoryRequests[${index}].name`
                                      )
                                        ? 'error'
                                        : undefined
                                    }
                                    value={aiStoryRequest.name}
                                  />
                                  {hasAgeSelect && (
                                    <Field
                                      as={Select}
                                      label={t('brazeForm:label.age')}
                                      message={
                                        getFormikError(
                                          `aiStoryRequests[${index}].age`
                                        )
                                          ? {
                                              text: getFormikError(
                                                `aiStoryRequests[${index}].age`
                                              ),
                                            }
                                          : undefined
                                      }
                                      name={`aiStoryRequests[${index}].age`}
                                      status={
                                        getFormikError(
                                          `aiStoryRequests[${index}].age`
                                        )
                                          ? 'error'
                                          : undefined
                                      }
                                      options={ageOptions}
                                      value={aiStoryRequest.age}
                                    />
                                  )}
                                  <Field
                                    as={InputField}
                                    label={t('brazeForm:label.tonie1')}
                                    message={
                                      getFormikError(
                                        `aiStoryRequests[${index}].tonie1`
                                      )
                                        ? {
                                            text: getFormikError(
                                              `aiStoryRequests[${index}].tonie1`
                                            ),
                                          }
                                        : undefined
                                    }
                                    name={`aiStoryRequests[${index}].tonie1`}
                                    status={
                                      getFormikError(
                                        `aiStoryRequests[${index}].tonie1`
                                      )
                                        ? 'error'
                                        : undefined
                                    }
                                    value={aiStoryRequest.tonie1}
                                  />
                                  {hasOptionalTonies && (
                                    <>
                                      <Field
                                        as={InputField}
                                        isOptional
                                        label={t('brazeForm:label.tonie2')}
                                        name={`aiStoryRequests[${index}].tonie2`}
                                        optionalHint={t(
                                          'brazeForm:label:optional'
                                        )}
                                        value={aiStoryRequest.tonie2}
                                      />
                                      <Field
                                        as={InputField}
                                        isOptional
                                        label={t('brazeForm:label.tonie3')}
                                        name={`aiStoryRequests[${index}].tonie3`}
                                        optionalHint={t(
                                          'brazeForm:label.optional'
                                        )}
                                        value={aiStoryRequest.tonie3}
                                      />
                                    </>
                                  )}
                                  {formik.values.aiStoryRequests.length > 1 && (
                                    <IconButton
                                      icon="delete1"
                                      fillColor="transparent"
                                      borderColor="transparent"
                                      iconColor={colors.primary}
                                      onClick={() => arrayHelpers.remove(index)}
                                      size="small"
                                    />
                                  )}
                                </RepeaterWrapper>
                              </Spacing>
                            </Box>
                          </Spacing>
                        )
                      )}
                      <Spacing mb="spacing-m">
                        <CTARow justifyContent="center">
                          {/* @TODO: third cta variant */}
                          <OmniButton
                            isCoupon
                            onClick={() =>
                              arrayHelpers.push({
                                name: '',
                                age: '',
                                tonie1: '',
                                tonie2: '',
                                tonie3: '',
                              })
                            }
                          >
                            {t('brazeForm:label.addAiStory')}
                          </OmniButton>
                        </CTARow>
                      </Spacing>
                    </Spacing>
                  )}
                />
              )}

              <Spacing mt="spacing-s">
                <CTARow justifyContent={align}>
                  <Button type="submit">
                    {ctaText || t('brazeForm:label.submit')}
                  </Button>
                </CTARow>
              </Spacing>
              <Spacing mt="spacing-s">
                <Paragraph size={4}>
                  {t(
                    `brazeForm:consent.${
                      hasPhoneInput ? 'phone.' : ''
                    }withCtaLabel`,
                    {
                      button: ctaText || t('brazeForm:label.submit'),
                    }
                  )}{' '}
                  <a href={ppPageUrl} target="_blank" rel="noopener noreferrer">
                    {t('brazeForm:consent.privacyPolicy')}
                  </a>
                  .
                  {tcPageUrl && (
                    <>
                      {' '}
                      <a
                        href={tcPageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('brazeForm:consent.termsAndConditions')}
                      </a>
                      .
                    </>
                  )}
                  {consentAppendedText && <> {consentAppendedText}</>}
                </Paragraph>
              </Spacing>
            </FormWrapper>
          </FormikProvider>
        </>
      )}
    </Wrapper>
  )
}

export const BrazeForm = withGTMV4Context(BrazeFormContainer, 'BrazeForm')
