import React, { ComponentProps, FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { Icon, media } from '@boxine/tonies-ui'
import { CountIndicator } from '@/tonies-ui/atoms/CountIndicator'

const Wrapper = styled.span`
  ${({ theme: { colors } }) => css`
    display: block;
    position: relative;
    color: ${colors['darkergrey']};

    &:hover {
      color: ${colors.primary};

      .count-indicator-selector {
        background-color: ${colors['primary-hover']};
      }
    }
  `}
`

const StyledCountIndicator = styled(CountIndicator)<{
  position: 'left' | 'right'
}>`
  ${({ position }) => css`
    position: absolute;
    top: -0.25rem;
    ${position}: -0.5rem;

    ${media.laptop`
      top: -0.5rem;
      ${position}: -0.75rem;
    `}
  `}
`

const StyledIcon = styled(Icon)<{ isDisabled: boolean }>`
  ${props =>
    props.isDisabled &&
    css`
      color: ${props.theme.DirtyWhiteDarker};
      cursor: not-allowed;
    `}

  height: auto;
  width: 1.5rem;
`

export interface IconWithCountProps {
  count: number
  dataTestIdCount?: string
  iconType: ComponentProps<typeof Icon>['type']
  isAnimated?: boolean
  isDisabled?: boolean
  statusMessage: string
  countPosition?: 'left' | 'right'
}

export const IconWithCount: FunctionComponent<IconWithCountProps> = ({
  count,
  dataTestIdCount,
  iconType,
  isAnimated = false,
  isDisabled = false,
  statusMessage,
  countPosition = 'right',
}) => (
  <Wrapper>
    <StyledIcon isDisabled={isDisabled} type={iconType} title={statusMessage} />
    {!isDisabled && (
      <StyledCountIndicator
        isAnimated={isAnimated}
        count={count}
        dataTestId={dataTestIdCount}
        statusMessage={statusMessage}
        position={countPosition}
      />
    )}
  </Wrapper>
)
