import { Headline3, Headline4 } from '@boxine/tonies-ui'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'

export type Size = 'small' | 'large'

export type BadgeProps = {
  size: Size
}

const OuterCircle = styled.div<{ size: Size }>`
  background: ${({
    theme: {
      colors: { white },
    },
  }) => white};
  border-radius: 100%;
  height: ${p => (p.size === 'small' ? 6 : 12)}rem;
  width: ${p => (p.size === 'small' ? 6 : 12)}rem;
  padding: ${p => (p.size === 'small' ? 0.25 : 0.5)}rem;
  transform: rotateZ(17deg);
  transform-origin: center;
`

const InnerCircle = styled.div<{ size: Size }>`
  align-content: center;
  border: ${p => (p.size === 'small' ? 2 : 3)}px solid
    ${({
      theme: {
        colors: { darkergrey },
      },
    }) => darkergrey};
  border-radius: 100%;
  display: grid;
  height: 100%;
  line-height: 100%;
  justify-content: center;
  padding: ${p => (p.size === 'small' ? 0.25 : 0.5)}rem;
  width: 100%;

  h3,
  h4 {
    line-height: 100%;
  }
`

export const Badge: FunctionComponent<BadgeProps & PropsWithChildren> = ({
  children,
  size,
}) => (
  <OuterCircle size={size}>
    <InnerCircle size={size}>
      {size === 'small' ? (
        <Headline4 align="center" color="darkergrey" weight={500}>
          {children}
        </Headline4>
      ) : (
        <Headline3 align="center" color="darkergrey" weight={500}>
          {children}
        </Headline3>
      )}
    </InnerCircle>
  </OuterCircle>
)
