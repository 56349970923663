import React, { useMemo } from 'react'
import { useProductOverview } from '../../../../providers/productOverview'
import styled, { css, useTheme } from 'styled-components'
import { Icon, Paragraph, media, useIsViewportMobile } from '@boxine/tonies-ui'
import { useFilterOverlay } from '../hooks/useFilterOverlay'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import { TopFilterTags } from './TopFilterTags'
import { ActiveFilterTags } from './ActiveFilterTags'
import { TagList } from '@/tonies-ui/molecules/TagList'
import { useTranslation } from 'next-i18next'
import { CountIndicator } from '@/tonies-ui/atoms/CountIndicator'
import { withGTMV4Context } from '../../../../providers/gtmV4'
import { Box } from '@/tonies-ui/atoms/Box'

const Wrapper = styled(Box)`
  // needed for sticky behavior
  display: inline;
  overflow: inherit;
`

const TagWrapper = styled(Box)<{ $isSticky: boolean }>`
  ${({ $isSticky }) => css`
    width: calc(100% - 2.5rem);
    display: inline-block;

    ${media.tablet`
      width: calc(100% - 3rem);
    `}

    // 80rem (max-content + 2rem padding + 3rem StickyWrapper width)
    @media (min-width: 80rem) {
      width: calc(100% - 0.5rem ${!$isSticky && ' - 3rem'});
    }
  `}
`

const StickyWrapper = styled(Box)<{ $isSticky: boolean; $offset?: number }>`
  ${({ $isSticky, $offset = 0 }) => css`
    float: right;
    display: inline-grid;
    margin-right: -1rem;
    margin-top: 0.25rem; // floating fix

    ${$isSticky &&
    css`
      position: sticky;
      z-index: 1;
      top: ${$offset}px;

      @media (min-width: 75rem) {
        margin-right: calc((100vw - 75rem + 2rem) * -0.5);
      }
    `}
  `}
`

const StyledCountIndicator = styled(CountIndicator)`
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
`

const StyledButton = styled.button`
  ${cssReset}
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

export const FiltersContainer = ({
  isSticky,
  stickyOffset,
  hasFilters,
  hasSearch,
  hasSorting,
  hasTopFilters,
}: {
  isSticky: boolean
  stickyOffset: number
  hasFilters?: boolean
  hasSearch?: boolean
  hasSorting?: boolean
  hasTopFilters?: boolean
}) => {
  const {
    entries: { total },
    filter,
    search,
    resetAll,
  } = useProductOverview()
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [, handleFilter] = useFilterOverlay(hasFilters, hasSearch, hasSorting)
  const isMobile = useIsViewportMobile()

  const activeFilterCount = useMemo(
    () =>
      filter?.items
        .map(f => f.activeFilters.length)
        .reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue
          },
          !search?.value ? 0 : 1
        ),
    [search, filter?.items]
  )
  const hasReset = filter?.isFilterActive || search?.value
  const iconSize = isMobile ? '1rem' : '1.5rem'
  const iconFillColor = colors['darkergrey']

  if (!hasFilters && !hasSearch && !hasSorting) {
    return null
  }

  return (
    <Wrapper mb="spacing-s" applyToChildren data-testid="filter-infos-mainpage">
      <TagWrapper mb="spacing-s" applyToChildren $isSticky={isSticky}>
        <TagList>
          {hasTopFilters && (
            <TopFilterTags referenceTitle="[coded] TopFilterTags" />
          )}
          {(hasFilters || hasSearch) && (
            <ActiveFilterTags referenceTitle="[coded] ActiveFilterTags" />
          )}
        </TagList>
        <Paragraph
          asHTMLTag="div"
          weight={700}
          data-testid="filter-result-text"
          data-testextra={total}
        >
          {total === 1
            ? t('products:productFoundHint.one')
            : t('products:productFoundHint.other', {
                count: total,
              })}
        </Paragraph>
      </TagWrapper>
      <StickyWrapper
        backgroundColor="white"
        borderColor="lightgrey"
        borderRadius={['s', 0, 0, 's']}
        py="spacing-xs"
        pl="spacing-xs"
        $isSticky={isSticky}
        $offset={stickyOffset}
      >
        <StyledButton
          onClick={() => handleFilter(true)}
          data-testid="open-search-button"
        >
          <Box
            p="spacing-xs"
            pb="spacing-xxs"
            mb="spacing-xs"
            backgroundColor="lightgrey"
            borderRadius={['xs', 0, 0, 'xs']}
          >
            <Icon
              type="search"
              width={iconSize}
              height={iconSize}
              fill={iconFillColor}
            />
            <StyledCountIndicator
              count={search?.value ? 1 : 0}
              statusMessage="active search"
              isAnimated
            />
          </Box>
        </StyledButton>
        <StyledButton
          onClick={() => handleFilter(true)}
          data-testid="open-filter-button"
        >
          <Box
            p="spacing-xs"
            pb="spacing-xxs"
            mb={hasReset ? 'spacing-xs' : undefined}
            backgroundColor="lightgrey"
            borderRadius={['xs', 0, 0, 'xs']}
          >
            <Icon
              type="filter"
              width={iconSize}
              height={iconSize}
              fill={iconFillColor}
            />
            <StyledCountIndicator
              count={activeFilterCount || 0}
              statusMessage={`${activeFilterCount} active filters`}
              isAnimated
              dataTestId="filter-count"
            />
          </Box>
        </StyledButton>
        {hasReset && (
          <StyledButton
            onClick={resetAll}
            data-testid="delete-all-filters-button"
          >
            <Box
              // @ts-expect-error: 'style' is not supported on BOX
              style={{
                justifySelf: 'center',
              }}
            >
              <Icon type="delete1" width={iconSize} height={iconSize} />
            </Box>
          </StyledButton>
        )}
      </StickyWrapper>
    </Wrapper>
  )
}

export const Filters = withGTMV4Context(FiltersContainer, 'Filters')
