import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Spinner } from '@boxine/tonies-ui'

const SpinnerWrapper = styled.div<{ height: string }>`
  align-items: center;
  display: flex;
  height: ${({ height }) => height};
  justify-content: center;
`

export type ComponentSpinnerProps = {
  height?: string
  dataTestid?: string
}

export const ComponentSpinner: FunctionComponent<ComponentSpinnerProps> = ({
  height = '20rem',
  dataTestid = 'component-spinner',
}) => (
  <SpinnerWrapper data-testid={dataTestid} height={height}>
    <Spinner />
  </SpinnerWrapper>
)
