import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FocusEvent,
  forwardRef,
  KeyboardEventHandler,
  useCallback,
  useState,
} from 'react'
import { useTheme } from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import { Label } from '../components/Label'
import { Icon } from '@boxine/tonies-ui'
import { Message, MessageProps } from '../components/Message'
import { COLOR } from '../variables'
import { Styling, Status } from '../types'
import {
  DisabledWrapper,
  FieldWrapper,
  StatusWrapperInputField,
} from '../styles'

export type SelectProps = {
  /**
   * Autocomplete function (see all supported values here: https://devdocs.io/html/attributes/autocomplete)
   */
  autoComplete?: 'on' | 'off'

  /**
   * Component is disabled
   */
  isDisabled?: boolean

  /**
   * Component is optional
   */
  optionalHint?: string

  /**
   * Label for the select element. If you don't want to see the label visually,
   * set the label to a meaningful value nevertheless and use
   * `isLabelHidden={true}` so that assistive technologies (e.g. screen
   * readers) are still able to identify this select.
   */
  label: React.ReactNode

  /**
   * Message text (selection-dependent) to be displayed below the select.
   */
  message?: MessageProps

  /**
   * Should be unique. Allows to identify this unambiguously
   */
  name: string

  /**
   * Gets called with the event object when the blur event occurs
   */
  onBlur?: FocusEventHandler<HTMLSelectElement>

  /**
   * Gets called with the event object when the change event occurs
   */
  onChange?: ChangeEventHandler<HTMLSelectElement>

  /**
   * Gets called with the event object when the focus event occurs
   */
  onFocus?: FocusEventHandler<HTMLSelectElement>

  /**
   * The keydown event fires when the user presses a keyboard key.
   */
  onKeyDown?: KeyboardEventHandler<HTMLSelectElement>

  /**
   * Status (selection-dependent) which modifies the color and the indicator icon.
   */
  status?: Status

  /**
   * Overwrite the internal data-testid of the select component for testing purposes.
   */
  dataTestIdSelect?: string

  /**
   * Overwrite the internal data-testid of the label for testing purposes.
   */
  dataTestIdLabel?: string

  /**
   * Overwrite the internal data-testid of the message for testing purposes.
   */
  dataTestIdMessage?: string

  /**
   * Maximum length of characters allowed
   */
  maxLength?: number

  /**
   * The value of the selected option
   */
  value: string

  /**
   * Optional hint text
   */
  hint?: string

  /**
   * Color scheme; default "primary", use "secondary" on vivid backgrounds
   */
  styling?: Styling

  /**
   * Array with possible options to select from
   */
  options: Array<{
    value: string
    label: string
  }>

  /**
   * Component label is only shown for assistive technologies (e.g. screen readers).
   */
  isLabelHidden?: boolean
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      autoComplete = 'on',
      dataTestIdSelect = 'select',
      dataTestIdLabel = 'select-label',
      dataTestIdMessage = 'select-message',
      hint,
      isDisabled = false,
      isLabelHidden = false,
      optionalHint = undefined,
      label,
      message,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      status,
      styling: stylingProp,
      value,
      options,
    },
    ref
  ) => {
    const selectId = `select-id-${name}`
    const messageId = `select-message-id-${name}`
    const hintId = `select-hint-id-${name}`
    const [hasFloatingLabel, setHasFloatingLabel] = useState(value === '')
    const theme = useTheme()

    const styling =
      stylingProp || (theme.colorContext === 'dark' ? 'secondary' : 'primary')

    const handleLabelOnFocus = useCallback(
      (event: FocusEvent<HTMLSelectElement>) => {
        setHasFloatingLabel(false)
        if (onFocus) {
          onFocus(event)
        }
      },
      [setHasFloatingLabel, onFocus]
    )

    const handleLabelOnBlur = useCallback(
      (event: FocusEvent<HTMLSelectElement>) => {
        setHasFloatingLabel(value === '' ? true : false)

        if (onBlur) {
          onBlur(event)
        }
      },
      [setHasFloatingLabel, onBlur, value]
    )

    return (
      <DisabledWrapper
        data-testid="select-disabled-wrapper"
        isDisabled={isDisabled}
      >
        <Label
          data-testid={dataTestIdLabel}
          htmlFor={selectId}
          isFloating={hasFloatingLabel}
          styling={styling}
          isLabelHidden={isLabelHidden}
        >
          {label}
          {optionalHint && (
            <span data-testid="select-label-optional"> ({optionalHint})</span>
          )}
          {hint && <span data-testid="select-label-hint">*</span>}
        </Label>
        <FieldWrapper
          data-testid="select-field-wrapper"
          isDisabled={isDisabled}
          status={status}
          styling={styling}
          isSelect
        >
          <Icon
            type={icons.arrowDown}
            fill={
              styling === 'primary' || styling === undefined
                ? theme.colors['darkergrey']
                : theme.colors['white']
            }
            height={32}
            width={32}
          />
          <select
            aria-describedby={
              message?.text && !message.ariaLive ? messageId : ''
            }
            aria-invalid={status === 'error'}
            autoComplete={autoComplete}
            data-testid={dataTestIdSelect}
            disabled={isDisabled}
            id={selectId}
            name={name}
            onBlur={handleLabelOnBlur}
            onChange={onChange}
            onFocus={handleLabelOnFocus}
            onKeyDown={onKeyDown}
            ref={ref}
            required={!optionalHint}
            value={value}
          >
            {options.map(option => (
              <option
                value={option.value}
                key={option.value}
                disabled={!optionalHint && option.value === ''}
              >
                {option.label}
              </option>
            ))}
          </select>
        </FieldWrapper>
        {(status || message) && (
          <StatusWrapperInputField>
            {message && (
              <Message
                styling={styling}
                message={message}
                status={status}
                dataTestId={dataTestIdMessage}
                id={messageId}
              />
            )}
            {status === 'error' && (
              <Icon type={icons.exclamationMark} fill={COLOR.ERROR} />
            )}
            {status === 'ok' && (
              <Icon type={icons.checkmark} fill={COLOR.SUCCESS} />
            )}
          </StatusWrapperInputField>
        )}
        {hint && (
          <StatusWrapperInputField>
            {hint && (
              <Message
                message={{ text: '* ' + hint }}
                dataTestId="select-hint"
                id={hintId}
              />
            )}
          </StatusWrapperInputField>
        )}
      </DisabledWrapper>
    )
  }
)

Select.displayName = 'Select'
