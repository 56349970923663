import React from 'react'
import { useTranslation } from 'next-i18next'
import { ProductOverviewErrorStates, Button } from '@boxine/tonies-ui'
import { useProductOverview } from '../../../../providers/productOverview'

export function NoProductsFound({
  causedBy = 'filter',
}: {
  causedBy: 'filter' | 'search' | 'no-products'
}) {
  const { t } = useTranslation()
  const { filter } = useProductOverview()

  if (causedBy === 'filter' && filter) {
    return (
      <ProductOverviewErrorStates
        data-testid="no-products-found-by-filter-state"
        headline={t('NoProductsByFilter:headline')}
        text={t('NoProductsByFilter:text')}
      >
        <Button onClick={() => filter.resetAll()}>
          {t('NoProductsByFilter:resetFilter')}
        </Button>
      </ProductOverviewErrorStates>
    )
  } else if (causedBy === 'search') {
    return (
      <ProductOverviewErrorStates
        data-testid="no-products-found-by-search-state"
        headline={t('NoProductsBySearch:headline')}
        text={t('NoProductsBySearch:text')}
      />
    )
  } else {
    return (
      <ProductOverviewErrorStates
        data-testid="no-products-found-empty-set"
        headline={t('NoProductsEmptySet:headline')}
        text={t('NoProductsEmptySet:text')}
      />
    )
  }
}
