import { useMemo } from 'react'

// Adds promotion objects to products when they are present
export const useInsertPromotionTeasers = (
  products: NormalizedProduct[],
  promotions: Promotion[],
  isFilterActive: boolean
) =>
  useMemo(() => {
    const modifiedProducts: NormalizedCardEntry[] = [...products]

    if (!isFilterActive && promotions && promotions.length > 0) {
      promotions.sort((a, b) => {
        if (a.position < b.position) {
          return -1
        }
        return 1
      })
      for (const promotion of promotions) {
        modifiedProducts.splice(promotion.position - 1, 0, promotion)
      }
    }
    return modifiedProducts
  }, [products, promotions, isFilterActive])
