import styled from 'styled-components'
import { Box, Paragraph } from '@boxine/tonies-ui'
import { mainFirstChildPaddingTop } from '@/tonies-ui/helpers/css-helper'

export const Wrapper = styled(Box)<{
  backgroundColor: string
}>`
  main > &:first-child {
    ${mainFirstChildPaddingTop}
  }
`

export const FormWrapper = styled.form<{ align: 'center' | 'left' }>`
  margin: 0 ${p => (p.align === 'center' ? 'auto' : '0')};
  width: 100%;
  max-width: 24rem;
`

export const RepeaterWrapper = styled.div`
  position: relative;
  button {
    position: absolute;
    right: -0.5rem;
    top: 0.25rem;
  }
`

export const DataOfBirthParagraph = styled(Paragraph)`
  margin-top: 1rem;
  margin-bottom: -0.5rem;
`

export const BirthdayWrapper = styled.div`
  display: flex;
  gap: 1rem;
  > div {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
  }
`
