import styled from 'styled-components'

export const Container = styled.section<{ isFocused?: boolean }>`
  border: 0;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  /**
     * .5rem to offset progress bar which somehow is still part of
     * the parent flex measurements...
     */
  transition: border-radius 0.5s;
  transition-delay: 0.5s;
  animation: ${({ isFocused, theme }) => {
    return isFocused
      ? `${theme.animation.heartBeat.duration} ${theme.animation.heartBeat.timingFunction} ${theme.animation.heartBeat.delay} heartBeat`
      : 'none'
  }};
  @keyframes heartBeat {
    ${({ theme }) => theme.animation.heartBeat.keyframes}
  }
`
