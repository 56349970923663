import React from 'react'
import Head from 'next/head'
import { BlogPostLayoutSchema } from '../../../../lib/contentful/datamodel/layouts'
import { shopConfig } from '../../../../config/shop'

type StructuredDataBlogPostingProps = {
  description: string
  imageUrl: string
  pageUrl: string
  post: Pick<BlogPostLayoutSchema, 'author' | 'date'>
  title: string
}

export const StructuredDataBlogPosting = ({
  description,
  imageUrl,
  post,
  title,
  pageUrl,
}: StructuredDataBlogPostingProps) => {
  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pageUrl,
    },
    headline: title,
    description: description,
    image: imageUrl,
    author: {
      '@type': 'Organization',
      name: post.author?.name || 'Tonies SE',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Tonies SE',
      logo: {
        '@type': 'ImageObject',
        url: shopConfig.assets.toniesLogo,
      },
    },
    datePublished: post.date,
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        data-testid="structured-data__blogposting"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />
    </Head>
  )
}
