import React, { useMemo } from 'react'
import { useCompatibleContentTonies } from '../../../hooks/useCompatibleContentTonies'
import {
  CompatibleToniesProductCardCarouselView,
  CompatibleToniesProductCardCarouselViewProps,
} from './components/View'
import { useProducts } from '../../../hooks/useProducts'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'

type CompatibleToniesProductCardCarouselProps = Omit<
  CompatibleToniesProductCardCarouselViewProps,
  'contentError'
>

/**
 * takes current productId and salesIds of compatible items
 * and uses a `ProductCardCarousel` to display the resolved items.
 * The `currentProductId` is filtered from the result set.
 *
 * during request and if no items are found, genereic Skeletons are shown
 */
export const CompatibleToniesProductCardCarousel = ({
  product,
  showHeadline = true,
  actionAdd2CartCallback,
  columns,
  showOutOfStock,
}: CompatibleToniesProductCardCarouselProps) => {
  const {
    commonPages: { creativeToniesPage },
  } = useAggregatedShopLocale()
  const { compatibleTonies, state } = useCompatibleContentTonies(
    product.salesId
  )

  const contentError =
    !compatibleTonies && (state === 'idle' || state === 'error')

  const isAssignableToCreativeTonies = product.assignableToAllCreativeTonies
  const showFallback = contentError && isAssignableToCreativeTonies

  const { products: creativeTonies } = useProducts({
    hasClientFetch: showFallback,
    parameter: {
      limit: 10,
      categoryKey: ['creative-tonies'],
      sort: { sortOutOfStockToTheEnd: true },
    },
  })

  const items = useMemo(
    () => (showFallback ? creativeTonies : compatibleTonies),
    [compatibleTonies, creativeTonies, showFallback]
  )

  return (
    <CompatibleToniesProductCardCarouselView
      product={product}
      contentError={!isAssignableToCreativeTonies && contentError}
      products={items}
      showHeadline={showHeadline}
      actionAdd2CartCallback={actionAdd2CartCallback}
      columns={columns}
      showOutOfStock={showOutOfStock}
      showAllLink={showFallback ? `${creativeToniesPage?.slug}/` : undefined}
    />
  )
}
