import React from 'react'
import { useModalNotifications } from '../providers/ModalNotifications'
import { useHouseholdData } from '../providers/auth'
import { TunesValidationModal } from '../components/organisms/TunesValidationModal'
import { useGtmV4 } from '../providers/gtmV4'
import { GtmV4AudioContentEvent } from '../providers/gtmV4/types'

export const useAssignTune = (product: NormalizedProduct) => {
  const { getIsMyCompatibleTune } = useHouseholdData()
  const { setModal } = useModalNotifications()

  const { pushGtmV4Event } = useGtmV4()

  const handleClickAssign = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    getIsMyCompatibleTune(product.salesId).then(isCompatible =>
      !isCompatible
        ? setModal(
            <TunesValidationModal
              product={product}
              onClose={() => setModal(undefined)}
            />
          )
        : product.audioLibraryAssignUrl &&
          handleStartAssign(product.audioLibraryAssignUrl)
    )
  }

  const handleStartAssign = (redirectUrl: string) => {
    const trackingEvent: GtmV4AudioContentEvent = {
      eventType: 'audioContent',
      step: 'start_assign',
      item: product,
    }
    pushGtmV4Event(trackingEvent)
    window.location.href = redirectUrl
  }

  return {
    handleClickAssign,
  }
}
