import {
  AssistiveTechnologyOnly,
  Grid,
  GridArea,
  Headline3,
  Icon,
  Spacing,
  media,
} from '@boxine/tonies-ui'
import React, { ReactNode } from 'react'
import * as icons from '@boxine/tonies-ui/icons'
import styled, { css, useTheme } from 'styled-components'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import { TranslucentOverlay } from '@/tonies-ui/atoms/TranslucentOverlay'
import ReactFocusLock from 'react-focus-lock'
import { isToniesColor } from '@/tonies-ui/themes/colors'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { ToniesMotions } from '@/tonies-ui/motions'
import { ComponentSpinner } from '@/tonies-ui/atoms/ComponentSpinner'

const Panel = styled(ToniesMotions.SlideIt)<{
  $backgroundColor?: string
}>`
  ${({ theme: { colors }, $backgroundColor = 'white' }) => css`
    background: ${isToniesColor($backgroundColor)
      ? colors[$backgroundColor]
      : $backgroundColor};
    bottom: 0;
    overflow: auto;
    position: fixed;
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    z-index: 9999;
    right: 0;

    ${media.tablet`
      width: 23.5rem;
      z-index: 11;
      padding-top: 4rem;
    `}

    ${media.laptop`
      padding-top: 5rem;
    `}
  `}
`

const StyledButton = styled.button`
  ${cssReset}
  cursor: pointer;
`

const StyledHeadline = styled(Headline3)<{ isHidden: boolean }>`
  ${({ isHidden }) => isHidden && AssistiveTechnologyOnly}
`

export type SidebarOverlayProps = {
  'data-testid'?: string
  backgroundColor?: string
  headline: ReactNode
  id: string
  isCloseButtonHidden?: boolean
  isHeadlineHidden?: boolean
  isPending?: boolean
  onClose?: (type: 'x' | 'translucent-overlay') => void
  top?: number
}

export const SidebarOverlay: NormalizedFunctionComponent<
  SidebarOverlayProps & Styleable
> = ({
  backgroundColor,
  children,
  headline,
  id,
  isCloseButtonHidden = false,
  isPending = false,
  onClose = () => null,
  top = 0,
  'data-testid': dataTestId = id || 'sidebar-overlay',
  className,
  isHeadlineHidden = false,
}) => {
  const { colors } = useTheme()

  return (
    <>
      <TranslucentOverlay onClick={() => onClose('translucent-overlay')} />
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <ReactFocusLock autoFocus={true} returnFocus={true} as="aside">
        <Panel
          style={{ top }}
          $backgroundColor={backgroundColor}
          className={className}
          data-testid={dataTestId}
        >
          <>
            <Spacing m="spacing-s" asHtmlTag="header">
              <Grid>
                <GridArea
                  colStart={2}
                  colWidth={8}
                  contentAlignH="center"
                  contentAlignV="center"
                >
                  <StyledHeadline isHidden={isHeadlineHidden}>
                    {headline}
                  </StyledHeadline>
                </GridArea>
                <GridArea
                  colStart={10}
                  colWidth={2}
                  contentAlignH="end"
                  contentAlignV="center"
                >
                  {!isCloseButtonHidden && (
                    <StyledButton
                      onClick={() => onClose('x')}
                      data-testid="close-sidebar-overlay-button"
                    >
                      <Icon
                        type={icons.closeX}
                        width="1.5rem"
                        height="1.5rem"
                        fill={colors['darkergrey']}
                      />
                    </StyledButton>
                  )}
                </GridArea>
              </Grid>
            </Spacing>
            {!isPending ? children : <ComponentSpinner height="20rem" />}
          </>
        </Panel>
      </ReactFocusLock>
    </>
  )
}
