import { useEffect } from 'react'
import { useEcomLocale } from '../providers/locale'
import { normalizeProduct } from '../lib/commercetools/normalizers/normalizeProduct'
import { getProductsByAttributes } from '../lib/commercetools/requests/products/getProductsByAttributes'
import { useStateWhenMounted } from './useStateWhenMounted'

/**
 * retrieves all Products with the given `attributeValues` of the given `attributeKey`
 * - only one `attributeKey` per request
 * - currently used to resolve product data of compatible content Tonies
 * @param attributeKey single attribute like `Artikelnummer` for `salesId`
 * @param attributeValues list of values of selected Attribute key
 * @param limit max. number of products to request
 * @returns `NormalizedProduct[]` | `undefined`
 */
export const useMoreProductsFromAttributeValueList = (
  attributeKey: 'Artikelnummer',
  attributeValues: string[],
  limit?: number
) => {
  const lcCC = useEcomLocale()
  const [products, setProducts] = useStateWhenMounted<
    NormalizedProduct[] | undefined
  >(undefined)

  useEffect(() => {
    if (attributeKey && attributeValues) {
      getProductsByAttributes(
        'Artikelnummer',
        attributeValues,
        lcCC,
        limit
      ).then(response => {
        if (response.result === 'successful' && response.data.results) {
          const np = response.data.results
            .map(p => {
              return normalizeProduct(p, lcCC)
            })
            // sort ASC
            .sort((a, b) => {
              if (a.name.toUpperCase() < b.name.toUpperCase()) {
                return -1
              }
              if (a.name.toUpperCase() > b.name.toUpperCase()) {
                return 1
              }
              return 0
            })
            // sort sold out to the end
            .sort((a, b) => {
              if (
                a.availability.state === 'sold-out' &&
                b.availability.state !== 'sold-out'
              ) {
                return 1
              } else if (
                a.availability.state !== 'sold-out' &&
                b.availability.state === 'sold-out'
              ) {
                return -1
              }
              return 0
            })

          setProducts(np)
        }
      })
    }
  }, [lcCC, setProducts, attributeKey, attributeValues, limit])

  return products
}
