import { BxCart } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

type Request = {
  version: number
  actions: {
    action: 'addDiscountCode'
    code: string
  }[]
}

/**
 * Adds a discount to the given cart. If there is already a discount on the cart, it gets replaced.
 */
export const addDiscountCode = async (
  lcCC: EcomLocale,
  code: string,
  cartId: string,
  cartVersion: number
) =>
  fetch<Request, BxCart>({
    query: `/carts/${cartId}`,
    lcCC,
    method: 'post',
    request: {
      version: cartVersion,
      actions: [
        {
          action: 'addDiscountCode',
          code,
        },
      ],
    },
  })
