import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

/**
 * A safety wrapper around React's `useState()` which internally skips
 * `setState()` executions if the component that holds the state has
 * already unmounted.
 *
 * The background is that React raises errors when a component state is
 * updated after that component has unmounted. Such cases are typically
 * caused by long-running operations like data fetches that may complete
 * when the initiating component is no longer on the screen. While browsers
 * may tolerate these React errors and proceed, unit tests will always fail.
 */
export const useStateWhenMounted = <S>(
  initialState: S | (() => S)
): Readonly<[S, Dispatch<SetStateAction<S>>]> => {
  const isMounted = useRef(false)
  const [state, setState] = useState<S>(initialState)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  })

  const mountedSetState = useCallback(
    (update: SetStateAction<S>) => {
      if (isMounted.current) {
        setState(update)
      }
    },
    // S is missing in the dep array and
    //  is not accepted because it refers to a type
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState]
  )

  return useMemo(
    () => [state, mountedSetState] as const,
    [state, mountedSetState]
  )
}
