import { Spacing } from '@boxine/tonies-ui'
import { Expandable } from '../../atoms/Expandable'
import React, { ComponentProps, useState } from 'react'
import { StateIcon } from '../../../layouts/CheckoutLayout/components/StateIcon'
import { ProductCardCarousel } from '../../organisms/ProductCardCarousel'
import { useTranslation } from 'next-i18next'
import { CompatibleToniesProductCardCarousel } from '../CompatibleToniesProductCardCarousel'

export const CompatibleToniesExpandable = ({
  product,
  hasToggle = false,
  actionAdd2CartCallback,
  isValid = false,
}: {
  product: NormalizedProduct
  hasToggle?: boolean
  actionAdd2CartCallback?: ComponentProps<
    typeof ProductCardCarousel
  >['actionAdd2CartCallback']
  isValid?: boolean
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Expandable
      isExpanded={isOpen}
      onToggle={hasToggle ? () => setIsOpen(!isOpen) : undefined}
      headline={t('products:tunes:compatibleToniesHeadline')}
      rightSlot={<StateIcon isValid={isValid} />}
      data-testid="compatible-tonies-expandable"
    >
      {
        // @FIXME: workaround for layout break due to the Expandable__ContentWrapper "display: grid"
      }
      <div style={{ minWidth: 0 }}>
        <Spacing p="spacing-s">
          <CompatibleToniesProductCardCarousel
            product={product}
            showHeadline={false}
            showOutOfStock
            columns={{ desktop: 3, mobile: 1.5 }}
            actionAdd2CartCallback={actionAdd2CartCallback}
          />
        </Spacing>
      </div>
    </Expandable>
  )
}
