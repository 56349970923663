import React, { ChangeEvent, FunctionComponent, useCallback } from 'react'
import { LabelSelectorProps } from './types'
import { SelectV3 } from '@boxine/tonies-ui'

export const LabelSelector: FunctionComponent<LabelSelectorProps> = ({
  label,
  variants,
  activeVariantId,
  onChange,
  uniqueInputName,
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      onChange(e.currentTarget.value)
    },
    [onChange]
  )

  return (
    <SelectV3
      name={uniqueInputName}
      label={label}
      onChange={handleChange}
      value={activeVariantId}
      options={variants.map(variant => ({
        label: variant.label,
        value: variant.id,
      }))}
    />
  )
}
