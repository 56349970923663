import React, { FunctionComponent, ComponentProps } from 'react'
import { CloudinaryVideoPlayer as CloudinaryVideoPlayerComp } from '@/tonies-ui/molecules/CloudinaryVideoPlayer'
import { homepageUrl } from '../../../utils/urlHelpers'
import { useLocale } from '../../../providers/locale'

export const CloudinaryVideoPlayer: FunctionComponent<
  SetOptional<
    ComponentProps<typeof CloudinaryVideoPlayerComp>,
    'logoOnclickUrl'
  >
> = ({ logoOnclickUrl, ...rest }) => {
  const lcCC = useLocale()

  return (
    <CloudinaryVideoPlayerComp
      logoOnclickUrl={logoOnclickUrl || homepageUrl(lcCC)}
      {...rest}
    />
  )
}
