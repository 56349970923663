import React, { FunctionComponent, useCallback, useContext } from 'react'
import { CarouselContext } from 'pure-react-carousel'
import styled from 'styled-components'

const Impl = styled.span<{ isSelected: boolean }>`
  cursor: pointer;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.625rem;
  margin: 0 0.3125rem;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary : theme.colors.middlegrey};
`

export const Dot: FunctionComponent<{ index: number }> = ({ index }) => {
  const context = useContext(CarouselContext)
  const { currentSlide, visibleSlides, totalSlides } = context.getStoreState()
  const isSelected =
    index >= currentSlide && index < currentSlide + visibleSlides

  const handleClick = useCallback(() => {
    context.setStoreState({
      currentSlide: Math.min(index, totalSlides - visibleSlides),
    })
  }, [context, index, totalSlides, visibleSlides])

  return <Impl isSelected={isSelected} onClick={handleClick} />
}
