import { useColorContextConfig } from '@/tonies-ui/hooks/useColorContextConfig'
import { Icon, Paragraph, ParagraphProps } from '@boxine/tonies-ui'
import { Box } from '@/tonies-ui/atoms/Box'
import { BoxProps } from '@boxine/tonies-ui/dest/types/src/atoms/Box'
import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'

const StyledBox = styled(Box)`
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  margin-left: 0.25rem;
`

export type TagProps = {
  isActive?: boolean
} & Partial<BoxProps>

export const Tag: FunctionComponent<TagProps> = ({
  isActive,
  children,
  ...rest
}) => {
  const { colors } = useTheme()
  const config = useColorContextConfig<{
    bg: { [key in 'default' | 'active']: BoxProps['backgroundColor'] }
    text: {
      default: ParagraphProps['color']
      active: NonNullable<ParagraphProps['color']>
    }
  }>({
    dark: {
      bg: {
        default: 'white',
        active: 'white',
      },
      text: {
        default: undefined,
        active: 'primary',
      },
    },
    light: {
      bg: {
        default: 'middlegrey',
        active: 'primary',
      },
      text: {
        default: undefined,
        active: 'white',
      },
    },
  })

  return (
    <StyledBox
      asHtmlTag="span"
      backgroundColor={config.bg[isActive ? 'active' : 'default']}
      borderColor={config.bg[isActive ? 'active' : 'default']}
      borderRadius={['s']}
      px="spacing-xs"
      py="spacing-xxs"
      {...rest}
    >
      <Paragraph
        asHTMLTag="span"
        size={3}
        weight={isActive ? 500 : 300}
        color={config.text[isActive ? 'active' : 'default']}
      >
        {children}
      </Paragraph>
      {isActive && (
        <StyledIcon
          fill={colors[config.text.active]}
          height="0.625rem"
          type="closeX"
          width="0.625rem"
        />
      )}
    </StyledBox>
  )
}
