import React, { FunctionComponent } from 'react'
import {
  MetaNavigation as ToniesUiMetaNavigation,
  MetaNavigationLinkExternal,
  MetaNavIcon,
} from '@boxine/tonies-ui'
import { useRouter } from 'next/router'
import { IconJson } from '@boxine/tonies-ui/icons'
import styled from 'styled-components'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { LocaleSelectorButton } from './components/LocaleSelectorButton'

const Wrapper = styled.div`
  position: relative;
  /* Avoid z-fighting issue on iOS with the header */
  z-index: 999;
`

const StyledSpan = styled.span`
  strong {
    font-weight: 700;
  }
`

export interface MetaNavigationItemProps {
  link: string
  text: string
  icon?: IconJson
}

export interface MetaNavigationProps {
  metaNavigationItems: Array<MetaNavigationItemProps>
}

export const isRelative = (parent: URL, child: URL) =>
  child.toString().startsWith(parent.toString())

export const MetaNavigationContainer: FunctionComponent<
  MetaNavigationProps
> = ({ metaNavigationItems }) => {
  const { pushGtmV4Event } = useGtmV4()
  const basePath = process.env.NEXT_PUBLIC_BASE_URL || ''
  const pageUrl = new URL(useRouter()?.asPath, basePath)

  return (
    <Wrapper>
      <ToniesUiMetaNavigation dataTestId="meta-navigation">
        {metaNavigationItems.map(item => (
          <MetaNavigationLinkExternal
            key={item.link + item.text}
            href={item.link}
            isActive={isRelative(new URL(item.link, basePath), pageUrl)}
            onClick={() =>
              pushGtmV4Event({
                eventType: 'click',
                type: 'link',
                label: item.text,
                link: item.link,
              })
            }
          >
            {item.icon && <MetaNavIcon type={item.icon} />}
            <StyledSpan dangerouslySetInnerHTML={{ __html: item.text }} />
          </MetaNavigationLinkExternal>
        ))}
        <LocaleSelectorButton position="metaNavigation" />
      </ToniesUiMetaNavigation>
    </Wrapper>
  )
}

export const MetaNavigation = withGTMV4Context(
  MetaNavigationContainer,
  'MetaNavigation'
)
