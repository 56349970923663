import styled, { css } from 'styled-components'
import { media } from '@boxine/tonies-ui'
import { ProductCollectionProps } from '../types'

export const Layout = styled.div<{
  cardOrientation?: ProductCollectionProps['cardOrientation']
  columns: number
}>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(${p => p.columns}, 1fr);
  font-size: 1rem;
  width: 100%;

  ${({ cardOrientation }) =>
    cardOrientation !== 'landscape' &&
    css`
      row-gap: 2rem;

      ${media.tablet`
        column-gap: 2rem;
      `}

      @media (min-width: 32rem) {
        font-size: 1.25rem;
      }
    `}
`
