import { useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import makeDebug from 'debug'
import { getProducts } from '../lib/opensearch/requests/products/getProducts'
import { useEcomLocale } from '../providers/locale'
import isEqual from 'lodash/isEqual'
import { useGtmV4 } from '../providers/gtmV4'

const debug = makeDebug('hooks:useProducts')

type useProductsProps = {
  initialState?: NormalizedOpenSearchProductsResponse
  hasClientFetch?: boolean
  parameter?: Partial<NormalizedOpenSearchParameters>
  hasLogging?: boolean
}

export const areOpenSearchParametersEqual = (
  prevParams: Partial<NormalizedOpenSearchParameters> | undefined = {},
  newParams: Partial<NormalizedOpenSearchParameters> | undefined = {},
  hasLogging = false,
  context = ''
) => {
  const { sort: prevSort, ...restPrev } = prevParams
  const { sort: clientSort, ...restNew } = newParams

  const isDeepEqual =
    isEqual(restPrev, restNew) && isEqual(prevSort, clientSort)

  if (!isDeepEqual && hasLogging) {
    debug(`🔁 params changed (for ${context})`, {
      0: prevParams,
      1: newParams,
      'deep equal': isDeepEqual,
      'sort equal': isEqual(prevSort, clientSort),
      'rest equal': isEqual(restPrev, restNew),
    })
  }

  return isDeepEqual
}

export function useProducts({
  initialState,
  hasClientFetch = true,
  parameter,
  hasLogging = false,
}: useProductsProps) {
  const lcCC = useEcomLocale()
  const { componentName } = useGtmV4()
  const [state, setState] = useState<'initial' | 'client'>('initial')
  const [response, setResponse] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const prevClientParameter = useRef(initialState?.parameter)

  const clientParameter = useMemo(
    () => ({
      ...initialState?.parameter,
      ...parameter,
      lcCC: initialState?.parameter?.lcCC || lcCC,
    }),
    [initialState?.parameter, lcCC, parameter]
  )

  useEffect(() => {
    if (
      !hasClientFetch ||
      areOpenSearchParametersEqual(
        prevClientParameter.current,
        clientParameter,
        hasLogging,
        componentName
      )
    ) {
      return
    }

    prevClientParameter.current = clientParameter

    const fetchData = () => {
      setIsLoading(true)

      getProducts(clientParameter)
        .then(res => {
          const { result } = res

          if (result === 'successful') {
            setResponse(res.data)
            setState('client')

            if (hasLogging) {
              debug(`✅ client fetch done (for ${componentName}):`, {
                gtmContext: componentName,
                data: res.data,
                params: {
                  clientParameter,
                  'res.data.parameter': res.data.parameter,
                },
              })
            }
          }

          if (result === 'validation-failed') {
            console.error('Validation failed', res.errors)
          }

          if (result === 'failed') {
            console.error('Failed', res.error)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    fetchData()
  }, [componentName, clientParameter, hasClientFetch, hasLogging])

  const deferredResponse = useDeferredValue(response)

  return {
    isLoading,
    aggregations: deferredResponse?.aggregations || [],
    products: deferredResponse?.products || [],
    total: deferredResponse?.total || 0,
    parameter: deferredResponse?.parameter,
    state,
  }
}
