import { Alignment } from '@boxine/tonies-ui/dest/types/components/Grid/components/GridArea'
import { useViewportConfig } from '../../../tonies-ui/hooks/useViewport'

export const useLayout = (
  hasHeadline: boolean,
  hasText: boolean,
  hasSocialLinks: boolean
) => {
  return useViewportConfig({
    mobile: {
      headline: {
        colStart: 0,
        colWidth: 12,
        rowStart: 0,
        contentAlignV: 'end' as Alignment,
      },
      copytext: {
        colStart: 0,
        colWidth: 12,
        rowStart: 0 + (hasHeadline ? 1 : 0),
        contentAlignH: 'start' as Alignment,
        contentAlignV: 'end' as Alignment,
      },
      images: {
        colWidth: 12,
        rowStart: 0 + (hasHeadline ? 1 : 0) + (hasText ? 1 : 0),
        columns: { list: 2, slider: 1.5 },
      },
      socialmedialinks: {
        colStart: 0,
        colWidth: 12,
        rowStart: 1 + (hasHeadline ? 1 : 0) + (hasText ? 1 : 0),
        contentAlignH: 'center' as Alignment,
        contentAlignV: 'center' as Alignment,
      },
    },
    desktop: {
      headline: {
        colStart: 0,
        colWidth: 8 + (hasSocialLinks ? 4 : 0),
        rowStart: 0,
        contentAlignV: 'end' as Alignment,
      },
      copytext: {
        colStart: 0,
        colWidth: 8 + (hasSocialLinks ? 4 : 0),
        rowStart: 0 + (hasHeadline ? 1 : 0),
        contentAlignH: 'start' as Alignment,
        contentAlignV: 'start' as Alignment,
      },
      images: {
        colWidth: 12,
        rowStart:
          0 +
          (hasHeadline ? 1 : 0) +
          (hasText ? 1 : 0) +
          (!hasHeadline && !hasText && hasSocialLinks ? 1 : 0),
        columns: { list: 4, slider: 4 },
      },
      socialmedialinks: {
        colStart: 8,
        colWidth: 4,
        rowStart: 0,
        rowHeight: 1,
        contentAlignH: 'end' as Alignment,
        contentAlignV: 'end' as Alignment,
      },
    },
  })
}
