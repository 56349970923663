import React, { useCallback, useEffect } from 'react'
import { useBipocTonies } from './useBipocTonies'
import { useModalNotifications } from '../providers/ModalNotifications'
import { BIPoCModal } from '../components/organisms/BIPoCModal'

export const useBipocValidation = () => {
  const { products, setBipocColor } = useBipocTonies()
  const { setModal } = useModalNotifications()

  useEffect(() => {
    if (products.length) {
      setModal(
        <BIPoCModal
          products={products}
          onClose={() => {
            setModal(undefined)
          }}
        />
      )
    }
  }, [products, setModal])

  return useCallback(
    (product: NormalizedProduct | undefined) =>
      product?.shopCategory === 'tonieboxes' && setBipocColor(product?.color),
    [setBipocColor]
  )
}
