import styled, { css } from 'styled-components'
import { Styling } from '../types'
import { AssistiveTechnologyOnly } from '@boxine/tonies-ui'
import { theme } from '../../../../tonies-ui/themes/theme'

export const Label = styled.label<{
  isFloating: boolean
  isLabelHidden?: boolean
  styling?: Styling
}>`
  cursor: inherit;
  color: ${props =>
    props.styling === 'secondary'
      ? theme.colors.white
      : theme.colors.darkergrey};
  display: block;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  text-align: left;
  transition: all 0.25s ease-in-out;
  transform: scale(0.625) translateY(1rem);
  transform-origin: top left;

  ${props =>
    props.isFloating &&
    css`
      color: ${props.styling === 'secondary'
        ? theme.colors.white
        : theme.colors['anthracite-100']};
      transform: scale(1) translateY(2rem);
    `}

  ${props =>
    props.isLabelHidden &&
    css`
      ${AssistiveTechnologyOnly};
    `}
`
