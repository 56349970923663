import React, { useEffect } from 'react'
import { Button, Headline4, Spacing } from '@boxine/tonies-ui'
import { HorizontalScrollList } from '@/tonies-ui/index'
import type { HorizontalScrollListProps } from '@/tonies-ui/index'
import { NormalizedProductCard } from '../../molecules/NormalizedProductCard'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'
import {
  useViewportConfig,
  ViewportConfigType,
} from '../../../tonies-ui/hooks/useViewport'
import { NormalizedProductCardProps } from '../../../components/molecules/NormalizedProductCard/types'
import { GenericProductCardSkeleton } from '../../../components/molecules/GenericProductCard/variants/Skeleton'
import { isNormalizedProduct } from '../../../utils/isNormalizedProduct'
import { useInsertPromotionTeasers } from '../../../providers/productOverview/hooks/useInsertPromotionTeasers'
import { ContentModels } from '../../../lib/contentful/datamodel/contentModels'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useCartState } from '../../../hooks/useCartState'
import {
  TooltipSchema,
  isOfSchema,
  productOverviewPromotionItemSchema,
} from '../../../lib/contentful/datamodel/schemas'
import { EditorialTeaser } from '@/tonies-ui/molecules/EditorialTeaser'
import { IconButton } from '@/tonies-ui/atoms/IconButton'
import { useModalNotifications } from '../../../providers/ModalNotifications'
import { TooltipModal } from '../TooltipModal'
import { SectionTitle } from '@/tonies-ui/molecules/SectionTitle'
import { useRecommendations } from '../../../providers/recommendations'

const Wrapper = styled.div<{ hideSliderButtons: boolean }>`
  ${({
    hideSliderButtons,
    theme: {
      section: {
        column: { padding },
      },
    },
  }) => css`
    margin: 0 -${padding};

    ${hideSliderButtons &&
    css`
      [data-scroll-next],
      [data-scroll-prev] {
        display: none;
      }
    `}
  `}
`
const StyledIconButton = styled(IconButton)`
  margin-top: -0.25rem;
`

export type ProductCardCarouselProps = {
  headline?: string
  headlineIsSectionTitle?: boolean
  showEmptyCards?: boolean
  /**
   * get recommendations from `/api/recommendations`
   */
  getAPIRecommendations?: boolean
  items?: NormalizedProduct[]
  maxItems?: number
  promotions?: Promotion[]
  showAllLink?: string
  showOutOfStock?: boolean
  columns?: Partial<ViewportConfigType<number>>
  hideSliderButtons?: boolean
  tooltip?: Omit<TooltipSchema, 'contentTypeId' | 'referenceTitle'>
  /**
   * if true, the cart line items will be hidden
   */
  hideCartLineItems?: boolean
} & Partial<
  Omit<
    HorizontalScrollListProps,
    'className' | 'dataTestId' | 'className' | 'children' | 'columns'
  >
> &
  Pick<NormalizedProductCardProps, 'actionAdd2CartCallback'>

const ProductCardCarouselContainer = ({
  headline,
  headlineIsSectionTitle = true,
  showEmptyCards = true,
  getAPIRecommendations,
  items = [],
  promotions = [],
  showAllLink,
  showOutOfStock,
  columns,
  hideSliderButtons = false,
  hideCartLineItems = false,
  maxItems = 10,
  tooltip,
  actionAdd2CartCallback,
  ...sliderProps
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ProductCardCarouselProps) => {
  const { t } = useTranslation()
  const { isItemInCart } = useCartState()
  const { useRecommendationsAPI: getAPIRecommendationsFeatureFlag } =
    useFeatureFlags()
  const getRecommendationsFromAPI =
    getAPIRecommendations && getAPIRecommendationsFeatureFlag
  const { pushGtmV4Event } = useGtmV4()
  const { setModal } = useModalNotifications()
  const { recommendations } = useRecommendations()
  const cols = useViewportConfig({
    mobile: 1.5,
    tablet: 3,
    desktop: 4,
    ...columns,
  })
  const cardOrientation = 'portrait'

  const filterOutOfStock = (product: NormalizedProduct) =>
    !showOutOfStock
      ? ['orderable', 'preorderable'].includes(product.availability?.state)
      : true

  const filterInCart = (product: NormalizedProduct) =>
    hideCartLineItems ? !isItemInCart(product.sku) : true

  const itemsFiltered = items
    .filter(filterOutOfStock)
    .filter(filterInCart)
    .slice(0, maxItems - 1)

  const recommendedProductsFiltered = recommendations
    ?.filter(filterOutOfStock)
    .filter(filterInCart)
    .slice(0, maxItems - 1)

  const products =
    getRecommendationsFromAPI && recommendedProductsFiltered?.length
      ? recommendedProductsFiltered
      : itemsFiltered

  useEffect(() => {
    if (products) {
      pushGtmV4Event({
        eventType: 'viewItemList',
        items: products,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const entries = useInsertPromotionTeasers(products, promotions, false)
  return showEmptyCards || entries?.length ? (
    <Wrapper hideSliderButtons={hideSliderButtons}>
      {headlineIsSectionTitle ? (
        headline || showAllLink ? (
          <Spacing mb="spacing-m" mx="spacing-s">
            <SectionTitle
              title={<Accent text={headline || ''} />}
              infoButton={
                tooltip && (
                  <StyledIconButton
                    data-testid="tooltip-button"
                    icon="questionMarkFill"
                    onClick={() =>
                      setModal(
                        <TooltipModal
                          {...tooltip}
                          onClose={() => setModal(undefined)}
                        />
                      )
                    }
                    iconColor="grey"
                    fillColor="transparent"
                    borderColor="transparent"
                  />
                )
              }
              showMoreLink={
                showAllLink && (
                  <Button variant="secondary" as="a" href={showAllLink}>
                    {t('Carousel:showAll')}
                  </Button>
                )
              }
            />
          </Spacing>
        ) : null
      ) : headline ? (
        <Spacing mb="spacing-s">
          <Headline4 align="center">{headline}</Headline4>
        </Spacing>
      ) : null}
      <HorizontalScrollList columns={cols} scrollByOne {...sliderProps}>
        {entries?.length
          ? entries.map(item =>
              isNormalizedProduct(item) ? (
                <NormalizedProductCard
                  action="addToCart"
                  actionAdd2CartCallback={actionAdd2CartCallback}
                  key={item.salesId}
                  orientation={cardOrientation}
                  product={item}
                  onClick="navigateToPdp"
                  dataTestExtra={item.sku}
                />
              ) : isOfSchema(item, productOverviewPromotionItemSchema) ? (
                <ContentModels.PromotionTeaser
                  key={`${item.ctaUrl}-${item}`}
                  newGtmContextRoot
                  {...item}
                />
              ) : (
                <EditorialTeaser
                  key={`${item.ctaUrl}-${item}`}
                  aspectRatio="inherit"
                  ctaStyling="primary"
                  {...item}
                />
              )
            )
          : showEmptyCards
          ? Array(Math.ceil(cols))
              .fill('x')
              .map((_x, i) => (
                <GenericProductCardSkeleton
                  orientation={cardOrientation}
                  key={i}
                />
              ))
          : null}
      </HorizontalScrollList>
      {showAllLink && !headlineIsSectionTitle && (
        <Spacing mb="spacing-s" mx="spacing-s">
          <Button variant="secondary" as="a" href={showAllLink}>
            {t('Carousel:showAll')}
          </Button>
        </Spacing>
      )}
    </Wrapper>
  ) : null
}

/**
 * renders a Carousel of ProductCards if `items` are given
 * or a Carousel of SkeletonProductCards if no `items` are passed
 * to be able to show a loading state for async items.
 * The `cols` passed define the number of Skeletons rendered
 * @param {ProductCardCarouselProps}productCardCarouselProps
 */
export const ProductCardCarousel = withGTMV4Context(
  ProductCardCarouselContainer,
  'ProductCardCarousel'
)
