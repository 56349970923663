import React from 'react'
import styled, { useTheme } from 'styled-components'
import { FocusStyles, Icon } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'

const StyledButton = styled.button`
  width: inherit;
  height: inherit;
  padding: 0;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${FocusStyles}
`

export type RemoveItemProps = {
  isPending?: boolean
  disabled?: boolean
  onClick: () => void
  text: string
}

export const RemoveItem = ({
  isPending = false,
  disabled = false,
  onClick,
  text,
}: RemoveItemProps) => {
  const theme = useTheme()

  return (
    <StyledButton
      onClick={onClick}
      aria-label={text}
      data-testid="remove-line-item"
      disabled={isPending || disabled}
    >
      <Icon
        type={icons.delete1}
        fill={
          isPending || disabled
            ? theme.colors['anthracite-10']
            : theme.colors['anthracite-100']
        }
        width="100%"
        height="100%"
      />
    </StyledButton>
  )
}
