import {
  Button,
  CTARow,
  Checkbox,
  Headline2,
  Paragraph,
  Spacing,
} from '@boxine/tonies-ui'
import { toast } from '@/tonies-ui/atoms/Toast'
import React, {
  ComponentProps,
  FormEventHandler,
  useCallback,
  useState,
} from 'react'
import { ProductCardCarousel } from '../../ProductCardCarousel'
import { useCustomBundler } from '../../../../hooks/useCustomBundler'
import { InputField } from '../../../../components/atoms/FormElements/Input'
import { Hr } from '../../../../components/atoms/Hr'
import { Trans, useTranslation } from 'react-i18next'
import { submit } from '../../../../lib/braze'
import { useLocale } from '../../../../providers/locale'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { Modal } from '@/tonies-ui/molecules/Modal'

export const GiftfinderModal = ({
  products,
  onClose,
}: {
  products: NormalizedProduct[] | undefined
} & Pick<ComponentProps<typeof Modal>, 'onClose'>) => {
  const lcCC = useLocale()
  const { hasBrazeFormInGiftfinder } = useFeatureFlags()
  const { t } = useTranslation()
  const { link, unsupportedProducts } = useCustomBundler({
    products,
    useBestTemplate: true,
  })
  const [email, setEmail] = useState('')
  const [tos, setTos] = useState(false)
  const unsupportedOrderableProducts = unsupportedProducts?.filter(
    p => p.availability.state === 'orderable'
  )

  const handleClose = useCallback(() => {
    setTos(false)
    onClose()
  }, [onClose])

  const handleSubmit: FormEventHandler = useCallback(
    e => {
      e.preventDefault()
      handleClose()

      if (products) {
        submit({ email, lcCC, giftFinderResults: products.map(p => p.sku) })
        toast(t('giftfinder:forms:submitSuccessToast'), 'success')
      }
    },
    [t, handleClose, products, email, lcCC]
  )

  if (unsupportedOrderableProducts?.length) {
    console.info(
      `ℹ️ [CustomBundler]: Not all products are compatible: ${unsupportedOrderableProducts
        .map(p => p.sku)
        .join(', ')}`
    )
  }

  return (
    <Modal
      headline={t('giftfinder:modal:headline')}
      isOpen={!!products?.length}
      onClose={handleClose}
      size="large"
    >
      <ProductCardCarousel
        columns={{ desktop: 3, mobile: 1.5 }}
        items={products?.slice(0, 3)}
        showOutOfStock
        actionAdd2CartCallback={async () => {
          toast(t('cart:toast:addedToCart'), 'success')
        }}
      />
      <Spacing mt="spacing-m" mb="spacing-m" applyToChildren>
        {link && !unsupportedOrderableProducts?.length && (
          <CTARow justifyContent="center">
            <Button as="a" href={link}>
              {t('giftfinder:modal:customBundlerButtonText')}
            </Button>
          </CTARow>
        )}

        {hasBrazeFormInGiftfinder && (
          <>
            <Hr />
            <form onSubmit={handleSubmit}>
              <Spacing mb="spacing-xs">
                <Headline2 align="center">
                  {t('giftfinder:modal:brazeHeadline')}
                </Headline2>
              </Spacing>
              <Paragraph align="center">
                {t('giftfinder:modal:brazeText')}
              </Paragraph>
              <InputField
                label="E-Mail"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <Spacing pb="spacing-s">
                <Checkbox
                  name="tos"
                  label={
                    <Paragraph asHTMLTag="span" size={2}>
                      <Trans
                        i18nKey="checkout:summary:checkbox-tos-text"
                        components={{
                          tos: (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                              href={t('checkout:summary:checkbox-tos-link')}
                              target="_blank"
                              rel="noreferrer"
                            />
                          ),
                        }}
                      />
                    </Paragraph>
                  }
                  isChecked={tos}
                  onChange={e => setTos(e.target.checked)}
                />
              </Spacing>
              <CTARow justifyContent="center">
                <Button type="submit">
                  {t('giftfinder:modal:brazeSubmit')}
                </Button>
              </CTARow>
            </form>
          </>
        )}
      </Spacing>
    </Modal>
  )
}
