import { useMemo, useState, useEffect, useRef } from 'react'
import {
  useColorSelectorVariants,
  useLabelSelectorVariants,
} from './useColorSelectorVariants'
import { useNormalizedProductsSortedByColor } from './useSortedByColor'
import { useGtmV4 } from '../providers/gtmV4'

export const useProductVariants = <T extends NormalizedProduct>(
  initialProduct: T,
  variants: T[]
) => {
  const { pushGtmV4Event } = useGtmV4()

  const sortedVariants = useNormalizedProductsSortedByColor(variants)
  const colorSelectorVariants = useColorSelectorVariants(sortedVariants)
  const labelSelectorVariants = useLabelSelectorVariants(sortedVariants)

  const [activeProductId, setActiveProductId] = useState(initialProduct.id)

  const activeProduct = useMemo(() => {
    return (
      sortedVariants.find(product => product.id === activeProductId) ||
      initialProduct
    )
  }, [initialProduct, activeProductId, sortedVariants])

  // GTM product select variant event
  const activeProductIdRef = useRef('')

  useEffect(() => {
    if (
      activeProduct &&
      activeProductIdRef.current !== activeProduct.id &&
      sortedVariants.length !== 0
    ) {
      pushGtmV4Event({
        eventType: 'selectVariant',
        step: activeProductIdRef.current === '' ? 'pageload' : 'select',
        item: activeProduct,
      })
      activeProductIdRef.current = activeProduct.id
    }
  }, [sortedVariants, activeProduct, pushGtmV4Event])

  return {
    activeProduct,
    variants: sortedVariants,
    setActiveProductId,
    colorSelectorVariants:
      activeProduct?.variantType === 'color' ? colorSelectorVariants : [],
    labelSelectorVariants:
      activeProduct?.variantType === 'select' ? labelSelectorVariants : [],
  }
}
