import React, { useCallback } from 'react'
import {
  SidebarOverlayProps,
  SidebarOverlay as SidebarOverlayView,
} from '@/tonies-ui/molecules/SidebarOverlay'
import { useMeasurements } from '../../../providers/measurements'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { useIsMobileNavigation } from '@boxine/tonies-ui'

const SidebarOverlayContainer: NormalizedFunctionComponent<
  SidebarOverlayProps
> = ({ onClose = () => null, id, ...props }) => {
  const { pushGtmV4Event } = useGtmV4()
  const isMobile = useIsMobileNavigation()
  const { headersHeight } = useMeasurements()

  const handleClose = useCallback(
    (type: 'x' | 'translucent-overlay') => {
      pushGtmV4Event({
        eventType: 'click',
        type: 'onClick',
        label: `[coded] SidebarOverlay ${id}`,
        action: `sidebarOverlay_close--${type}`,
      })
      onClose(type)
    },
    [pushGtmV4Event, id, onClose]
  )

  return (
    <SidebarOverlayView
      {...props}
      id={id}
      top={props.top || (isMobile ? 0 : headersHeight)}
      onClose={type => handleClose(type)}
    />
  )
}

export const SidebarOverlay = withGTMV4Context(
  SidebarOverlayContainer,
  'SidebarOverlay'
)
