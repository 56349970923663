import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'

export const GridArea = styled.div<{
  name:
    | 'action'
    | 'audiosample'
    | 'bundle-contents'
    | 'contents'
    | 'image'
    | 'top-right-icon-slot'
    | 'assign-link'
    | 'prices'
    | 'quantity'
    | 'strikeprice'
    | 'text'
  alignX?: Pick<CSSProperties, 'justifyContent'>['justifyContent']
  alignY?: Pick<CSSProperties, 'alignItems'>['alignItems']
  height?: string
  scale?: number
  width?: string
}>`
  align-items: ${p => p.alignY};
  display: ${p => (p.alignX || p.alignY ? 'grid' : 'block')};
  grid-area: ${p => p.name};
  justify-content: ${p => p.alignX};
  width: ${p => p.width};
  height: ${p => p.height};

  ${p =>
    typeof p.scale === 'number'
      ? css`
          transform: scale(${p.scale});
          transform-origin: center;
        `
      : undefined}
`
