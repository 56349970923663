import * as React from 'react'
import styled, { css } from 'styled-components'
import { ColorContextProvider } from '@/tonies-ui/index'
import { Spacing, SpacingsProps } from '@boxine/tonies-ui'
import { ToniesColor } from '@/tonies-ui/themes/colors'
import { spacings } from '@/tonies-ui/themes/spacings'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { isToniesColor } from '@/tonies-ui/themes/colors'

const borderRadiuses = {
  xxs: spacings['spacing-xxs'],
  xs: spacings['spacing-xs'],
  s: spacings['spacing-s'],
  m: spacings['spacing-m'],
  l: spacings['spacing-l'],
  xl: spacings['spacing-xl'],
  xxl: spacings['spacing-xxl'],
} as const

type borderRadiusKeys = keyof typeof borderRadiuses | 0

export type BoxProps = SpacingsProps & {
  backgroundColor?: string | ToniesColor
  borderRadius?: [
    borderRadiusKeys,
    borderRadiusKeys?,
    borderRadiusKeys?,
    borderRadiusKeys?
  ]
  borderColor?: string | ToniesColor
  borderWidth?: [number, number?, number?, number?]
}

const StyledBox = styled(Spacing)<BoxProps>`
  ${({
    backgroundColor,
    borderColor,
    borderRadius = [0],
    borderWidth,
    theme: { colors },
  }) => {
    return css`
      background-color: ${backgroundColor && isToniesColor(backgroundColor)
        ? colors[backgroundColor] || backgroundColor
        : 'transparent'};
      border-color: ${borderColor && isToniesColor(borderColor)
        ? colors[borderColor] || borderColor
        : 'transparent'};
      border-radius: ${borderRadius
        .map(key => (key ? borderRadiuses[key] : 0))
        .join(' ')};
      border-width: ${borderWidth
        ? borderWidth.map(width => (width ? width + 'px' : 0)).join(' ')
        : borderColor
        ? '2px'
        : 0};
      border-style: solid;
      overflow: hidden;
    `
  }}
`

export function Box({
  children,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  ...rest
}: Styleable & BoxProps) {
  return (
    <ColorContextProvider backgroundColor={backgroundColor}>
      <StyledBox
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        {...rest}
      >
        {children}
      </StyledBox>
    </ColorContextProvider>
  )
}
