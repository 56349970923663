import { HeadlineChooser, HeadlineMapper } from '@boxine/tonies-ui'
import React from 'react'
import { Accent } from '@/tonies-ui/atoms/Accent'

/**
 * HeadlineWithAccent
 */
type HeadlineWithAccentProps = Omit<HeadlineMapper, 'children'> & {
  children: string | undefined
}

export const HeadlineWithAccent = ({
  children,
  ...headlineProps
}: HeadlineWithAccentProps) => {
  return children ? (
    <HeadlineChooser {...(headlineProps as HeadlineMapper)}>
      <Accent
        text={children}
        color={headlineProps.color === 'white' ? 'white' : 'primary'}
      />
    </HeadlineChooser>
  ) : null
}
