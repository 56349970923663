import rgb2hex from 'rgb2hex'
import { theme } from '../tonies-ui/themes/theme'
import { ColorSelectorVariantProps } from '../components/atoms/ColorSelector/types'

// Product colors used for e.g. a color picker should have a fixed order.
// Colors not listed here are appended.
const sortOrder = [
  theme.colors.primary,
  rgb2hex(theme.colors['primary']).hex,
  theme.colors['lightblue-100'],
  rgb2hex(theme.colors['lightblue-100']).hex,
  theme.colors['green-100'],
  rgb2hex(theme.colors['green-100']).hex,
  theme.colors['pink-100'],
  rgb2hex(theme.colors['pink-100']).hex,
  theme.colors['berry-100'],
  rgb2hex(theme.colors['berry-100']).hex,
  theme.colors['anthracite-100'],
  rgb2hex(theme.colors['anthracite-100']).hex,
  theme.colors['1-color-9-special-edition-limegreen-100'],
  rgb2hex(theme.colors['1-color-9-special-edition-limegreen-100']).hex,
]

export const useColorSelectorSortedByColor = (
  variants: ColorSelectorVariantProps[]
) => {
  return variants.sort((a, b) => {
    const indexA = sortOrder.indexOf(a.colorcode || '')
    const indexB = sortOrder.indexOf(b.colorcode || '')
    if (indexA === -1 && indexB === -1) return 0
    if (indexA === -1) return 1
    if (indexB === -1) return -1
    return indexA > indexB ? 1 : indexA < indexB ? -1 : 0
  })
}

export const useNormalizedProductsSortedByColor = <T extends NormalizedProduct>(
  products: T[]
) => {
  return products.sort((a, b) => {
    const indexA = sortOrder.indexOf(a.color || '')
    const indexB = sortOrder.indexOf(b.color || '')
    if (indexA === -1 && indexB === -1) return 0
    if (indexA === -1) return 1
    if (indexB === -1) return -1
    return indexA > indexB ? 1 : indexA < indexB ? -1 : 0
  })
}
