import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react'
import { useResizeObserver } from '@boxine/tonies-ui'
import styled from 'styled-components'

export type StickyFooterProps = {
  onResize: (width: number, height: number) => void
}

const Wrapper = styled.div`
  bottom: 0;
  display: block;
  left: 0;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 10;

  > * {
    pointer-events: initial;
  }
`

export const StickyFooter: FunctionComponent<
  StickyFooterProps & PropsWithChildren
> = ({ children, onResize }) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (entries.length) {
        const { width, height } = entries[0].contentRect
        onResize(width, height)
      }
    },
    [onResize]
  )

  useResizeObserver(handleResize, ref)

  return <Wrapper ref={ref}>{children}</Wrapper>
}
