import React, { useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FocusStyles, Icon, media } from '@boxine/tonies-ui'
import { theme } from '@/tonies-ui/themes/theme'
import { variables } from '@/tonies-ui/themes/variables'
import { arrowLeftFill, arrowRightFill } from '@boxine/tonies-ui/icons'
import { useInView } from 'react-intersection-observer'
import { useIsomorphicLayoutEffect } from '@/tonies-ui/hooks/useIsomorphicLayoutEffect'

const Root = styled.section`
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100%;
  height: auto;
  position: relative;
  margin: 1rem;
  /* HorizontalList would overflow on mobile */
  max-width: 100vw;

  ${media.tablet`
    max-width: ${(props: {
      theme: { application: { maxContentWidth: string } }
    }) => props.theme.application.maxContentWidth};
    margin-left: auto;
    margin-right: auto;
  `}

  button {
    ${media.tablet` 
      display: flex;
    `}
  }
`

const ScrollPane = styled.div`
  overflow-x: auto;
  display: flex;

  /* hide scrollbar */
  scrollbar-width: none; /* Firefox */

  /* chrome, safari, edge */
  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollListInner = styled.ul<{
  columnWidth: number
}>`
  display: grid;
  grid-template-columns: repeat(
    ${props => React.Children.count(props.children)},
    ${props => props.columnWidth}px
  );
  margin: 0;
  grid-gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.laptop`
    grid-gap: 2rem; 
  `}
`

const IconButtonBaseStyles = css<{ isVisible: boolean }>`
  display: none;

  ${media.tablet`
    padding: 0;
    opacity: ${({ isVisible }: { isVisible: boolean }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }: { isVisible: boolean }) =>
      isVisible ? 'visible' : 'hidden'};
    transition: all .3s;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    ${FocusStyles};
  `}
`

const IconButtonLeft = styled.button<{
  isVisible: boolean
  left: number
}>`
  ${IconButtonBaseStyles}
  left: ${props => props.left}px;
  transform: translateY(-50%) translateX(-50%);
`
const IconButtonRight = styled.button<{
  right: number
  isVisible: boolean
  alwaysShowNavigation?: boolean
}>`
  ${IconButtonBaseStyles}
  right: ${props => props.right}px;
  transform: translateY(-50%) translateX(50%);
  display: ${props => (props.alwaysShowNavigation ? 'flex' : 'none')};
`

const StyledIcon = styled(Icon)`
  height: 3rem;
  width: 3rem;
`

export interface HorizontalScrollListProps {
  className?: string
  dataTestId?: string
  children: React.ReactNode
  columns: number
  scrollByOne?: boolean
  alwaysShowNavigation?: boolean
}

export const HorizontalScrollList = ({
  className,
  dataTestId = 'horizontal-scroll-list',
  children,
  columns,
  scrollByOne,
  alwaysShowNavigation,
}: HorizontalScrollListProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const scrollPaneRef = useRef<HTMLDivElement>(null)
  const scrollListRef = useRef<HTMLUListElement>(null)

  const { ref: markerStart, inView: hideBack } = useInView({
    root: scrollPaneRef.current,
    rootMargin: '0px',
    threshold: 0.99,
    fallbackInView: false,
  })

  const { ref: markerEnd, inView: hideForward } = useInView({
    root: scrollPaneRef.current,
    rootMargin: '0px 1px',
    threshold: 0.99,
    fallbackInView: false,
  })

  // Resize items to fit available width
  const [sizes, setSizes] = useState({
    itemWidth: 0,
    gap: 0,
    listPadLeft: 0,
    listPadRight: 0,
  })

  const [repaint, setRepaint] = useState(0)

  useEffect(() => {
    if (!scrollPaneRef.current) return
    // reset scrollpane to the left on rerender
    scrollPaneRef.current.scrollLeft = 0
  }, [children])

  useIsomorphicLayoutEffect(() => {
    const root = rootRef.current
    const list = scrollListRef.current

    if (!list || !root) return

    const style = getComputedStyle(list)
    // Add fallback value for JSDom where layout properties are noop'ed
    const gap = +(style.columnGap || '16px').replace('px', '')

    const listPadLeft = +(style.paddingLeft || '0').replace('px', '')
    const listPadRight = +(style.paddingRight || '0').replace('px', '')
    const availableWidth = root.offsetWidth - listPadLeft - listPadRight
    const itemWidth = availableWidth / columns

    // Ignore spaces here to get the number of items we can
    // theoretically fit into the available width
    const count = availableWidth / itemWidth
    let nextSize = availableWidth / count

    // Now that we know how many items we can fit into the available width,
    // we can add back gaps and adapt the item size accordingly.
    nextSize = (availableWidth - (count - 1) * gap) / count

    setSizes({ gap, itemWidth: nextSize, listPadLeft, listPadRight })
  }, [columns, repaint])

  useEffect(() => {
    const onResize = () => setRepaint(repaint + 1)

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [repaint])

  function scrollPane(direction: 'forward' | 'back') {
    const el = scrollPaneRef.current

    if (el) {
      const dir = direction === 'forward' ? 1 : -1
      const width = scrollByOne ? sizes.itemWidth : el.offsetWidth

      // Jump over start/end gap to align items at the outer edge
      const left = dir * width + dir * sizes.gap

      el.scrollBy({
        left,
        behavior: 'smooth',
      })
    }
  }

  const childCount = React.Children.count(children)
  return (
    <Root className={className} ref={rootRef} data-testid={dataTestId}>
      <ScrollPane ref={scrollPaneRef}>
        <IconButtonLeft
          isVisible={!hideBack}
          left={sizes.itemWidth / 2 + sizes.listPadLeft}
          aria-hidden={hideBack}
          data-scroll-prev
          onClick={() => scrollPane('back')}
        >
          <StyledIcon type={arrowLeftFill} fill={theme.colors.primary} />
        </IconButtonLeft>

        <IconButtonRight
          isVisible={!hideForward}
          right={sizes.itemWidth / 2 + sizes.listPadRight}
          aria-hidden={hideForward}
          data-scroll-next
          onClick={() => scrollPane('forward')}
          alwaysShowNavigation={alwaysShowNavigation}
        >
          <StyledIcon type={arrowRightFill} fill={theme.colors.primary} />
        </IconButtonRight>

        <span ref={markerStart} />
        <ScrollListInner columnWidth={sizes.itemWidth} ref={scrollListRef}>
          {React.Children.map(children, (child, i) => {
            let forceVisible = false
            if (i === 0) {
              forceVisible = hideBack
            } else if (i === childCount - 1) {
              forceVisible = hideForward
            }

            return (
              <ScrollItem
                wrapperRef={rootRef}
                key={i}
                forceVisible={forceVisible}
              >
                {child}
              </ScrollItem>
            )
          })}
        </ScrollListInner>
        <span ref={markerEnd} />
      </ScrollPane>
    </Root>
  )
}

const StyledListItem = styled.li`
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  /**
   * Enable inner elements to stretch to fill available space
   * via "position: absolute"
   */
  position: relative;

  &[data-visible='true'] {
    opacity: 1;
  }
  &[data-visible='false'] {
    opacity: 0.5;
    pointer-events: none;
  }
`

interface ScrollItemProps {
  children: React.ReactNode
  wrapperRef: React.RefObject<HTMLDivElement>
  forceVisible: boolean
}

function ScrollItem({ wrapperRef, children, forceVisible }: ScrollItemProps) {
  const { ref, inView: isIntersecting } = useInView({
    root: wrapperRef.current,
    // Use a bigger area to make sure we catch the element despite padding
    rootMargin: '0px -20px',
    // Use `0.99` instead of 1 because of a rounding error in Chrome
    threshold: 0.99,
    fallbackInView: false,
  })

  const [showFade, setShowFade] = useState(false)
  useEffect(() => {
    function update() {
      const match = window.matchMedia(
        `(min-width: ${variables.screenTabletL}px)`
      ).matches
      setShowFade(match)
    }

    update()

    window.addEventListener('resize', update)
    return () => window.removeEventListener('resize', update)
  }, [])

  return (
    <StyledListItem
      ref={ref}
      data-visible={showFade ? isIntersecting || forceVisible : true}
    >
      {children}
    </StyledListItem>
  )
}
