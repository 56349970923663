import rgb2hex from 'rgb2hex'
import { ImageBackend } from '@/tonies-ui/atoms/Image/types'
import { ERROR_SRC } from '@/tonies-ui/atoms/Image/lib'
import { parseColorString, theme } from '@boxine/tonies-ui'
import { isToniesColor } from '@/tonies-ui/themes/colors'

const CLOUD_NAME = 'tonies'

export const isColorCode = (color: string) => {
  const isValid = Array.isArray(
    color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/) ||
      color.match(/^#[A-Fa-f0-9]{3}([A-Fa-f0-9]{2,3})?$/)
  )
  if (!isValid && process.env.NODE_ENV === 'development')
    console.warn(
      `Image: '${color}' is not a valid color code like '#abcdef' or 'rgb(51,51,51)'.`
    )
  return isValid
}

export const isUrl = (src: string) => {
  return /^(?:https?:)?\/\//.test(src)
}

export const isGif = (src: string) => {
  return /.gif$/i.test(src)
}

export const isSvg = (src: string) => {
  return /.svg$/i.test(src)
}

/**
 * Helper function to generate a Cloudinary image URL (CDN-cached, optimized)
 * from a given original image URL.
 *
 * Please note that this will only work if the domain name in the original
 * image URL is white-listed in the Cloudinary "security" admin panel.
 */
export const cloudinaryUrl: ImageBackend = ({
  src,
  width,
  height,
  background,
  crop,
  quality = 'auto',
  isPlaceholder,
}) => {
  // handle svg images or invalid image sources
  if (isSvg(src)) return src
  if (!isUrl(src)) return ERROR_SRC

  const s = src.startsWith('//') ? 'https://' + src.substr(2) : src
  const w = typeof width === 'number' ? `,w_${width}` : ''
  const h = typeof height === 'number' ? `,h_${height}` : ''

  // We don't want to show animated placeholder images.
  // pg_1 displays only the first frame of an (animated) image.
  // This also works for none animated images - because they have
  // a single frame - so there is no need here to check for formats
  // that support animations.
  const pg = isPlaceholder ? ',pg_1' : ''

  let c = ''

  // Our "autoGravity" is not supported for animated GIFs by Cloudinary
  // so we have to fall back to "crop" for GIFs in general in this case
  // because we can't test here if it's an animated or a regular GIF.
  if (isGif(src) && crop === 'autoGravity') {
    crop = 'crop'
  }

  switch (crop) {
    case 'crop':
      c = w || h ? ',c_crop' : ''
      break
    case 'fill':
      c = w && h ? ',c_fill' : ''
      break
    case 'autoGravity':
      c = w && h ? ',c_fill_pad,g_auto' : ''
      break
  }

  let b = ''
  if (background && isToniesColor(background)) {
    b = `,b_rgb:${rgb2hex(theme.colors[background]).hex.replace('#', '')}`
  } else if (
    background &&
    background.toLowerCase() !== 'transparent' &&
    isColorCode(background)
  ) {
    const rgba = parseColorString(background)
    const rgb = `rgb(${rgba.r},${rgba.g},${rgba.b})`
    b = `,b_rgb:${rgb2hex(rgb).hex.replace('#', '')}`
  }

  return `https://res.cloudinary.com/${CLOUD_NAME}/image/fetch/f_auto,q_${quality}${c}${b}${w}${h}${pg}/${s}`
}
