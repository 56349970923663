import React, { FunctionComponent } from 'react'
import { GenericSelectionCardProps } from '../types'
import { GenericProductCardView } from './View'
import { IconButtonProps } from '@/tonies-ui/atoms/IconButton'
import { useTheme } from 'styled-components'
import { useSelection } from '../../../../providers/selection'

const useSelectionIconButton = (
  isSelected?: boolean,
  isMaximumReached?: boolean
): Pick<
  IconButtonProps,
  'borderColor' | 'fillColor' | 'iconColor' | 'icon' | 'styling'
> => {
  const {
    colors: { 'green-100': green100, primary, white },
  } = useTheme()

  return isMaximumReached && !isSelected
    ? {
        icon: 'emptyCircle',
        fillColor: white,
        borderColor: white,
        iconColor: white,
      }
    : {
        styling: !isSelected ? 'primary' : 'secondary',
        icon: !isSelected ? 'addSolo' : 'lessSolo',
        borderColor: isSelected ? primary : green100,
        fillColor: !isSelected ? green100 : undefined,
      }
}

export const GenericSelectionCard: FunctionComponent<
  GenericSelectionCardProps
> = ({ id, action, ...props }) => {
  const { isMaximumReached, handleOnChange, isSelected, isSingleSelection } =
    useSelection(id)
  const icon = useSelectionIconButton(isSelected, isMaximumReached)

  const handleChange =
    !isMaximumReached || isSelected ? () => handleOnChange(id) : undefined

  return (
    <GenericProductCardView
      {...props}
      isSelected={isSelected}
      dataTestId="generic-selection-card"
      action={
        isSingleSelection
          ? undefined
          : {
              ...icon,
              onClick: handleChange,
            }
      }
      onClick={handleChange}
    />
  )
}
