import React from 'react'
import styled from 'styled-components'
import PaypalIcon from './assets/paypal.svg'
import VisaIcon from './assets/visa.svg'
import McIcon from './assets/mc.svg'
import MaestroIcon from './assets/maestro.svg'
import CartebancaireIcon from './assets/cartebancaire.svg'
import GooglepayIcon from './assets/googlepay.svg'
import PaywithgoogleIcon from './assets/paywithgoogle.svg'
import KlarnaIcon from './assets/klarna.svg'
import KlarnaAccountIcon from './assets/klarna_account.svg'
import KlarnaPaynowIcon from './assets/klarna_paynow.svg'
import IdealIcon from './assets/ideal.svg'
import BcmcIcon from './assets/bcmc.svg'
import BcmcMobileIcon from './assets/bcmc_mobile.svg'
import ApplepayIcon from './assets/applepay.svg'
import SchemeIcon from './assets/scheme.svg'
import { PaymentIconName } from 'providers/payment/lib'

const paymentIconComponentNames = {
  paypal: PaypalIcon,
  visa: VisaIcon,
  mc: McIcon,
  maestro: MaestroIcon,
  cartebancaire: CartebancaireIcon,
  klarna: KlarnaIcon,
  klarna_account: KlarnaAccountIcon,
  klarna_paynow: KlarnaPaynowIcon,
  ideal: IdealIcon,
  bcmc: BcmcIcon,
  bcmc_mobile: BcmcMobileIcon,
  applepay: ApplepayIcon,
  googlepay: GooglepayIcon,
  paywithgoogle: PaywithgoogleIcon,
  scheme: SchemeIcon,
}

const iconSizesWidth = {
  s: '1.5rem',
  m: '2rem',
  l: '2.5rem',
}

export type IconSize = 's' | 'm' | 'l'

type PaymentIconProps = {
  icon: PaymentIconName
  hasBorder?: boolean
  size?: IconSize
}

const IconWrapper = styled.span<{
  hasBorder: boolean
  size: IconSize
}>`
  display: inline-block;
  line-height: 1;

  svg {
    aspect-ratio: 40 / 26;
    width: ${props => iconSizesWidth[props.size]};
    border: ${props => (props.hasBorder ? '1px' : 'none')} solid
      ${({ theme }) => theme.colors.middlegrey};
    border-radius: ${props => (props.hasBorder ? '2px' : 'none')};
  }
`

export const PaymentIcon = ({
  icon,
  hasBorder = true,
  size = 'l',
}: PaymentIconProps) => {
  const needsBorder =
    icon === 'paypal' ||
    icon === 'mc' ||
    icon === 'maestro' ||
    icon === 'scheme' ||
    icon === 'ideal' ||
    Boolean(icon.match(/.*bcmc/))

  const PaymentIconComponent = paymentIconComponentNames[icon]

  return (
    <IconWrapper hasBorder={hasBorder && needsBorder} size={size}>
      <PaymentIconComponent />
    </IconWrapper>
  )
}
