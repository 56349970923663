import React from 'react'
import { NormalizedLineItemCard } from '../../molecules/NormalizedLineItemCard'
import { Hr } from '../../atoms/Hr'
import { Wrapper } from './components/Wrapper'
import { LineItemCardContext } from '../../molecules/NormalizedLineItemCard/types'
import {
  GenericSelectionType,
  SelectionProvider,
} from '../../../providers/selection'
import { ListViewItem } from '@boxine/tonies-ui'

export type LineItemCollectionProps = {
  hasBorders?: boolean
  hasSeparators?: boolean
  lineItems: NormalizedLineItem[]
  quantityChange?: {
    hasQuantityChange: boolean
    hasRemove: boolean
  }
  selection?: GenericSelectionType
  context?: LineItemCardContext
  as?: 'NormalizedLineItemCard' | 'ListViewItem'
}

export const LineItemCollection = ({
  hasBorders,
  hasSeparators,
  lineItems,
  quantityChange,
  selection,
  context = undefined,
  as = 'NormalizedLineItemCard',
}: LineItemCollectionProps) => {
  return (
    <SelectionProvider {...selection}>
      <Wrapper data-testid="lineItem-collection">
        {lineItems?.map((lineItem, index) => (
          <React.Fragment key={lineItem.id}>
            {hasSeparators && index > 0 && <Hr />}
            {as === 'ListViewItem' ? (
              <ListViewItem
                data-testid={lineItem.product.sku}
                key={lineItem.product.name + lineItem.product.series}
                imgSrc={lineItem.product.image.src}
                imgAlt={lineItem.product.image.alt}
                title={lineItem.product.name}
                subtitle={lineItem.product.subName}
                // FIXME: set required "to" prop in tonies-ui's ListViewItem as optional and remove react-router-dom dependency there
                to={undefined}
                // FIXME: remove desktop version in component
                desktopBreakpoint="999999"
              />
            ) : (
              <NormalizedLineItemCard
                hasBorder={hasBorders}
                key={lineItem.id}
                lineItem={lineItem}
                quantityChange={quantityChange}
                context={context}
              />
            )}
          </React.Fragment>
        ))}
      </Wrapper>
    </SelectionProvider>
  )
}
