import { Expandable } from '../../atoms/Expandable'
import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { LineItemCollection } from '../../../components/organisms/LineItemCollection'
import { StateIcon } from '../../../components/organisms/Process/components/StateIndicator'
import { Spacing } from '@boxine/tonies-ui'

export const AlreadyBoughtExpandable = ({
  products,
  hasToggle = false,
}: {
  products: NormalizedCart['lineItems']
  hasToggle?: boolean
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Expandable
      headline={t('removeAlreadyBoughtTunesFromCartModal:expandable:headline')}
      isExpanded={isOpen}
      rightSlot={<StateIcon state="complete" />}
      onToggle={hasToggle ? () => setIsOpen(curr => !curr) : undefined}
      data-testid="tunes-already-bought-expandable"
    >
      <Spacing p="spacing-s">
        <LineItemCollection
          hasSeparators={true}
          lineItems={products}
          as="ListViewItem"
        />
      </Spacing>
    </Expandable>
  )
}
