import React, { ComponentProps } from 'react'
import { CarouselSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { Carousel } from '../../../../../../components/organisms/Carousel'

export const CM_Carousel = ({
  componentProps,
  ...rest
}: ContentModelType<ComponentProps<typeof Carousel>, CarouselSchema>) => {
  return <Carousel {...rest} {...componentProps} />
}
