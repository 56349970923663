import { Headline2 } from '@boxine/tonies-ui'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

const Left = styled.div<{ hasFullWidth: boolean; hasInfoButton: boolean }>`
  display: ${props => (props.hasInfoButton ? 'inline-grid' : 'inline')};
  ${props => (props.hasInfoButton ? 'grid-template-columns: 1fr 2.5rem;' : '')}
  ${props =>
    props.hasInfoButton ? 'align-items: top;' : 'vertical-align: top;'}
  padding-right: 0.5rem;
  max-width: ${props => (props.hasFullWidth ? '100%' : '75%')};
  align-items: center;
`

const Right = styled.div`
  display: flex;
  align-self: center;
  height: fit-content;
`

export interface SectionTitleProps {
  className?: string
  title: ReactNode
  infoButton?: ReactNode
  children?: ReactNode
  showMoreLink?: ReactNode
}

export function SectionTitle({
  className,
  title,
  infoButton,
  children = undefined,
  showMoreLink,
}: SectionTitleProps) {
  return (
    <div className={className}>
      <Top>
        <Left
          hasFullWidth={children === undefined}
          hasInfoButton={Boolean(infoButton)}
        >
          <Headline2 weight={700}>{title}</Headline2>
          {infoButton}
        </Left>
        <Right>{showMoreLink}</Right>
      </Top>
      {children}
    </div>
  )
}
