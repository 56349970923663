import React from 'react'
import {
  Headline1,
  Paragraph,
  useIsViewportMobile,
  Grid,
  GridArea,
  HorizontalScrollList,
  Spacing,
} from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { SocialMediaLinks } from '../../molecules/SocialMediaLinks'
import { useLayout } from './layouts'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { GenericImageText } from '../../molecules/GenericImageText'
import styled, { CSSProperties } from 'styled-components'

const StyledGrid = styled(Grid)<{
  childLength: number
  columns: number
  alignV?: Pick<CSSProperties, 'alignContent'>['alignContent']
}>`
  ${({ childLength, columns, alignV = 'center' }) => `
    align-items: ${alignV};
    grid-template-columns: repeat(${
      columns < childLength ? columns : childLength
    }, minmax(0, calc(100% / ${columns})));
    justify-content: center;
  `}
`

type GalleryProps = {
  headline?: string
  headlineAsHTMLTag?: SupportedHTMLTags
  text?: string
  images: {
    imageSrc: string
    link?: { href: string; target: '_parent' | '_blank' }
    caption?: string
  }[]
  hasSocialLinks?: boolean
  columnsDesktop?: number
  variant?: 'list' | 'slider'
}

const GalleryContainer = ({
  headline,
  headlineAsHTMLTag = 'h3',
  text,
  images,
  hasSocialLinks = true,
  variant = 'list',
  columnsDesktop = 4,
}: GalleryProps) => {
  const isMobile = useIsViewportMobile()
  const layout = useLayout(!!headline, !!text, hasSocialLinks)
  const isList = variant === 'list'
  const VariantComp = isList ? StyledGrid : HorizontalScrollList

  return (
    <Grid hasGapH hasGapV data-testid="gallery">
      {/* Headline */}
      {headline && (
        <GridArea {...layout.headline}>
          <Spacing mx={isList ? undefined : 'spacing-s'}>
            <Headline1
              asHTMLTag={headlineAsHTMLTag}
              align={isMobile ? 'center' : 'left'}
            >
              <Accent text={headline} />
            </Headline1>
          </Spacing>
        </GridArea>
      )}

      {/* Copytext */}
      {text && (
        <GridArea {...layout.copytext}>
          <Spacing mx={isList ? undefined : 'spacing-s'}>
            <Paragraph size={2}>{text}</Paragraph>
          </Spacing>
        </GridArea>
      )}

      {/* Social Media Icons */}
      {hasSocialLinks && (
        <GridArea {...layout.socialmedialinks}>
          <SocialMediaLinks iconSizeInEm={2.5} iconGapInEm={1} />
        </GridArea>
      )}

      {/* Images */}
      <GridArea {...layout.images}>
        <VariantComp
          alignV={images.some(image => image.caption) ? 'start' : 'center'}
          columns={
            isList && !isMobile
              ? columnsDesktop
              : layout.images.columns[variant]
          }
          childLength={images.length}
          scrollByOne
          hasGapH
          hasGapV
        >
          {images.map((image, i) => (
            <GenericImageText
              key={`${i + 1} of ${images.length}`}
              orientation="vertical"
              media={{
                variant: 'image',
                src: image.imageSrc,
                link: image.link,
                alt: `${i + 1} of ${images.length}`,
              }}
            >
              {image.caption && <Paragraph size={5}>{image.caption}</Paragraph>}
            </GenericImageText>
          ))}
        </VariantComp>
      </GridArea>
    </Grid>
  )
}

export const Gallery = withGTMV4Context(GalleryContainer, 'Gallery-2023')
