import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { shopConfig } from '../../../../config/shop'
import { useLocale } from '../../../../providers/locale'
import { Spacing } from '@boxine/tonies-ui'
import { BazaarvoiceScript } from '../../../organisms/Meta/components/Bazaarvoice'

/**
 * I hate external stuff
 */
const Wrapper = styled.div`
  height: 22px; // prevent layout shift because of async fetch
  > div {
    display: inline-block;
    margin: 0 auto;
  }
  [data-bv-rating] .bv_main_container .bv_stars_component_container,
  [data-bv-show='inline_rating']
    .bv_main_container
    .bv_stars_component_container,
  [data-bv-rating] .bv_main_container .bv_averageRating_component_container,
  [data-bv-show='inline_rating']
    .bv_main_container
    .bv_averageRating_component_container {
    padding-right: 8px !important;
  }
  [data-bv-rating] .bv_main_container .bv_text,
  [data-bv-show='inline_rating'] .bv_main_container .bv_text {
    font-weight: 300 !important;
  }
`

export const Ratings: FunctionComponent<{
  activeProduct: NormalizedProduct
}> = ({ activeProduct }) => {
  const lcCC = useLocale()
  const hasBazaarvoice = !!shopConfig.locales[lcCC].bazaarvoice

  return hasBazaarvoice ? (
    <>
      <BazaarvoiceScript lcCC={lcCC} />
      <Spacing mt="spacing-xs">
        <Wrapper>
          <div
            data-bv-show="inline_rating"
            data-bv-product-id={activeProduct.salesId}
            data-bv-redirect-url={`${activeProduct.path}#reviews`}
          />
        </Wrapper>
      </Spacing>
    </>
  ) : null
}
