import React from 'react'
import { Icon } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { useTheme } from 'styled-components'

export const StateIcon = ({ isValid }: { isValid: boolean }) => {
  const theme = useTheme()

  return (
    <Icon
      type={isValid ? icons.checkmark : icons.exclamationMark}
      fill={isValid ? theme.colors['green-100'] : theme.colors.primary}
      height="1.25rem"
      width="1.25rem"
    />
  )
}
