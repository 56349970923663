import React from 'react'
import styled from 'styled-components'
import { Notch } from '../../../atoms/Notch'

const Wrapper = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  line-height: 0;
  margin: 0 3.25rem;
  text-align: center;
`

export const ProductCardNotch: typeof Notch = props => {
  return (
    <Wrapper>
      <Notch {...props} />
    </Wrapper>
  )
}
