import React, { useCallback } from 'react'
import { BxLineItemCustomFields } from '../lib/commercetools/requests/bxTypes'
import { useModalNotifications } from '../providers/ModalNotifications'
import { useUrlQuery } from '../providers/urlQuery'
import { RepairModal } from '../components/organisms/RepairModal'

export const useProductConfirmation = () => {
  const { setModal } = useModalNotifications()
  const urlTonieboxID = useUrlQuery().getUrlParamAsSingleton('tonieboxID')

  return useCallback(
    (
      product: NormalizedProduct
    ): Promise<{
      product: NormalizedProduct
      custom?: BxLineItemCustomFields
    }> =>
      new Promise((resolve, reject) => {
        if (product.shopCategory === 'repairs') {
          setModal(
            <RepairModal
              onResolve={custom => {
                resolve({
                  product,
                  custom,
                })
                setModal(undefined)
              }}
              onReject={() => {
                reject()
                setModal(undefined)
              }}
              tonieboxID={urlTonieboxID}
            />
          )
        } else {
          resolve({ product })
        }
      }),
    [setModal, urlTonieboxID]
  )
}
