import React, { useEffect } from 'react'
import { NormalizedProductCard } from '../../molecules/NormalizedProductCard'
import { ProductCollectionProps } from './types'
import { Layout } from './components/Layout'
import { useGridColumns } from './hooks/useGridColumns'
import styled, { css } from 'styled-components'
import { GridLayout } from '../../molecules/GenericProductCard/components/GridLayout'
import { isNormalizedProduct } from '../../../utils/isNormalizedProduct'
import { isPromotionEntry } from '../../../utils/isPromotionEntry'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { GenericProductCardSkeleton } from '../../../components/molecules/GenericProductCard/variants/Skeleton'
import { ContentModels } from '../../../lib/contentful/datamodel/contentModels'
import {
  isOfSchema,
  productOverviewPromotionItemSchema,
} from '../../../lib/contentful/datamodel/schemas'
import { EditorialTeaser } from '@/tonies-ui/molecules/EditorialTeaser'

const Wrapper = styled.div<{
  hasVariants: boolean
}>`
  ${({ hasVariants }) =>
    hasVariants &&
    css`
      grid-column: auto / span 2;
      display: grid;

      ${GridLayout} {
        grid-template-columns: calc(50% - 1.5rem) 1fr max-content auto;
      }
    `}
`
// TODO: Rename to "ProductList"
const ProductCollectionContainer: NormalizedFunctionComponent<
  ProductCollectionProps
> = ({
  getCardAction,
  cardOrientation = 'portrait',
  columns,
  entries,
  hasPrices = true,
  pendingState = {
    isPending: false,
    productsPerPage: 24,
  },
  preventWishlist,
}) => {
  let visibleEntries = [...entries]
  const { pushGtmV4Event } = useGtmV4()

  if (cardOrientation === 'landscape') {
    // Promotions are still unsupported in landscape orientation
    visibleEntries = entries.filter(isNormalizedProduct)
  }

  // GTM products list view event on page load
  useEffect(() => {
    pushGtmV4Event({
      eventType: 'viewItemList',
      items: visibleEntries.filter(isNormalizedProduct),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleEntries])

  return (
    <Layout
      cardOrientation={cardOrientation}
      columns={useGridColumns(cardOrientation, columns)}
      data-testid="product-list"
    >
      {!pendingState.isPending &&
        visibleEntries.map((entry, index) => {
          if (isPromotionEntry(entry)) {
            if (isOfSchema(entry, productOverviewPromotionItemSchema)) {
              return (
                <ContentModels.PromotionTeaser
                  key={`${entry.ctaUrl}-${entry}`}
                  newGtmContextRoot
                  {...entry}
                />
              )
            } else {
              return (
                <EditorialTeaser
                  key={`${entry.ctaUrl}-${entry}`}
                  {...entry}
                  aspectRatio={entry.aspectRatio || 'inherit'}
                  ctaStyling="primary"
                />
              )
            }
          } else {
            const cardAction = entry.sku
              ? getCardAction?.(entry.sku, entry.availability)
              : undefined
            const hasVariants = false

            return (
              <Wrapper hasVariants={hasVariants} key={`${entry.id}-${index}`}>
                <NormalizedProductCard
                  orientation={cardOrientation}
                  action={cardAction}
                  product={entry}
                  onClick={
                    cardAction === 'addToCart' ? 'navigateToPdp' : undefined
                  }
                  hasPrices={hasPrices}
                  preventWishlist={preventWishlist}
                  {...entry.options}
                  hasNote={entry.hasNote}
                />
              </Wrapper>
            )
          }
        })}
      {pendingState.isPending &&
        Array(pendingState.productsPerPage)
          .fill('x')
          .map((_x, i) => (
            <GenericProductCardSkeleton orientation={cardOrientation} key={i} />
          ))}
    </Layout>
  )
}

export const ProductCollection = withGTMV4Context(
  ProductCollectionContainer,
  'ProductCollection'
)
