import { DiscountCodeState } from '../types/normalize/cart'

export type DiscountCodeErrorResponse =
  | 'does_not_exist'
  | 'is_not_active'
  | 'is_not_valid_anymore'
  | 'is_not_yet_valid'

const errorMessages: Record<
  DiscountCodeState | DiscountCodeErrorResponse,
  string
> = {
  does_not_exist: 'cart:error:discountCodeNotFound',
  is_not_active: 'cart:error:discountIsNotActive',
  is_not_valid_anymore: 'cart:error:discountIsNotActive',
  is_not_yet_valid: 'cart:error:discountIsNotActive',
  NotActive: 'cart:error:discountIsNotActive',
  NotValid: 'cart:error:discountIsNotActive',
  MaxApplicationReached: 'cart:error:discountCodeMaxApplicationReached',
  MatchesCart: 'cart:coupon:success',
  DoesNotMatchCart: 'cart:error:DoesNotMatchCart',
  ApplicationStoppedByPreviousDiscount: 'cart:error:DoesNotMatchCart',
} as const

/**
 * Gets the translation string for discountCode State Messages
 *
 * we are getting various string states for applied and non-applied
 * discountCodes from our ct API which we want to show 'customer-readable'
 * in the cart. Therefore we need to translate them
 *
 * @param discountCodeState state string from the API response to be translated
 */

export const localizeDiscountState = (discountCodeState: string) => {
  // discountCodeState from non-applied codes is prefixed with discount_code.
  const trimmedInput: DiscountCodeState | DiscountCodeErrorResponse =
    discountCodeState.indexOf('discount_code.') === -1
      ? (discountCodeState as DiscountCodeState)
      : (discountCodeState.substring(
          discountCodeState.indexOf('discount_code.') + 14
        ) as DiscountCodeErrorResponse)

  // return found errorMessage or fallback
  return errorMessages[`${trimmedInput}`] || errorMessages['does_not_exist']
}
