import React, { ComponentProps, FunctionComponent } from 'react'
import { Box, Icon, Spacing } from '@boxine/tonies-ui'
import { DeliveryInfoIcon } from '../../atoms/DeliveryInfoIcon'
import styled, { css } from 'styled-components'
import { BoxProps } from '@boxine/tonies-ui/dest/types/src/atoms/Box'
import { ToniesMotions } from '@/tonies-ui/motions'
import { Image } from '@/tonies-ui/atoms/Image'

const Wrapper = styled(Box)<{
  $isVertical: boolean
  $imageWidth?: string
}>`
  ${({ $isVertical, $imageWidth = 'auto' }) => css`
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(2rem, ${$imageWidth}) 1fr;

    ${$isVertical &&
    css`
      gap: 0.5rem;
      grid-template-columns: none;
    `}
  `}
`

const MediaWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  align-items: flex-start;
`

const Video = styled.video`
  max-width: 100%;
`

export type GenericImageTextProps = {
  orientation?: 'horizontal' | 'vertical'
} & {
  media: {
    width?: string
    link?: { href: string; target?: '_blank' | '_parent' }
    onClick?: () => void
  } & (
    | ({
        variant: 'image'
      } & ComponentProps<typeof Image>)
    | ({
        variant: 'video'
      } & { src: string })
    | ({ variant: 'icon' } & ComponentProps<typeof Icon>)
    | ({ variant: 'deliveryInfo' } & ComponentProps<typeof DeliveryInfoIcon>)
  )
} & Partial<BoxProps>

export const GenericImageText: FunctionComponent<GenericImageTextProps> = ({
  children,
  media: { width, ...media },
  orientation,
  ...boxProps
}) => {
  const isVertical = orientation === 'vertical'

  return (
    <Wrapper $isVertical={isVertical} $imageWidth={width} {...boxProps}>
      <ToniesMotions.ZoomIt
        style={{
          height: 'max-content',
        }}
        viewport={{ amount: 0.6 }}
      >
        <MediaWrapper
          as={media.link ? 'a' : 'div'}
          onClick={media.onClick}
          {...media.link}
        >
          {media.variant === 'image' && (
            <Image crop="fill" responsive={[100, 320, 540]} {...media} />
          )}
          {media.variant === 'video' && (
            <Video
              autoPlay
              loop
              muted
              playsInline
              controls={false}
              src={media.src}
            />
          )}
          {media.variant === 'icon' && (
            <Icon width={width || '2rem'} height={width || '2rem'} {...media} />
          )}
          {media.variant === 'deliveryInfo' && (
            <DeliveryInfoIcon width={width || '3.125rem'} {...media} />
          )}
        </MediaWrapper>
      </ToniesMotions.ZoomIt>
      {children && <Spacing>{children}</Spacing>}
    </Wrapper>
  )
}
