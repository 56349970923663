import React from 'react'
import { Button, CTARow, Paragraph, Spacing } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { AlreadyBoughtExpandable } from '../../../components/molecules/AlreadyBoughtExpandable'
import { useCartActions } from '../../../hooks/useCartActions'
import { Modal } from '@/tonies-ui/molecules/Modal'

export const AlreadyBoughtTunesModal = ({
  onClose = () => null,
  products,
}: {
  onClose?: () => void
  products: NormalizedLineItem[]
}) => {
  const { t } = useTranslation()
  const { state } = useCartActions()

  return (
    <Modal
      isOpen
      onClose={onClose}
      headline={
        <Accent text={t('removeAlreadyBoughtTunesFromCartModal:headline')} />
      }
      footer={
        <CTARow>
          <Button
            onClick={onClose}
            data-testid="tunes-already-bought-modal-cancel"
            isPending={state === 'processing'}
            disabled={state === 'processing'}
          >
            {t('removeAlreadyBoughtTunesFromCartModal:confirmButton')}
          </Button>
        </CTARow>
      }
    >
      <Spacing mb="spacing-m">
        <Paragraph size={2}>
          {t('removeAlreadyBoughtTunesFromCartModal:text')}
        </Paragraph>
      </Spacing>
      <AlreadyBoughtExpandable products={products} />
    </Modal>
  )
}
