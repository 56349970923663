import { CartAction } from '..'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { addProduct } from '../../../lib/commercetools/requests/carts/addProduct'
import { GtmV4Event } from '../../gtmV4/types'
import { BxLineItemCustomFields } from '../../../lib/commercetools/requests/bxTypes'

export type AddProductAction = CartAction<boolean> & {
  type: 'addProductAction'
  sku: string
  custom?: BxLineItemCustomFields
}

export const isAddProductAction = (
  action: CartAction<unknown>
): action is AddProductAction =>
  (action as AddProductAction).type === 'addProductAction'

export const addProductAction = (
  args:
    | {
        sku: string
        quantity: number
        custom?: BxLineItemCustomFields
      }
    | {
        product: NormalizedProduct
        quantity: number
        custom?: BxLineItemCustomFields
        pushGtmV4Event?: (e: GtmV4Event) => void
      }
): AddProductAction => {
  const sku = 'sku' in args ? args.sku : args.product.sku
  const action: CartAction<boolean> = async ({
    lcCC,
    fetchOrCreateCart,
    replaceCart,
    logError,
  }) => {
    const cart = await fetchOrCreateCart()
    const { quantity, custom } = args

    // Update quantity in CommerceTools
    const addProductResponse = await addProduct(
      lcCC,
      cart.id,
      cart.version,
      sku,
      quantity,
      custom
    )

    if (addProductResponse.result !== 'successful') {
      logError(
        addProductResponse.error,
        'addProductAction',
        addProductResponse.data
      )
      return false
    }

    // update cart state
    replaceCart(normalizeCart(addProductResponse.data, lcCC))

    // push to gtm
    if ('pushGtmV4Event' in args && args.pushGtmV4Event) {
      args.pushGtmV4Event({
        eventType: 'addToCart',
        item: { ...args.product, quantity: 1 },
      })
    }

    return true
  }

  const result = action as AddProductAction
  result.type = 'addProductAction'
  result.sku = sku

  return result
}
