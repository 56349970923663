import {
  useGridContext,
  useIsViewportDesktop,
  useIsViewportTablet,
} from '@boxine/tonies-ui'
import { ProductCollectionProps } from '../types'

export const useGridColumns = (
  cardOrientation?: ProductCollectionProps['cardOrientation'],
  columns?: number
) => {
  const { availableColumns } = useGridContext()
  const isTablet = useIsViewportTablet()
  const isDesktop = useIsViewportDesktop()
  const isMobile = !isTablet && !isDesktop

  if (typeof columns === 'number') {
    return columns
  } else if (cardOrientation === 'landscape') {
    return 1
  } else {
    return isDesktop
      ? availableColumns / 3
      : isTablet
      ? availableColumns / 4
      : isMobile
      ? availableColumns / 6
      : 1
  }
}
