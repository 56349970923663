import { MetaNavigationItemProps } from '../components/organisms/MetaNavigation'
import { homepageUrl } from '../utils/urlHelpers'
import { useLocale } from '../providers/locale'
import { useTranslation } from 'next-i18next'
import { shopConfig } from '../config/shop'

export interface HeaderData {
  [key: string]: {
    logoAlt: string
    logoSrc: string
    logoLink: string
    metaNavigationItems: Array<MetaNavigationItemProps>
  }
}

export const useMetaItems = () => {
  const { t } = useTranslation()
  const lcCC = useLocale()

  const defaultItems = [
    {
      text: 'tonies®',
      link: `/${lcCC.toLowerCase()}/`,
    },
    {
      text: '<strong>my</strong>tonies',
      link: `${process.env.NEXT_PUBLIC_MY_TONIES_URL}`,
    },
    {
      text: t('metaNavigation:supportLinkText'),
      link: 'https://tonies.support',
    },
  ]
  const additionalItems = additionalmetaItems[lcCC]

  return additionalItems ? defaultItems.concat(additionalItems) : defaultItems
}

export interface MetaData {
  [key: string]: {
    text: string
    link: string
  }[]
}

// these could come from Contentful in the future
const additionalmetaItems: MetaData = {
  'de-DE': [
    {
      text: 'Händler',
      link: `${process.env.NEXT_PUBLIC_B2B_URL_DE_DE}`,
    },
  ],
  'en-GB': [
    {
      text: 'For Retailers',
      link: '/en-gb/retailer/',
    },
  ],
}

export const useHeaderData = () => {
  const lcCC = useLocale()

  return {
    logoAlt: 'tonies®',
    logoSrc: shopConfig.assets.toniesLogo,
    logoLink: homepageUrl(lcCC),
    metaNavigationItems: useMetaItems(),
  }
}
