import { BundleTemplate } from '../../components/organisms/CustomBundler/types'
import { EcomLocale, ShopProductCategory } from '../shopAPI/types'
import DeDeBasic from './de-DE/basic.json'
import DeDeAdvanced from './de-DE/advanced.json'
import DeDeCollector from './de-DE/collector.json'
import EnEuSingle from './en-EU/single.json'
import EnEuStarter from './en-EU/starter.json'
import EnEuCollector from './en-EU/collector.json'
import EnEuFull from './en-EU/full.json'
import EnGbSingle from './en-GB/single.json'
import EnGbStarter from './en-GB/starter.json'
import EnGbAdvanced from './en-GB/advanced.json'
import EnGbFull from './en-GB/full.json'
import FrFrExplorateur from './fr-FR/explorateur.json'
import FrFrConnaisseur from './fr-FR/connaisseur.json'
import FrFrCollectionneur from './fr-FR/collectionneur.json'
import { getTypedLocaleConfig } from '../../hooks/useLocaleConfig'
import { getProducts } from '../../lib/opensearch/requests/products/getProducts'

export type CustomBundlerConfig = {
  templates: BundleTemplate[]
  tonieboxes: Partial<NormalizedOpenSearchProductsResponse> & {
    products: NormalizedProduct[]
  }
  tonies: Partial<NormalizedOpenSearchProductsResponse> & {
    products: NormalizedProduct[]
  }
  creativeTonies: Partial<NormalizedOpenSearchProductsResponse> & {
    products: NormalizedProduct[]
  }
}

export const bundleBase = getTypedLocaleConfig({
  'de-DE': [DeDeBasic, DeDeAdvanced, DeDeCollector],
  'en-EU': [EnEuSingle, EnEuStarter, EnEuCollector, EnEuFull],
  'en-GB': [EnGbSingle, EnGbStarter, EnGbAdvanced, EnGbFull],
  'fr-FR': [FrFrExplorateur, FrFrConnaisseur, FrFrCollectionneur],
} as const)

const currency = getTypedLocaleConfig({
  'de-DE': 'EUR',
  'en-GB': 'GBP',
  'fr-FR': 'EUR',
  'en-EU': 'EUR',
} as const)

// Include products by category

const bundlerCategories: ShopProductCategory[] = [
  'tonieboxes',
  'tonies',
  'creative-tonies',
]

const isSupportedBundlerCategory = (category?: string) =>
  category && bundlerCategories.includes(category)

// Exclude products by price

const bundlerToniePriceFilter = getTypedLocaleConfig<[number, number?]>({
  // Excludes all Tonies with non-standard prices from the bundler,
  // see https://boxine.atlassian.net/jira/software/c/projects/TWAS/issues/TWAS-3437
  'de-DE': [16.99],
  'en-GB': [14.99],
  'fr-FR': [14.99],
  'en-EU': [16.99],
})

const isSupportedBundlerPrice = (
  product: NormalizedProduct,
  lcCC: EcomLocale
) =>
  product.shopCategory !== 'tonies'
    ? true
    : bundlerToniePriceFilter[lcCC].some(p => p === product.price?.centAmount)

// Exclude products by SKU

const bundlerExcludedSkus: string[] = []

const isSupportedBundlerSku = (sku: string) =>
  /**
   * although `bundlerExcludedSkus` will stay an empty array we want to keep this function for the future and have to eslint-ignore accosiated errors
   */
  // eslint-disable-next-line sonarjs/no-empty-collection
  !bundlerExcludedSkus.includes(sku)

// Exclude Tonies by series key

const bundlerExcludedSeriesKeys: string[] = []

const isSupportedBundlerSeriesKey = (series: string) =>
  /**
   * although `bundlerExcludedSeriesKeys` will stay an empty array we want to keep this function for the future and have to eslint-ignore accosiated errors
   */
  // eslint-disable-next-line sonarjs/no-empty-collection
  !bundlerExcludedSeriesKeys.includes(series)

// isSupported checks

// This check is also used on our PDPs to decide wether to show
// the CreateCustomBundleAccordion or not, see
// /layouts/ProductDetailLayout/components/CreateCustomBundleAccordion.tsx

export const isSupportedBundlerProduct = (
  product: NormalizedProduct,
  lcCC: EcomLocale
) =>
  isSupportedBundlerCategory(product.shopCategory) &&
  isSupportedBundlerPrice(product, lcCC) &&
  isSupportedBundlerSku(product.sku) &&
  isSupportedBundlerSeriesKey(product.series?.key || '')

export const isOrderableSupportedBundlerProduct = (
  product: NormalizedProduct,
  lcCC: EcomLocale
) =>
  isSupportedBundlerProduct(product, lcCC) &&
  product.availability.state === 'orderable' &&
  product.shopCategory !== 'creative-tonies'

export const getCustomBundlerConfig = async (
  lcCC: EcomLocale
): Promise<CustomBundlerConfig> => {
  const tonieboxes = await getProducts({
    categoryKey: ['tonieboxes'],
    lcCC,
    available: true,
    limit: 12,
  }).then(response => {
    if (response.result !== 'successful') {
      throw new Error(
        `getCustomBundlerProducts() failed to fetch tonieboxes for locale "${lcCC}"`
      )
    }
    return response.data
  })

  const tonies = await getProducts({
    lcCC,
    available: true,
    categoryKey: ['tonies'],
    withBucketAggs: true,
    priceGte: bundlerToniePriceFilter[lcCC][0].toString(),
    priceLte: (
      bundlerToniePriceFilter[lcCC][1] || bundlerToniePriceFilter[lcCC][0]
    ).toString(),
    limit: 12,
  }).then(response => {
    if (response.result !== 'successful') {
      throw new Error(
        `getCustomBundlerProducts() failed to fetch tonies for locale "${lcCC}"`
      )
    }
    return response.data
  })

  const creativeTonies = await getProducts({
    lcCC,
    available: true,
    categoryKey: ['creative-tonies'],
    limit: 12,
  }).then(response => {
    if (response.result !== 'successful') {
      throw new Error(
        `getCustomBundlerProducts() failed to fetch creativeTonies for locale "${lcCC}"`
      )
    }
    return response.data
  })

  const templates = bundleBase[lcCC].map((base): BundleTemplate => {
    const tbQuantity = base.tonieboxes.quantity as 0 | 1

    if (tbQuantity !== 0 && tbQuantity !== 1) {
      throw new Error('Toniebox quantity in bundle template must be 0 or 1')
    }

    return {
      ...base,
      price: {
        ...base.price,
        // inject type-safe currency
        currency: currency[lcCC],
      },
      strikePrice: base.strikePrice
        ? {
            ...base.strikePrice,
            // inject type-safe currency
            currency: currency[lcCC],
          }
        : undefined,
      tonieboxes: {
        quantity: tbQuantity,
      },
      tonies: {
        ...base.tonies,
      },
      creativeTonies: {
        ...base.creativeTonies,
      },
    }
  })

  return {
    templates,
    tonies,
    creativeTonies,
    tonieboxes,
  }
}
