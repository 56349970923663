import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useMeasurements } from '../../../providers/measurements'

type MainProps = { hasOverflowHidden?: boolean; hasPaddingTop?: boolean }

export const Wrapper = styled.main<{ top?: number }>`
  position: relative;
  padding-top: ${({ top = 0 }) => top + 'px'};
`

export const Main: FunctionComponent<MainProps & PropsWithChildren> = ({
  children,
  hasPaddingTop,
}) => {
  const { mainNavigationHeight } = useMeasurements()

  return (
    <Wrapper top={hasPaddingTop ? mainNavigationHeight : undefined}>
      {children}
    </Wrapper>
  )
}
