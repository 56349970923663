import React, { FunctionComponent, PropsWithChildren } from 'react'
import { GenericProductCardProps } from './types'
import { GenericSelectionCard } from './variants/GenericSelectionCard'
import { GenericProductCardView } from './variants/View'

export const GenericProductCard: FunctionComponent<
  GenericProductCardProps & PropsWithChildren
> = props => {
  return props.action === 'toggleSelection' ? (
    <GenericSelectionCard {...props} />
  ) : (
    <GenericProductCardView {...props} />
  )
}
