import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import { spacings } from '@boxine/tonies-ui'
import { media } from '@/tonies-ui/themes/theme'
import * as icons from '@boxine/tonies-ui/icons'
import { Icon } from '@boxine/tonies-ui'
import { Box } from '@/tonies-ui/atoms/Box'

const TagListOuter = styled.div<
  Pick<TagListProps, 'isScrollable' | 'horizontalOffset'>
>`
  display: flex;
  position: relative;

  ${({ isScrollable, horizontalOffset }) =>
    isScrollable &&
    css`
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      margin: 0 ${horizontalOffset ? '-' + spacings[horizontalOffset] : 0};
      padding: 0 ${horizontalOffset ? spacings[horizontalOffset] : 0};

      @supports (selector(::-webkit-scrollbar)) {
        ::-webkit-scrollbar {
          display: none;
        }
      }
    `}
`

const TagListInner = styled.div<Pick<TagListProps, 'isScrollable'>>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;

  ${({ isScrollable }) =>
    isScrollable
      ? css`
          flex-wrap: nowrap;

          * {
            white-space: nowrap !important;
            hyphens: none !important;
          }
        `
      : css`
          ${media.tablet`
            display: inline-flex;
          `}
        `}
`

const wobble = keyframes`
  0% { transform: translateX(0%); }
  58% { transform: translateX(0%); }
  65% { transform: translateX(-15%); }
  72% { transform: translateX(12%); }
  79% { transform: translateX(-9%); }
  86% { transform: translateX(6%); }
  93% { transform: translateX(-3%); }
  100% { transform: translateX(0%); }
`

const Arrows = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  height: 100%;
  background: rgb(255, 255, 255, 0.8);
  padding: 0 0.5rem;

  svg {
    animation: ${wobble} 4s ease infinite;
  }
`

type TagListProps = {
  children: ReactNode
  'data-testid'?: string
  hasBox?: boolean
  horizontalOffset?: keyof typeof spacings
  isScrollable?: boolean
}

export const TagList: FunctionComponent<TagListProps> = ({
  children,
  'data-testid': dataTestId = 'tagList',
  hasBox,
  horizontalOffset,
  isScrollable,
}) => {
  const outerRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const [hasTouch, setHasTouch] = useState<boolean | undefined>(undefined)
  const [repaint, setRepaint] = useState(0)
  const [hasArrows, setHasArrows] = useState(false)
  const { colors } = useTheme()

  const handleOnScroll = useCallback(() => {
    setHasArrows(false)
    outerRef.current?.removeEventListener('scroll', handleOnScroll)
  }, [])

  const handleOnClick = useCallback(() => {
    setHasArrows(false)
    outerRef.current?.scrollTo({ left: 100, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined' || !hasTouch) {
      return
    }
    const onResize = () => setRepaint(repaint + 1)
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [hasTouch, repaint])

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !isScrollable ||
      hasTouch === false ||
      !outerRef.current ||
      !innerRef.current
    ) {
      return
    }

    if (hasTouch === undefined) {
      setHasTouch(
        window.matchMedia('(hover: none)').matches &&
          window.matchMedia('(pointer: coarse)').matches
      )
    }

    outerRef.current.scrollLeft = 0

    if (innerRef.current.clientWidth > outerRef.current.clientWidth) {
      setHasArrows(true)
      outerRef.current.removeEventListener('scroll', handleOnScroll)
      outerRef.current.addEventListener('scroll', handleOnScroll)
    } else {
      handleOnScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTouch, repaint])

  const tagList = (
    <TagListOuter
      isScrollable={isScrollable && hasTouch}
      horizontalOffset={
        isScrollable && hasTouch
          ? hasBox
            ? 'spacing-xs'
            : horizontalOffset
          : undefined
      }
      ref={outerRef}
      data-testid={dataTestId}
      /**
       * To prevent a layout shift if isScrollable, we hide
       * the TagList until we know if it's a touch device or not.
       */
      style={
        isScrollable && hasTouch === undefined ? { display: 'none' } : undefined
      }
    >
      <TagListInner isScrollable={isScrollable && hasTouch} ref={innerRef}>
        {children}
      </TagListInner>
      {hasArrows && (
        <Arrows onClick={handleOnClick}>
          <Icon
            type={icons.arrowsRight}
            fill={colors.darkergrey}
            width="1.25rem"
            height="1.25rem"
          />
        </Arrows>
      )}
    </TagListOuter>
  )

  if (hasBox) {
    return (
      <Box
        p="spacing-xs"
        backgroundColor="white"
        borderRadius={['s']}
        borderColor="lightgrey"
      >
        {tagList}
      </Box>
    )
  }

  return tagList
}
