import styled, { css } from 'styled-components'
import { ImageProps, borderRadiuses } from './types'
import { theme } from '@/tonies-ui/themes/theme'

export type StyledContainerProps = {
  isLoading: boolean
  isError: boolean
}

export const StyledContainer = styled.span<StyledContainerProps>`
  display: block;
  width: 100%;

  ${props =>
    props.isLoading &&
    css`
      filter: blur(1rem);
      /**
       * Avoid background bleed around blurred edges.
       * See: https://stackoverflow.com/questions/12224320
       */
      transform: scale(1.05);
    `}
  ${props =>
    props.isError &&
    css`
      background: ${theme.colors['lightergrey']};
      border: 1px solid ${theme.colors['white']};
      border-radius: 4px;
      opacity: 0.8;
    `}
`

export const StyledImage = styled.img<Pick<ImageProps, 'borderRadius'>>`
  /* Remove space below image caused by inline positioning */
  display: block;
  /* Ensure image resizes according to container */
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;

  border-radius: ${props =>
    props.borderRadius
      ? props.borderRadius.map(key => (key ? borderRadiuses[key] : 0)).join(' ')
      : '0'};
`
