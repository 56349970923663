import React from 'react'
import { useTranslation } from 'react-i18next'
import { NormalizedLineItemCardProps } from './types'
import { useQuantityChangeHandlers } from './hooks/useQuantityChange'
import { GenericProductCard } from '../GenericProductCard'
import { GenericProductCardProps } from '../GenericProductCard/types'
import { useLineItemCardNote } from './hooks/useLineItemCardNote'
import { useAssignTune } from '../../../hooks/useAssignTune'
import { useOwnAudioContentContext } from '../../../providers/ownAudioContent/OwnAudioContext'
import { OwnAudioContentCard } from '../GenericProductCard/variants/OwnAudioContentCard'
import { useToggleWishlistIconProps } from '../../../components/atoms/ToggleFromWishlist'
import { withGTMV4Context } from '../../../providers/gtmV4'

const NormalizedLineItemCardAudio = withGTMV4Context(
  OwnAudioContentCard,
  'NormalizedLineItemCard'
)

export const NormalizedLineItemCard = ({
  'data-testid': dataTestId = 'line-item',
  'data-trackingid': dataTrackingId,
  action,
  context,
  hasBorder,
  lineItem,
  preventWishlist,
  quantityChange,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
NormalizedLineItemCardProps) => {
  const { t } = useTranslation()
  const { product, quantity, total } = lineItem
  const isTune = product.shopCategory === 'tunes'
  const isRepair = product.shopCategory === 'repairs'
  const name = product.name
  const { handleClickAssign } = useAssignTune(product)
  const wishlistIconProps = useToggleWishlistIconProps(product)

  /**
   * Some products should have limited functionality as a lineItem.
   * This affects GiftLineItem (free extras), that sometimes do not have
   * an own PDP, e.g. bonus products like christmas wrapping paper
   * all Items without a PDP:
   * - no display of subName
   */
  const description = product.normalizedCategories.find(
    category => category.key === 'no-pdp'
  )
    ? ''
    : product.subName

  const hasQuantitySelector = Boolean(quantityChange)

  const {
    handleQuantityDecrease,
    handleQuantityIncrease,
    handleQuantityRemove,
    hasPendingActions,
  } = useQuantityChangeHandlers({ lineItem, quantityChange })

  // Card Note
  const note = useLineItemCardNote(product)
  const dataTestIdWithPrefix = `${dataTestId}${note ? '--' + note.state : ''}`

  type NormalizedLineItemConfig = {
    image: GenericProductCardProps['image']
    audioLibraryAssignUrl?: GenericProductCardProps['audioLibraryAssignUrl']
    price?: GenericProductCardProps['price']
    strikePrice?: GenericProductCardProps['strikePrice']
    quantity?: GenericProductCardProps['quantity']
    children?: React.ReactNode
    topRightIconSlot?: GenericProductCardProps['topRightIconSlot']
  }
  const productImage = (product: NormalizedBundledProduct) => {
    return {
      src: product.image.src,
      alt: product.image.alt,
    }
  }
  const bundleContents = product.bundledProducts?.map(p => ({
    name: p.name,
    subtitle: p.subName,
    image: productImage(p),
  }))
  const {
    isDirectOwnAudioContentAssignmentEnabled,
    isAllOwnAudioContentReady,
  } = useOwnAudioContentContext()
  const config: NormalizedLineItemConfig = {
    image: {
      alt: product.image.alt,
      src: product.image.src,
      placeholder: false,
      variant: isTune ? 'coverCard' : 'image',
    },
    audioLibraryAssignUrl:
      !isDirectOwnAudioContentAssignmentEnabled &&
      (context === 'orderSuccess' || context === 'orderHistory') &&
      lineItem.audioLibraryAssignUrl
        ? lineItem.audioLibraryAssignUrl
        : undefined,
    price:
      context === 'cartViolation'
        ? undefined
        : lineItem.isFreeGift
        ? 'free'
        : total && {
            amount: total.price.amount * quantity,
            centAmount: total.price.centAmount * quantity,
            currency: total.price.currency,
          },
    strikePrice: lineItem.isFreeGift
      ? undefined
      : total.strikePrice && {
          amount: total.strikePrice.amount * quantity,
          centAmount: total.strikePrice.centAmount * quantity,
          currency: total.strikePrice.currency,
        },
    quantity:
      context === 'cartViolation'
        ? undefined
        : {
            value: quantity,
            selector: hasQuantitySelector
              ? {
                  isPending: hasPendingActions,
                  isMaximumReached: product.maxQuantity
                    ? quantity >= product.maxQuantity
                    : false,
                  onAdd: handleQuantityIncrease,
                  onRemove: handleQuantityDecrease,
                  onRemoveAll: handleQuantityRemove,
                  /**
                   * because the `quantityChange` settings are configured cart wide
                   * in `providers/checkout/config/index.ts` and we don't want this
                   * option for `isFreeGift` and `maxQuantity === 1` items, we have to override this setting here
                   */
                  hasQuantityChange:
                    lineItem.isFreeGift || lineItem.product.maxQuantity === 1
                      ? false
                      : quantityChange?.hasQuantityChange,
                  hasRemove: lineItem.isFreeGift
                    ? false
                    : quantityChange?.hasRemove,
                }
              : undefined,
          },
    topRightIconSlot:
      !preventWishlist && !lineItem.isFreeGift ? wishlistIconProps : undefined,
    /** productType hint for OrderReview */
    children:
      context === 'orderReview' && (isTune || isRepair)
        ? t(`lineItem:productType:${isTune ? 'digital' : 'repair'}`)
        : undefined,
  }

  return product.sku &&
    isDirectOwnAudioContentAssignmentEnabled &&
    isTune &&
    isAllOwnAudioContentReady &&
    context === 'orderSuccess' ? (
    <NormalizedLineItemCardAudio
      referenceTitle="[coded] OrderSucess Assign"
      tune={product}
      dataTestExtra={product.sku}
      dataTestId={dataTestIdWithPrefix}
      orientation="landscape"
    />
  ) : product.sku ? (
    <GenericProductCard
      action={action}
      id={product.sku}
      dataTestExtra={product.sku}
      dataTestId={dataTestIdWithPrefix}
      dataTrackingId={dataTrackingId}
      description1={description}
      hasBorder={hasBorder}
      name={name}
      note={note}
      orientation="landscape"
      {...config}
      bundleContents={bundleContents}
      onClickAssign={handleClickAssign}
    />
  ) : null
}
