import React from 'react'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import styled, { useTheme } from 'styled-components'
import { Tag } from '@/tonies-ui/atoms/Tag'
import { useProductOverview } from '../../../../providers/productOverview'
import { TagList } from '@/tonies-ui/molecules/TagList'
import { FilterItem, Icon, Paragraph } from '@boxine/tonies-ui'
import { SupportedFilterKeys } from '../../../../hooks/useProductFilter'
import { useTranslation } from 'next-i18next'
import { useAggregatedShopLocale } from '../../../../providers/aggregatedShopLocale'
import { isNotUndefined } from '../../../../utils/isNotUndefined'
import { useFilterOverlay } from '../hooks/useFilterOverlay'
import { useGtmV4, withGTMV4Context } from '../../../../providers/gtmV4'
import { ComponentSpinner } from '@/tonies-ui/atoms/ComponentSpinner'
import { AnimatePresence } from 'framer-motion'
import { ToniesMotions } from '@/tonies-ui/motions'

const StyledButton = styled.button`
  ${cssReset}
  cursor: pointer;
  display: inline;
`

const StyledFilterIcon = styled(Icon)`
  position: relative;
  top: 2px;
  margin-right: 0.25rem;
`

const MoreFilterTag = () => {
  const { t } = useTranslation()
  const {
    colors: { darkergrey },
  } = useTheme()
  const [, handleFilter] = useFilterOverlay()

  return (
    <ToniesMotions.PopIt>
      <StyledButton
        onClick={() => handleFilter(true)}
        data-testid="top-filter-tag-list__tag--more"
      >
        <Tag backgroundColor="transparent" borderColor="lightgrey">
          <StyledFilterIcon
            fill={darkergrey}
            type="filter"
            width="0.75rem"
            height="0.75rem"
          />
          {t('productFilter:moreFilters')}
        </Tag>
      </StyledButton>
    </ToniesMotions.PopIt>
  )
}

const TopFilterTagsContainer = () => {
  const { t } = useTranslation()
  const { filter, shopCategory, search, isPending } = useProductOverview()
  const {
    config: { topFilters },
  } = useAggregatedShopLocale()
  const { pushGtmV4Event } = useGtmV4()

  const tags = topFilters?.filter(
    f => shopCategory && f.categories.includes(shopCategory)
  )
  const filterTags = tags
    ?.map(tag => {
      const filterGroup = filter?.items.find(f => f.filterKey === tag.filterKey)

      const isTopFilter = (filterItem: FilterItem) =>
        filterItem.id === tag.value

      // find item in lvl1
      const findLvl1 = filterGroup?.filters.find(isTopFilter)

      // find item in lvl2 (options)
      const findLvl2 = filterGroup?.filters
        .find(f => f.options?.some(isTopFilter))
        ?.options?.find(isTopFilter)

      const filterItem = findLvl1 || findLvl2

      return filterItem &&
        !filterItem?.disabled &&
        // for legacy code
        (typeof filterItem?.doc_count === 'undefined'
          ? true
          : filterItem.doc_count > 0) &&
        !filterItem.value
        ? { ...filterItem, filterKey: tag.filterKey as SupportedFilterKeys }
        : undefined
    })
    // delete undefined
    .filter(isNotUndefined)

  return (
    <AnimatePresence>
      {filterTags && filterTags?.length !== 0 ? (
        <ToniesMotions.PopIt>
          <TagList hasBox data-testid="top-filter-tag-list">
            <Paragraph size={3} weight={500}>
              {t('products:filterTags:ideas')}
            </Paragraph>
            {isPending ? (
              <ComponentSpinner height="2rem" />
            ) : (
              <>
                {filterTags.map(f => (
                  <ToniesMotions.PopIt key={f.label}>
                    <StyledButton
                      data-testid="tag"
                      data-testextra={f.label}
                      onClick={() => {
                        const newFilters = filter?.onChangeAll(
                          f.filterKey,
                          !f.value,
                          f.id
                        )

                        if (newFilters) {
                          pushGtmV4Event({
                            eventType: 'search',
                            filters: newFilters,
                            searchTerm: search?.value || '',
                          })
                        }
                      }}
                    >
                      <Tag isActive={f.value}>{f.label}</Tag>
                    </StyledButton>
                  </ToniesMotions.PopIt>
                ))}
                <MoreFilterTag />
              </>
            )}
          </TagList>
        </ToniesMotions.PopIt>
      ) : null}
    </AnimatePresence>
  )
}

export const TopFilterTags = withGTMV4Context(
  TopFilterTagsContainer,
  'TopFilterTags'
)
