import { media } from '@boxine/tonies-ui'
import styled from 'styled-components'
import Heart from '../assets/heart.svg'
import Hearts from '../assets/hearts.svg'

export const StyledHeart = styled(Heart)`
  position: absolute;
  bottom: 6rem;
  right: 2rem;
  zoom: 0.6;

  ${media.tablet`
    zoom: 1;
    bottom: 0;
    right: 0;
  `}
`

export const StyledHeartsReversed = styled(Hearts)`
  position: absolute;
  top: 0;
  left: -1rem;
  transform: scale(-1, 1);
  zoom: 0.6;

  ${media.tablet`
    zoom: 1;
  `}
`

export const StyledHearts = styled(Hearts)`
  position: absolute;
  top: 6rem;
  display: block;
`
