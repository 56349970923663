import { media } from '@boxine/tonies-ui'
import styled from 'styled-components'

export const Inner = styled.div`
  margin: auto;
  padding: 1.5rem 1rem 1.75rem;
  max-width: 75rem;
  text-align: center;

  ${media.tablet`
    padding: 2rem 1rem 2.25rem;
  `}

  ${media.laptop`
    padding: 3rem 1rem 2.25rem;
  `}
`
