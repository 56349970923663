import React, { useContext } from 'react'
import { ProductOverviewContextProps } from './types'

export const defaultProps: ProductOverviewContextProps = {
  shopCategory: undefined,
  entries: {
    all: [],
    filtered: [],
    filteredAndPaginated: [],
    total: 0,
  },
  filter: {
    items: [],
    resetAll: (): null => null,
    isFilterActive: false,
    onChangeAll: () => [],
  },
  sorting: {
    id: 'bestseller-desc',
    onChange: () => [],
  },
  search: {
    value: '',
    clear: (): null => null,
    setValue: (): null => null,
  },
  loadMore: {
    isShown: false,
    onLoadMore: (): null => null,
    maxItems: 0,
    setMaxItems: (): null => null,
  },
  pagination: {
    onPageChange: () => null,
    isShown: false,
    currentPage: 1,
    maxPage: 1,
  },
  isPending: false,
  resetAll: () => null,
  usedApi: undefined,
}

export const ProductOverviewContext =
  React.createContext<ProductOverviewContextProps>(defaultProps)

export const useProductOverview = (): ProductOverviewContextProps =>
  useContext(ProductOverviewContext)
