import React, { ComponentProps } from 'react'
import { audioPlayer } from '../../../schemas'
import { AudioPlayerSection } from '../../../../../../components/organisms/AudioPlayerSection'
import { ContentModelType } from '../../types/types'

export const CM_AudioPlayerSectionSchema = ({
  componentProps,
  ...rest
}: ContentModelType<
  ComponentProps<typeof AudioPlayerSection>,
  audioPlayer
>) => {
  return (
    <AudioPlayerSection
      backgroundColor={rest.backgroundColor}
      entries={rest.entries}
      headlineAsHTMLTag={rest.headlineAsHTMLTag}
      skuList={rest.skuList}
      text={rest.text}
      title={rest.title}
      {...componentProps}
    />
  )
}
