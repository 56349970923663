import { CarouselContext } from 'pure-react-carousel'
import { useContext, useEffect } from 'react'
import { CarouselChangeHandler } from '../types'

export const useNotifyOnChange = (
  onChange: CarouselChangeHandler | undefined
) => {
  const carouselContext = useContext(CarouselContext)

  const { totalSlides, currentSlide, visibleSlides } =
    carouselContext.getStoreState()

  useEffect(() => {
    if (onChange) {
      onChange({
        currentSlide,
        visibleSlides,
        totalSlides,
        setCurrentSlide: (currentSlide: number) => {
          carouselContext.setStoreState({
            currentSlide,
          })
        },
      })
    }
  }, [onChange, carouselContext, currentSlide, totalSlides, visibleSlides])
}
