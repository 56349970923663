import React, {
  ReactNode,
  useEffect,
  useCallback,
  FunctionComponent,
  useState,
  useMemo,
  useRef,
} from 'react'
import { useStateProductOverview } from './hooks/useStateProductOverview'
import { useInsertPromotionTeasers } from './hooks/useInsertPromotionTeasers'
import { ProductOverviewContext } from '.'
import { useUrlProductOverview } from './hooks/useUrlProductOverview'
import { useScrollIntoView } from '../../hooks/useScrollIntoView'
import {
  areOpenSearchParametersEqual,
  useProducts,
} from '../../hooks/useProducts'

import { useAggregatedShopLocale } from '../../providers/aggregatedShopLocale'
import { toAltNotation } from '../../providers/locale/altNotation'
import { isSupportedFilterKey } from '../../hooks/useProductFilter'
import { ShopProductCategory } from '../../config/shopAPI/types'

import { ProductApiType } from './types'

import debounce from 'lodash/debounce'

export type ProductOverviewProviderProps = {
  children?: ReactNode
  hasLoadMoreButton?: boolean
  promotions?: Promotion[]
  isSalesPage?: boolean
  /**
   * Two mechanisms to store the filter state.
   * 1 - `useState` (default): Internal useState that no longer exists on reload. Relevant for pages like the Bundler, where multiple ProductLists exist on one page.
   * 2 - `useUrlState`: Filters are persisted via a query-string in the URL.
   */
  statePersistenceMechanism?: 'useState' | 'useUrlState'
  /**
   * @deprecated replaced by productsFetchConfig.initialState.parameter?.categoryKey?.[0]
   */
  shopCategory?: ShopProductCategory
  productsPerPage?: number
  hasScrollBehaviorOnChange?: boolean
  productsFetchConfig: {
    initialState: NormalizedOpenSearchProductsResponse
    api: ProductApiType
  }
}

type Filter = {
  filterKey: string
  activeFilters: string[]
}

function mapNewParams(
  filters: Filter[],
  shopCategories: string[] | undefined,
  sort: Partial<NormalizedSorting> | undefined,
  searchValue: string | undefined,
  offset: number,
  limit: number
): Partial<NormalizedOpenSearchParameters> | undefined {
  const params = new Map<
    string,
    NormalizedOpenSearchParameters[keyof NormalizedOpenSearchParameters]
  >()

  if (shopCategories) {
    params.set('categoryKey', shopCategories)
  }

  if (sort) {
    params.set('sort', sort)
  }

  if (searchValue) {
    params.set('search', [searchValue])
  }

  if (offset) {
    params.set('offset', offset)
  }

  if (limit) {
    params.set('limit', limit)
  }

  filters.forEach(currentFilter => {
    if (currentFilter.activeFilters.length === 0) {
      return
    }

    if (currentFilter.filterKey === 'series') {
      params.set('categorySlug', currentFilter.activeFilters)
    }

    if (currentFilter.filterKey === 'lcCC') {
      params.set('language', currentFilter.activeFilters)
    }

    if (
      ['ageMin', 'genre', 'theme', 'flag'].includes(currentFilter.filterKey)
    ) {
      params.set(currentFilter.filterKey, currentFilter.activeFilters)
    }
  })

  return params.size > 0 ? Object.fromEntries(params) : undefined
}

export const ProductOverviewProvider: FunctionComponent<
  ProductOverviewProviderProps
> = ({
  children,
  hasLoadMoreButton = false,
  isSalesPage = false,
  promotions = [],
  statePersistenceMechanism = 'useState',
  hasScrollBehaviorOnChange = false,
  productsPerPage = 24,
  productsFetchConfig: { api, initialState },
  shopCategory: shopCategoryLegacy,
}) => {
  const shopCategory =
    shopCategoryLegacy || initialState.parameter?.categoryKey?.[0]
  const providerRef = useRef(null)
  const aggregatedShopLocale = useAggregatedShopLocale()

  // Filter and search are only enabled for results sets of length >= 12, see TWAS-3422
  const hasFiltersSearch = Boolean(initialState.products.length >= 12)

  /**
   * OpenSearch
   */
  const [openSearchFetchParams, setOpenSearchFetchParams] = useState(
    initialState.parameter
  )

  /**
   * products
   */
  const {
    aggregations,
    products,
    isLoading: isProductLoading,
    total: totalAll,
    state: fetchState,
  } = useProducts({
    initialState,
    hasClientFetch: api === 'openSearch',
    parameter: openSearchFetchParams,
    hasLogging: true,
  })

  /**
   * FIXME
   * Dirtiest POC ever. 😀
   * Idea is to inject the initAggregations options for the first applied filter
   * to that filter here for OpenSearch.
   * This could e.g. be achieved with a useState that holds the key of the
   * first applied filter instead of searching in the URLs query params.
   */
  if (typeof window !== 'undefined') {
    const urlSearchParams = Array.from(
      new Set(new URLSearchParams(window.location.search).keys()).values()
    )
    const urlFilterKeys = urlSearchParams.filter(k => isSupportedFilterKey(k))

    if (urlFilterKeys.length === 1) {
      const firstFilterKey = urlFilterKeys[0]

      aggregations.every(a => {
        if (a.key === firstFilterKey) {
          a.options =
            initialState.aggregations.find(a => a.key === firstFilterKey)
              ?.options || a.options
          return false
        }
        return true
      })
    }
  }

  /**
   * state mechanism
   */
  const useStateMechanism =
    statePersistenceMechanism === 'useState'
      ? useStateProductOverview
      : useUrlProductOverview

  const {
    search: { clearSearch, searchValue, setSearchTerm },
    filters: { handleFilterChange: onFilterChange, isFilterActive, filters },
    loadMore: { showItemMax, setShowItemMax },
    sorting: { setSortingId, ...sorting },
    matchingProducts,
    resetAll,
    pagination: { page, setPage },
  } = useStateMechanism({
    api,
    products,
    aggregations,
    shopCategory,
    hasLoadMoreButton,
    productsPerPage,
    promotions,
    parameter: openSearchFetchParams,
    total: totalAll,
  })

  const [isPending, setIsPending] = useState(false)

  /**
   * Wrapper function for the handleFilterChange function in useStateMechanism
   * @param key - The key of the filter.
   * @param value - The value of the filter.
   * @param id - The id of the filter.
   * @returns {ReturnType<typeof onFilterChange>} - The result of the onFilterChange function.
   */
  const handleFilterChange: typeof onFilterChange = (
    key,
    value,
    id
  ): ReturnType<typeof onFilterChange> => {
    setIsPending(true)

    return onFilterChange(key, value, id)
  }

  useEffect(() => {
    setIsPending(isProductLoading)

    return () => {
      setIsPending(false)
    }
  }, [isProductLoading])

  /**
   * We only want to show the language filter when there is
   * at least one Tonie that's `lc` doesn't match the shop's `lc`
   */
  const shopLc = toAltNotation(aggregatedShopLocale.lcCC, 'lc')
  if (
    isSalesPage &&
    initialState?.products.filter(
      product => (product.lcCC && toAltNotation(product.lcCC, 'lc')) === shopLc
    ).length
  ) {
    filters.filter(f => f.filterKey !== 'lcCC')
  }

  const total = useMemo(
    () => (api === 'openSearch' ? totalAll || 0 : matchingProducts.length),
    [api, matchingProducts, totalAll]
  )

  const hasPromotions = !isFilterActive && !searchValue

  /**
   * OpenSearch Parameters
   */
  useEffect(() => {
    const handleOpenSearchFetchParamChange = debounce(
      setOpenSearchFetchParams,
      500
    )

    const limit =
      productsPerPage - (page === 1 && hasPromotions ? promotions.length : 0)

    const offset =
      page > 1
        ? (page - 1) * productsPerPage - (hasPromotions ? promotions.length : 0)
        : 0

    const newParams = mapNewParams(
      filters,
      openSearchFetchParams?.categoryKey,
      sorting,
      searchValue,
      offset,
      limit
    )

    if (!areOpenSearchParametersEqual(newParams, openSearchFetchParams)) {
      handleOpenSearchFetchParamChange(newParams)
    }
  }, [
    filters,
    isFilterActive,
    openSearchFetchParams,
    sorting,
    page,
    productsPerPage,
    promotions.length,
    searchValue,
    hasPromotions,
  ])

  /**
   * add promotions
   */
  const entries = useInsertPromotionTeasers(
    matchingProducts,
    page === 1 || api === 'legacy' ? promotions : [],
    isFilterActive || !!searchValue
  )

  /**
   * pagination / load more
   */
  const showLoadMoreButton = hasLoadMoreButton && entries.length > showItemMax

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page)
    },
    [setPage]
  )

  const handleLoadMore = useCallback(() => {
    setShowItemMax(showItemMax + productsPerPage)
  }, [productsPerPage, setShowItemMax, showItemMax])

  /**
   * effects
   */
  useScrollIntoView(
    providerRef.current,
    hasScrollBehaviorOnChange && fetchState === 'client' && isPending,
    0,
    250
  )

  return (
    <ProductOverviewContext.Provider
      value={{
        shopCategory,
        entries: {
          all: products,
          filtered: entries,
          filteredAndPaginated:
            api === 'openSearch' ? entries : entries.slice(0, showItemMax),
          total,
        },
        filter: hasFiltersSearch
          ? {
              items: filters,
              resetAll,
              isFilterActive,
              onChangeAll: handleFilterChange,
            }
          : undefined,
        sorting: {
          id: sorting.id,
          onChange: setSortingId,
        },
        search: hasFiltersSearch
          ? {
              value: searchValue,
              clear: clearSearch,
              setValue: setSearchTerm,
            }
          : undefined,
        loadMore: {
          isShown: showLoadMoreButton,
          onLoadMore: handleLoadMore,
          maxItems: showItemMax,
          setMaxItems: setShowItemMax,
        },
        pagination: {
          onPageChange: handlePageChange,
          isShown:
            api === 'openSearch' &&
            totalAll + (hasPromotions ? promotions.length : 0) >
              productsPerPage,
          currentPage: page,
          maxPage: Math.ceil(
            (totalAll + (hasPromotions ? promotions.length : 0)) /
              productsPerPage
          ),
        },
        // prevent loading state for legacy code (no product fetching)
        isPending: api === 'openSearch' ? isPending : false,
        resetAll,
        usedApi: api,
      }}
    >
      <div data-testid="product-overview-provider" ref={providerRef}>
        {children}
      </div>
    </ProductOverviewContext.Provider>
  )
}
