import React, { FunctionComponent, useEffect } from 'react'
import {
  ProductSelectorViewProps,
  ProductSelectorView,
} from './components/View'
import { AggregatedProductList } from '../../../lib/transformation/aggregateProductList'
import { NormalizedProductCard } from '../../molecules/NormalizedProductCard'
import { useProductVariants } from '../../../hooks/useProductVariants'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { WithReferenceTitle } from '../../../providers/gtmV4/types'
import { AggregatedProductSelector } from '../../../lib/transformation/aggregateProductSelector'

type ProductSelectorContainerProps = ProductSelectorViewProps & {
  productList: AggregatedProductList | AggregatedProductSelector
}

export const ProductSelectorSection = withGTMV4Context(
  ({ productList, ...props }: ProductSelectorContainerProps) => {
    const { pushGtmV4Event } = useGtmV4()

    const { activeProduct, colorSelectorVariants, setActiveProductId } =
      useProductVariants(
        productList.normalizedProducts.find(
          p => p.id === productList.randomProductId
        ) || productList.normalizedProducts[0],
        productList.normalizedProducts
      )

    // GTM products list view event on page load
    useEffect(() => {
      pushGtmV4Event({
        eventType: 'viewItemList',
        items: productList.normalizedProducts,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return activeProduct ? (
      <ProductSelectorView
        {...props}
        activeProduct={activeProduct}
        colorSelectorVariants={colorSelectorVariants}
        onVariantChange={setActiveProductId}
      />
    ) : null
  },
  'ProductSelectorSection'
)

export const ProductSelectorCard = withGTMV4Context(
  ({ productList, ...props }: ProductSelectorContainerProps) => {
    const { pushGtmV4Event } = useGtmV4()

    // GTM products list view event on page load
    useEffect(() => {
      pushGtmV4Event({
        eventType: 'viewItemList',
        items: productList.normalizedProducts,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activeProduct = productList.normalizedProducts.find(
      p => p.id === productList.randomProductId
    )

    return activeProduct ? (
      <NormalizedProductCard
        {...props}
        product={activeProduct}
        action="addToCart"
        orientation="portrait"
        variants={productList.normalizedProducts}
        onClick="navigateToPdp"
      />
    ) : null
  },
  'ProductSelectorCard'
)

export const ProductSelector: FunctionComponent<
  WithReferenceTitle<
    ProductSelectorContainerProps & {
      variant?: 'card' | 'section'
    }
  >
> = ({ variant = 'section', ...props }) => {
  return variant === 'section' ? (
    <ProductSelectorSection {...props} />
  ) : (
    <ProductSelectorCard {...props} />
  )
}
