import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Card } from '@/tonies-ui/atoms/Card'
import { GridLayout } from '../components/GridLayout'
import { GridArea } from '../components/GridArea'
import { GenericProductCardViewProps } from '../types'
import { GenericProductCardImage } from '../components/Image'

type CardOrientation = Pick<GenericProductCardViewProps, 'orientation'>

const Skeleton = styled.div<{
  orientation: CardOrientation
  widthPercentage: number
}>`
  width: ${p => p.widthPercentage}%;
  margin: 0.1rem;
  justify-self: ${p =>
    p.orientation === ('portrait' as unknown as CardOrientation)
      ? 'center'
      : 'start'};
  animation: skeleton-loading 1s linear infinite alternate;
  @keyframes skeleton-loading {
    0% {
      background-color: ${props => props.theme.colors.lightgrey};
      /* background-color: hsl(200, 20%, 80%); */
    }
    100% {
      background-color: ${props => props.theme.colors.lightergrey};
      /* background-color: hsl(200, 20%, 95%); */
    }
  }
`

export const GenericProductCardSkeleton: FunctionComponent<CardOrientation> = ({
  orientation,
}) => {
  return (
    <Card>
      <GridLayout
        hasAction={false}
        hasAudioSample={false}
        hasBundleContents={false}
        hasContents={false}
        hasPrices={true}
        hasQuantity={false}
        hasTopRightIconSlot={true}
        hasAssignLink={false}
        hasImage={true}
        orientation={orientation}
      >
        <GridArea name="image" alignY="center">
          <GenericProductCardImage
            src=""
            alt="image placeholder"
            placeholder={true}
            variant="coverCard"
          />
        </GridArea>
        <GridArea
          name="text"
          alignY="stretch"
          style={{ gridTemplateRows: 'auto 1fr' }}
        >
          <Skeleton
            widthPercentage={50}
            orientation={orientation as unknown as CardOrientation}
          >
            &nbsp;
          </Skeleton>
          <Skeleton
            widthPercentage={75}
            orientation={orientation as unknown as CardOrientation}
          >
            &nbsp;
          </Skeleton>
        </GridArea>

        <GridArea alignX="end" name="top-right-icon-slot" tabIndex={-120}>
          <Skeleton
            widthPercentage={200}
            orientation={orientation as unknown as CardOrientation}
          >
            &nbsp;
          </Skeleton>
        </GridArea>
      </GridLayout>
    </Card>
  )
}
