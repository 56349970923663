import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  supportedFilterKeys,
  SupportedFilterKeys,
  useOsProductFilters,
} from '../../../hooks/useProductFilter'
import { useUrlQuery } from '../../urlQuery'
import {
  getProducts,
  getNewFilters as getNewFiltersFunc,
  isNoFilterActive as isNoFilterActiveFunc,
  getNewFiltersType,
} from '../actions'
import { ProductOverviewProviderProps } from '../ProductOverviewProvider'
import { ProductApiType } from '../../../providers/productOverview/types'
import { getSorting } from '../../../lib/opensearch/util/sorting'
import { SupportedSortingIds } from 'lib/opensearch/requests/products/types'

export const useUrlProductOverview = ({
  aggregations = [],
  parameter,
  api,
  hasLoadMoreButton,
  products = [],
  productsPerPage = 24,
  promotions,
}: Pick<
  ProductOverviewProviderProps,
  'promotions' | 'hasLoadMoreButton' | 'productsPerPage' | 'shopCategory'
> &
  NormalizedOpenSearchProductsResponse & {
    api: ProductApiType
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }) => {
  const urlQuery = useUrlQuery()
  const [initalized, setInitalized] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const filterBase = useOsProductFilters({
    aggregations,
  })

  /**
   * sorting
   */
  const sorting = useMemo(
    () =>
      getSorting({
        ...parameter,
        sort: {
          ...parameter?.sort,
          id: urlQuery.getUrlParamAsSingleton('sorting') as SupportedSortingIds,
        },
      }),
    [parameter, urlQuery]
  )

  const setSortingId = useCallback(
    (sorting: string) => {
      urlQuery.setUrlParam({
        sorting,
      })
    },
    [urlQuery]
  )

  const countItems: number =
    products.length + (promotions ? promotions.length : 0)
  const countShownItems = !hasLoadMoreButton ? countItems : productsPerPage

  const page = urlQuery.getUrlParamAsSingleton('page')
  const urlPage = page ? parseInt(page) : 1

  const showItemMax = useMemo(() => {
    return urlPage ? urlPage * productsPerPage : countShownItems
  }, [urlPage, productsPerPage, countShownItems])

  const handleSetPage = useCallback(
    (page: number) => {
      urlQuery.setUrlParam({
        page: page.toString(),
      })
    },
    [urlQuery]
  )
  const setShowItemMax = useCallback(
    (showItems: number) => {
      urlQuery.setUrlParam({
        page: `${Math.floor(showItems / productsPerPage)}`,
      })
    },
    [productsPerPage, urlQuery]
  )

  // set initial value (url param)
  useEffect(() => {
    if (!initalized && urlQuery) {
      setInitalized(true)

      // get search term from url
      const urlSearchTerm = urlQuery.getUrlParamAsSingleton('search')
      if (urlSearchTerm) {
        setSearchTerm(urlSearchTerm)
      }
    }
  }, [urlQuery, initalized])

  // transmitted URL filters to FilterState
  const filters = useMemo(() => {
    // apply all url filters
    const filtersStateByURLFilters = filterBase.reduce(
      (newFilters, { filterKey }) => {
        const urlParam = urlQuery.getUrlParamAsArray(filterKey)

        if (!urlParam) {
          // no filter > return previous
          return newFilters
        } else {
          return urlParam.reduce(
            (newFilters, urlParam) =>
              // build new Filter Groups in loop of url params
              getNewFiltersFunc({
                products: products,
                filters: newFilters,
                search: '',
                updateSingleFilter: {
                  key: filterKey,
                  value: true,
                  id: urlParam,
                },
              }),
            newFilters
          )
        }
      },
      filterBase
    )

    return !searchTerm
      ? filtersStateByURLFilters
      : getNewFiltersFunc({
          products: products,
          filters: filtersStateByURLFilters,
          search: searchTerm,
        })
  }, [filterBase, searchTerm, products, urlQuery])

  const isNoFilterActive = useMemo(
    () => isNoFilterActiveFunc(filters),
    [filters]
  )

  const matchingProducts = useMemo(
    () =>
      api === 'openSearch'
        ? products
        : getProducts(products, filters, searchTerm),
    [api, products, filters, searchTerm]
  )

  const getNewFilters = useCallback(
    ({
      products: productProp = products,
      filters: filtersProp = filters,
      search = searchTerm,
      updateSingleFilter,
    }: Partial<getNewFiltersType>) =>
      getNewFiltersFunc({
        products: productProp,
        filters: filtersProp,
        search,
        updateSingleFilter,
      }),
    [filters, products, searchTerm]
  )

  const handleFilterChange = useCallback(
    (key: SupportedFilterKeys, value: boolean, id: string) => {
      const newFilters = getNewFilters({
        updateSingleFilter: {
          key,
          value,
          id,
        },
      })

      const matchingFilter = newFilters.find(filter => filter.filterKey === key)

      if (matchingFilter) {
        urlQuery.setUrlParam({
          page: undefined,
          [key]:
            matchingFilter.activeFilters.length !== 0
              ? matchingFilter.activeFilters
              : undefined,
        })
      }

      return newFilters
    },
    [getNewFilters, urlQuery]
  )

  const handleUpdateSearch = useCallback(
    (term: string) => {
      setSearchTerm(term)
      urlQuery.setUrlParam({
        page: undefined,
        search: term,
      })
    },
    [urlQuery]
  )

  const resetAll = useCallback(
    (clearSearch = true) => {
      if (clearSearch) setSearchTerm('')

      urlQuery.setUrlParam(
        [...supportedFilterKeys, clearSearch ? 'search' : ''].reduce(
          (params, key) => ({ ...params, page: undefined, [key]: undefined }),
          {}
        )
      )
    },
    [urlQuery]
  )

  const clearSearch = useCallback(() => {
    if (searchTerm !== '') {
      setSearchTerm('')
      urlQuery.setUrlParam({ page: undefined, search: undefined })
    }
  }, [searchTerm, urlQuery])

  return {
    search: {
      searchValue: searchTerm,
      setSearchTerm: handleUpdateSearch,
      clearSearch,
    },
    filters: {
      filters,
      isFilterActive: !isNoFilterActive,
      handleFilterChange,
    },
    sorting: { setSortingId, ...sorting },
    loadMore: {
      showItemMax,
      setShowItemMax,
    },
    pagination: {
      page: urlPage,
      setPage: handleSetPage,
    },
    matchingProducts,
    resetAll,
  }
}
