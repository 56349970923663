import { LocaleConfigType } from '../../hooks/useLocaleConfig'
import config from './config.json'
import { EcomLocale } from '../../config/shopAPI/types'

const BIPoCSkus: LocaleConfigType<
  { [color: string]: string[] } | null,
  EcomLocale
> = config

export default BIPoCSkus
