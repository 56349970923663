import { BxCart, BxResourceIdentifierInput } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

type Request = {
  version: number
  actions: {
    action: 'removeDiscountCode'
    discountCode: BxResourceIdentifierInput
  }[]
}

export const removeDiscountCode = async (
  lcCC: EcomLocale,
  discountCodeId: string,
  cartId: string,
  cartVersion: number
) =>
  fetch<Request, BxCart>({
    query: `/carts/${cartId}`,
    lcCC,
    method: 'post',
    request: {
      version: cartVersion,
      actions: [
        {
          action: 'removeDiscountCode',
          discountCode: {
            id: discountCodeId,
            typeId: 'discount-code',
          },
        },
      ],
    },
  })
