import styled from 'styled-components'

export const StyledLane = styled.div<{
  removePaddingX?: boolean
  noGaps?: boolean
}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${props => (props.noGaps ? 0 : '2.5rem')};
  margin: 0 auto;
  width: 100%;
  padding: 0
    ${props => (props.removePaddingX ? 0 : props.theme.section.column.padding)};

  // FIXME: safari fix
  > * {
    min-width: 0;
  }

  @media (min-width: ${props => props.theme.breakpoint.screenLaptop}) {
    gap: ${props => (props.noGaps ? 0 : '2.5rem')};
  }

  &.normal {
    max-width: 75rem;
  }
`
