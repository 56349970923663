import React from 'react'
import Head from 'next/head'

type DomainVerificationProps = {
  facebookKey?: string
  googleKey?: string
  google2Key?: string
  pinterestKey?: string
}

export const DomainVerification = ({
  facebookKey,
  googleKey,
  google2Key,
  pinterestKey,
}: DomainVerificationProps) => (
  <Head>
    {googleKey && <meta name="google-site-verification" content={googleKey} />}
    {google2Key && (
      <meta name="google-site-verification" content={google2Key} />
    )}
    {facebookKey && (
      <meta name="facebook-domain-verification" content={facebookKey} />
    )}
    {pinterestKey && <meta name="p:domain_verify" content={pinterestKey} />}
  </Head>
)
