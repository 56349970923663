import { Button, Paragraph, Spacing } from '@boxine/tonies-ui'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

export function NoTonieCompatibleYetModalContent({
  setIsAssignModalOpen,
  tune,
}: {
  setIsAssignModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  tune: NormalizedProduct
}) {
  const { t } = useTranslation()
  if (!tune) {
    return null
  }
  return (
    <div data-testid="no-compatible-tonie-modal-inner">
      <Spacing py="spacing-s">
        <Paragraph>
          {tune.assignableToAllCreativeTonies
            ? t('ownAudioContent:AssignModalNoCreativeTonieYetCopy')
            : t('ownAudioContent:AssignModalNoCompatibleTonieYetCopy')}
        </Paragraph>
      </Spacing>
      <Paragraph>
        <Trans
          i18nKey="ownAudioContent:AddTonieCrosslink"
          components={{
            a: (
              <Button
                as="a"
                href={`https://mytonies.com/${
                  tune.assignableToAllCreativeTonies ? 'creative-' : ''
                }tonies`}
                target="_blank"
                rel="noopener noreferrer"
                variant="secondary"
                data-testid="mytonies-link"
              ></Button>
            ),
            b: <b />,
          }}
        />
      </Paragraph>
      <Spacing mt="spacing-m">
        <Button
          type="button"
          variant="primary"
          onClick={() => setIsAssignModalOpen(false)}
        >
          {t('ownAudioContent:AssignModalContinue')}
        </Button>
      </Spacing>
    </div>
  )
}
