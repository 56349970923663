import styled from 'styled-components'

export type ContainerProps = {
  backgroundColor?: string
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: ${props => props.backgroundColor || 'transparent'};

  @media (min-width: ${props => props.theme.breakpoint.screenTablet}) {
    padding-bottom: 3rem;
  }
`
