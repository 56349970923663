import React, {
  ComponentPropsWithoutRef,
  FunctionComponent,
  useCallback,
} from 'react'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { GtmV4SelectContentEvent } from '../../../providers/gtmV4/types'
import { EditorialTeaser as EditorialTeaserUI } from '@/tonies-ui/molecules/EditorialTeaser'
import { Card } from '@braze/web-sdk'
import { logContentCardClick } from '../../../lib/braze'

type EditorialTeaserProps = ComponentPropsWithoutRef<
  typeof EditorialTeaserUI
> & {
  contentCard?: Card
}

const EditorialTeaserCointainer: FunctionComponent<EditorialTeaserProps> = ({
  backgroundImageUrl,
  ...props
}) => {
  const { pushGtmV4Event } = useGtmV4()

  const handleOnClick = useCallback(
    (type: GtmV4SelectContentEvent['type']) => {
      pushGtmV4Event({
        eventType: 'selectContent',
        ctaLabel: props.ctaText,
        ctaLink: props.ctaUrl,
        type,
      })

      if (props.contentCard) {
        logContentCardClick(props.contentCard)
      }
    },
    [pushGtmV4Event, props]
  )

  return (
    <EditorialTeaserUI
      {...props}
      backgroundImageUrl={backgroundImageUrl}
      onClick={handleOnClick}
    />
  )
}

export const EditorialTeaser = withGTMV4Context(
  EditorialTeaserCointainer,
  'EditorialTeaser'
)
