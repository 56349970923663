import React, { ReactNode } from 'react'
import styled, { css, CSSProperties, useTheme } from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import {
  Box,
  Icon,
  Paragraph,
  spacings,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { BoxProps } from '@boxine/tonies-ui/dest/types/src/atoms/Box'
import { ThemeSpacing } from '@/tonies-ui/themes/spacings'

export interface InfoBoxProps {
  children: ReactNode
  type: InfoBoxType
  borderRadius?: BoxProps['borderRadius'] | 'none' | 'default'
  alignment?: CSSProperties['justifyContent']
  width?: 'auto' | 'fullwidth'
  'data-testid'?: string
  px?: ThemeSpacing
}

const useBackgroundColor = (type: InfoBoxType) => {
  const { colorContext, colors } = useTheme()

  if (colorContext === 'dark') return colors.white

  switch (type) {
    case 'warning':
      return colors['yellow-20']
    case 'info':
      return colors['lightblue-20']
    case 'error':
      return colors['pink-20']
    case 'success':
    default:
      return colors['green-20']
  }
}

function useIconColor(type: InfoBoxType) {
  const { colors } = useTheme()

  switch (type) {
    case 'success':
      return colors['1-color-6-shades-of-secondary-shaded-green']
    case 'error':
      return colors['1-color-6-shades-of-secondary-shaded-pink']
    case 'warning':
      return colors['yellow-100']
    case 'info':
    default:
      return colors['1-color-6-shades-of-secondary-shaded-blue']
  }
}

function getIconType(type: InfoBoxType) {
  switch (type) {
    case 'success':
      return icons.checkmarkFill
    case 'info':
      return icons.infoFill
    case 'warning':
    case 'error':
    default:
      return icons.exclamationMarkFill
  }
}

export const StyledWrapper = styled(Box)<InfoBoxProps>`
  ${({ width, alignment }) => `
    display: flex;
    align-items: center;
    justify-content: ${alignment};

    ${
      width === 'auto' &&
      css`
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;
      `
    }
  `}
`

export const StyledIcon = styled(Icon)`
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  margin-right: ${spacings['spacing-xs']};
`

export type InfoBoxType = 'info' | 'success' | 'error' | 'warning'

export const InfoBox = ({
  children,
  className,
  type,
  width = 'auto',
  alignment = 'center',
  borderRadius = 'default',
  'data-testid': dataTestId,
  px = 'spacing-xs',
}: InfoBoxProps & Styleable) => {
  const bgColor = useBackgroundColor(type)
  const iconColor = useIconColor(type)
  const icon = getIconType(type)
  const isMobile = useIsViewportMobile()

  return (
    <StyledWrapper
      data-testid={dataTestId}
      backgroundColor={bgColor}
      className={className}
      type={type}
      borderRadius={
        (borderRadius === 'default'
          ? isMobile
            ? ['l']
            : ['m']
          : borderRadius !== 'none'
          ? [borderRadius]
          : undefined) as BoxProps['borderRadius']
      }
      py="spacing-xs"
      px={px}
      width={width}
      alignment={alignment}
    >
      <StyledIcon type={icon} fill={iconColor} />

      <Paragraph asHTMLTag="span" size={3} fontFamily="serif" weight={300}>
        {children}
      </Paragraph>
    </StyledWrapper>
  )
}
