import { useMemo } from 'react'
import { isPromotionEntry } from '../../../../utils/isPromotionEntry'
import { useProducts } from '../../../../hooks/useProducts'

export const useVisibleEntries = (
  filteredAndPaginated: NormalizedCardEntry[], // remaining entries after filters & pagination have been applied
  selectedSkus: string[] | undefined // SKUs of selected products in "all"
) => {
  const selectionFetch = useProducts({
    parameter: {
      sku: selectedSkus,
    },
    hasClientFetch: !!selectedSkus?.length,
  })

  return useMemo(() => {
    // Extract the SKUs of all products that are contained in the current set of
    // filtered and paginated entries (to ease product lookups in the next step)

    const shownSkus: string[] = []

    filteredAndPaginated.forEach(e => {
      if (!isPromotionEntry(e) && e.sku) {
        shownSkus.push(e.sku)
      }
    })

    // Initialize result set

    let visibleEntries = [...filteredAndPaginated]

    // Identify all products which have been manually selected by the user but
    // would usually NOT be shown because they don't match the current filter/pagination
    // criteria; then append a card for each of these product to ensure that the current
    // is always fully visible

    selectionFetch.products?.forEach(product => {
      if (!shownSkus.includes(product.sku)) {
        visibleEntries.push(product)
      }
    })

    // If the current filter criteria match no products, we want to display an empty
    // selection, even if we would have promotion cards to display.

    const productCount = visibleEntries.reduce(
      (count, e) => (isPromotionEntry(e) ? count : count + 1),
      0
    )

    if (productCount === 0) {
      visibleEntries = []
    }

    // Return the result set

    return visibleEntries
  }, [filteredAndPaginated, selectionFetch.products])
}
