import React from 'react'
import { useTheme } from 'styled-components'
import { Paragraph } from '@boxine/tonies-ui'
import { COLOR } from '../variables'
import { Status } from '../types'

export type AriaLiveType = 'polite' | 'assertive'

export type MessageProps = {
  ariaLive?: AriaLiveType
  text: string
}

export type Props = {
  message?: MessageProps
  status?: Status
  styling?: string
  id: string
  dataTestId: string
}

export const ZERO_WIDTH_SPACE = '\u200B'

export const Message = ({
  message,
  status,
  styling: stylingProp,
  id,
  dataTestId,
}: Props) => {
  const theme = useTheme()
  const { colorContext } = theme
  const styling =
    stylingProp || (colorContext === 'dark' ? 'secondary' : 'primary')

  return (
    <Paragraph size={4} weight={500}>
      <span
        id={id}
        data-testid={dataTestId}
        style={{
          userSelect: 'none',
          color:
            status === 'error' && styling !== 'secondary'
              ? COLOR.ERROR
              : status === 'ok' && styling !== 'secondary'
              ? COLOR.SUCCESS
              : styling === 'secondary'
              ? theme.colors.white
              : theme.colors['anthracite-100'],
        }}
        // FIXME
        // Use <Trans /> and <Link /> component here
        // We use dangerouslySetInnerHTML to allow links in the message text.
        // We use an invisible space character to enforce equal height when there is no message text.
        dangerouslySetInnerHTML={{ __html: message?.text || ZERO_WIDTH_SPACE }}
      />
    </Paragraph>
  )
}
