import React from 'react'
import styled from 'styled-components'
import { Paragraph, useIsViewportTablet } from '@boxine/tonies-ui'
import { DeliveryInfoTeaserCollectionProps } from '../../../components/organisms/DeliveryInfoTeaserCollection'
import { GenericImageText } from '../../../components/molecules/GenericImageText'

const StyledGrid = styled.div<{ layout: 'horizontal' | 'vertical' }>`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: ${p =>
    p.layout === 'horizontal' ? '1fr 1fr 1fr' : '1fr'};
  text-align: center;
  align-items: flex-start;
`

const StyledGridArea = styled.div`
  display: grid;
  justify-items: center;
`

export const CartDeliveryInfoList = ({
  cartDeliveryInfoTeaserList,
}: {
  cartDeliveryInfoTeaserList:
    | DeliveryInfoTeaserCollectionProps['items']
    | undefined
    | null
}) => {
  const isTablet = useIsViewportTablet()

  if (cartDeliveryInfoTeaserList) {
    return (
      <StyledGrid layout={isTablet ? 'vertical' : 'horizontal'}>
        {cartDeliveryInfoTeaserList.map((item, i) => (
          <StyledGridArea key={item.title + i}>
            <GenericImageText
              media={{
                variant: 'deliveryInfo',
                iconType: item.iconType,
                width: '2rem',
              }}
              orientation="vertical"
            >
              <Paragraph size={4}>{item.title}</Paragraph>
            </GenericImageText>
          </StyledGridArea>
        ))}
      </StyledGrid>
    )
  }
  return <></>
}
