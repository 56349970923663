import React, { useCallback } from 'react'
import {
  Grid,
  Paragraph,
  Spacing,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import { NormalizedProductCard } from '../../molecules/NormalizedProductCard'
import { useCartActions } from '../../../hooks/useCartActions'
import { useTranslation } from 'next-i18next'
import { addProductAction } from '../../../providers/cartActions/actions/addProductAction'
import { useGtmV4 } from '../../../providers/gtmV4'
import { cart } from '@boxine/tonies-ui/icons'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { useRecommendations } from '../../../providers/recommendations'

export const BIPoCModal: NormalizedFunctionComponent<{
  products: NormalizedProduct[]
  onClose: () => void
}> = ({ onClose, products }) => {
  const { push } = useCartActions()
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()
  const { pushGtmV4Event } = useGtmV4()
  const { handleSetLastSeenSku } = useRecommendations()

  const handleAddProduct = useCallback(
    (product: NormalizedProduct) => {
      // prevent action in case of the default included Toniebox-Tonie (Beige Tonie)
      if (product.sku?.includes('beige')) {
        return onClose
      }
      handleSetLastSeenSku(product.sku)
      return () =>
        push(
          addProductAction({
            pushGtmV4Event,
            product,
            quantity: 1,
          })
        ).then(onClose)
    },
    [handleSetLastSeenSku, onClose, push, pushGtmV4Event]
  )

  return (
    <Modal
      isOpen
      headline={t('BIPoCModal:headline')}
      shouldFocusFirstElement
      onClose={onClose}
    >
      <Spacing mb="spacing-m">
        <Paragraph size={2}>{t('BIPoCModal:copy')}</Paragraph>
      </Spacing>
      <Grid columns={isMobile ? 1 : 3} hasGapH hasGapV>
        {products.map(product => (
          <NormalizedProductCard
            key={`BIPoC-${product.sku}`}
            action={{
              icon: cart,
              onClick: handleAddProduct(product),
            }}
            hasBorder
            hasPrices={false}
            onClick={handleAddProduct(product)}
            orientation={isMobile ? 'landscape' : 'portrait'}
            product={product}
          />
        ))}
      </Grid>
    </Modal>
  )
}
