import rgb2hex from 'rgb2hex'
import {
  getModeByBackgroundColor,
  parseColorString,
  theme,
  RGBA,
} from '@boxine/tonies-ui'

export { getModeByBackgroundColor, parseColorString }

export const rgbaToString = ({ r, g, b, a }: RGBA): string =>
  a === 0
    ? 'transparent'
    : a === 1
    ? `rgb(${r},${g},${b})`
    : `rgba(${r},${g},${b},${a})`

/**
 * lighten: 0 > amount <= 1.0
 * darken: -1.0 >= amount < 0
 */
export const lightenDarkenColor = (color: string, amount: number): string => {
  const { r, g, b, a } = parseColorString(color)
  const t = amount < 0 ? 0 : 255
  const p = amount < 0 ? amount * -1 : amount

  return rgbaToString({
    r: Math.round((t - r) * p) + r,
    g: Math.round((t - g) * p) + g,
    b: Math.round((t - b) * p) + b,
    a: a,
  })
}

export function getLightBackgroundColor(
  color: string | null | undefined
): string {
  if (!color || color === 'transparent') {
    return 'transparent'
  }

  const { a } = parseColorString(color)
  if (a === 0) {
    return 'transparent'
  }

  switch (color) {
    // the primary color is not allowed to be lightend
    // therefor we use pink20 also for the primary color
    case theme.colors['primary']:
    case rgb2hex(theme.colors['primary']).hex:
    case theme.colors['pink-100']:
    case rgb2hex(theme.colors['pink-100']).hex:
      return theme.colors['pink-20']

    case theme.colors['lightblue-100']:
    case rgb2hex(theme.colors['lightblue-100']).hex:
      return theme.colors['lightblue-20']

    case theme.colors['green-100']:
    case rgb2hex(theme.colors['green-100']).hex:
      return theme.colors['green-20']

    case theme.colors['berry-100']:
    case rgb2hex(theme.colors['berry-100']).hex:
      return theme.colors['berry-20']

    case theme.colors['anthracite-100']:
    case rgb2hex(theme.colors['anthracite-100']).hex:
      return theme.colors['anthracite-20']

    // Toniebox special edition Lime Green
    case '#a7c82c':
    case theme.colors['1-color-9-special-edition-limegreen-100']:
    case rgb2hex(theme.colors['1-color-9-special-edition-limegreen-100']).hex:
      return theme.colors['1-color-9-special-edition-limegreen-20']

    // Preloved Toniebox Turquoise
    case '#00afaa':
      return rgbaToString(parseColorString('#ccefee'))

    default:
      return lightenDarkenColor(color, 0.8)
  }
}
