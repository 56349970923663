import React, { useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import styled, { useTheme } from 'styled-components'
import { Button, Spacing, Box, Icon } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { useCartState, useTunesInCart } from '../../../hooks/useCartState'
import { Hr } from '../../../components/atoms/Hr'
import { ShopNowButton } from '../../../components/organisms/EmptyState'
import { useCartActions } from '../../../hooks/useCartActions'
import { useAuth } from '../../../providers/auth'
import { InfoBox } from '@/tonies-ui/atoms/InfoBox'
import { useNativeApp } from '../../../providers/nativeApp'
import { Summary } from '../../../components/organisms/CartSummary'
import { useGtmV4 } from '../../../providers/gtmV4'

const Wrapper = styled.div`
  bottom: 0;
  position: sticky;
  width: 100%;
  z-index: 1;
`

const StyledButton = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  text-align: initial;
`

const StyledButtonSpacing = styled(Spacing)`
  display: flex;
  gap: 1rem;
`

const StyledFooterSpacing = styled(Spacing)`
  text-align: center;
`

const StyledIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`

export const StickyFooter = () => {
  const { authenticated, login } = useAuth()
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { cart } = useCartState()
  const { state: cartActionState } = useCartActions()
  const { hasTunes } = useTunesInCart()
  const { isApp } = useNativeApp()
  const { pushGtmV4Event } = useGtmV4()
  const lineItems = useMemo(() => cart?.lineItems || [], [cart?.lineItems])
  const isEmptyCart = lineItems.length === 0
  const needLogin = hasTunes && !authenticated
  const [isOpen, setIsOpen] = useState(false)

  const handleOnClick = useCallback(() => {
    setIsOpen(!isOpen)

    pushGtmV4Event({
      eventType: 'click',
      type: 'onClick',
      label: '[coded] Cart Sticky Footer Prices',
      action: isOpen
        ? 'sticky_footer_prices_hide'
        : 'sticky_footer_prices_show',
    })
  }, [isOpen, pushGtmV4Event])

  const CheckoutButton = () => {
    const hasLineItems = lineItems.length > 0
    const productsCount = hasLineItems
      ? ` (${lineItems.map(l => l.quantity).reduce((a, b) => a + b)})`
      : undefined

    return (
      <Button
        as="a"
        data-testid="checkout-button"
        /**
         * use window.location.search to keep the existing query parameters
         * when going to checkout
         */
        href={t('header:CheckoutLink') + window.location.search}
        // @ts-expect-error: `disabled` is not supported on <a />, but can be used for disabled behavior
        disabled={cartActionState === 'processing' || !hasLineItems}
      >
        {t('cart:checkoutButtonText')}
        {productsCount}
      </Button>
    )
  }

  const LoginButton = () => {
    const redirectUri = new URL(window.location.toString())

    return (
      <Button
        data-testid="checkout-login-button"
        icon="login"
        onClick={() => {
          redirectUri.searchParams.append('expandSideCart', '1')
          login({
            redirectUri: redirectUri.toString(),
          })
        }}
      >
        {t('checkout:login:loginButtonText')}
      </Button>
    )
  }

  return (
    <Wrapper>
      {needLogin && (
        <InfoBox
          type="error"
          width="fullwidth"
          borderRadius="none"
          px="spacing-s"
          data-testid="infoBox-login-required"
        >
          {t('cart:digital:loginRequired')}
        </InfoBox>
      )}
      <Box backgroundColor={colors.middlegrey}>
        {!isEmptyCart && (
          <>
            <StyledButton
              data-testid="button-stickyFooter-toggle"
              onClick={handleOnClick}
            >
              <StyledButtonSpacing px="spacing-s" pt="spacing-s">
                <Summary
                  hasSubtotalRows={false}
                  hasCouponRows={false}
                  hasShippingCostRows={false}
                />
                <Spacing pt="spacing-xxs">
                  {isOpen ? (
                    <StyledIcon type={icons.arrowDown} />
                  ) : (
                    <StyledIcon type={icons.arrowUp} />
                  )}
                </Spacing>
              </StyledButtonSpacing>
            </StyledButton>
            {isOpen && (
              <>
                <Spacing py="spacing-s">
                  <Hr color="anthracite-40" />
                </Spacing>
                <Box px="spacing-s">
                  <Summary
                    hasShippingCostRows={cart?.bxLineItemTypes.includes(
                      'physical'
                    )}
                    hasTotalRows={false}
                  />
                </Box>
              </>
            )}
          </>
        )}
        <StyledFooterSpacing
          px="spacing-s"
          pb="spacing-s"
          pt={!isOpen ? 'spacing-s' : 'spacing-xs'}
        >
          {!isApp && isEmptyCart ? (
            <ShopNowButton />
          ) : needLogin ? (
            <LoginButton />
          ) : (
            <CheckoutButton />
          )}
        </StyledFooterSpacing>
      </Box>
    </Wrapper>
  )
}
