import React, {
  useEffect,
  FunctionComponent,
  useState,
  useCallback,
  useRef,
  ReactNode,
  PropsWithChildren,
  useMemo,
} from 'react'
import { Document as RichTextDocument } from '@contentful/rich-text-types'
import { Header } from '../../components/organisms/Header'
import { Footer } from '../../components/organisms/Footer'
import { Meta, MetaProps } from '../../components/organisms/Meta'
import { ToastContainer } from '@/tonies-ui/atoms/Toast'
import { StickyFooter } from './components/StickyFooter'
import { StickySpacer } from './components/StickySpacer'
import { Banner } from './components/Banner'
import { useLocaleSelectorContext } from '../../providers/localeSelector'
import { MetaNavigation } from '../../components/organisms/MetaNavigation'
import { MainNavigation } from '../../components/organisms/MainNavigation'
import { Main } from './components/Main'
import styled, { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useAggregatedShopLocale } from '../../providers/aggregatedShopLocale'
import { useNativeApp } from '../../providers/nativeApp'
import { useHeaderData } from '../../hooks/useHeaderData'
import { useLocale } from '../../providers/locale'
import { isEcomLocale } from '../../config/shopAPI'
import { useMeasurements } from '../../providers/measurements'
import { StickyHeader } from './components/StickyHeader'
import { ColorContextProvider } from '@/tonies-ui/index'
import dynamic from 'next/dynamic'
import debounce from 'lodash/debounce'
import { useRemoveAlreadyBoughtTunesFromCart } from '../../hooks/useRemoveAlreadyBoughtTunesFromCart'
import { ComponentSpinner } from '@/tonies-ui/atoms/ComponentSpinner'
import * as Cookies from 'js-cookie'
import { abTestsArray, cookiePrefix } from '../../lib/configcat/abTests'
import { useGtmV4 } from '../../providers/gtmV4'
import { SideCartProvider } from '../../providers/sideCart'
import { useProtectedRoute } from '../../hooks/useProtectedRoute'
import { isToniesColor } from '@/tonies-ui/themes/colors'

interface PageLayoutProps {
  meta?: MetaProps
  bgColor?: string
  dataTestId?: string
  header?: ReactNode
  footer?: ReactNode
  isMinimalHeaderVersion?: boolean
  isMinimalFooterVersion?: boolean
  stickyFooterChildren?: ReactNode
  stickyHeaderChildren?: ReactNode
  hasPaddingTop?: boolean
  preventRemoveAlreadyBoughtTunes?: boolean
  hasSideCartAutoExpandOnAddToCart?: boolean
  preventScrollingHeadsInFooter?: boolean
  isProtectedRoute?: boolean
  isPending?: boolean
}

const Wrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor, theme: { colors } }) =>
    backgroundColor && isToniesColor(backgroundColor)
      ? colors[backgroundColor]
      : backgroundColor};
  min-height: 100vh;
`

// hide Zendesk Launcher
const GlobalZendeskLauncherStyle = createGlobalStyle`
  #launcher {
    display: none !important;
  }
`

// disable SSR to avoid hydration error
const SupportButtons = dynamic(
  () =>
    import('../../components/atoms/SupportButtons').then(
      mod => mod.SupportButtons
    ),
  {
    ssr: false,
  }
)

// Anchor-Behavior for async/client pages, where the dom isnt initial ready
const useScrollToAnchor = () => {
  const scrolledRef = React.useRef(false)
  const hashRef = React.useRef<string>()

  useEffect(() => {
    const { hash } = window.location

    if (hash) {
      // We want to reset if the hash has changed
      if (hashRef.current !== hash) {
        hashRef.current = hash
        scrolledRef.current = false
      }
      // only attempt to scroll if we haven't yet
      if (!scrolledRef.current) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          scrolledRef.current = true
        }
      }
    }
  })
}

export const PageLayout: FunctionComponent<
  PageLayoutProps & PropsWithChildren
> = ({
  children,
  dataTestId,
  meta,
  bgColor,
  header,
  footer,
  isMinimalHeaderVersion = false,
  isMinimalFooterVersion = false,
  isProtectedRoute = false,
  isPending = false,
  stickyFooterChildren = null,
  stickyHeaderChildren = null,
  hasPaddingTop = false,
  preventRemoveAlreadyBoughtTunes = false,
  hasSideCartAutoExpandOnAddToCart = true,
  preventScrollingHeadsInFooter = false,
}) => {
  const { t } = useTranslation()
  const { isApp } = useNativeApp()
  const { hasKeycloak, hasSideCartAutoExpandOnAddToCart: hasAutoExpandFF } =
    useFeatureFlags()
  /**
   * Since we hide the header for the app (and therefore the entry into the cart and checkout),
   * it is necessary to always set the autoExpand to "true" when we are in the AppView.
   */
  const hasSideCartAutoExpandOnAddToCartFeatureFlag = isApp || hasAutoExpandFF
  const removeAlreadyBoughtTunesFromCart = useRemoveAlreadyBoughtTunesFromCart()
  const aggregatedShopLocale = useAggregatedShopLocale()

  // redirect to login
  const { isPending: isProtectedRoutePending } =
    useProtectedRoute(isProtectedRoute)

  const hasCart = isEcomLocale(useLocale())

  const { isSelectorExpanded: isLocaleSelectorExpanded } =
    useLocaleSelectorContext()

  const headerData = useHeaderData()
  const refBanner = useRef<HTMLDivElement>(null)
  const refMetaNavigation = useRef<HTMLDivElement>(null)

  const {
    headersHeight,
    setHeadersHeight,
    mainNavigationHeight,
    stickyFooterHeight,
    stickyHeaderHeight,
    setStickyFooterHeight,
    setStickyHeaderHeight,
    getHeightOfRefs,
  } = useMeasurements()

  const topStickyHeader = useMemo(
    () => headersHeight + mainNavigationHeight,
    [headersHeight, mainNavigationHeight]
  )

  const [bannerContent, setBannerContent] = useState<
    RichTextDocument | string | undefined
  >()

  useEffect(() => {
    if (hasKeycloak === false) {
      // Replace BannerContent if KeyCloak is disabled
      setBannerContent(t('Maintenance:banner:keycloak'))
    } else {
      // Display locale-specific promotion banner (optional)
      setBannerContent(aggregatedShopLocale.content.promotionBanner?.content)
    }
  }, [hasKeycloak, aggregatedShopLocale, t])

  // remove automatically already bought tunes from cart
  useEffect(() => {
    if (!preventRemoveAlreadyBoughtTunes) {
      removeAlreadyBoughtTunesFromCart()
    }
  }, [preventRemoveAlreadyBoughtTunes, removeAlreadyBoughtTunesFromCart])

  // Initialize Google Tag Manager V4
  const gtmDomain = process.env.NEXT_PUBLIC_GTM_DOMAIN
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID || ''
  const gtmAuth = process.env.NEXT_PUBLIC_GTM_AUTH
  const gtmPreview = process.env.NEXT_PUBLIC_GTM_PREVIEW
  useEffect(() => {
    /**
     * TODO
     * Move 1. and 2. to GTM in coordination with Marc
     */
    /**
     * https://docs.usercentrics.com/#/consent-mode
     */
    const scripts = [
      // 1. Google Consent Mode Defaults
      `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments)}gtag("consent","default",{ad_user_data:"denied",ad_personalization:"denied",ad_storage:"denied",analytics_storage:"denied",wait_for_update:2e3});gtag("set","ads_data_redaction",true);`,
      // 2. Usercentrics Consent Listener
      `window.addEventListener("ucEvent",(function(e){if(e.detail&&e.detail.event=="consent_status"){var ucAnalyticsService="Google Analytics 4";var ucAdService="Google Ads";if(e.detail.hasOwnProperty(ucAnalyticsService)&&e.detail.hasOwnProperty(ucAdService)){gtag("consent","update",{ad_storage:e.detail[ucAdService]?"granted":"denied",ad_user_data:e.detail[ucAdService]?"granted":"denied",ad_personalization:e.detail[ucAdService]?"granted":"denied",analytics_storage:e.detail[ucAnalyticsService]?"granted":"denied"})}else{if(e.detail.hasOwnProperty(ucAdService)){gtag("consent","update",{ad_storage:e.detail[ucAdService]?"granted":"denied",ad_user_data:e.detail[ucAdService]?"granted":"denied",ad_personalization:e.detail[ucAdService]?"granted":"denied"})}if(e.detail.hasOwnProperty(ucAnalyticsService)){gtag("consent","update",{analytics_storage:e.detail[ucAnalyticsService]?"granted":"denied"})}}}}));`,
      // 3. Google Tag Manager
      `(function(t,e,a,m,g){t[m]=t[m]||[];t[m].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var n=e.getElementsByTagName(a)[0],r=e.createElement(a),i=m!="dataLayer"?"&l="+m:"";r.async=true;r.src="${gtmDomain}/gtm.js?id="+g+i+"&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x";n.parentNode.insertBefore(r,n)})(window,document,"script","dataLayer","${gtmId}");`,
    ]
    scripts.forEach((script, index) => {
      const el = document.createElement('script')
      el.innerHTML = script
      document.head.insertBefore(el, document.head.childNodes[index])
    })
  }, [gtmAuth, gtmDomain, gtmId, gtmPreview])

  const onStickyHeaderResize = useCallback(
    (_width: number, height: number) => {
      setStickyHeaderHeight(height)
    },
    [setStickyHeaderHeight]
  )

  const onStickyFooterResize = useCallback(
    (_width: number, height: number) => {
      setStickyFooterHeight(height)
    },
    [setStickyFooterHeight]
  )

  const showFooter = useMemo(() => {
    return !isApp
  }, [isApp])

  const measureHeaders = useMemo(
    () =>
      debounce(() => {
        // Sum of the visible heights of all headers
        setHeadersHeight(getHeightOfRefs([refBanner, refMetaNavigation]))
      }, 50),
    [setHeadersHeight, getHeightOfRefs]
  )

  useEffect(() => {
    measureHeaders()

    window.addEventListener('resize', measureHeaders)
    window.addEventListener('scroll', measureHeaders)

    return () => {
      window.removeEventListener('resize', measureHeaders)
      window.removeEventListener('scroll', measureHeaders)
    }
  }, [measureHeaders])

  // Scroll to top when locale selector is expanded
  useEffect(() => {
    if (isLocaleSelectorExpanded) {
      window.scrollTo(0, 0)
    }
  }, [isLocaleSelectorExpanded])

  // Push AB-Tests-Cookies to datalayer
  const { pushGtmV4Event } = useGtmV4()

  useEffect(() => {
    abTestsArray.forEach(({ key }) => {
      const variantId = Cookies.get(`${cookiePrefix}${key}`)

      if (variantId) {
        pushGtmV4Event({
          eventType: 'experimentImpression',
          key,
          value: variantId,
        })
      }
    }, {})
  }, [pushGtmV4Event])

  // Anchor behavior
  useScrollToAnchor()

  return (
    <SideCartProvider
      autoExpandOnAddToCart={
        hasSideCartAutoExpandOnAddToCart &&
        hasSideCartAutoExpandOnAddToCartFeatureFlag
      }
    >
      <noscript>
        <iframe
          title="gtmv4-noscript-iframe"
          src={`${gtmDomain}/ns.html?id=${gtmId}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <Wrapper backgroundColor={bgColor} data-testid={dataTestId}>
        {!isApp && meta && <Meta {...meta} />}
        {bannerContent && !isMinimalHeaderVersion && (
          <aside ref={refBanner}>
            <Banner
              referenceTitle="[coded] PromotionBanner"
              content={bannerContent}
              onClose={measureHeaders}
            />
          </aside>
        )}
        {!isApp &&
          (header || (
            <>
              {!isMinimalHeaderVersion && headerData.metaNavigationItems && (
                <aside ref={refMetaNavigation}>
                  <MetaNavigation
                    metaNavigationItems={headerData.metaNavigationItems}
                  />
                </aside>
              )}
              <Header>
                <MainNavigation
                  logoSrc={headerData.logoSrc}
                  logoAlt={headerData.logoAlt}
                  logoLink={headerData.logoLink}
                  primaryNavigationItems={
                    aggregatedShopLocale.config.primaryNavigationItems
                  }
                  primaryNavigationBreakpointPx={
                    aggregatedShopLocale.config.primaryNavigationBreakpointPx
                  }
                  primaryNavigationSmallerBreakpointRem={
                    aggregatedShopLocale.config
                      .primaryNavigationSmallerBreakpointRem
                  }
                  primaryNavigationLargerBreakpointRem={
                    aggregatedShopLocale.config
                      .primaryNavigationLargerBreakpointRem
                  }
                  isMinimalVersion={isMinimalHeaderVersion}
                  hasCart={hasCart}
                />
              </Header>
            </>
          ))}
        {stickyHeaderChildren && (
          <>
            <StickyHeader top={topStickyHeader} onResize={onStickyHeaderResize}>
              {stickyHeaderChildren}
            </StickyHeader>
            <StickySpacer
              height={stickyHeaderHeight - headersHeight}
              placement="header"
            />
          </>
        )}
        <Main hasPaddingTop={hasPaddingTop}>
          <ColorContextProvider backgroundColor={bgColor}>
            {isPending || isProtectedRoutePending ? (
              <ComponentSpinner height="80vh" />
            ) : (
              children
            )}
          </ColorContextProvider>
        </Main>
        <ToastContainer />
        <GlobalZendeskLauncherStyle />
        {showFooter && (
          <>
            {footer ||
              (aggregatedShopLocale.content.footer && (
                <Footer
                  hasScollingHeads={!preventScrollingHeadsInFooter}
                  {...aggregatedShopLocale.content.footer}
                />
              ))}
            <StickyFooter onResize={onStickyFooterResize}>
              {!isMinimalFooterVersion && <SupportButtons />}
              {stickyFooterChildren}
            </StickyFooter>
            {/* Reserve extra height at the bottom so that the sticky footer never hides content */}
            <StickySpacer height={stickyFooterHeight} placement="footer" />
          </>
        )}
      </Wrapper>
    </SideCartProvider>
  )
}
