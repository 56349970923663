const environments = ['dev', 'local', 'prod', 'preview', 'stage'] as const
type Environments = (typeof environments)[number]

export const isSupportedEnvironment = (supportedEnvironments: Environments[]) =>
  process.env.NEXT_PUBLIC_ENVIRONMENT
    ? supportedEnvironments.includes(
        process.env.NEXT_PUBLIC_ENVIRONMENT as Environments
      )
    : false

export const isDeployedDevEnvironment = isSupportedEnvironment([
  'dev',
  'preview',
  'stage',
])

export const isDevEnvironment = isSupportedEnvironment([
  'dev',
  'preview',
  'stage',
  'local',
])

export const isDeployedPreviewEnvironment = isSupportedEnvironment(['preview'])
export const isPreviewEnvironment = isSupportedEnvironment(['local', 'preview'])
