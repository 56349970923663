import { Paragraph } from '@boxine/tonies-ui'
import { addSolo, delete1, lessSolo } from '@boxine/tonies-ui/icons'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import styled, { useTheme } from 'styled-components'
import { IconButton } from '@/tonies-ui/atoms/IconButton'

export type QuantitySelectorProps = {
  isMaximumReached: boolean
  isPending: boolean
  hasQuantityChange?: boolean
  hasRemove?: boolean
  onAdd: () => void
  onRemove: () => void
  onRemoveAll: () => void
  value: number
}

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, max-content);
`

const ignoreClick = () => undefined

export const QuantitySelector: FunctionComponent<QuantitySelectorProps> = ({
  isMaximumReached,
  isPending,
  hasQuantityChange = true,
  hasRemove = true,
  onAdd,
  onRemove,
  onRemoveAll,
  value,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    // FIXME: IconButton doesn't yet support "disabled" states
    <Wrapper>
      {hasRemove && (
        <IconButton
          ariaLabel={t('changeQuantity:removeAll')}
          borderColor={colors.white}
          fillColor={colors.white}
          icon={delete1}
          iconColor={
            value === 0 || isPending ? colors.lightgrey : colors['darkgrey-50']
          }
          onClick={value === 0 || isPending ? ignoreClick : onRemoveAll}
          size="small"
          dataTestId="quantity-selector-remove-all"
        />
      )}
      {hasQuantityChange && (
        <IconButton
          ariaLabel={t('changeQuantity:remove')}
          borderColor={colors.white}
          fillColor={colors.white}
          icon={lessSolo}
          iconColor={
            value === 0 || isPending ? colors.lightgrey : colors['darkgrey-50']
          }
          onClick={value === 0 || isPending ? ignoreClick : onRemove}
          size="small"
          dataTestId="quantity-selector-minus"
        />
      )}
      <Paragraph size={3} align="center" weight={700}>
        {value}
      </Paragraph>
      {hasQuantityChange && (
        <IconButton
          ariaLabel={
            isMaximumReached
              ? t('changeQuantity:maxQuantityReached')
              : t('changeQuantity:add')
          }
          borderColor={colors.white}
          fillColor={colors.white}
          icon={addSolo}
          cursor={isMaximumReached ? 'not-allowed' : 'pointer'}
          iconColor={
            isMaximumReached || isPending
              ? colors.lightgrey
              : colors['darkgrey-50']
          }
          onClick={isMaximumReached || isPending ? ignoreClick : onAdd}
          size="small"
          dataTestId="quantity-selector-plus"
        />
      )}
    </Wrapper>
  )
}
