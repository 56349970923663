import React, { MouseEvent, useCallback } from 'react'
import styled, { CSSProperties, useTheme } from 'styled-components'
import { GridLayout } from '../components/GridLayout'
import { GridArea } from '../components/GridArea'
import { Card } from '@/tonies-ui/atoms/Card'
import { GenericProductCardViewProps } from '../types'
import { useCardBorderColor } from '../hooks/useCardBorderColor'
import { AudioPlayer, Paragraph, Spacing } from '@boxine/tonies-ui'
import { BundleContent, Content } from '../components/Content'
import { Text } from '../components/Text'
import { Contents } from '../components/Contents'
import { Price } from '../../../atoms/Price'
import { IconButton } from '@/tonies-ui/atoms/IconButton'
import { QuantitySelector } from '../components/QuantitySelector'
import { useTranslation } from 'next-i18next'
import { GenericProductCardImage } from '../components/Image'
import { ProductCardNotch } from '../components/Notch'
import { AssignButton } from '../../../../components/atoms/AssignButton'
import { useAudioLibraryAssingUrlWithContext } from '../hooks/useAudioLibraryAssignUrlWithContext'

const StretchedLink = styled.a`
  grid-column: 1/-1;
  grid-row: 1/-1;
  opacity: 0;
`

export const GenericProductCardView: NormalizedFunctionComponent<
  GenericProductCardViewProps
> = ({
  action,
  audioSample,
  contents,
  bundleContents,
  children,
  dataTestExtra,
  dataTestId,
  dataTrackingId,
  description1,
  description2,
  hasBorder = true,
  image,
  isGreyToned = false,
  isSelected = false,
  orientation,
  name,
  note,
  onClick,
  topRightIconSlot,
  audioLibraryAssignUrl,
  price,
  quantity,
  strikePrice,
  notch,
  assignButtonLabelText = undefined,
  onClickAssign,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const borderColor = useCardBorderColor(hasBorder, isSelected)

  const stopPropagation = useCallback((e: MouseEvent) => {
    // click handler which prevents button clicks from bubbling up to the Card
    e.stopPropagation()
  }, [])

  const cssForGridArea: Pick<CSSProperties, 'pointerEvents'> = {
    pointerEvents: onClick ? 'none' : 'initial',
  }

  const audioLibraryAssingUrlWithContext = useAudioLibraryAssingUrlWithContext(
    audioLibraryAssignUrl
  )

  return (
    <Card
      borderColor={borderColor}
      dataTestExtra={dataTestExtra}
      dataTestId={dataTestId}
      dataTrackingId={dataTrackingId}
      note={note}
      onClick={typeof onClick === 'function' ? onClick : undefined}
      isGreyToned={isGreyToned}
    >
      {notch && (
        <ProductCardNotch
          backgroundColor={notch.backgroundColor}
          text={notch.text}
        />
      )}
      <GridLayout
        hasAction={Boolean(action)}
        hasAudioSample={Boolean(audioSample)}
        hasBundleContents={Boolean(bundleContents)}
        hasContents={!bundleContents && Boolean(contents)}
        hasPrices={Boolean(price)}
        hasQuantity={Boolean(quantity)}
        hasTopRightIconSlot={Boolean(topRightIconSlot)}
        hasAssignLink={Boolean(audioLibraryAssignUrl)}
        hasImage={Boolean(image)}
        orientation={orientation}
      >
        {onClick && 'href' in onClick ? (
          <StretchedLink
            {...onClick}
            tabIndex={-110}
            data-testid={`pdp-link${
              !action?.isDisabled &&
              price &&
              price !== 'free' &&
              price.centAmount > 0 &&
              '--orderable'
            }`}
          >
            {name + ' - ' + description1}
          </StretchedLink>
        ) : null}

        <GridArea name="image" alignY="center" style={cssForGridArea}>
          <GenericProductCardImage {...image} />
        </GridArea>
        <GridArea
          name="text"
          alignY="stretch"
          style={{ gridTemplateRows: 'auto 1fr' }}
        >
          <Text
            align={orientation === 'portrait' ? 'center' : 'left'}
            description1={description1}
            description2={description2}
            name={name}
          />
          {children}
        </GridArea>
        {audioSample && (
          <GridArea
            height="2.5rem"
            name="audiosample"
            onClick={stopPropagation}
            tabIndex={-110}
            width="2.5rem"
          >
            <AudioPlayer
              loading="lazy"
              src={audioSample.url}
              styling="secondary"
              onPlay={audioSample.onPlay}
              onStop={audioSample.onStop}
            />
          </GridArea>
        )}
        {bundleContents && (
          <GridArea
            alignX={orientation === 'portrait' ? 'center' : 'start'}
            name="bundle-contents"
            style={cssForGridArea}
          >
            <Contents align={orientation === 'portrait' ? 'center' : 'left'}>
              {bundleContents.map((c, i) => (
                <BundleContent {...c} key={i} />
              ))}
            </Contents>
          </GridArea>
        )}
        {contents && (
          <GridArea
            alignX={orientation === 'portrait' ? 'center' : 'start'}
            name="contents"
            style={cssForGridArea}
          >
            <Spacing mt="spacing-xs" mb="spacing-xs">
              <Paragraph
                align={orientation === 'portrait' ? 'center' : 'left'}
                size={3}
                weight={500}
              >
                {t('products:contents')}
              </Paragraph>
            </Spacing>
            <Contents align={orientation === 'portrait' ? 'center' : 'left'}>
              {contents.map((c, i) => (
                <Content {...c} key={i} />
              ))}
            </Contents>
          </GridArea>
        )}
        {quantity && (
          <GridArea
            alignX={orientation === 'portrait' ? 'center' : 'start'}
            height={orientation === 'portrait' ? undefined : '1.5rem'}
            name="quantity"
            onClick={stopPropagation}
            tabIndex={-210}
          >
            <Spacing p={orientation === 'portrait' ? 'spacing-s' : undefined}>
              {quantity.selector ? (
                <QuantitySelector
                  {...quantity.selector}
                  value={quantity.value}
                />
              ) : (
                <Paragraph size={3} weight={300}>
                  {t('products:quantity', { quantity: quantity.value })}
                </Paragraph>
              )}
            </Spacing>
          </GridArea>
        )}
        {price && (
          <GridArea alignX="end" alignY="center" name="prices">
            {price === 'free' ? (
              <Paragraph weight={700} size={3}>
                {t('price:free')}
              </Paragraph>
            ) : (
              <Price price={price} strikePrice={strikePrice} size="s" />
            )}
          </GridArea>
        )}
        {topRightIconSlot && (
          <GridArea
            alignX="end"
            name="top-right-icon-slot"
            onClick={stopPropagation}
            tabIndex={-120}
          >
            <IconButton
              borderColor={colors.white}
              dataTestId={`top-right-icon-slot${
                !action?.isDisabled &&
                price &&
                price !== 'free' &&
                price.centAmount > 0 &&
                '--orderable'
              }`}
              fillColor={colors.white}
              icon="info"
              iconColor={colors.darkergrey}
              size="small"
              {...topRightIconSlot}
            />
          </GridArea>
        )}
        {audioLibraryAssingUrlWithContext && (
          <GridArea
            alignX={orientation === 'portrait' ? 'center' : 'start'}
            name="assign-link"
            onClick={stopPropagation}
            tabIndex={-120}
          >
            <Spacing mb="spacing-xs">
              {/* Checking if a # instead of the audioLibraryUrl is set to open the assign modal instead of crosslinking to mytonies */}
              <AssignButton
                audioLibraryAssignUrl={
                  audioLibraryAssignUrl !== '#'
                    ? audioLibraryAssingUrlWithContext.toString()
                    : '#'
                }
                referenceTitle="[coded] GenericProductCard Assign"
                dataTestId={
                  audioLibraryAssingUrlWithContext === '#'
                    ? 'assign-button'
                    : 'assign-link'
                }
                handleClickAssign={
                  audioLibraryAssingUrlWithContext === '#'
                    ? onClickAssign
                    : undefined
                }
                labelText={
                  audioLibraryAssingUrlWithContext === '#' &&
                  assignButtonLabelText
                    ? assignButtonLabelText
                    : undefined
                }
              />
            </Spacing>
          </GridArea>
        )}
        {action && (
          <GridArea name="action" onClick={stopPropagation} tabIndex={-220}>
            <IconButton {...action} />
          </GridArea>
        )}
      </GridLayout>
    </Card>
  )
}
