import { useColorContext } from '@boxine/tonies-ui'
import { DefaultTheme } from 'styled-components'

type ColorContextConfigType<ColorContextConfig> = {
  [key in DefaultTheme['colorContext']]: ColorContextConfig
}

export const useColorContextConfig = <ColorContextConfig>(
  props: ColorContextConfigType<ColorContextConfig>
) => {
  const colorContext = useColorContext()

  return props[colorContext]
}
