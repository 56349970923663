import { mainFirstChildPaddingTop } from '@/tonies-ui/helpers/css-helper'
import { Box } from '@boxine/tonies-ui'
import styled from 'styled-components'

export const Wrapper = styled(Box)<{
  backgroundColor: string
}>`
  main > &:first-child {
    ${mainFirstChildPaddingTop}
  }
`
