import React from 'react'
import { HeadlineMapper } from '@boxine/tonies-ui'
import { Inner } from './components/Inner'
import { Wrapper } from './components/Wrapper'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import {
  SectionHeadlineView,
  SectionHeadlineViewProps,
} from '../../molecules/SectionHeadline/components/View'
import { Curve } from '@/tonies-ui/atoms/Curve'

export type TextHeroProps = {
  backgroundColor: string
  title: string
  headlineAsHTMLTag?: SupportedHTMLTags
  text?: string
  variant?: HeadlineMapper['variant']
  hasCurve?: boolean
} & Pick<
  SectionHeadlineViewProps,
  'primaryCtaText' | 'secondaryCtaText' | 'primaryCtaUrl' | 'secondaryCtaUrl'
>

export const TextHero: NormalizedFunctionComponent<TextHeroProps> = ({
  children,
  title,
  headlineAsHTMLTag,
  text,
  backgroundColor,
  primaryCtaText,
  primaryCtaUrl,
  secondaryCtaText,
  secondaryCtaUrl,
  variant = 'Headline1',
  hasCurve,
}) => {
  return (
    <>
      <Wrapper backgroundColor={backgroundColor} data-testid="text-hero">
        <Inner>
          <SectionHeadlineView
            headline={{
              variant,
              'data-testid': 'text-hero-headline',
              children: title,
              asHTMLTag: headlineAsHTMLTag,
              weight: 500,
            }}
            subheadline={{ 'data-testid': 'text-hero-text', children: text }}
            secondaryCtaUrl={secondaryCtaUrl}
            secondaryCtaText={secondaryCtaText}
            primaryCtaUrl={primaryCtaUrl}
            primaryCtaText={primaryCtaText}
          />
          {children}
        </Inner>
      </Wrapper>

      {hasCurve && backgroundColor !== 'transparent' && (
        <Curve color={backgroundColor} layout="bottomRight" />
      )}
    </>
  )
}
