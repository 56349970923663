import React, { useState } from 'react'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { GenericProductCard } from '..'
import { useEcomLocale } from '../../../../providers/locale'
import { useOwnAudioContentContext } from '../../../../providers/ownAudioContent/OwnAudioContext'
import { AssignModalContent } from './components/AssignModalContent'
import { NoTonieCompatibleYetModalContent } from './components/NoCompatibleTonieYetModalContent'
import { useAssignIdOfOwnAudioContent } from '../../../../hooks/useAssignIdOfOwnAudioContent'
import { useToniesToAssign } from '../../../../hooks/useToniesToAssign'
import { useTranslation } from 'next-i18next'
import { useWishlist } from '../../../../providers/Wishlist'
import { useToggleWishlistIconProps } from '../../../../components/atoms/ToggleFromWishlist'
import { useGtmV4 } from '../../../../providers/gtmV4'
import { NormalizedProductCardProps } from 'components/molecules/NormalizedProductCard/types'

export const OwnAudioContentCard = ({
  tune,
  dataTestExtra = undefined,
  dataTestId = undefined,
  orientation = 'portrait',
  hasNote = true,
}: {
  tune: NormalizedProduct
  dataTestExtra?: string
  dataTestId?: string
  orientation?: 'portrait' | 'landscape'
  hasNote?: Pick<NormalizedProductCardProps, 'hasNote'>['hasNote']
}) => {
  const lcCC = useEcomLocale()
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [hasAssignSuccess, setHasAssignAccess] = useState(false)
  const { toniesToAssign } = useToniesToAssign(tune.salesId)
  const tuneToAssign = useAssignIdOfOwnAudioContent(lcCC, tune.salesId)
  const { isAllOwnAudioContentReady, assignStatus } =
    useOwnAudioContentContext()
  const hasAssignStatus = assignStatus.length > 0

  const { t } = useTranslation()
  const { context } = useWishlist()
  const wishlistIconProps = useToggleWishlistIconProps(tune)
  const { pushGtmV4Event } = useGtmV4()

  const isCurrentlyAssigned = hasAssignStatus
    ? assignStatus.some(item => item.sku === tune.sku)
    : toniesToAssign?.some(tonie => tonie.tune?.id === tuneToAssign)
  const note = () => {
    if (!tuneToAssign || !isAllOwnAudioContentReady) {
      return undefined
    }
    if (!isCurrentlyAssigned && hasNote) {
      return {
        text: t('products:tunes:alreadyBoughtHint'),
        fillColor: 'green-20',
        animationDelay: 0.75,
      }
    }
    if (isCurrentlyAssigned) {
      return {
        text: t('ownAudioContent:IsAlreadyAssignedHint'),
        fillColor: 'green-20',
        animationDelay: 0.75,
      }
    }
    if (!toniesToAssign?.length) {
      return {
        text: t('ownAudioContent:NoCompatibleTonieYetHint'),
        fillColor: 'berry-20',
        animationDelay: 0.75,
      }
    }
    return undefined
  }

  const assignButtonLabelText = () => {
    let label = undefined
    if (
      !isAllOwnAudioContentReady ||
      !toniesToAssign ||
      (toniesToAssign && toniesToAssign.length < 1) ||
      (isCurrentlyAssigned && toniesToAssign && toniesToAssign.length < 2)
    ) {
      label = t('ownAudioContent:ButtonLabelInfo')
    }
    if (isCurrentlyAssigned && toniesToAssign && toniesToAssign.length > 1) {
      label = t('ownAudioContent:ChangeTonieButtonLabel')
    }
    return label
  }

  if (!tune) {
    return null
  }

  return (
    <>
      <GenericProductCard
        key={tune.salesId}
        image={{
          src: tune.image.src,
          alt: tune.image.alt,
          variant: 'coverCard',
          placeholder: true,
        }}
        name={tune.name}
        description1={tune.series?.label}
        // Setting a # instead of the audioLibraryUrl to open the assign modal instead of crosslinking to mytonies
        audioLibraryAssignUrl="#"
        onClickAssign={() => {
          setIsAssignModalOpen(true)
          pushGtmV4Event({
            eventType: 'audioContent',
            item: tune,
            step: 'start_assign',
          })
        }}
        orientation={orientation}
        topRightIconSlot={
          context !== 'WishlistLayout'
            ? {
                fillColor: 'white',
                icon: 'info',
                iconColor: 'darkerGrey',
                href: `/${
                  tune.lcCC ? tune.lcCC.toLowerCase() : lcCC.toLowerCase()
                }/audio-content/${tune.series?.key}/${tune.slug}`,
                cursor: 'pointer',
              }
            : wishlistIconProps
        }
        note={note()}
        assignButtonLabelText={assignButtonLabelText()}
        dataTestExtra={dataTestExtra}
        dataTestId={dataTestId}
      />

      <Modal
        isOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        headline={
          tuneToAssign && toniesToAssign && !hasAssignSuccess
            ? t('ownAudioContent:AssignModalHeadline')
            : !toniesToAssign
            ? t('ownAudioContent:NoCompatibleTonieYetHeadline')
            : undefined
        }
      >
        {tuneToAssign && toniesToAssign && toniesToAssign.length > 0 ? (
          <AssignModalContent
            tonies={toniesToAssign}
            tune={tune}
            tuneId={tuneToAssign} // mytune => id for assignment
            setIsAssignModalOpen={setIsAssignModalOpen}
            setHasAssignSuccess={setHasAssignAccess}
          />
        ) : !toniesToAssign?.length ? (
          <NoTonieCompatibleYetModalContent
            tune={tune}
            setIsAssignModalOpen={setIsAssignModalOpen}
          />
        ) : null}
      </Modal>
    </>
  )
}
