import { Box, lineClampCss } from '@boxine/tonies-ui'
import styled, { css } from 'styled-components'

export const Note = styled(Box)<{ allowMultiline: boolean }>`
  display: grid;
  padding-top: 1rem;
  padding-bottom: 0.0625rem;
  margin-top: -1rem;
  position: relative;
  width: 100%;
  z-index: 1;
  ${p =>
    !p.allowMultiline
      ? css`
          p {
            ${lineClampCss(1)}
          }
        `
      : null}
`
