import React, { ComponentProps, FunctionComponent } from 'react'
import { Grid, media } from '@boxine/tonies-ui'
import { HorizontalScrollList } from '@/tonies-ui/index'
import { GenericImageTextProps } from '../../../components/molecules/GenericImageText'
import { MediaCopy } from '../../../components/molecules/GenericImageText/variants/MediaCopy'
import { useViewportConfig } from '../../../tonies-ui/hooks/useViewport'
import {
  SectionHeadline,
  SectionHeadlineProps,
} from '../../../components/molecules/SectionHeadline'
import styled from 'styled-components'
import { withGTMV4Context } from '../../../providers/gtmV4'

const StyledGrid = styled(Grid)`
  column-gap: 2rem;
  row-gap: 3rem;

  ${media.tablet`
    column-gap: 2rem;
    row-gap: 4rem;
  `}
`

export type Props = {
  entries: ComponentProps<typeof MediaCopy>[]
  variant?: 'list' | 'slider'
  orientation?: GenericImageTextProps['orientation']
  columnsMobile?: number
  columnsDesktop?: number
} & Pick<SectionHeadlineProps, 'title' | 'headlineAsHTMLTag' | 'text'>

export const MediaCopyCollectionContainer: FunctionComponent<Props> = ({
  title,
  headlineAsHTMLTag,
  entries,
  columnsMobile = 1,
  columnsDesktop = 4,
  orientation = 'vertical',
  variant = 'list',
  text,
}) => {
  const isSlider = variant === 'slider'
  const WrapperComp = isSlider ? HorizontalScrollList : StyledGrid

  const config = useViewportConfig<{
    [key in typeof variant]: { [key in typeof orientation]: number }
  }>({
    mobile: {
      list: { horizontal: 1, vertical: columnsMobile },
      slider: { horizontal: 1.2, vertical: 1.5 },
    },
    tablet: {
      list: {
        horizontal: 2,
        vertical:
          columnsDesktop < 3 ? columnsDesktop : Math.round(columnsDesktop / 2),
      },
      slider: { horizontal: 2.5, vertical: 3 },
    },
    desktop: {
      list: {
        horizontal: columnsDesktop > 3 ? 3 : columnsDesktop,
        vertical: columnsDesktop,
      },
      slider: { horizontal: 3, vertical: 4 },
    },
  })

  return (
    <div>
      <SectionHeadline
        title={title}
        headlineAsHTMLTag={headlineAsHTMLTag}
        text={text}
        headlineAsStyleTag="Headline1"
        weight={700}
      />
      <WrapperComp
        hasGapH
        hasGapV
        columns={config[variant][orientation]}
        scrollByOne
      >
        {entries.map((entry, i) => (
          <MediaCopy
            key={entry.headline + i}
            orientation={orientation}
            {...entry}
          />
        ))}
      </WrapperComp>
    </div>
  )
}

export const MediaCopyCollection = withGTMV4Context(
  MediaCopyCollectionContainer,
  'MediaCopyCollection'
)
