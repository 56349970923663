import React, { FunctionComponent } from 'react'
import { FocusLink, Logo } from '@boxine/tonies-ui'

interface LogoLinkProps {
  logoLink: string
  logoAlt: string
  logoSrc: string
  isMobile: boolean
  onClick?: () => void
}

const LogoLink: FunctionComponent<LogoLinkProps> = ({
  logoLink,
  logoAlt,
  logoSrc,
  isMobile,
  onClick,
}) => {
  return (
    <FocusLink to={logoLink} onClick={onClick}>
      <Logo
        alt={logoAlt}
        src={logoSrc}
        width={isMobile ? '6.5rem' : '9.75rem'}
        ratio={[4, 1]}
        responsive={[104, 156]}
      />
    </FocusLink>
  )
}

export { LogoLink }
