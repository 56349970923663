import React from 'react'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import styled from 'styled-components'
import { Tag } from '@/tonies-ui/atoms/Tag'
import { useProductOverview } from '../../../../providers/productOverview'
import { TagList } from '@/tonies-ui/molecules/TagList'
import { Paragraph } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import flatten from 'lodash/flatten'
import { isNotUndefined } from '../../../../utils/isNotUndefined'
import { useGtmV4, withGTMV4Context } from '../../../../providers/gtmV4'
import { AnimatePresence } from 'framer-motion'
import { ToniesMotions } from '@/tonies-ui/motions'

const StyledButton = styled.button`
  ${cssReset}
  cursor: pointer;
  display: inline;
`

const ActiveFilterTagsContainer = () => {
  const { t } = useTranslation()
  const { filter, search } = useProductOverview()
  const { pushGtmV4Event } = useGtmV4()

  const activeFilterGroups =
    filter?.items.filter(f => f.activeFilters.length > 0) || []

  const activeFilterTags = flatten(
    activeFilterGroups.map(activeFilterGroup =>
      activeFilterGroup.activeFilters.map(activeFilter => {
        const lvl1 = activeFilterGroup.filters.find(f => f.id === activeFilter)

        if (lvl1) {
          return {
            ...lvl1,
            filterKey: activeFilterGroup.filterKey,
          }
        }

        const lvl2 = activeFilterGroup.filters
          .find(f => f.options?.some(o => o.id === activeFilter))
          ?.options?.find(o => o.id === activeFilter)

        if (lvl2) {
          return {
            ...lvl2,
            filterKey: activeFilterGroup.filterKey,
          }
        }

        return undefined
      })
    )
    // delete undefined
  ).filter(isNotUndefined)

  return (
    <AnimatePresence>
      {(activeFilterTags && activeFilterTags.length !== 0) ||
      !!search?.value ? (
        <ToniesMotions.PopIt>
          <TagList hasBox data-testid="active-filter-tag-list">
            <Paragraph size={3} weight={500}>
              {t('products:filterTags:active')}
            </Paragraph>
            {activeFilterTags.map(f => (
              <ToniesMotions.PopIt
                key={f.label}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                <StyledButton
                  data-testid="tag"
                  onClick={() => {
                    const newFilters = filter?.onChangeAll(
                      f.filterKey,
                      !f.value,
                      f.id
                    )

                    if (newFilters) {
                      pushGtmV4Event({
                        eventType: 'search',
                        filters: newFilters,
                        searchTerm: search?.value || '',
                      })
                    }
                  }}
                >
                  <Tag isActive={f.value}>{f.label}</Tag>
                </StyledButton>
              </ToniesMotions.PopIt>
            ))}
            {!!search?.value && (
              <ToniesMotions.PopIt key="active-filter-tag-search">
                <StyledButton onClick={() => search.clear()}>
                  <Tag isActive>&quot;{search.value}&quot;</Tag>
                </StyledButton>
              </ToniesMotions.PopIt>
            )}
          </TagList>
        </ToniesMotions.PopIt>
      ) : null}
    </AnimatePresence>
  )
}

export const ActiveFilterTags = withGTMV4Context(
  ActiveFilterTagsContainer,
  'ActiveFilterTags'
)
