import { useLocaleAltNotation } from '../providers/locale/altNotation'

type LocalizedEnvVarsMapping = {
  [key: string]: {
    [key: string]: string | undefined
  }
}

// IMPORTANT: we need to list the *full* name of all ENV vars we use
// otherwise the values of the ENV vars can not be inlined during build using string replacement
// see: https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser
const localizedEnvVarsMapping: LocalizedEnvVarsMapping = {
  NEXT_PUBLIC_ADYEN_DROPIN_CLIENT_KEY: {
    // EN_AU: ,
    EN_EU: process.env.NEXT_PUBLIC_ADYEN_DROPIN_CLIENT_KEY_EN_EU,
    EN_GB: process.env.NEXT_PUBLIC_ADYEN_DROPIN_CLIENT_KEY_EN_GB,
    // EN_HK: ,
    // EN_NZ: ,
    DE_DE: process.env.NEXT_PUBLIC_ADYEN_DROPIN_CLIENT_KEY_DE_DE,
    FR_FR: process.env.NEXT_PUBLIC_ADYEN_DROPIN_CLIENT_KEY_FR_FR,
  },
  NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY: {
    EN_AU: process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY_DE_DE,
    EN_EU: process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY_DE_DE,
    EN_GB: process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY_EN_GB,
    EN_HK: process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY_DE_DE,
    EN_NZ: process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY_DE_DE,
    DE_DE: process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY_DE_DE,
    FR_FR: process.env.NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY_FR_FR,
  },
} as const

type LocalizedEnvKey = keyof typeof localizedEnvVarsMapping

// can be used to get the locale-specific value from a set of suffixed ENV vars
// that have to be listed in the mapping above
// e.g. the value from NEXT_PUBLIC_ADYEN_DROPIN_CLIENT_KEY_EN_EU can be retrieved via
// useLocalizedEnv('NEXT_PUBLIC_ADYEN_DROPIN_CLIENT_KEY') in the 'en-eu' ShopLocale
export default function useLocalizedEnv(key: LocalizedEnvKey): string {
  const LC_CC = useLocaleAltNotation('LC_CC')
  const value = localizedEnvVarsMapping[key][LC_CC]
  if (!value) {
    const errorMessage = `value not found for ${key}_${LC_CC}`
    console.error(errorMessage)
    return errorMessage
  } else {
    return value
  }
}
