import { BxCart, BxLineItemCustomFields } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

type Request = {
  version: number
  quantity: number
  sku: string
  custom?: BxLineItemCustomFields
}

/**
 * Adds a selected quantity of a product to the shopping cart.
 *
 * If the shopping cart already contains the selected product, CommerceTools
 * will increase the quantity in the cart by the selected quantity.
 */
export const addProduct = async (
  lcCC: EcomLocale,
  cartId: string,
  cartVersion: number,
  sku: string,
  quantity: number,
  custom?: BxLineItemCustomFields
) => {
  // Add a line item (if the line item exists, this will update its quantity)
  const cartUpdate: Request = {
    version: cartVersion,
    sku,
    quantity,
    custom,
  }

  return await fetch<Request, BxCart>({
    query: `/bx/carts/${cartId}/products`,
    lcCC,
    method: 'put',
    request: cartUpdate,
  })
}
