import React, { FunctionComponent } from 'react'
import { Headline3, Paragraph } from '@boxine/tonies-ui'
import { TestimonialSchema } from '../../../lib/contentful/datamodel/schemas'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useIsViewportMobile } from '@boxine/tonies-ui'

const StyledTestimonial = styled.div`
  color: ${props => props.theme.colors.darkergrey};
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoint.screenTablet}) {
    gap: 0.3125rem;
  }
`

type TestimonialProps = Omit<TestimonialSchema, 'backgroundColor'>

export const Testimonial: FunctionComponent<TestimonialProps> = ({
  statement,
  name,
  occupation,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()
  const citationStart = t('citation:start')
  const citationEnd = t('citation:end')

  return (
    <StyledTestimonial>
      <Headline3 asHTMLTag={isMobile ? 'h2' : 'h3'}>
        {citationStart}
        {statement}
        {citationEnd}
      </Headline3>
      <Paragraph size={2}>
        {name}
        {occupation && `, ${occupation}`}
      </Paragraph>
    </StyledTestimonial>
  )
}
