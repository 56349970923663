import { CartAction } from '..'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { addDiscountCode } from '../../../lib/commercetools/requests/carts/addDiscountCode'
import { GtmV4Event } from '../../gtmV4/types'

export type AddDiscountCodeResult = string

export const addDiscountAction =
  (
    code: string,
    pushGtmV4Event?: (e: GtmV4Event) => void
  ): CartAction<AddDiscountCodeResult> =>
  async ({ lcCC, fetchOrCreateCart, replaceCart }) => {
    const normalizedCouponCode = code.replace(/\s/g, '')

    const cart = await fetchOrCreateCart()

    const addDiscountCodeResponse = await addDiscountCode(
      lcCC,
      normalizedCouponCode,
      cart.id,
      cart.version
    )

    if (addDiscountCodeResponse.result === 'unexpected-response-format') {
      return 'system-error'
    } else if (addDiscountCodeResponse.result === 'request-failed') {
      return addDiscountCodeResponse?.data?.detail
        ? addDiscountCodeResponse?.data?.detail
        : 'discount_code.does_not_exist'
    } else if (
      addDiscountCodeResponse.data.bxDiscounts.bxDiscountDiscountCodes[0]
        .state !== 'MatchesCart'
    ) {
      replaceCart(normalizeCart(addDiscountCodeResponse.data, lcCC)) // code has been added to the cart, so it needs an update
      return addDiscountCodeResponse.data.bxDiscounts.bxDiscountDiscountCodes[0]
        .state
    }

    replaceCart(normalizeCart(addDiscountCodeResponse.data, lcCC))

    if (pushGtmV4Event) {
      pushGtmV4Event({
        eventType: 'coupon',
        event: 'add',
        coupon: normalizedCouponCode,
      })
    }

    return 'ok'
  }
