import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import { useId } from '@reach/auto-id'
import { Icon, FocusStyles, media } from '@boxine/tonies-ui'

const Wrapper = styled.section`
  position: relative;
  /* Avoid z-fighting issue on iOS with the header */
  z-index: 999;
  background-color: ${props => props.theme.colors['lightblue-20']};
`

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
  font-family: ${props => props.theme.fonts.serif};
  font-weight: 300;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.darkergrey};
  max-width: ${(props: {
    theme: { application: { maxContentWidth: string } }
  }) => props.theme.application.maxContentWidth};
  margin-left: auto;
  margin-right: auto;

  ${media.tablet`
    flex-direction: row;
    height: 3rem;
    font-size: 1rem;
    line-height: 1.75rem;
    flex-wrap: nowrap;
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 75%;
  justify-content: center;
  flex-wrap: wrap;

  a {
    color: ${props => props.theme.colors.darkergrey};
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  border: none;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  transition: transform 0.25s ease-out;
  padding: 0;
  background: transparent;
  transform: translate3d(0, -50%, 0);
  color: ${props => props.theme.colors.darkergrey};

  &:focus,
  &:hover {
    transform: translate3d(0, -50%, 0) rotate(-180deg);
  }

  ${FocusStyles}
`

export interface BannerProps {
  children?: React.ReactNode
  onClose: () => void
  isOpen: boolean
}

export function PromotionBanner({
  children = undefined,
  isOpen,
  onClose,
}: BannerProps) {
  const { t } = useTranslation()
  const promotionBannerId = 'pb-' + useId()

  if (!isOpen) return null

  return (
    <Wrapper data-testid="promotion-banner" aria-labelledby={promotionBannerId}>
      <Inner>
        <TextContainer id={promotionBannerId}>{children}</TextContainer>
        <CloseButton
          aria-label={t('FlashMessage:Close')}
          type="button"
          data-testid="promotion-banner-btn"
          onClick={onClose}
        >
          <Icon type={icons.closeX} height={16} width={16} />
        </CloseButton>
      </Inner>
    </Wrapper>
  )
}
