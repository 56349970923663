import React from 'react'
import { DefaultMotionSpanElement } from '@/tonies-ui/motions/constants'
import { MotionProps } from 'framer-motion'

export function createLowerItAnimationWithCustomDelay(
  delay: number
): MotionProps['animate'] {
  return {
    translateY: ['-100%', '0%'],
    transition: {
      duration: 0.25,
      delay: delay,
      ease: 'easeIn',
    },
  }
}

export const LowerIt: NormalizedMotionComponent = ({
  children,
  animate = {
    translateY: ['-100%', '0%'],
    transition: {
      duration: 0.75,
      delay: 0.75,
      ease: 'easeIn',
    },
  },
  ...rest
}: MotionProps) => {
  return (
    <DefaultMotionSpanElement
      data-testid="lowerit-motion"
      {...rest}
      initial={{ y: '0%' }}
      style={{
        position: 'relative',
        bottom: '0',
        left: '0',
        height: 'auto',
        marginBottom: '0',
      }}
      animate={animate}
    >
      {children}
    </DefaultMotionSpanElement>
  )
}
