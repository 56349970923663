import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icon, Button } from '@boxine/tonies-ui'
import { toast } from '@/tonies-ui/atoms/Toast'
import { theme } from '@/tonies-ui/themes/theme'

export type CopyToCliboardButtonProps = {
  clipboardCopy: string
  onClick?: () => void
  toastAutoCloseSeconds: number
  toastId: string
  toastSuccessMessage: string
}

const CopyButton = styled(Button)`
  color: ${props => props.theme.colors.darkergrey};
  text-decoration: none;
  font-size: inherit;
  font-weight: 700;

  > svg {
    vertical-align: middle;
    margin-top: -0.2em;
  }
`

export function CopyToCliboardButton(
  copyToClipboardButtonProps: CopyToCliboardButtonProps
) {
  const { t } = useTranslation()
  const {
    clipboardCopy,
    onClick = () => null,
    toastSuccessMessage,
    toastAutoCloseSeconds,
    toastId,
  } = copyToClipboardButtonProps

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(clipboardCopy)
    toast(toastSuccessMessage, 'success', {
      toastId: toastId,
      autoClose: toastAutoCloseSeconds,
    })
    onClick()
  }, [
    clipboardCopy,
    onClick,
    toastAutoCloseSeconds,
    toastId,
    toastSuccessMessage,
  ])

  return (
    <CopyButton
      aria-label={t('Icon:copyToClipboard')}
      type="button"
      variant="secondary"
      data-testid="promotion-banner-copy-btn"
      onClick={handleClick}
    >
      <Icon
        type="copyToClipboard"
        height={16}
        width={16}
        fill={theme.colors.primary}
      />{' '}
      {clipboardCopy}
    </CopyButton>
  )
}
