import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react'
import {
  Button,
  Paragraph,
  useIsViewportMobile,
  Spacing,
  Spinner,
  CTARow,
  Headline3,
} from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { ColorSelector } from '../../../atoms/ColorSelector'
import { CloudLeft, CloudRight, StarBig, StarSmall } from './Doodles'
import { useTranslation } from 'next-i18next'
import { useCurrencyFormatter } from '../../../../hooks/useCurrencyFormatter'
import { useDateFormatter } from '../../../../hooks/useDateFormatter'
import { Badge } from './Badge'
import { AddToCart } from '../../../atoms/AddToCart'
import { useGtmV4 } from '../../../../providers/gtmV4'
import {
  BadgeWrapper,
  ColorSelectorWrapper,
  Column,
  Lane,
  ProductImage,
  ProductImageContainer,
  ProductImageLink,
  Row,
  ProductSelectorSection,
  SpinnerWrapper,
} from './styles'
import { ColorSelectorVariantProps } from '../../../atoms/ColorSelector/types'
import { getLightBackgroundColor } from '../../../../utils/colors'
import { useCustomBundler } from '../../../../hooks/useCustomBundler'
import { Curve } from '@/tonies-ui/atoms/Curve'
import { ToniesMotions } from '@/tonies-ui/motions'
import { Image } from '@/tonies-ui/atoms/Image'
import { UrlReferralCodeSection } from './UrlReferralCodeSection'
import { Ratings } from './Ratings'

export type ProductSelectorViewProps = {
  headline?: ReactNode
  linkUrl?: string
  linkText?: string
  hasDetailLink?: boolean
  detailLinkText?: string
  isImageLink?: boolean
  isImageRight?: boolean
  /**
   * adds doodle svgs around product image
   */
  hasSkyIllustration?: boolean
  /**
   * 25% bigger product image on mobile
   */
  hasLargerProductImage?: boolean
  /**
   * default="center" - aligns content in a flex container
   */
  tabletAlignContent?: 'left' | 'center' | 'right'
  /**
   * default="top" - puts the mobile headline either on top or bottom of the product images
   */
  headlinePosition?: 'top' | 'bottom'
  lowerSection?: JSX.Element
  hasAddToCart?: boolean
  hasProductDetails?: boolean
  curveColor?: string
  curveLayout?: 'bottomLeft' | 'bottomRight'
  colorSelectorVariants?: ColorSelectorVariantProps[]
  /**
   * a (per page) unique and stable name for the radio buttons
   */
  uniqueInputName: string
  dataTestId?: string
  listEntries?: string[]
  backgroundColor?: string
  id?: string
  hasUrlReferralCodeSection?: boolean
}

export const ProductSelectorView: FunctionComponent<
  ProductSelectorViewProps & {
    activeProduct: NormalizedProduct
    onVariantChange: (selectedVariantId: string) => void
  }
> = ({
  headline,
  linkUrl,
  linkText,
  hasDetailLink,
  detailLinkText,
  isImageLink,
  isImageRight,
  curveColor,
  curveLayout = 'bottomLeft',
  colorSelectorVariants,
  hasAddToCart,
  hasProductDetails,
  headlinePosition = 'top',
  hasLargerProductImage,
  tabletAlignContent = 'center',
  activeProduct,
  onVariantChange,
  hasSkyIllustration,
  backgroundColor,
  lowerSection,
  uniqueInputName,
  dataTestId,
  id,
  hasUrlReferralCodeSection = false,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()
  const { isSupported } = useCustomBundler({
    products: [activeProduct],
  })

  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const cf = useCurrencyFormatter()
  const df = useDateFormatter()
  const { pushGtmV4Event } = useGtmV4()

  const [imageSrc, setImageSrc] = useState(activeProduct?.image.src)

  const onImageLoad = useCallback(() => setIsImageLoaded(true), [])

  const sectionBackgroundColor =
    backgroundColor ?? getLightBackgroundColor(activeProduct?.color)

  const handlePdpClick = useCallback(() => {
    if (activeProduct) {
      pushGtmV4Event({ eventType: 'selectItem', item: activeProduct })
    }
  }, [activeProduct, pushGtmV4Event])

  useEffect(() => {
    const src = activeProduct?.image.src

    if (src && src !== imageSrc) {
      setIsImageLoaded(false)
      setImageSrc(src)
    }
  }, [activeProduct, imageSrc, isImageLoaded])

  let availabilityInfo = ''

  if (activeProduct?.availability.state === 'preorderable') {
    availabilityInfo = t('products:shippingFromDate', {
      date: df(new Date(activeProduct.availability.shippingFromDate)),
    })
  } else if (activeProduct?.availability.state === 'announced') {
    availabilityInfo = t('products:orderFromDate', {
      date: df(new Date(activeProduct.availability.orderFromDate)),
    })
  }

  return (
    <>
      {activeProduct && (
        <>
          {hasUrlReferralCodeSection && (
            <UrlReferralCodeSection activeProduct={activeProduct} />
          )}
          <ColorContextProvider backgroundColor={sectionBackgroundColor}>
            <ProductSelectorSection
              backgroundColor={sectionBackgroundColor}
              data-testid={dataTestId}
              id={id}
            >
              <Lane>
                <Row>
                  <Column className="idx-0" order={isImageRight ? 2 : 1}>
                    {headlinePosition === 'top' && isMobile && headline}
                    <ProductImage>
                      {!isImageLoaded && (
                        <SpinnerWrapper>
                          <Spinner />
                        </SpinnerWrapper>
                      )}
                      {hasSkyIllustration && (
                        // Doodles for marketLaunch page mode
                        <>
                          <ToniesMotions.PulseIt
                            style={{
                              position: 'absolute',
                              right: '10%',
                              top: '4rem',
                            }}
                            transition={{ duration: 15, delay: 0.3 }}
                          >
                            <CloudRight />
                          </ToniesMotions.PulseIt>
                          <ToniesMotions.RotateIt
                            style={{
                              position: 'absolute',
                              right: '11%',
                              bottom: '12rem',
                            }}
                          >
                            <StarSmall />
                          </ToniesMotions.RotateIt>
                          <ToniesMotions.PulseIt
                            style={{
                              position: 'absolute',
                              left: '-2%',
                              bottom: '2rem',
                            }}
                            transition={{ duration: 18, delay: 0.3 }}
                          >
                            <CloudLeft />
                          </ToniesMotions.PulseIt>
                          <ToniesMotions.RotateIt
                            style={{
                              position: 'absolute',
                              zoom: 1.5,
                              left: '5%',
                              top: '4rem',
                            }}
                            transition={{ delay: 0.3 }}
                          >
                            <StarBig />
                          </ToniesMotions.RotateIt>
                        </>
                      )}
                      <ProductImageContainer
                        hasLargerProductImage={hasLargerProductImage}
                        hasRelativeProductImage={hasSkyIllustration}
                        isImageLoaded={isImageLoaded}
                      >
                        {imageSrc && (
                          <Image
                            src={imageSrc}
                            alt={activeProduct.name}
                            ratio={[1, 1]}
                            responsive={[320, 560, 640]}
                            onLoad={onImageLoad}
                            crop="fill"
                            placeholder={false}
                          />
                        )}
                      </ProductImageContainer>
                      {isImageLink && (
                        <ProductImageLink
                          href={activeProduct.path}
                          title={activeProduct.name}
                          onClick={handlePdpClick}
                        >
                          <span>{activeProduct.name}</span>
                        </ProductImageLink>
                      )}
                      {/* TODO add wishlist functionality
                      <StyledIcon
                        type="heart"
                        fill="#000000"
                        align={isImageRight ? 'right' : 'left'}
                      /> */}
                      {/* Display badge if orderable/shipping in the future */}
                      {availabilityInfo && (
                        <BadgeWrapper>
                          <Badge size={isMobile ? 'small' : 'large'}>
                            {availabilityInfo}
                          </Badge>
                        </BadgeWrapper>
                      )}
                    </ProductImage>
                  </Column>
                  <Column
                    tabletAlignContent={tabletAlignContent}
                    className="idx-1"
                    order={isImageRight ? 1 : 2}
                  >
                    {!isMobile && headline}
                    {colorSelectorVariants &&
                      colorSelectorVariants.length > 1 && (
                        <ColorSelectorWrapper>
                          <ColorSelector
                            activeVariantId={activeProduct.id}
                            variants={colorSelectorVariants}
                            onChange={onVariantChange}
                            uniqueInputName={uniqueInputName}
                          />
                        </ColorSelectorWrapper>
                      )}
                    {headlinePosition === 'bottom' && isMobile && headline}
                    {hasProductDetails && (
                      <Headline3>{`${activeProduct.normalizedCategories[0]?.name} - ${activeProduct.name}`}</Headline3>
                    )}
                    <Ratings activeProduct={activeProduct} />
                    {hasAddToCart && (
                      <Spacing mt="spacing-m">
                        <CTARow
                          data-testid="add-to-cart-button-wrapper"
                          data-testextra={activeProduct.sku}
                          justifyContent="center"
                          flexDirection="column"
                        >
                          <AddToCart
                            type="omniButton"
                            gtmActionField="ProductSelector"
                            product={activeProduct}
                            renderPrice
                          />

                          {/* Eco Tax - FR only */}
                          {activeProduct.ecoTax && (
                            <Paragraph size={4}>
                              {t('products:ecoTax', {
                                amount: cf(activeProduct.ecoTax.amount),
                              })}
                            </Paragraph>
                          )}

                          {isSupported && (
                            <Button
                              backgroundColor="white"
                              as="a"
                              href={t('tonieboxSelector:bundleUpCta:url')}
                              onClick={() =>
                                pushGtmV4Event({
                                  eventType: 'selectContent',
                                  ctaLabel: t(
                                    'tonieboxSelector:bundleUpCta:text'
                                  ),
                                  ctaLink: t(
                                    'tonieboxSelector:bundleUpCta:url'
                                  ),
                                  type: 'text-link',
                                })
                              }
                            >
                              {t('tonieboxSelector:bundleUpCta:text')}
                            </Button>
                          )}
                        </CTARow>

                        {linkText && linkUrl && (
                          <Button as="a" variant="secondary" href={linkUrl}>
                            {linkText}
                          </Button>
                        )}
                        {hasDetailLink && (
                          <Spacing mt="spacing-s">
                            <Button
                              as="a"
                              variant="secondary"
                              data-testid={`pdp-link${
                                hasAddToCart && '--orderable'
                              }`}
                              href={activeProduct.path}
                              onClick={handlePdpClick}
                            >
                              {detailLinkText || t('products:learnMore')}
                            </Button>
                          </Spacing>
                        )}
                      </Spacing>
                    )}
                  </Column>
                </Row>
              </Lane>
              {curveColor && (
                <Curve
                  backgroundColor={curveColor}
                  color={sectionBackgroundColor}
                  layout={curveLayout}
                  isInverted
                />
              )}
              {lowerSection}
            </ProductSelectorSection>
          </ColorContextProvider>
        </>
      )}
    </>
  )
}
