import styled, { css } from 'styled-components'
import { IconButton } from '@/tonies-ui/atoms/IconButton'

// FIXME: avoid overwriting internal component styles
export const CarouselButton = styled(IconButton)<{
  $x: 'left' | 'right'
  $y: 'top' | 'center' | 'bottom'
}>`
  position: absolute;
  transform: translateY(-50%);

  &:hover,
  &:active {
    transform: translateY(-45%);
  }

  ${p =>
    p.$x === 'left' &&
    css`
      left: 0.5rem;
    `}

  ${p =>
    p.$x === 'right' &&
    css`
      right: 0.5rem;
    `}

  top: ${p => (p.$y === 'top' ? 33.333 : p.$y === 'center' ? 50 : 66.666)}%;
`
