import React, { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { Paragraph, Icon, Spacing } from '@boxine/tonies-ui'
import { Price } from '../../../components/atoms/Price'
import * as icons from '@boxine/tonies-ui/icons'
import { useCurrencyFormatter } from '../../../hooks/useCurrencyFormatter'
import { useCartState } from '../../../hooks/useCartState'
import { useDiscountCodeInput } from '../../../providers/discountCodeInput'
import { useCartActions } from '../../../hooks/useCartActions'
import { removeDiscountAction } from '../../../providers/cartActions/actions/removeDiscountAction'
import { Table } from './components/Table'
import { CouponCode } from './components/CouponCode'
import { RemoveCouponButton } from './components/RemoveCouponButton'
import { useGtmV4 } from '../../../providers/gtmV4'
import { useCartProperties } from '../../../hooks/useCartProperties'

export type CartSummaryProps = {
  hasCouponRows?: boolean
  hasCouponRemoveOption?: boolean
  hasSubtotalRows?: boolean
  hasShippingCostRows?: boolean
  hasTotalRows?: boolean
  values?: {
    subTotal: NormalizedAmount
    shipping?: NormalizedAmount
    total: NormalizedAmount
    codeDiscounts?: NormalizedDiscountCode[]
    cartDiscounts?: NormalizedCartDiscount[]
    freeShippingThreshold?: NormalizedAmount
  }
}

/**
 * A component which shows the summary of cartProps (subtotal, shipping cost, total)
 * and the discounts for a cart or order.
 */
export const Summary: FunctionComponent<CartSummaryProps> = ({
  hasCouponRows = true,
  hasCouponRemoveOption = true,
  hasSubtotalRows = true,
  hasShippingCostRows = true,
  hasTotalRows = true,
  values,
}) => {
  const { t } = useTranslation()
  const format = useCurrencyFormatter()
  const { cart } = useCartState()
  const cartProps = useCartProperties(cart)
  const maxCharsDescription = 80
  const { setDiscountCodeInput } = useDiscountCodeInput()
  const { push: queue } = useCartActions()
  const total = values?.total || cartProps.total
  const subTotal = values?.subTotal || cartProps.subTotal
  const shipping = values?.shipping || cartProps.shipping
  const codeDiscounts = values?.codeDiscounts || cartProps.codeDiscounts
  const cartDiscounts = values?.cartDiscounts || cartProps.cartDiscounts
  const freeShippingThreshold =
    values?.freeShippingThreshold || cartProps.freeShippingThreshold
  const isFreeShipping = cartProps.isFreeShipping
  const { pushGtmV4Event } = useGtmV4()
  const handleRemoveCoupon = useCallback(() => {
    queue(removeDiscountAction(pushGtmV4Event)).then(() =>
      setDiscountCodeInput(undefined)
    )
  }, [queue, setDiscountCodeInput, pushGtmV4Event])

  return (
    <Table>
      {/* // subTotal */}
      {hasSubtotalRows && (
        <>
          <Spacing mb="spacing-xs">
            <Paragraph size={2} weight={500}>
              {t('cart:summary:subtotal')}
            </Paragraph>
          </Spacing>
          <Price
            price={subTotal}
            size="m"
            align="right"
            data-testid="summary-subtotal-price"
          />
        </>
      )}

      {/* // shipping */}
      {hasShippingCostRows && (
        <>
          <Spacing mb="spacing-xs">
            <Paragraph size={2} weight={500}>
              {t('cart:summary:shipping')}
            </Paragraph>
            {freeShippingThreshold.amount > 0 && (
              <Paragraph
                size={4}
                color="darkergrey"
                data-testid="summary-shipping-info"
              >
                {isFreeShipping
                  ? t('cart:summary:subtotal_freeShipping')
                  : t('cart:summary:subtotal_info', {
                      freeShipping: format(
                        freeShippingThreshold.amount -
                          (total.amount - shipping.amount)
                      ),
                    })}
              </Paragraph>
            )}
          </Spacing>
          <Price
            price={shipping}
            size="m"
            align="right"
            data-testid="summary-shipping-price"
          />
        </>
      )}

      {/* // coupons */}
      {hasCouponRows &&
        codeDiscounts?.map(({ code, appliedCartDiscounts, savings }, i) => (
          <React.Fragment key={i}>
            <Spacing mb="spacing-xs" key={`coupon-${i}-label`}>
              <Paragraph size={2} weight={500}>
                {t('cart:summary:coupon')}
                <CouponCode data-testid="summary-coupon-code">
                  {code}
                </CouponCode>
                {hasCouponRemoveOption && (
                  <RemoveCouponButton
                    onClick={handleRemoveCoupon}
                    aria-label="remove coupon"
                    data-testid="summary-coupon-remove"
                  >
                    <Icon type={icons.closeX} width="0.8em" height="0.8em" />
                  </RemoveCouponButton>
                )}
              </Paragraph>
              {appliedCartDiscounts &&
                appliedCartDiscounts.map((d, i) => (
                  <Paragraph
                    size={4}
                    color="darkergrey"
                    data-testid={`summary-coupon-info-${i}`}
                    key={i}
                  >
                    {d.description.length > maxCharsDescription
                      ? d.description.substring(0, maxCharsDescription) + '...'
                      : d.description}
                  </Paragraph>
                ))}
            </Spacing>
            <Price
              price={savings}
              negate
              size="m"
              align="right"
              data-testid={`summary-coupon-discount-${code}`}
            />
          </React.Fragment>
        ))}
      {/* // other cartDiscounts */}
      {hasCouponRows &&
        cartDiscounts?.map(({ description, savings }, i) => (
          <React.Fragment key={`otherdiscounts-${i}`}>
            <Spacing mb="spacing-xs" key={`coupon-${i}-label`}>
              <Paragraph size={2} weight={500}>
                {t('cart:summary:discount')}
              </Paragraph>
              <Paragraph
                size={4}
                color="darkergrey"
                data-testid={`summary-other-discount-info-${i}`}
                key={i}
              >
                {description.length > maxCharsDescription
                  ? description.substring(0, maxCharsDescription) + '...'
                  : description}
              </Paragraph>
            </Spacing>
            <Price
              price={savings}
              negate
              size="m"
              align="right"
              data-testid={`summary-other-discount-value-${i}`}
            />
          </React.Fragment>
        ))}

      {/* // total */}
      {hasTotalRows && (
        <>
          <div>
            <Paragraph size={1} weight={700}>
              {t('cart:summary:total')}
            </Paragraph>
            <Paragraph size={4} color="darkergrey">
              {t(
                `${
                  cart?.normalizedCartType === 'digital'
                    ? 'price:vatIncluded'
                    : 'price:vatAndShippingIncluded'
                }`
              )}
            </Paragraph>
          </div>
          <Price
            price={cartProps.total}
            strikePrice={cartProps.strike}
            align="right"
            data-testid="summary-total-price"
          />
        </>
      )}
    </Table>
  )
}
