import React from 'react'
import styled from 'styled-components'

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const AvatarInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  /* Subtract shadow height */
  width: calc(100% - 0.25rem);
  height: calc(100% - 0.25rem);

  /* Background shadow of the avatar which is a
  simple circle with an offset applied to it. */
  &::after {
    content: '';
    z-index: -1;
    background: ${props => props.theme.GrayLighter};
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0.25rem;
    border-radius: 50%;
  }
`

const AvatarAspectRatio = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
`

export const Avatar: NormalizedFunctionComponent<{
  src: string
  alt: string
  size?: number
}> = ({ src, alt, size }) => {
  return (
    <AvatarAspectRatio>
      <AvatarInner>
        <AvatarImg src={src} alt={alt} width={size} height={size} />
      </AvatarInner>
    </AvatarAspectRatio>
  )
}
