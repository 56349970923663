import { Button, CTARow, useIsViewportMobile } from '@boxine/tonies-ui'
import React, { FunctionComponent, useCallback } from 'react'
import { AddToCart } from '../../../atoms/AddToCart'
import { useTranslation } from 'next-i18next'
import { useGtmV4 } from '../../../../providers/gtmV4'

export type Props = {
  product: NormalizedProduct
  textLinkText?: string
  textLinkUrl?: string
}

export const CallToAction: FunctionComponent<Props> = ({ product }) => {
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()
  const isMobile = useIsViewportMobile()

  const handlePdpClick = useCallback(() => {
    pushGtmV4Event({ eventType: 'selectItem', item: product })
  }, [product, pushGtmV4Event])

  return (
    <CTARow justifyContent={isMobile ? 'center' : 'start'}>
      <AddToCart
        type="omniButton"
        product={product}
        gtmActionField="SectionProductCopy"
        renderPrice
      />
      <Button
        as="a"
        variant="secondary"
        href={product.path}
        onClick={handlePdpClick}
      >
        {t('sectionProductCopy:DiscoverMore')}
      </Button>
    </CTARow>
  )
}
