import { CartAction } from '..'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { removeDiscountCode } from '../../../lib/commercetools/requests/carts/removeDiscountCode'
import { GtmV4Event } from '../../gtmV4/types'

export const removeDiscountAction =
  (pushGtmV4Event?: (e: GtmV4Event) => void): CartAction<void> =>
  async ({ lcCC, fetchOrCreateCart, replaceCart, logError }) => {
    const cart = await fetchOrCreateCart()

    if (!cart.cartDiscount?.byDiscountCode) {
      const discountError = new Error('No discount code in cart')
      logError(discountError, 'removeDiscountAction', cart)
      throw discountError
    }
    const discountCodeId = cart.cartDiscount?.byDiscountCode.id
    const discountCode = cart.cartDiscount?.byDiscountCode.code

    const removeDiscountCodeResponse = await removeDiscountCode(
      lcCC,
      discountCodeId,
      cart.id,
      cart.version
    )

    if (removeDiscountCodeResponse.result === 'successful') {
      replaceCart(normalizeCart(removeDiscountCodeResponse.data, lcCC))

      if (pushGtmV4Event) {
        pushGtmV4Event({
          eventType: 'coupon',
          event: 'remove',
          coupon: discountCode,
        })
      }
    } else {
      logError(
        removeDiscountCodeResponse.error,
        'removeDiscountAction',
        removeDiscountCodeResponse.data
      )
    }
  }
