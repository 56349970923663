import React, { FunctionComponent } from 'react'
import { Button } from '@boxine/tonies-ui'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { useTranslation } from 'react-i18next'
import { GtmV4AudioContentEvent } from 'providers/gtmV4/types'

export type AssingButtonProps = {
  isDisabled?: boolean
  audioLibraryAssignUrl: string
  dataTestId: string
  handleClickAssign?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => void
  isAssignButtonPending?: boolean
  labelText?: string
  dataLayerEvent?: GtmV4AudioContentEvent
}

const AssignButtonContainer: FunctionComponent<AssingButtonProps> = ({
  isDisabled = false,
  audioLibraryAssignUrl,
  dataTestId,
  handleClickAssign,
  labelText = undefined,
  dataLayerEvent = undefined,
  isAssignButtonPending = false,
}) => {
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()
  // Checking if a # instead of the audioLibraryUrl is set to open the assign modal instead of crosslinking to mytonies
  if (handleClickAssign && audioLibraryAssignUrl === '#') {
    const createAssignButtonDataTestid = () => {
      let testId = `${dataTestId}`

      if (
        labelText &&
        labelText === t('ownAudioContent:ChangeTonieButtonLabel')
      ) {
        testId = `${dataTestId}--change-tonie`
      }
      if (
        labelText &&
        labelText !== t('ownAudioContent:ChangeTonieButtonLabel')
      ) {
        testId = `${dataTestId}--info`
      }
      return testId
    }
    return (
      <Button
        isPending={isAssignButtonPending}
        disabled={isDisabled}
        icon={
          labelText && labelText !== t('ownAudioContent:ChangeTonieButtonLabel')
            ? 'info'
            : 'assign'
        }
        data-testid={createAssignButtonDataTestid()}
        onClick={(
          e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
        ) => {
          handleClickAssign(e)
          if (dataLayerEvent) {
            pushGtmV4Event(dataLayerEvent)
          }
        }}
        type="button"
      >
        {!isAssignButtonPending && labelText
          ? labelText
          : t('products:tunes:assign')}
      </Button>
    )
  }

  return (
    <Button
      // @ts-expect-error disabled
      disabled={isDisabled}
      icon="assign"
      as="a"
      data-testid={dataTestId}
      href={audioLibraryAssignUrl}
      onClick={handleClickAssign}
    >
      {t('products:tunes:assign')}
    </Button>
  )
}

export const AssignButton = withGTMV4Context(
  AssignButtonContainer,
  'AssignButton'
)
