import styled from 'styled-components'
import CloudSvg from '../assets/cloud.svg'
import StarBigSvg from '../assets/star-big.svg'
import StarSmallSvg from '../assets/star-small.svg'
import { media } from '@boxine/tonies-ui'

export const CloudRight = styled(CloudSvg)`
  scale: 0.6;

  ${media.laptop`
    scale: 1;
  `}
`

export const CloudLeft = styled(CloudSvg)`
  scale: 0.4;

  ${media.laptop`
    scale: 0.6;
  `}
`

export const StarSmall = styled(StarSmallSvg)`
  scale: 0.6;

  ${media.laptop`
    scale: 1;
  `}
`

export const StarBig = styled(StarBigSvg)`
  scale: 0.6;

  ${media.laptop`
    scale: 1;
  `}
`
