import React, { FunctionComponent } from 'react'
import { Paragraph, useIsViewportMobile } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { useDateFormatter } from '../../../hooks/useDateFormatter'

export type Props = {
  shippingFromDate: number
}

export const PreOrderDate: FunctionComponent<Props> = ({
  shippingFromDate,
}) => {
  const isMobile = useIsViewportMobile()
  const { t } = useTranslation()
  const df = useDateFormatter()

  return (
    <Paragraph
      align="center"
      color="primary"
      size={isMobile ? 2 : 3}
      weight={500}
    >
      {t('checkout:preOrderDate', {
        date: df(new Date(shippingFromDate)),
      })}
    </Paragraph>
  )
}
