import React, { FunctionComponent } from 'react'
import { Paragraph } from '@boxine/tonies-ui'
import { SupportedAlignments } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import styled from 'styled-components'

export type Props = {
  html: string
  pSize?: 1 | 2 | 3 | 4 | 5
  pWeight?: 300 | 500 | 700
  align?: SupportedAlignments
}

const Wrapper = styled.div`
  display: grid;
  gap: 0.25rem;
`

// FIXME: avoid overwriting internal component styles
const StyledParagraph = styled(Paragraph)`
  b,
  strong {
    font-weight: 500;
  }

  em,
  i {
    font-style: italic;
  }

  a {
    color: ${p => p.theme.colors.primary};
  }
`

/**
 * This component maps all <p> tags in the given free HTML to ToniesUI-complaint
 * <Paragraph>s with optionally given font size and font weight.
 *
 * If the input string contains no <p> tags at all, the entire string is treated
 * as one Paragraph.
 *
 * Linebreaks in the input string are converted to HTML breaks (<br/>).
 *
 * All other tags remain unchanged and are passed to React's
 * `dangerouslySetInnerHtml` hack. It is up to the caller to ensure that this
 * results in no invalid HTML nesting like headlines in paragraphs etc.
 *
 * The component applies some basic styling, such as <b> font-weight, <a> link colors
 * and spacing inbetween paragraphs.
 */
export const FreeHtml: FunctionComponent<Props> = ({
  html,
  pSize = 1,
  pWeight = 300,
  align = 'left',
}) => {
  const splitHtml = html
    .replace(/<\/p>/g, '')
    .split('<p>')
    .map(html => html.trim()) // remove leading/trailing whitespace (mostly: newlines)
    .filter(html => html.length > 0) // remove empty paragraphs
    .map(html => html.replace(/\n+/g, '<br/>'))

  return (
    <Wrapper>
      {splitHtml.map(pHtml => (
        <StyledParagraph
          key={pSize + pWeight + pHtml}
          size={pSize}
          weight={pWeight}
          align={align}
          // temp fix for https://gitlab.boxine.de/tonies/tonies-ui/-/commit/3d843f68c5128da08e29399ec67d6c6d7c92d6da#note_104570
          // dangerouslySetInnerHTML={{ __html: pHtml }}
        >
          <span dangerouslySetInnerHTML={{ __html: pHtml }} />
        </StyledParagraph>
      ))}
    </Wrapper>
  )
}
