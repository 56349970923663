import { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useAuth } from '../providers/auth'
import { useLocale } from '../providers/locale'
import { useGtmV4 } from '../providers/gtmV4'
import {
  GtmV4GenerateLeadEvent,
  isGtmV4GenerateLeadEventLeadType,
} from '../providers/gtmV4/types'
import { BrazeFormProps } from '../components/organisms/BrazeForm'
import { submit } from '../lib/braze'
import { useUrlQuery } from '../providers/urlQuery'

type FormState = 'IDLE' | 'SUCCESS'

export const useBrazeFormik = ({
  customUserAttributes,
  eventLabel,
  eventName,
  formLocation,
  hasAgeSelect,
  optinEventName,
  variant,
}: Pick<
  BrazeFormProps,
  | 'customUserAttributes'
  | 'eventLabel'
  | 'eventName'
  | 'formLocation'
  | 'hasAgeSelect'
  | 'optinEventName'
  | 'variant'
>) => {
  const { t } = useTranslation()
  const lcCC = useLocale()
  const [status, setStatus] = useState<FormState>('IDLE')
  const { geoIpCountry, isPending, user } = useAuth()
  const { pushGtmV4Event } = useGtmV4()
  const { getUrlParamAsSingleton } = useUrlQuery()

  const aiStoryRequestsValidationSchema =
    variant === 'AI Story Requests'
      ? hasAgeSelect
        ? yup.array().of(
            yup.object().shape({
              name: yup.string().required(t('brazeForm:error:required')),
              age: yup.number().required(t('brazeForm:error:required')),
              tonie1: yup.string().required(t('brazeForm:error:required')),
            })
          )
        : yup.array().of(
            yup.object().shape({
              name: yup.string().required(t('brazeForm:error:required')),
              tonie1: yup.string().required(t('brazeForm:error:required')),
            })
          )
      : yup.array().default([])

  const childrenValidationSchema =
    variant === 'Kids Birthdays'
      ? yup.array().of(
          yup.object().shape({
            day: yup.string().required(t('brazeForm:error:required')),
            month: yup.string().required(t('brazeForm:error:required')),
            year: yup.string().required(t('brazeForm:error:required')),
          })
        )
      : yup.array().default([])

  const eventMessageValidationSchema =
    variant === 'Sweepstake' && eventLabel
      ? yup.string().required(t('brazeForm:error:required'))
      : yup.string().default('')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aiStoryRequests:
        variant === 'AI Story Requests'
          ? [{ name: '', age: '', tonie1: '', tonie2: '', tonie3: '' }]
          : [],
      children:
        variant === 'Kids Birthdays'
          ? [{ name: '', day: '', month: '', year: '' }]
          : [],
      country: geoIpCountry.toLowerCase(),
      email: '',
      eventMessage: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    validationSchema: yup.object().shape({
      aiStoryRequests: aiStoryRequestsValidationSchema,
      children: childrenValidationSchema,
      email: yup
        .string()
        .email(t('brazeForm:error:email:invalid'))
        .required(t('brazeForm:error:required')),
      eventMessage: eventMessageValidationSchema,
    }),
    onSubmit: (values, actions) => {
      const variantAsGtmV4LeadType = variant.toLowerCase().replace(' ', '_')
      const leadType = isGtmV4GenerateLeadEventLeadType(variantAsGtmV4LeadType)
        ? variantAsGtmV4LeadType
        : (`useBrazeFormik unknown gtmV4 leadType '${variantAsGtmV4LeadType}'` as GtmV4GenerateLeadEvent['leadType'])

      submit({
        ...values,
        customUserAttributes,
        eventName,
        gtmV4: {
          formLocation,
          leadType,
          pushGtmV4Event,
        },
        lcCC,
        optinEventName,
      })

      setStatus('SUCCESS')
      actions.setSubmitting(false)
    },
  })

  useEffect(() => {
    const email = !isPending
      ? getUrlParamAsSingleton('email') || user?.email
      : undefined
    if (email) {
      formik.setFieldValue('email', email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUrlParamAsSingleton, user, isPending])

  return {
    ...formik,
    status,
  }
}
