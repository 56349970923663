import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import { Paragraph, ProgressBar } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { useCurrencyFormatter } from '../../../hooks/useCurrencyFormatter'

export type Props = {
  goodsValue: number
  freeShippingThreshold: number
}

const Wrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 1rem;
  justify-items: center;
`

const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(max, value))

export const FreeShippingIndicator: FunctionComponent<
  Props & Pick<ComponentPropsWithoutRef<typeof ProgressBar>, 'backgroundColor'>
> = ({ goodsValue, freeShippingThreshold, ...progressBarProps }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const format = useCurrencyFormatter()
  const percentage =
    freeShippingThreshold > 0
      ? 100 * clamp(goodsValue / freeShippingThreshold, 0, 1)
      : 100

  return (
    <Wrapper>
      <Paragraph
        size={3}
        weight={500}
        data-testid="freeshipping-indicator-label"
      >
        {t('cart:freeshipping:unreached', {
          diff: format(freeShippingThreshold - goodsValue),
        })}
      </Paragraph>
      <ProgressBar
        percentage={percentage}
        backgroundColor={theme.colors['anthracite-20']}
        {...progressBarProps}
      />
    </Wrapper>
  )
}
