import React, { ComponentProps, useCallback } from 'react'
import { ProductOverviewPromotionItemSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { EditorialTeaser } from '../../../../../../components/molecules/EditorialTeaser'
import { useGtmV4, withGTMV4Context } from '../../../../../../providers/gtmV4'
import { GtmV4SelectContentEvent } from '../../../../../../providers/gtmV4/types'

export const CM_ProductOverviewPromotionItemSchemaContainer = ({
  componentProps,
  ...rest
}: ContentModelType<
  ComponentProps<typeof EditorialTeaser>,
  ProductOverviewPromotionItemSchema
>) => {
  const { pushGtmV4Event } = useGtmV4()

  const handleOnClick = useCallback(
    (type: GtmV4SelectContentEvent['type']) => {
      pushGtmV4Event({
        eventType: 'selectContent',
        type,
        ctaLabel: rest.ctaText,
        ctaLink: rest.ctaUrl,
      })
    },
    [rest.ctaUrl, rest.ctaText, pushGtmV4Event]
  )

  return (
    <>
      <EditorialTeaser
        {...rest}
        aspectRatio="inherit"
        backgroundImageUrl={rest.promotionImage.file.url}
        ctaStyling="primary"
        onClick={handleOnClick}
        {...componentProps}
      />
    </>
  )
}

export const CM_ProductOverviewPromotionItemSchema = withGTMV4Context(
  CM_ProductOverviewPromotionItemSchemaContainer,
  'PromotionTeaser'
)
