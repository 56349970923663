import { useCallback } from 'react'
import { useTuneValidation } from './useTuneValidation'
import { useBipocValidation } from './useBipocValidation'

export const useProductValidation = () => {
  const tuneValidation = useTuneValidation()
  const bipocValidation = useBipocValidation()

  return useCallback(
    async (p: NormalizedProduct) => {
      try {
        await bipocValidation(p)
        await tuneValidation(p)
        return true
      } catch {
        return false
      }
    },
    [tuneValidation, bipocValidation]
  )
}
