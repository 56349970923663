import { http } from '../../../http.service'

export const handleAssignAudioContent = ({
  assignUrl,
  onSuccess,
  onError,
}: {
  assignUrl?: string
  onSuccess: () => void
  onError: () => void
}) => {
  if (!assignUrl) {
    return
  }
  http
    .put(assignUrl)
    .then(response => {
      if (response.status === 200 && onSuccess) {
        onSuccess()
      }
    })
    .catch(error => {
      // TODO: Add sentry
      console.log(error)
      if (onError) {
        onError()
      }
    })
}
