import React from 'react'
import Head from 'next/head'
import { stripTags } from '../../../../utils/stripTags'

type ApplicationProps = {
  description: string
  homepageUrl: string
  title: string
}

export const Application = ({
  description,
  homepageUrl,
  title,
}: ApplicationProps) => (
  <Head>
    <meta name="title" content={stripTags(title)} />
    <meta name="description" content={description} />
    <link rel="manifest" href="/manifest.json" />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="application-name" content="tonies®" />
    <meta name="apple-mobile-web-app-title" content="tonies®" />
    <meta name="theme-color" content="#d2000f" />
    <meta name="msapplication-navbutton-color" content="#d2000f" />
    <meta name="apple-mobile-web-app-status-bar-style" content="black" />
    <meta name="msapplication-starturl" content={homepageUrl} />
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1,shrink-to-fit=no"
    />
    <link rel="preconnect" href={process.env.NEXT_PUBLIC_GTM_DOMAIN} />
    <link
      rel="dns-prefetch"
      href={process.env.NEXT_PUBLIC_GTM_DOMAIN}
      crossOrigin="anonymous"
    />
    <link rel="preconnect" href="https://res.cloudinary.com/" />
    <link
      rel="dns-prefetch"
      href="https://res.cloudinary.com/"
      crossOrigin="anonymous"
    />
  </Head>
)
