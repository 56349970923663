import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { ProductCollection } from '../ProductCollection'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { Button, CTARow, Headline1, Spacing } from '@boxine/tonies-ui'
import { useProductOverview } from '../../../providers/productOverview'
import { NoProductsFound } from './components/NoProductsFound'
import { useVisibleEntries } from './hooks/useVisibleEntries'
import {
  GenericSelectionType,
  SelectionProvider,
} from '../../../providers/selection'
import { SidebarOverlayProvider } from '../../../providers/SidebarOverlay'
import { Filters } from './components/Filters'
import Pagination from '@/tonies-ui/molecules/Pagination'
import { Document } from '@contentful/rich-text-types'
import { RichText } from '../../../components/molecules/RichText'
import { ProductCollectionProps } from '../ProductCollection/types'

export type ProductOverviewProps = {
  headline?: string
  text?: Document
  selection?: GenericSelectionType
  columns?: number
  stickyFilter?: { isActive: boolean; stickyOffset: number }
  hasFilters?: boolean
  hasSearch?: boolean
  hasSorting?: boolean
  hasTopFilters?: boolean
} & Pick<ProductCollectionProps, 'hasPrices' | 'getCardAction'>

export const ProductOverview: FunctionComponent<ProductOverviewProps> = ({
  headline,
  text,
  selection,
  columns,
  stickyFilter = { isActive: false, stickyOffset: 0 },
  hasFilters,
  hasSearch,
  hasSorting,
  hasTopFilters,
  ...productCollectionProps
}) => {
  const { t } = useTranslation()
  const {
    search,
    filter,
    entries: products,
    loadMore,
    isPending,
    shopCategory,
    pagination: {
      onPageChange,
      isShown: isPaginationShown,
      currentPage,
      maxPage,
    },
  } = useProductOverview()

  const visibleEntries = useVisibleEntries(
    products.filteredAndPaginated,
    selection?.selection
  )

  return (
    <SelectionProvider {...selection}>
      <SidebarOverlayProvider>
        {headline && (
          <Spacing mb="spacing-s">
            <Headline1>
              <Accent text={headline} />
            </Headline1>
          </Spacing>
        )}
        {text && (
          <Spacing mb="spacing-xs">
            <RichText document={text} size={1} />
          </Spacing>
        )}
        <Filters
          isSticky={stickyFilter.isActive}
          stickyOffset={stickyFilter.stickyOffset}
          hasFilters={hasFilters}
          hasSearch={hasSearch}
          hasSorting={hasSorting}
          hasTopFilters={hasTopFilters}
        />
        {!isPending && visibleEntries.length === 0 ? (
          <Spacing mb="spacing-s">
            <NoProductsFound
              causedBy={
                filter?.isFilterActive
                  ? 'filter'
                  : search
                  ? 'search'
                  : 'no-products'
              }
            />
          </Spacing>
        ) : (
          <>
            <Spacing mb="spacing-s">
              <ProductCollection
                {...productCollectionProps}
                columns={columns}
                referenceTitle={shopCategory}
                entries={visibleEntries}
                cardOrientation="portrait"
                pendingState={{ isPending, productsPerPage: 24 }}
              />
            </Spacing>
            {isPaginationShown && (
              <Spacing mt="spacing-l">
                <Pagination
                  current={currentPage}
                  max={maxPage}
                  onPageChange={onPageChange}
                />
              </Spacing>
            )}
            {!isPaginationShown && loadMore.isShown && (
              <CTARow justifyContent="center">
                <Button
                  icon="arrowDown"
                  onClick={loadMore.onLoadMore}
                  isPending={isPending}
                >
                  {t('products:loadMore')}
                </Button>
              </CTARow>
            )}
          </>
        )}
      </SidebarOverlayProvider>
    </SelectionProvider>
  )
}
