import { CM_AudioPlayerSectionSchema } from './schemas/organisms/AudioPlayerSectionSchema'
import { CM_FrequentlyAskedQuestions } from './schemas/organisms/FrequentlyAskedQuestions'
import { CM_ProductItemTeaserCarouselSchema } from './schemas/organisms/ProductItemTeaserCarouselSchema'
import { CM_ProductItemTeaserListSchema } from './schemas/organisms/ProductItemTeaserListSchema'
import { CM_EditorialTeaserListSchema } from './schemas/organisms/EditorialTeaserListSchemaSchema'
import { CM_TextHeroSchema } from './schemas/organisms/TextHeroSchema'
import { CM_Carousel } from './schemas/organisms/Carousel'
import { CM_CommunitySchema } from './schemas/organisms/CommunitySchema'
import { CM_MediaCopyCollectionSchema } from './schemas/organisms/MediaCopyCollectionSchema'
import { CM_MediaCopySectionSchema } from './schemas/organisms/MediaCopySectionSchema'
import { CM_DownloadTeaserEntryListSchema } from './schemas/organisms/DownloadTeaserEntryListSchema'
import { CM_GallerySchema } from './schemas/organisms/GallerySchema'
import { CM_BrazeFormSchema } from './schemas/organisms/BrazeFormSchema'
import { CM_GiftfinderSchema } from './schemas/organisms/GiftfinderSchema'
import { CM_ProductOverviewPromotionItemSchema } from './schemas/molecules/ProductOverviewPromotionItemSchema'
import { CM_ProductSelectorSchema } from './schemas/organisms/ProductSelectorSchema'
import { CM_ProductsSchema } from './schemas/organisms/ProductsSchema'

export const ContentModels = {
  AudioPlayerSection: CM_AudioPlayerSectionSchema,
  ProductItemTeaserList: CM_ProductItemTeaserListSchema,
  ProductItemTeaserCarousel: CM_ProductItemTeaserCarouselSchema,
  FrequentlyAskedQuestions: CM_FrequentlyAskedQuestions,
  DownloadTeaserEntryList: CM_DownloadTeaserEntryListSchema,
  EditorialTeaserList: CM_EditorialTeaserListSchema,
  TextHero: CM_TextHeroSchema,
  Carousel: CM_Carousel,
  Community: CM_CommunitySchema,
  MediaCopyCollection: CM_MediaCopyCollectionSchema,
  MediaCopySection: CM_MediaCopySectionSchema,
  Gallery: CM_GallerySchema,
  BrazeForm: CM_BrazeFormSchema,
  Giftfinder: CM_GiftfinderSchema,
  PromotionTeaser: CM_ProductOverviewPromotionItemSchema,
  ProductSelector: CM_ProductSelectorSchema,
  Products: CM_ProductsSchema,
}
