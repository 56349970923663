import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { Accent, Button, Paragraph, Spacing, CTARow } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { useTranslation, Trans } from 'next-i18next'
import { useGtmV4 } from '../../../../providers/gtmV4'
import { StyledSection } from './styles'
import { useUrlQuery } from '../../../../providers/urlQuery'
import { theme } from '@/tonies-ui/themes/theme'
import { useCartState } from '../../../../hooks/useCartState'
import { useCartActions } from '../../../../hooks/useCartActions'
import { addDiscountAction } from '../../../../providers/cartActions/actions/addDiscountAction'
import { removeDiscountAction } from '../../../../providers/cartActions/actions/removeDiscountAction'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { toast } from '@/tonies-ui/atoms/Toast'

export const UrlReferralCodeSection: FunctionComponent<{
  activeProduct: NormalizedProduct
}> = ({ activeProduct }) => {
  const { t } = useTranslation()
  const { push: queue } = useCartActions()
  const { pushGtmV4Event } = useGtmV4()
  const { getUrlParamAsSingleton } = useUrlQuery()
  const [isIdle, setIsIdle] = useState(true)

  const {
    cart,
    isPendingState: [isPending],
  } = useCartState()
  const cartCode = cart?.cartDiscount?.byDiscountCode?.code

  const urlCode = getUrlParamAsSingleton('referral-code')

  const shouldExecute = !!urlCode && activeProduct.shopCategory === 'tonieboxes'

  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen(currentValue => !currentValue)

  const addUrlCodeToCart = useCallback(() => {
    if (urlCode) {
      // remove prior cart code
      if (cartCode) {
        queue(removeDiscountAction())
      }
      // try to add url referral code
      queue(addDiscountAction(urlCode, pushGtmV4Event)).then(res => {
        /**
         * FIXME (maybe)
         *
         * For visitors WITHOUT A CART yet:
         *
         * Investigate why we are returning "DoesNotMatchCart" in this case and whether this
         * is a problem here because the code has actually been successfully added to the cart.
         *
         * https://gitlab.boxine.de/shop/tonies-nextjs/-/blob/master/providers/cartActions/actions/addDiscountAction.ts#L36
         */
        if (['ok', 'DoesNotMatchCart'].includes(res)) {
          setIsIdle(false)
          toast(t('tonieboxSelector:urlReferralCode.toast.success'))
        } else {
          toast(t('tonieboxSelector:urlReferralCode.toast.error'), 'error')
          // readd prior cart code on error
          if (cartCode) {
            queue(addDiscountAction(cartCode))
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCode, urlCode])

  const handleOnClick = useCallback(() => {
    addUrlCodeToCart()
    setIsOpen(false)
  }, [addUrlCodeToCart])

  useEffect(() => {
    if (!isPending && shouldExecute) {
      // do nothing, if cart code and url code match
      if (cartCode === urlCode) {
        return
      }

      if (cartCode) {
        // open modal on existing cart code
        setIsOpen(true)
      } else {
        // add url code
        addUrlCodeToCart()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending])

  if (!shouldExecute) {
    return null
  }

  return (
    <>
      {!isIdle && (
        <ColorContextProvider backgroundColor={theme.colors['lightblue-20']}>
          <StyledSection backgroundColor={theme.colors['lightblue-20']}>
            <Spacing pt="spacing-xxs">
              <Paragraph size={2} weight={500} align="center">
                <Trans
                  i18nKey="tonieboxSelector:urlReferralCode.message"
                  components={{
                    em: <Accent />,
                    b: <Paragraph size={2} weight={700} asHTMLTag="strong" />,
                  }}
                />
              </Paragraph>
            </Spacing>
          </StyledSection>
        </ColorContextProvider>
      )}
      <Modal
        headline={t(
          'tonieboxSelector:urlReferralCode.modal.cartHasCode.headline'
        )}
        footer={
          <CTARow>
            <Button variant="secondary" onClick={handleToggle}>
              {t('tonieboxSelector:urlReferralCode.modal.cartHasCode.keepCode')}
            </Button>
            <Button onClick={handleOnClick}>
              {t(
                'tonieboxSelector:urlReferralCode.modal.cartHasCode.replaceCode'
              )}
            </Button>
          </CTARow>
        }
        isOpen={isOpen}
        onClose={handleToggle}
      >
        <Paragraph size={2}>
          <Trans
            i18nKey="tonieboxSelector:urlReferralCode.modal.cartHasCode.text"
            components={{
              em: <Accent />,
              b: <Paragraph size={2} weight={700} asHTMLTag="strong" />,
            }}
          />
        </Paragraph>
      </Modal>
    </>
  )
}
