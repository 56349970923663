import { BxReferralCode } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

export const getCode = async (lcCC: EcomLocale) =>
  fetch<Record<string, unknown>, BxReferralCode>({
    query: `/bx/referral/`,
    lcCC,
    method: 'post',
    request: {},
  })
