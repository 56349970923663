import React, {
  ComponentPropsWithoutRef,
  FunctionComponent,
  useEffect,
  useMemo,
} from 'react'
import { useTranslation } from 'next-i18next'
import styled, { useTheme } from 'styled-components'
import {
  GridArea,
  Grid,
  Headline2,
  Button,
  Spacing,
  Paragraph,
  Box,
  useIsViewportTablet,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import { FreeShippingIndicator as FreeShippingIndicatorView } from '../../../components/molecules/FreeShippingIndicator'
import { Layout } from '../../../components/organisms/ProductCollection/components/Layout'
import { CartDeliveryInfoList } from './CartDeliveryInfoList'
import { useCartState, useTunesInCart } from '../../../hooks/useCartState'
import { Hr } from '../../../components/atoms/Hr'
import { LineItemCollection } from '../../../components/organisms/LineItemCollection'
import { CouponSummary } from '../../CheckoutLayout/components/CouponSummary'
import { PreOrderDate } from '../../CheckoutLayout/components/PreOrderDate'
import { EmptyCart } from '../../../components/organisms/EmptyState/variants/EmptyCart'
import { useCartActions } from '../../../hooks/useCartActions'
import { ComponentSpinner } from '@/tonies-ui/atoms/ComponentSpinner'
import { useSyncCartStateWithCookies } from '../../../hooks/useSyncCartStateWithCookies'
import { useSyncCartStateWithAuth } from '../../../hooks/useSyncCartStateWithAuth'
import { Expandable } from '../../../components/atoms/Expandable'
import { Content, StyledButtonWrapper } from '../styles'
import { useGtmV4 } from '../../../providers/gtmV4'
import { useCheckoutConfig } from '../../../providers/checkout'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'
import { ProductCardCarousel } from '../../../components/organisms/ProductCardCarousel'
import { DeliveryInfoTeaserCollectionProps } from '../../../components/organisms/DeliveryInfoTeaserCollection'
import { useAuth } from '../../../providers/auth'
import { InfoBox } from '@/tonies-ui/atoms/InfoBox'
import { PaymentIconList } from '../../../components/molecules/PaymentIconList'
import { useCartProperties } from '../../../hooks/useCartProperties'
import { StickyFooter } from './StickyFooter'

export type CartProps = {
  layout: 'narrow' | 'wide'
  cartDeliveryInfoTeaserList?: DeliveryInfoTeaserCollectionProps['items'] | null
  cartDeliveryInfo?: string
  handleClose?: () => void
  hasHeadline?: boolean
}

const Wrapper = styled.div`
  display: grid;
  align-items: start;
  grid-template-rows: 1fr auto;
`

export const Cart: FunctionComponent<CartProps> = ({
  layout,
  cartDeliveryInfoTeaserList,
  cartDeliveryInfo,
  handleClose,
  hasHeadline,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { authenticated, login } = useAuth()
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { cart } = useCartState()
  const cartProps = useCartProperties(cart)
  const { pushGtmV4Event } = useGtmV4()
  const {
    content: { recommendedNormalizedProductsCart: rnpCart = [] },
  } = useAggregatedShopLocale()
  const hasPhysical = cart?.bxLineItemTypes.includes('physical')
  const showCartDeliveryInfo = hasPhysical && cartDeliveryInfo
  const { state: cartActionState } = useCartActions()
  const { hasTunes } = useTunesInCart()
  const { state: cookieSyncState } = useSyncCartStateWithCookies()
  const { state: authSyncState } = useSyncCartStateWithAuth()
  const config = useCheckoutConfig()
  const isMobile = useIsViewportMobile()
  const isTablet = useIsViewportTablet()
  const isNarrowLayout = layout === 'narrow'
  const lineItems = useMemo(() => cart?.lineItems || [], [cart?.lineItems])
  const isEmptyCart = lineItems.length === 0
  const needLogin = hasTunes && !authenticated

  // Only show orderdable recommended products that are not in the cart
  const staticRecommendations = useMemo(
    () =>
      rnpCart.filter(i => {
        return i.availability.state !== 'sold-out'
      }),
    [rnpCart]
  )

  // Push to GTM
  useEffect(() => {
    if (cart) {
      pushGtmV4Event({
        eventType: 'checkout',
        event: 'view_cart',
        cart,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, pushGtmV4Event])

  const headline = (
    <Headline2 asHTMLTag="h1" align={isNarrowLayout ? 'center' : undefined}>
      {t('cart:headline')}
    </Headline2>
  )

  const FreeShippingIndicator = (
    props: Partial<ComponentPropsWithoutRef<typeof FreeShippingIndicatorView>>
  ) =>
    cartProps.freeShippingThreshold.amount > 0 ? (
      <FreeShippingIndicatorView
        {...props}
        backgroundColor={colors.white}
        goodsValue={cartProps.goodsValue.amount}
        freeShippingThreshold={cartProps.freeShippingThreshold.amount}
      />
    ) : null

  const cartItems = (
    <LineItemCollection
      lineItems={lineItems}
      quantityChange={config.lineItems.quantityChange}
    />
  )

  const shippingFrom = cartProps.shippingFromDate && (
    <>
      <Hr />
      <Spacing pt="spacing-s" mr="spacing-s" pb="spacing-s" ml="spacing-s">
        <PreOrderDate shippingFromDate={cartProps.shippingFromDate} />
      </Spacing>
    </>
  )

  const CheckoutButton = () => (
    <Button
      as="a"
      data-testid="checkout-button"
      /**
       * use window.location.search to keep the existing query parameters
       * when going to checkout
       */
      href={t('header:CheckoutLink') + window.location.search}
      // @ts-expect-error: `disabled` is not supported on <a />, but can be used for disabled behavior
      disabled={cartActionState === 'processing' || lineItems.length === 0}
    >
      {t('cart:checkoutButtonText')} ({lineItems.length})
    </Button>
  )

  const LoginButton = () => {
    const redirectUri = new URL(window.location.toString())

    return (
      <Button
        data-testid="checkout-login-button"
        icon="login"
        onClick={() => {
          redirectUri.searchParams.append('expandSideCart', '1')

          login({
            redirectUri: redirectUri.toString(),
          })
        }}
      >
        {t('checkout:login:loginButtonText')}
      </Button>
    )
  }

  const grid =
    layout === 'wide' ? (
      <Grid hasGapH hasGapV>
        {isEmptyCart ? (
          <GridArea rowStart={0} colWidth={12}>
            <EmptyCart staticRecommendations={staticRecommendations} />
          </GridArea>
        ) : (
          <>
            {hasHeadline && (
              <GridArea rowStart={0} colStart={0} colWidth={8}>
                {/* Top-Left: Headline */}
                {headline}
              </GridArea>
            )}
            {config.shipping.freeShippingIndicator && (
              <GridArea rowStart={0} colStart={8} colWidth={4}>
                {!cartProps.isFreeShipping && <FreeShippingIndicator />}
              </GridArea>
            )}
            <GridArea rowStart={1} rowHeight={2} colStart={0} colWidth={8}>
              {/* Bottom-Left: LineItems */}
              <Layout columns={1} cardOrientation="landscape">
                {cartItems}
              </Layout>
              {staticRecommendations.length > 0 && (
                <div style={{ overflow: 'hidden' }}>
                  <Spacing mt="spacing-m">
                    <ProductCardCarousel
                      headline={t('productDetailPage:recommended')}
                      headlineIsSectionTitle={false}
                      items={staticRecommendations}
                      getAPIRecommendations={true}
                      columns={{ desktop: 3, tablet: 2.5 }}
                      hideSliderButtons={isTablet}
                      hideCartLineItems
                      referenceTitle="[coded] Recommendations Cart"
                    />
                  </Spacing>
                </div>
              )}
            </GridArea>
            <GridArea colStart={8} colWidth={4} rowStart={1}>
              {/* Bottom-Right Panel: Coupon + Cart Summary */}
              <Spacing pb="spacing-s">
                <Expandable
                  data-testid="checkout-expandable-order-summary"
                  headline={t('cart:summary:headline')}
                  isExpanded
                >
                  <Spacing m="spacing-s">
                    <Spacing
                      mb={
                        t('footer:paymentMethods.disclaimer')
                          ? 'spacing-xs'
                          : undefined
                      }
                    >
                      <PaymentIconList
                        iconAlignment="center"
                        iconSize="m"
                        context="global"
                      />
                    </Spacing>
                    {t('footer:paymentMethods.disclaimer') && (
                      <Paragraph size={4} align="center">
                        {t('footer:paymentMethods.disclaimer')}
                      </Paragraph>
                    )}
                  </Spacing>
                  <Hr />
                  {config.coupon.hasCouponCode && (
                    <CouponSummary
                      cartDeliveryInfo={
                        showCartDeliveryInfo ? cartDeliveryInfo : undefined
                      }
                      isFreeShipping={cartProps.isFreeShipping}
                    />
                  )}
                  {needLogin && (
                    <Spacing mt="spacing-s">
                      <InfoBox
                        type="error"
                        width="fullwidth"
                        borderRadius="none"
                        data-testid="infoBox-login-required"
                        px="spacing-s"
                      >
                        {t('cart:digital:loginRequired')}
                      </InfoBox>
                    </Spacing>
                  )}
                  <Spacing m="spacing-s">
                    <StyledButtonWrapper>
                      {needLogin ? <LoginButton /> : <CheckoutButton />}
                    </StyledButtonWrapper>
                  </Spacing>
                  {config.shipping.shippingMethodSelect && (
                    <>
                      {shippingFrom}
                      <Hr />
                      <Spacing m="spacing-s">
                        <CartDeliveryInfoList
                          cartDeliveryInfoTeaserList={
                            cartDeliveryInfoTeaserList
                          }
                        />
                      </Spacing>
                    </>
                  )}
                </Expandable>
              </Spacing>
            </GridArea>
          </>
        )}
      </Grid>
    ) : (
      <>
        {hasHeadline && <Spacing mb="spacing-s">{headline}</Spacing>}
        {!isEmptyCart ? (
          <Content>
            <div>
              {showCartDeliveryInfo && (
                <InfoBox type="info" borderRadius="none" width="fullwidth">
                  {cartDeliveryInfo}
                </InfoBox>
              )}
              {config.shipping.freeShippingIndicator && (
                <>
                  {cartProps.isFreeShipping ? (
                    <InfoBox
                      type="success"
                      borderRadius="none"
                      width="fullwidth"
                    >
                      {t('cart:freeshipping:reached')}
                    </InfoBox>
                  ) : (
                    <Box p="spacing-s" backgroundColor={colors.lightgrey}>
                      <FreeShippingIndicator />
                    </Box>
                  )}
                </>
              )}
              <Spacing
                mx="spacing-s"
                mt={
                  !hasHeadline && config.shipping.freeShippingIndicator
                    ? 'spacing-s'
                    : undefined
                }
              >
                <Spacing
                  mb={
                    t('footer:paymentMethods.disclaimer')
                      ? 'spacing-xs'
                      : undefined
                  }
                >
                  <PaymentIconList
                    iconAlignment="center"
                    iconSize="m"
                    context="global"
                  />
                </Spacing>
                {t('footer:paymentMethods.disclaimer') && (
                  <Paragraph size={4} align="center">
                    {t('footer:paymentMethods.disclaimer')}
                  </Paragraph>
                )}
              </Spacing>
            </div>
            <Hr />
            <Spacing mx="spacing-s">{cartItems}</Spacing>
            {staticRecommendations.length > 0 && (
              <div style={{ overflow: 'hidden' }}>
                <Spacing px={isMobile ? undefined : 'spacing-s'}>
                  <ProductCardCarousel
                    headline={t('productDetailPage:recommended')}
                    headlineIsSectionTitle={false}
                    showEmptyCards={false}
                    items={staticRecommendations}
                    getAPIRecommendations={true}
                    columns={{ desktop: 1.5, tablet: 1.5 }}
                    hideSliderButtons
                    hideCartLineItems
                    referenceTitle="[coded] Recommendations Cart"
                  />
                </Spacing>
              </div>
            )}
            {config.coupon.hasCouponCode && (
              <div>
                <Hr />
                <CouponSummary />
              </div>
            )}
            {config.orderReview.price.shouldShowShippingCosts && (
              <>
                <Hr />
                {shippingFrom}
                <Spacing mx="spacing-s" mb="spacing-s">
                  <CartDeliveryInfoList
                    cartDeliveryInfoTeaserList={cartDeliveryInfoTeaserList}
                  />
                </Spacing>
              </>
            )}
          </Content>
        ) : (
          <div style={{ minWidth: 0 }}>
            <Spacing m="spacing-s">
              <EmptyCart
                isInSidebar
                onShopNow={handleClose}
                staticRecommendations={staticRecommendations}
              />
            </Spacing>
          </div>
        )}
        <StickyFooter />
      </>
    )

  return (
    <Wrapper data-testid="cart-wrapper">
      {cookieSyncState === 'finished' && authSyncState === 'finished' ? (
        grid
      ) : (
        <ComponentSpinner data-testid="cart-spinner" height="20rem" />
      )}
    </Wrapper>
  )
}
