import { useEffect, useState } from 'react'

export function useLottieAnimationData(
  animationUrl?: string
): Record<string, unknown> | undefined {
  const [animationData, setAnimationData] = useState<
    Record<string, unknown> | undefined
  >(undefined)

  useEffect(() => {
    if (!animationUrl) {
      return
    }

    fetch(animationUrl)
      .then(async response => {
        if (response.ok) {
          setAnimationData(await response.json())
        }
      })
      .catch(error => console.log(error))
  }, [animationUrl])

  return animationData
}
