import React, { FunctionComponent } from 'react'
import { Paragraph, ParagraphProps } from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { useCurrencyFormatter } from '../../../hooks/useCurrencyFormatter'
import { Color, GridArea, PriceProps, Size } from './types'
import { Container } from './components/Container'
import { Cell } from './components/Cell'
import { StrikeThrough } from './components/StrikeThrough'

const textSizes: Record<Size, Record<GridArea, ParagraphProps['size']>> = {
  s: {
    price: 3,
    'strike-price': 4,
    'vat-hint': 5,
    'eco-tax-hint': 5,
  },
  m: {
    price: 2,
    'strike-price': 3,
    'vat-hint': 4,
    'eco-tax-hint': 4,
  },
  l: {
    price: 1,
    'strike-price': 2,
    'vat-hint': 3,
    'eco-tax-hint': 3,
  },
} as const

export const Price: FunctionComponent<PriceProps> = p => {
  const { t } = useTranslation()
  const cf = useCurrencyFormatter()
  const { strikePrice, price, ecoTax } = 'product' in p ? p.product : p
  const size: Size = p.size || 'l'
  const color: Color = p.color || 'darkergrey'

  if (!price) {
    return null
  }

  return (
    <Container {...p}>
      {strikePrice && (
        <Cell area="strike-price">
          <Paragraph
            color={color}
            size={textSizes[size]['strike-price']}
            weight={300}
          >
            <StrikeThrough data-testid="strike-price">
              {cf(strikePrice.amount)}
            </StrikeThrough>
          </Paragraph>
        </Cell>
      )}
      {price && (
        <Cell area="price">
          <Paragraph
            color={
              strikePrice && color !== 'white' && color !== 'normalgrey'
                ? 'primary'
                : color
            }
            size={textSizes[size]['price']}
            weight={700}
            data-testid="price"
          >
            {(p.negate ? '-' : '') + cf(price.amount)}
          </Paragraph>
        </Cell>
      )}
      {p.vatHint && (
        <Cell area="vat-hint">
          <Paragraph
            color={color}
            size={textSizes[size]['vat-hint']}
            weight={300}
            data-testid="vat-hint"
          >
            {t(
              p.vatHint.content === 'included'
                ? 'price:vatIncluded'
                : p.vatHint.content === 'includedShipping'
                ? 'price:vatAndShippingIncluded'
                : 'price:vatExcluded'
            )}
          </Paragraph>
        </Cell>
      )}
      {ecoTax && (
        <Cell area="eco-tax-hint">
          <Paragraph
            color={color}
            size={textSizes[size]['eco-tax-hint']}
            weight={300}
            data-testid="eco-tax-hint"
          >
            {t('price:ecoTax', {
              amount: cf(ecoTax.amount),
            })}
          </Paragraph>
        </Cell>
      )}
    </Container>
  )
}
