import { GtmV4Event } from '../../../providers/gtmV4/types'

/**
 * sets last seen product sku and pushes `audioSample` event `play` to GTMV4
 * using passed in handlers from hooks
 * @param product
 * @param pushEvent
 */
export const handleOnPlay = (
  product: NormalizedProduct,
  pushEvent: (event: GtmV4Event) => void
) => {
  pushEvent({
    eventType: 'audioSample',
    step: 'play',
    item: product,
  })
}
