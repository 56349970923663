import React, { ReactNode, useCallback } from 'react'
import {
  Document as RichTextDocument,
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { Accent, Paragraph } from '@boxine/tonies-ui'
import { PromotionBanner } from '@/tonies-ui/molecules/PromotionBanner'
import { CopyToCliboardButton } from '@/tonies-ui/molecules/PromotionBanner/components/CopyToClipboardButton'
import useStorage from '../../../hooks/useStorage'
import { useNativeApp } from '../../../providers/nativeApp'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { useTranslation } from 'next-i18next'

export type BannerProps = {
  content: RichTextDocument | string
  onClose: () => void
}

const BannerContainer = ({ content, onClose }: BannerProps) => {
  const { isApp } = useNativeApp()
  const { pushGtmV4Event } = useGtmV4()

  const [isPromotionBannerShown, setIsPromotionBannerShown] = useStorage(
    'promotion-banner-visible',
    true,
    'sessionStorage'
  )
  const { t } = useTranslation()
  const closeBanner = useCallback(() => {
    setIsPromotionBannerShown(false)
    setTimeout(onClose, 0)
  }, [setIsPromotionBannerShown, onClose])

  const RENDEROPTIONS: Options = {
    renderMark: {
      [MARKS.BOLD]: (children: ReactNode) => (
        <Paragraph size={2} weight={700} asHTMLTag="strong">
          {children}
        </Paragraph>
      ),
      [MARKS.ITALIC]: children => <Accent>{children}</Accent>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: unknown, children: ReactNode) => (
        <Paragraph size={2} align="center">
          {children}
        </Paragraph>
      ),
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, content) =>
        node.data.uri === '#copyToClipboard' ? (
          <CopyToCliboardButton
            clipboardCopy={content ? content.toString() : ''}
            toastSuccessMessage={
              `${content?.toString()} ` +
              t('promotion-banner:TOASTcopyToClipBoardSucess')
            }
            toastAutoCloseSeconds={3000}
            toastId="copy-to-clipboard-success-message"
            onClick={() =>
              pushGtmV4Event({
                eventType: 'click',
                type: 'onClick',
                label: content?.toString() || '',
                action: 'copy_to_clipboard',
              })
            }
          />
        ) : (
          <a
            href={node.data.uri}
            onClick={() =>
              pushGtmV4Event({
                eventType: 'selectContent',
                ctaLabel: content?.toString() || '',
                ctaLink: node.data.uri,
                type: 'text-link',
              })
            }
          >
            {content}
          </a>
        ),
    },
  }

  return !isApp ? (
    <PromotionBanner isOpen={isPromotionBannerShown} onClose={closeBanner}>
      {/* Typecasting here because content can't be undefined at this point */}
      {typeof content === 'string' ? (
        <Paragraph size={2} align="center">
          {content}
        </Paragraph>
      ) : (
        <>{documentToReactComponents(content, RENDEROPTIONS)}</>
      )}
    </PromotionBanner>
  ) : null
}

export const Banner = withGTMV4Context(BannerContainer, 'Banner')
