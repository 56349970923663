import React from 'react'
import Script from 'next/script'
import { Locale } from '../../../../config/shopAPI/types'
import { shopConfig } from '../../../../config/shop'
import { toAltNotation } from '../../../../providers/locale/altNotation'

type BazaarvoiceScriptProps = {
  lcCC: Locale
}

export const BazaarvoiceScript = ({ lcCC }: BazaarvoiceScriptProps) => {
  const bazaarvoiceClientKey = shopConfig.locales[lcCC].bazaarvoice?.clientKey
  if (!bazaarvoiceClientKey) {
    return null
  }

  const environment =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
      ? 'production'
      : 'staging'
  const locale = toAltNotation(lcCC, 'lc_CC')

  return (
    <Script
      id="bazaarvoice"
      src={`//apps.bazaarvoice.com/deployments/${bazaarvoiceClientKey}/main_site/${environment}/${locale}/bv.js`}
      async
    ></Script>
  )
}
