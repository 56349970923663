import React from 'react'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { IconButton, IconButtonProps } from '@/tonies-ui/atoms/IconButton'
import { useWishlist } from '../../../providers/Wishlist'
import { useTheme } from 'styled-components'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { useTranslation } from 'react-i18next'

export const useToggleWishlistIconProps = (
  product: NormalizedProduct,
  wishlist?: NormalizedWishlist
): IconButtonProps | undefined => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { toggleFromWishlist, hasWishlist, myDefaultWishlist, isPending } =
    useWishlist()
  const { pushGtmV4Event } = useGtmV4()
  const currentWishlist = wishlist || myDefaultWishlist
  const isOnWishlist = currentWishlist?.getItem(product.sku)

  return hasWishlist
    ? {
        icon: isOnWishlist ? 'heartFill' : 'heart',
        iconColor: colors[isOnWishlist ? 'primary' : 'darkergrey'],
        size: 'large',
        onClick: () =>
          toggleFromWishlist(product, pushGtmV4Event, currentWishlist),
        dataTestId: `toggle-from-wishlist-button--${
          isOnWishlist ? 'remove' : 'add'
        }`,
        ariaLabel: t(`wishlist:action:${isOnWishlist ? 'remove' : 'add'}`),
        isDisabled: isPending,
        isPending,
        styling: 'secondary',
      }
    : undefined
}

const ToggleFromWishlistContainer: NormalizedFunctionComponent<
  {
    product: NormalizedProduct
    wishlist?: NormalizedWishlist
  } & Styleable
> = ({ product, wishlist }) => {
  const iconProps = useToggleWishlistIconProps(product, wishlist)

  return iconProps ? (
    <IconButton
      fillColor="white"
      borderColor="white"
      size="large"
      {...iconProps}
    />
  ) : null
}

export const ToggleFromWishlist = withGTMV4Context(
  ToggleFromWishlistContainer,
  'ToggleFromWishlist'
)
