import React from 'react'
import Head from 'next/head'
import { fullUrl } from '../../../../utils/urlHelpers'
import { useBreadcrumbs } from '../../../../layouts/ProductDetailLayout/hooks/useBreadcrumbs'

type StructuredDataProductProps = {
  imageUrl: string
  pageUrl: string
  product: NormalizedProductExtended
}

export const StructuredDataProduct = ({
  imageUrl,
  pageUrl,
  product,
}: StructuredDataProductProps) => {
  let availability = 'https://schema.org/InStock'
  if (product.availability.state === 'sold-out') {
    availability = 'https://schema.org/OutOfStock'
  }
  if (product.availability.state === 'preorderable') {
    availability = 'https://schema.org/PreOrder'
  }

  let itemCondition = 'https://schema.org/NewCondition'
  if (product.shopCategory === 'tonieboxes-refurbished') {
    itemCondition = 'https://schema.org/RefurbishedCondition'
  }

  const name = [
    product.normalizedCategories[0].name,
    product.series?.label,
    product.name,
  ]
    .filter(Boolean)
    .join(' - ')

  const structuredDataProduct = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    '@id': fullUrl(product.path || '', true),
    name: name,
    image: imageUrl,
    description: product.metaDescription,
    brand: {
      '@type': 'Brand',
      name: product.brand,
    },
    sku: product.sku,
    productID: product.salesId,
    gtin: product.gtin,
    offers: {
      '@type': 'Offer',
      url: pageUrl,
      priceCurrency: product.price?.currency,
      price: `${product.price?.amount}`,
      availability: availability,
      itemCondition: itemCondition,
    },
  }

  const breadcrumbs = useBreadcrumbs(product)

  const structuredDataBreadcrumbs = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((b, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: b.label,
      item: b.url,
    })),
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        data-testid="structured-data__product"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredDataProduct),
        }}
      />
      <script
        type="application/ld+json"
        data-testid="structured-data__breadcrumbs"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredDataBreadcrumbs),
        }}
      />
    </Head>
  )
}
