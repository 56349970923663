/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  FunctionComponent,
  useMemo,
} from 'react'
import styled from 'styled-components'
import { AudioPlayerItem } from '../../../lib/contentful/datamodel/schemas'
import { Section } from '../../atoms/Section'
import {
  Grid,
  GridArea,
  Headline2,
  Spacing,
  media,
  useIsViewportDesktop,
  AudioPlayer,
  Button,
} from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { Cloud, BookAndStars } from './components/Doodles'
import { RichText } from '../../molecules/RichText'
import { Document } from '@contentful/rich-text-types'
import { useTranslation } from 'next-i18next'
import { AggregatedSkuList } from '../../../lib/transformation/aggregateSkuList'
import { SupportedHTMLTags } from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { shopConfig } from '../../../config/shop'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { WithReferenceTitle } from '../../../providers/gtmV4/types'
import { Items } from './components/Items'
import { Tonie } from './components/Tonie'
import { handleOnPlay } from './utils'

const StyledSection = styled(Section)`
  overflow: hidden;
`

const TonieboxImage = styled.img`
  width: 18.75rem;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  z-index: 5;

  ${media.tablet`
    width: 22rem;
  `}

  ${media.laptop`
    width: unset;
    bottom: 0;
    left: unset;
    transform: unset;
  `}
`

const TonieBoxWrapper = styled.div`
  position: relative;
  grid-column-start: 1;
  grid-column-end: 13;
  bottom: -2.5rem;
  min-height: 22rem;

  ${media.tablet`
    min-height: 34rem;
  `}

  ${media.laptop`
    grid-column-start: 7;
    width: 100%;
    bottom: -5rem;
  `}
`

const AudioPlayerWrapper = styled.span`
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  transform: translateX(-50%);
  left: 38%;
  top: 0;

  ${media.laptop`
    top: -2rem;
    left: 7rem;
    height: 3.75rem;
    width: 3.75rem;
  `}
`

const ButtonWrapper = styled.span`
  display: flex;
  justify-content: center;
`

export type AudioPlayerSectionProps = {
  backgroundColor?: string
  'data-testid'?: string
  entries?: Pick<AudioPlayerItem, 'audioFile' | 'image' | 'title'>[]
  headlineAsHTMLTag?: SupportedHTMLTags
  skuList?: AggregatedSkuList
  text: Document
  title: string
  id?: string
}

const AudioPlayerSectionContainer: FunctionComponent<
  WithReferenceTitle<AudioPlayerSectionProps>
> = ({
  backgroundColor,
  'data-testid': dataTestId,
  entries,
  headlineAsHTMLTag,
  skuList,
  title,
  text,
  id,
}) => {
  const { t } = useTranslation()
  const isDesktop = useIsViewportDesktop()

  // GTM product select variant event
  const { pushGtmV4Event } = useGtmV4()

  const [activeItemId, setActiveItemId] = useState('0')

  const audioPlayerItems = useMemo(
    () =>
      skuList
        ? skuList.normalizedProducts.map(p => {
            return {
              audio: p.audioSampleUrl ?? '',
              src: p.image.src,
              alt: p.image.alt,
              title: p.series?.label ?? '',
              pdpLink: p.path ?? '',
              product: p,
            }
          })
        : entries
        ? entries.map(p => {
            return {
              audio: p.audioFile.file.url,
              src: p.image.file.url,
              alt: p.title,
              title: p.title,
              pdpLink: '',
              product: undefined,
            }
          })
        : [],
    [entries, skuList]
  )

  const activeProduct = skuList?.normalizedProducts[parseInt(activeItemId)]
  const activeProductIdRef = useRef('')
  const activeTonie = audioPlayerItems[parseInt(activeItemId)]
  const hasPdpLink =
    activeTonie.pdpLink !== undefined && activeTonie.pdpLink !== ''

  /**
   * MOTIONS
   */
  const [isAnimated, setIsAnimated] = useState(false)

  /**
   * CALLBACKS
   */
  const handleChangeActiveItem = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setActiveItemId(event.target.value)
    },
    []
  )

  // GTM product audio sample event
  const handleAudioSampleOnPlay = useCallback(() => {
    if (activeProduct) {
      handleOnPlay(activeProduct, pushGtmV4Event)
      setIsAnimated(true)
    }
  }, [activeProduct, pushGtmV4Event])

  // GTM product select variant event
  useEffect(() => {
    if (activeProduct && activeProductIdRef.current !== activeProduct.id) {
      pushGtmV4Event({
        eventType: 'selectVariant',
        step: activeProductIdRef.current === '' ? 'pageload' : 'select',
        item: activeProduct,
      })
      activeProductIdRef.current = activeProduct.id
    }
  }, [activeProduct, pushGtmV4Event])

  return (
    <StyledSection
      data-testid={dataTestId}
      backgroundColor={backgroundColor}
      id={id}
    >
      <Spacing mt="spacing-xxl">
        <Grid>
          <GridArea colWidth={isDesktop ? 5 : 12}>
            <Headline2 asHTMLTag={headlineAsHTMLTag}>
              <Accent text={title} />
            </Headline2>
            <Spacing
              mt="spacing-s"
              mb={hasPdpLink ? 'spacing-m' : 'spacing-xxl'}
            >
              <RichText size={2} document={text} />
            </Spacing>
            <Spacing
              mb={
                isDesktop ? undefined : hasPdpLink ? 'spacing-m' : 'spacing-xxl'
              }
            >
              <Items
                value={activeItemId}
                onChange={handleChangeActiveItem}
                items={audioPlayerItems}
              />
            </Spacing>
            {hasPdpLink && (
              <ButtonWrapper>
                <Spacing
                  mt={isDesktop ? 'spacing-xxl' : undefined}
                  mb="spacing-xxl"
                >
                  <Button as="a" href={activeTonie.pdpLink}>
                    {t('audioPlayerSection:pdpLinkButton')}
                  </Button>
                </Spacing>
              </ButtonWrapper>
            )}
          </GridArea>
          <TonieBoxWrapper>
            <AudioPlayerWrapper>
              <AudioPlayer
                hasButtonBoxed
                src={activeTonie.audio ?? ''}
                loading="lazy"
                onPlay={handleAudioSampleOnPlay}
                onStop={() => setIsAnimated(false)}
                onEnded={() => setIsAnimated(false)}
              />
            </AudioPlayerWrapper>
            <Tonie activeItem={activeTonie} />
            <BookAndStars isAnimated={isAnimated} />
            {/* Note: because this component heavily relies on the image itself, 
                it uses a custom img element */}
            <TonieboxImage
              src={shopConfig.assets.audioPlayerSectionBackground}
              alt="red toniebox"
            />
          </TonieBoxWrapper>
          <Cloud />
        </Grid>
      </Spacing>
    </StyledSection>
  )
}

export const AudioPlayerSection = withGTMV4Context(
  AudioPlayerSectionContainer,
  'AudioPlayerSection'
)
