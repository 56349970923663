import React, { ComponentProps } from 'react'
import { CommunitySchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { Gallery } from '../../../../../../components/organisms/Gallery2'

export const CM_CommunitySchema = ({
  componentProps,
  entries,
  title,
  ...rest
}: ContentModelType<ComponentProps<typeof Gallery>, CommunitySchema>) => {
  return (
    <Gallery
      {...rest}
      {...componentProps}
      headline={title}
      images={entries.map(entry => ({
        imageSrc: entry.image.file.url,
        link: entry.link,
        caption: entry.caption,
      }))}
    />
  )
}
