import { useId } from '@reach/auto-id'
import React, {
  ComponentPropsWithoutRef,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  useIsViewportMobile,
  media,
  MediaPlayerButton,
  useMediaPlayer,
} from '@boxine/tonies-ui'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import { useInView } from 'framer-motion'

export type VideoPlayerProps = {
  alt: string
  srcDesktop: string
  srcMobile?: string
  posterMobile?: string
  posterDesktop?: string
  crop?: 'crop' | 'fill' | 'autoGravity'
  'data-testid'?: string
} & Omit<
  ComponentPropsWithoutRef<'video'>,
  'src' | 'poster' | 'playsInline' | 'preload' | 'onLoadedMetadata'
>

export const VideoWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  position: relative;
  padding-bottom: 1.5rem;

  ${media.laptop`
    padding-bottom: 1.875rem;
  `}
`

export const VideoArea = styled.div<{
  heightPercent: string
}>`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${props => props.heightPercent};
`

export const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ButtonArea = styled.div`
  bottom: 0;
  height: 3rem;
  position: absolute;
  right: 1.5rem;
  width: 3rem;

  ${media.laptop`
    height: 3.75rem;
    right: 1.875rem;
    width: 3.75rem;
  `}
`

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  'data-testid': dataTestId = 'video-player--audioPlayButton',
  alt,
  srcDesktop,
  srcMobile,
  posterMobile,
  posterDesktop,
  crop = 'fill',
  controls = true,
  ...rest
}) => {
  const isMobile = useIsViewportMobile()
  const [percentPlayed, setPercentPlayed] = useState<number | undefined>()
  const [heightPercent, setHeightPercent] = useState('56.25%')
  const videoRef = useRef<HTMLVideoElement>(null)
  const [poster, setPoster] = useState('')
  const [src, setSrc] = useState('')
  const uniqueId = useId(srcDesktop)

  // Generate unique player ID

  const mediaPlayerId = useMemo(() => uniqueId + src, [uniqueId, src])

  // Fetch list of all media players

  const { mediaPlayers } = useMediaPlayer()

  // Playback handlers

  const start = useCallback(() => {
    if (videoRef.current?.paused === true) {
      // Stop all other players
      for (const mp of mediaPlayers) {
        mp.stop()
      }

      // Start playback
      videoRef.current.play()

      // Update progress indicator
      setPercentPlayed(0)
    }
  }, [mediaPlayers])

  const stop = useCallback(() => {
    if (videoRef.current?.paused === false) {
      // Stop playback
      videoRef.current.pause()

      // Update progress indicator
      setPercentPlayed(undefined)

      // Rewind
      videoRef.current.currentTime = 0
    }
  }, [])

  // Click handler

  const handleClick = useCallback(() => {
    if (videoRef.current?.paused === true) {
      start()
    } else if (videoRef.current?.paused === false) {
      stop()
    }
  }, [start, stop])

  // Update the progress indicator

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          setPercentPlayed(undefined)
        } else {
          const p = videoRef.current.currentTime / videoRef.current.duration
          setPercentPlayed(p * 100)
        }
      }
    }, 250)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  // Update video wrapper height from video meta data

  const handleOnLoadedMetadata = useCallback(() => {
    if (videoRef.current) {
      const p =
        (videoRef.current.videoHeight / videoRef.current.videoWidth) * 100 + '%'
      setHeightPercent(p)
    }
  }, [setHeightPercent, videoRef])

  // Stop when player is scrolled out of viewport
  if (!useInView(videoRef)) {
    stop()
  }

  // MediaPlayer context integration

  useEffect(() => {
    const mp = {
      id: mediaPlayerId,
      start,
      stop,
    }

    mediaPlayers.push(mp)

    return () => {
      mediaPlayers.splice(mediaPlayers.indexOf(mp, 1))
    }
  }, [mediaPlayerId, mediaPlayers, start, stop])

  // video src

  useEffect(() => {
    if (isMobile) {
      if (src !== srcMobile) {
        setSrc(srcMobile || srcDesktop)
      }
    } else {
      if (src !== srcDesktop) {
        setSrc(srcDesktop)
      }
    }
  }, [isMobile, srcMobile, srcDesktop, src])

  // video poster

  useEffect(() => {
    if (isMobile) {
      if (posterMobile) {
        setPoster(
          cloudinaryUrl({
            src: posterMobile,
            width: 1000,
            height: 1000,
            crop,
          })
        )
      } else {
        setPoster(process.env.NEXT_PUBLIC_VIDEO_PLAYER_POST_MOBILE || '')
      }
    } else {
      if (posterDesktop) {
        setPoster(
          cloudinaryUrl({
            src: posterDesktop,
            width: 1920,
            height: 1080,
            crop,
          })
        )
      } else {
        setPoster(process.env.NEXT_PUBLIC_VIDEO_PLAYER_POST_DESKTOP || '')
      }
    }
  }, [crop, isMobile, posterMobile, posterDesktop])

  return (
    <VideoWrapper data-testid={dataTestId}>
      <VideoArea heightPercent={heightPercent}>
        <Video
          {...rest}
          data-testid={`${dataTestId}__video`}
          playsInline
          ref={videoRef}
          src={src}
          poster={rest.autoPlay ? undefined : poster}
          preload="metadata"
          onLoadedMetadata={handleOnLoadedMetadata}
        >
          {alt}
        </Video>
      </VideoArea>
      {controls !== false && (
        <ButtonArea>
          <MediaPlayerButton
            data-testid={`${dataTestId}__media-button`}
            isBoxed
            onClick={handleClick}
            progress={percentPlayed}
          />
        </ButtonArea>
      )}
    </VideoWrapper>
  )
}
