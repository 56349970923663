import React, { ComponentProps } from 'react'
import { SectionHeadline } from '../../../../../../components/molecules/SectionHeadline'
import { ProductCollection } from '../../../../../../components/organisms/ProductCollection'
import { ProductItemTeaserListSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'

export const CM_ProductItemTeaserListSchema = ({
  componentProps,
  skuList: { normalizedProducts, referenceTitle },
  ...rest
}: ContentModelType<
  ComponentProps<typeof ProductCollection>,
  ProductItemTeaserListSchema
>) => {
  return (
    <>
      <SectionHeadline {...rest} />
      <ProductCollection
        referenceTitle={referenceTitle}
        entries={normalizedProducts}
        cardOrientation="portrait"
        getCardAction={() => 'addToCart'}
        {...componentProps}
      />
    </>
  )
}
