import { useIsAudioLibraryCheckout } from '../../../../hooks/useIsAudioLibraryCheckout'
import { useNativeApp } from '../../../../providers/nativeApp'

export const useAudioLibraryAssingUrlWithContext = (
  audioLibraryAssignUrl?: string
) => {
  const { isApp } = useNativeApp()
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  if (!audioLibraryAssignUrl) {
    return
  }
  if (audioLibraryAssignUrl === '#') {
    return '#'
  }
  const updatedAudioLibraryUrl = new URL(audioLibraryAssignUrl)

  if (isApp) {
    updatedAudioLibraryUrl.searchParams.set('webview', 'app')
  }
  if (isAudioLibraryCheckout) {
    updatedAudioLibraryUrl.searchParams.set('context', 'audio-library-checkout')
  }
  return updatedAudioLibraryUrl
}
