import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'

const StyledLink = styled.a<{
  inheritColor?: boolean
}>`
  font-size: inherit;
  color: ${props =>
    props.inheritColor
      ? 'inherit !important'
      : props.theme.colorContext === 'light'
      ? props.theme.link.color
      : props.theme.colors.white};
  text-decoration: underline;
  font-weight: ${props =>
    props.theme.colorContext === 'light' && !props.inheritColor
      ? 'normal'
      : '500'};
`

interface LinkProps {
  inheritColor?: boolean
  onClick?: () => void
  target?: '_blank'
  to: string
  title?: string
}

export const Link: FunctionComponent<LinkProps & PropsWithChildren> = ({
  children,
  inheritColor,
  onClick,
  target,
  title,
  to,
}) => (
  <StyledLink
    href={to}
    inheritColor={inheritColor}
    onClick={onClick}
    target={target}
    title={title}
  >
    {children}
  </StyledLink>
)
