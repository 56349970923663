import React from 'react'
import Head from 'next/head'
import Script from 'next/script'

export const CmpPreload = () => (
  <Head>
    <link rel="preconnect" href="//app.usercentrics.eu" />
    <link rel="preconnect" href="//api.usercentrics.eu" />
    <link
      rel="preload"
      href="//app.usercentrics.eu/browser-ui/latest/loader.js"
      as="script"
    ></link>
  </Head>
)

export const CmpScript = () => (
  <Script
    id="usercentrics-cmp"
    src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
    data-version={
      process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production'
        ? 'preview'
        : undefined
    }
    data-settings-id={process.env.NEXT_PUBLIC_USERCENTRICS_ID}
    strategy="beforeInteractive"
    async
  />
)
