import { useMemo } from 'react'
import { SortingOptionType } from '../components/Sorting'
import { useTranslation } from 'react-i18next'

// FIXME
// move this to a config file or get this from contentful product overview pages
export const useSortingOptions = () => {
  const { t } = useTranslation()

  const sortingOptions: SortingOptionType[] = useMemo(
    () => [
      {
        key: 'bestseller',
        id: 'bestseller-desc',
        order: 'desc',
        label: t('sorting:bestseller'),
      },
      {
        key: 'newest',
        id: 'newest-desc',
        order: 'desc',
        label: t('sorting:newest'),
      },
      {
        key: 'alphabetical',
        id: 'alphabetical-asc',
        order: 'asc',
        label: t('sorting:alphabeticalASC'),
      },
      {
        key: 'alphabetical',
        id: 'alphabetical-desc',
        order: 'desc',
        label: t('sorting:alphabeticalDESC'),
      },
      {
        key: 'price',
        id: 'price-asc',
        order: 'asc',
        label: t('sorting:priceASC'),
      },
      {
        key: 'price',
        id: 'price-desc',
        order: 'desc',
        label: t('sorting:priceDESC'),
      },
    ],
    [t]
  )

  return sortingOptions
}
