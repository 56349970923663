import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'
import { useResizeObserver } from '@boxine/tonies-ui'
import styled, { createGlobalStyle } from 'styled-components'
import { useMeasurements } from '../../../providers/measurements'

const Wrapper = styled.header<{
  marginBottom: number
}>`
  margin-bottom: ${p => p.marginBottom}px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 8000;
`

// set scroll margin
const GlobalHeaderStyles = createGlobalStyle<{ offset: number }>`
  * {
    scroll-margin-top: ${({ offset }) => offset}px;
  }
`

/**
 * This header implementation measures the heights of its contents and applies
 * a negative margin-bottom of the same height, so that later components in the
 * page flow will appear "below" the header's contents.
 */
export const Header: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { setMainNavigationHeight } = useMeasurements()
  const ref = useRef<HTMLElement>(null)
  const [marginBottom, setMarginBottom] = useState(0)
  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const marginBottom = entries.length ? -entries[0].contentRect.height : 0
      setMarginBottom(marginBottom)
      setMainNavigationHeight(-marginBottom)
    },
    [setMainNavigationHeight]
  )

  useResizeObserver(handleResize, ref)

  return (
    <Wrapper marginBottom={marginBottom} ref={ref}>
      <GlobalHeaderStyles offset={(ref.current?.offsetHeight || 90) + 16} />
      {children}
    </Wrapper>
  )
}
