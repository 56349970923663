import { media } from '@boxine/tonies-ui'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import styled, { css } from 'styled-components'
import { SectionProps } from '../types'
import { isToniesColor } from '@/tonies-ui/themes/colors'

export const StyledSection = styled.section<
  SectionProps & {
    offset?: number
  }
>`
  ${({
    theme: { colors },
    backgroundColor,
    backgroundImage,
    aspectRatio,
    offset,
    removePaddingY,
  }) => css`
    overflow: clip; // for motion design, "hidden" will break a lot of sticky behaviors
    position: relative;
    background-color: ${backgroundColor && isToniesColor(backgroundColor)
      ? colors[backgroundColor]
      : backgroundColor};
    background-image: ${backgroundImage?.image
      ? 'url(' +
        cloudinaryUrl({
          src: backgroundImage.image,
          width: backgroundImage.width || undefined,
          height: backgroundImage.height || undefined,
        }) +
        ')'
      : 'none'};
    background-repeat: ${backgroundImage?.repeat
      ? backgroundImage.repeat
      : 'no-repeat'};
    background-position: ${backgroundImage?.position
      ? backgroundImage.position
      : 'center center'};
    background-size: ${backgroundImage?.size ? backgroundImage.size : 'cover'};
    padding: ${aspectRatio?.ratio
      ? '0 0 ' + aspectRatio.ratio + '% 0'
      : !removePaddingY
      ? '2.5rem 0'
      : '0'};

    ${!aspectRatio &&
    offset &&
    !removePaddingY &&
    css`
      padding-top: calc(${offset}px + 1.5rem);
    `}

    ${media.laptop`
    padding: ${
      aspectRatio?.ratio
        ? '0 0 ' + aspectRatio?.ratio + '% 0'
        : !removePaddingY
        ? '5rem 0'
        : '0'
    };

    ${
      !aspectRatio &&
      offset &&
      !removePaddingY &&
      css`
        padding-top: calc(${offset}px + 2rem);
      `
    }`}
  `}
`
