import React from 'react'
import { useTranslation } from 'next-i18next'
import { CTARow, Paragraph, Spacing, Button } from '@boxine/tonies-ui'
import { FormikProvider, Field, getIn, useFormik } from 'formik'
import * as yup from 'yup'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { InputField } from '../../../components/atoms/FormElements/Input'
import { BxLineItemCustomFields } from '../../../lib/commercetools/requests/bxTypes'

export const RepairModal = ({
  onResolve = () => null,
  onReject = () => null,
  tonieboxID,
}: {
  onResolve: (
    custom: Pick<
      BxLineItemCustomFields,
      'tonieboxID' | 'repairErrorDescription'
    >
  ) => void
  onReject: () => void
  tonieboxID?: string
}) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      tonieboxID: tonieboxID || '',
      repairErrorDescription: '',
    },
    validationSchema: yup.object().shape({
      tonieboxID: yup
        .string()
        .matches(
          /^([0-9A-Z]{3}-[0-9A-Z]{2}-[0-9A-Z]{3}|[0-9A-Z]{8})$/i,
          t('repairModal:input.tonieboxId.message')
        )
        .required(t('brazeForm:error.required')),
      repairErrorDescription: yup
        .string()
        .required(t('brazeForm:error.required')),
    }),
    onSubmit: values => {
      onResolve({
        tonieboxID: values.tonieboxID,
        repairErrorDescription: values.repairErrorDescription,
      })
    },
  })

  const getFormikError = (fieldName: string): { text: string } | undefined => {
    const error = getIn(formik.errors, fieldName)
    const errorMessage = typeof error === 'string' ? error : undefined
    const touched = getIn(formik.touched, fieldName) as boolean
    return touched && errorMessage ? { text: errorMessage } : undefined
  }

  const formatTonieboxID = () => {
    // uppercase everything
    let v = formik.values.tonieboxID.toUpperCase()
    // convenience for user input without dashes
    // format 12345678 to 123-45-678
    if (formik.values.tonieboxID.length === 8) {
      v = v.substring(0, 3) + '-' + v.substring(3, 5) + '-' + v.substring(5, 8)
    }
    // update field
    formik.setFieldValue('tonieboxID', v)
    // trigger validation
    formik.setFieldTouched('tonieboxID', true)
  }

  return (
    <FormikProvider value={formik}>
      <Modal
        isOpen
        headline={t('repairModal:headline')}
        onClose={onReject}
        footer={
          <CTARow>
            <Button onClick={formik.submitForm} data-testid="button-confirm">
              {t('repairModal:confirm')}
            </Button>
          </CTARow>
        }
        data-testid="repair-modal"
      >
        <Spacing mb="spacing-s">
          <Paragraph size={2}>{t('repairModal:text')}</Paragraph>
        </Spacing>
        <Spacing mb="spacing-s">
          <Field
            as={InputField}
            name="tonieboxID"
            label={t('repairModal:input.tonieboxId.label')}
            hint={t('repairModal:input.tonieboxId.hint')}
            message={getFormikError('tonieboxID')}
            status={getFormikError('tonieboxID') ? 'error' : undefined}
            value={formik.values.tonieboxID}
            maxLength={10}
            onBlur={formatTonieboxID}
          />
        </Spacing>
        <Field
          as={InputField}
          name="repairErrorDescription"
          label={t('repairModal:input.repairErrorDescription.label')}
          message={getFormikError('repairErrorDescription')}
          status={
            getFormikError('repairErrorDescription') ? 'error' : undefined
          }
          value={formik.values.repairErrorDescription}
          maxLength={500}
        />
      </Modal>
    </FormikProvider>
  )
}
