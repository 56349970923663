import { EcomLocale } from '../config/shopAPI/types'
import { getTunesItemBySalesId } from '../lib/cloudservices/requests/graphql/getHouseholdData'
import { useEffect, useState } from 'react'
import { sentryWithExtras } from './useSentry'
import { toast } from '@/tonies-ui/atoms/Toast'
import { useAuth } from '../providers/auth'

export function useAssignIdOfOwnAudioContent(
  lcCC: EcomLocale,
  salesId?: string
) {
  const [currentMyTuneIdToAssign, setCurrentMyTuneIdToAssign] = useState<
    string | undefined
  >(undefined)
  const { hasBearerToken } = useAuth()

  useEffect(() => {
    if (!salesId || !hasBearerToken) {
      return
    }

    getTunesItemBySalesId(lcCC, salesId)
      .then(response => {
        if (response.result === 'successful' && response.data) {
          const myTuneId =
            response.data.data.tunesItems.edges[0]?.node.myTune?.id

          if (myTuneId && setCurrentMyTuneIdToAssign)
            setCurrentMyTuneIdToAssign(myTuneId)
        }
      })
      .catch(error => {
        toast('default:TOASTSomethingWentWrong', 'error', {
          toastId: 'useAssignIdOfOwnAudioContent_Error',
        })
        sentryWithExtras('useAssignIdOfOwnAudioContent', error)
        console.log(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesId, hasBearerToken])
  return currentMyTuneIdToAssign
}
