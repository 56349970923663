import { EcomLocale, Locale } from '../config/shopAPI/types'
import { useEcomLocale, useLocale } from '../providers/locale'

export type LocaleConfigType<
  LocaleValue,
  Locales extends Locale | EcomLocale = EcomLocale
> = {
  [key in Locales]: LocaleValue
}

export const getTypedLocaleConfig = <
  LocaleValue,
  Locales extends Locale | EcomLocale = EcomLocale
>(
  config: LocaleConfigType<LocaleValue, Locales>
) => config

export const getLocaleConfig = <
  LocaleValue,
  Locales extends Locale | EcomLocale
>(
  lcCC: Locales,
  props: LocaleConfigType<LocaleValue, Locales>
) => getTypedLocaleConfig(props)[lcCC]

export const useLocaleConfig = <LocaleValue>(
  props: LocaleConfigType<LocaleValue, Locale>
) => getLocaleConfig(useLocale(), props)

export const useEcomLocaleConfig = <LocaleValue>(
  props: LocaleConfigType<LocaleValue, EcomLocale>
) => getLocaleConfig(useEcomLocale(), props)
