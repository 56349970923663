import { useAuth } from '../providers/auth'

export const useProtectedRoute = (isProtectedRoute: boolean) => {
  const { isPending, authenticated, login } = useAuth()
  const needAuthentication = !isPending && isProtectedRoute && !authenticated

  if (needAuthentication) {
    login()
  }

  return {
    isPending: isProtectedRoute ? isPending || needAuthentication : false,
    hasAccess: !needAuthentication,
  }
}
