import { useEcomLocale } from '../providers/locale'
import { useMemo, useState } from 'react'
import { getLocaleConfig } from './useLocaleConfig'
import BIPoCSkus from '../config/BIPoC'
import { useProducts } from './useProducts'
import { useFeatureFlags } from './useFeatureFlags'

export const useBipocTonies = (initialColor?: string) => {
  const lcCC = useEcomLocale()
  const { hasBIPoCs } = useFeatureFlags()
  const bipocLocaleConfig = getLocaleConfig(lcCC, BIPoCSkus)
  const [bipocColor, setBipocColor] = useState<string | undefined>(initialColor)

  const skus: string[] = useMemo(
    () =>
      hasBIPoCs && bipocColor && bipocLocaleConfig
        ? bipocLocaleConfig[bipocColor]
        : [],
    [bipocLocaleConfig, bipocColor, hasBIPoCs]
  )

  const response = useProducts({
    parameter: {
      sku: skus,
      sort: { isFixedSkuList: true },
      categoryKey: ['creative-tonies'],
    },
    hasClientFetch: !!(hasBIPoCs && skus.length),
  })

  return {
    config: bipocLocaleConfig,
    ...response,
    setBipocColor,
  }
}
