import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { media, useIsViewportDesktop } from '@boxine/tonies-ui'
import {
  motion,
  useAnimate,
  useScroll,
  useSpring,
  useTransform,
} from 'framer-motion'
import { useViewportConfig } from '../../../../tonies-ui/hooks/useViewport'
import { ItemProps } from './Items'
import { Hand } from './Hand'
import { Image } from '@/tonies-ui/atoms/Image'

const TonieImageContainer = styled(motion.div)`
  position: absolute;
  z-index: 1;
  width: 16rem;
  left: 50%;

  ${media.tablet`
    width: 22rem;
  `}

  ${media.laptop`
    width: 26rem;
  `}
`

export const Tonie = ({
  activeItem,
}: {
  activeItem: ItemProps['items'][0]
}) => {
  const isDesktop = useIsViewportDesktop()
  const [currentItem, setCurrentItem] = useState(activeItem)
  const { src, alt } = currentItem

  /**
   * MOTIONS
   */
  const [scope, animate] = useAnimate()
  const tonieScrollPosition = useScroll({
    target: scope,
  })
  const scrollY = useSpring(tonieScrollPosition.scrollYProgress, {
    stiffness: 50,
  })
  const scopeTransition = useViewportConfig({
    mobile: {
      bottom: useTransform(scrollY, [1, 0.85], ['12rem', '8.5rem']),
    },
    tablet: {
      bottom: useTransform(scrollY, [1, 0.85], ['14rem', '9.5rem']),
    },
    desktop: {
      bottom: useTransform(scrollY, [1, 0.5], ['22rem', '16rem']),
    },
  })

  const dataMotionHand = '[data-hand-motion="hand"]'
  const dataMotionImage = '[data-hand-motion="children"]'

  const initialCoordinates = useMemo(() => ({ x: '-50%', y: 0 }), [])
  const outOfWindowCoordinates = useMemo(
    () => ({ x: '150%', y: !isDesktop ? 0 : '-150%' }),
    [isDesktop]
  )

  /**
   * CALLBACKS
   */
  const onChangeTonie = useCallback(async () => {
    await animate([
      [dataMotionHand, initialCoordinates, { duration: 0.4 }],
      [dataMotionImage, outOfWindowCoordinates, { duration: 0.6, delay: 0.2 }],
      [
        dataMotionHand,
        outOfWindowCoordinates,
        { duration: 0.6, delay: 0.2, at: '<' },
      ],
    ])

    setCurrentItem(activeItem)

    await animate([
      [dataMotionHand, initialCoordinates, { duration: 0.4, delay: 0.3 }],
      [
        dataMotionImage,
        initialCoordinates,
        { duration: 0.4, delay: 0.3, at: '<' },
      ],
      [dataMotionHand, outOfWindowCoordinates, { duration: 0.3, delay: 0.1 }],
    ])
  }, [animate, outOfWindowCoordinates, activeItem, initialCoordinates])

  useEffect(() => {
    onChangeTonie()
  }, [onChangeTonie])

  return (
    <TonieImageContainer ref={scope} style={{ ...scopeTransition }}>
      <Hand>
        <Image
          src={src}
          alt={alt}
          ratio={[1, 1]}
          responsive={[75, 150, 250, 300, 400]}
          crop="fill"
        />
      </Hand>
    </TonieImageContainer>
  )
}
