import React, { useCallback } from 'react'
import { useAuth } from '../providers/auth'
import { useTunesInCart } from './useCartState'
import { useModalNotifications } from '../providers/ModalNotifications'
import { TunesValidationModal } from '../components/organisms/TunesValidationModal'

export const useTuneValidation = () => {
  const { authenticated, isPending } = useAuth()
  const { checkTunesStatus } = useTunesInCart()
  const { setModal } = useModalNotifications()

  return useCallback(
    async (product: NormalizedProduct | undefined) => {
      if (!authenticated && !isPending) return true
      if (!product) return false

      const tuneStatus = (await checkTunesStatus([product]))?.getTuneStatus(
        product
      )

      if (tuneStatus === 'invalid') {
        setModal(
          <TunesValidationModal
            product={product}
            onClose={() => {
              setModal(undefined)
            }}
          />
        )
      }

      return tuneStatus ? tuneStatus === 'compatible' : true
    },
    [authenticated, isPending, checkTunesStatus, setModal]
  )
}
