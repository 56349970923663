import React, { ComponentProps, forwardRef, ReactNode } from 'react'
import { Box, Spacing } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { ArrowDown } from './components/ArrowDown'
import { ArrowUp } from './components/ArrowUp'
import { Container } from './components/Container'
import { Header } from './components/Header'
import { HeadlineWrapper } from './components/HeadlineWrapper'
import { StateSlot } from './components/StateSlot'
import { StyledButton } from './components/StyledButton'
import { StyledHeadline } from './components/StyledHeadline'
import { StyledInner } from './components/StyledInner'
import { ToggleWrapper } from './components/ToggleWrapper'
import { RightSlot } from './components/RightSlot'
import { ContentWrapper } from './components/ContentWrapper'
import { useTheme } from 'styled-components'

export type ExpandableProps = {
  /** gets rendered as leftmost content in the header area */
  headline: ReactNode

  /** `true` enforces an alternative headline style */
  isDisabled?: boolean

  /** defines if the component is expanded */
  isExpanded: boolean

  /** if present, displays a toggle arrow and calls this handler function on click */
  onToggle?: () => void

  /** gets rendered next to the headline (e.g. Price in Order Review) */
  stateSlot?: ReactNode

  /** gets rendered rightmost in the header area (e.g. edit Button in readOnly state) */
  rightSlot?: ReactNode

  /** rendered as content of the expandable (only visible if expanded) */
  children: ReactNode

  /** will be attached to the rendered DOM node */
  'data-testid'?: string

  /** will be attached to the rendered DOM node */
  'data-trackingid'?: string

  /** set to `true` to trigger a CSS animation */
  isFocused?: boolean

  /** if true the expandable will have a CardShadow */
  hasShadow?: boolean
} & Pick<ComponentProps<typeof Box>, 'borderColor'>

export const Expandable = forwardRef<HTMLSpanElement, ExpandableProps>(
  (
    {
      headline,
      isDisabled = false,
      isExpanded,
      stateSlot,
      rightSlot,
      children,
      'data-testid': dataTestId = 'expandable',
      'data-trackingid': dataTrackingId,
      onToggle,
      isFocused = false,
      hasShadow = true,
      borderColor,
    }: ExpandableProps,
    ref
  ) => {
    const {
      colors: { lightergrey, white },
    } = useTheme()

    return (
      <span ref={ref} data-testid={dataTestId} data-trackingid={dataTrackingId}>
        <Box
          backgroundColor={white}
          borderColor={borderColor ?? hasShadow ? lightergrey : undefined}
          borderRadius={['s']}
        >
          <Container isFocused={isFocused}>
            <Header backgroundColor={lightergrey}>
              <StyledButton
                as={onToggle ? 'button' : 'div'}
                onClick={onToggle}
                style={{
                  cursor: onToggle ? 'pointer' : 'default',
                }}
                data-testid={`${dataTestId}-header-button`}
              >
                <Spacing m="spacing-s">
                  <StyledInner>
                    <HeadlineWrapper>
                      <StyledHeadline
                        color={isDisabled ? 'normalgrey' : 'darkergrey'}
                        asHTMLTag="h2"
                        data-testid={`${dataTestId}-headline`}
                        weight={isExpanded ? 700 : 500}
                      >
                        {headline}
                      </StyledHeadline>
                      {stateSlot && (
                        <StateSlot data-testid={`${dataTestId}-state-slot`}>
                          {stateSlot}
                        </StateSlot>
                      )}
                      {onToggle && (
                        <ToggleWrapper data-testid={`${dataTestId}-arrow`}>
                          <ArrowUp type={icons.arrowUp} $isOpen={isExpanded} />
                          <ArrowDown
                            type={icons.arrowDown}
                            $isOpen={isExpanded}
                          />
                        </ToggleWrapper>
                      )}
                    </HeadlineWrapper>
                    {rightSlot && (
                      <RightSlot data-testid={`${dataTestId}-right-slot`}>
                        {rightSlot}
                      </RightSlot>
                    )}
                  </StyledInner>
                </Spacing>
              </StyledButton>
            </Header>
            {isExpanded && (
              <ContentWrapper data-testid={`${dataTestId}-content`}>
                {children}
              </ContentWrapper>
            )}
          </Container>
        </Box>
      </span>
    )
  }
)

Expandable.displayName = 'Expandable'
