import { Box } from '@boxine/tonies-ui'
import styled from 'styled-components'

export const Content = styled(Box)`
  display: grid;
  position: relative;
  transition: border-color 0.25s ease;
  width: 100%;
  z-index: 2;
`
