import * as v from '@badrap/valita'
import { Button } from '@boxine/tonies-ui'
import { Document as RichTextDocument } from '@contentful/rich-text-types'
import { isSocialMediaIconType } from '../../../utils/socialMediaIcons'
import { isDeliveryInfoIconType } from '../../../components/atoms/DeliveryInfoIcon/types'
import { ComponentProps } from 'react'
import { MediaCopySection } from '@/tonies-ui/organisms/MediaCopySection'
import { BrazeForm } from '../../../components/organisms/BrazeForm'
import { isSupportedFilterKey } from '../../../hooks/useProductFilter'
import { isSupportedSortingId } from '../../../lib/opensearch/requests/products/types'
import * as icons from '@boxine/tonies-ui/icons'

const parseNumber = (n: unknown) => {
  if (typeof n === 'number') {
    return n
  } else if (typeof n === 'string') {
    const asNumber = Number(n)
    if (Number.isNaN(asNumber)) {
      return undefined
    } else {
      return asNumber
    }
  } else {
    return undefined
  }
}

const headlineAsHTMLTags = ['h1', 'h2', 'h3', 'h4', 'h5'] as const
type HeadlineAsHTMLTag = (typeof headlineAsHTMLTags)[number]
const isHeadlineAsHTMLTag = (s: string): s is HeadlineAsHTMLTag =>
  headlineAsHTMLTags.includes(s as HeadlineAsHTMLTag)
const headlineAsHTMLTagSchema = v.string().assert(isHeadlineAsHTMLTag)

export const topOrBottom = ['top', 'bottom'] as const
export type TopOrBottom = (typeof topOrBottom)[number]
export const isTopOrBottom = (s: unknown): s is TopOrBottom =>
  topOrBottom.includes(s as TopOrBottom)
export const topOrBottomSchema = v.string().assert(isTopOrBottom)

export const imageSchema = v.object({
  description: v.string().optional(),
  title: v.string(),
  file: v.object({
    url: v.string(),
    details: v.object({
      size: v.number(),
      image: v.object({
        width: v.number(),
        height: v.number(),
      }),
    }),
    fileName: v.string(),
    contentType: v.string(),
  }),
})
export type ContentfulImage = v.Infer<typeof imageSchema>

export const cloudinaryVideoSchema = v.object({
  // url: v.string(),
  // tags: v.array(v.string()),
  // type: v.string(),
  // bytes: v.number(),
  // width: v.number(),
  // format: v.string(),
  // height: v.number(),
  // version: v.number(),
  // duration: v.union(v.number(), v.null()),
  // metadata: v.array(v.string()),
  public_id: v.string(),
  // created_at: v.string(),
  // secure_url: v.string(),
  resource_type: v.string().assert<'video'>(s => s === 'video'),
  // original_url: v.string().optional(),
  // raw_transformation: v.string().optional(),
  // original_secure_url: v.string().optional(),
})
export type CloudinaryVideoSchema = v.Infer<typeof cloudinaryVideoSchema>

const fileSchema = v.object({
  title: v.string(),
  file: v.object({
    url: v.string(),
    fileName: v.string(),
    contentType: v.string(),
  }),
})
export type ContentfulFile = v.Infer<typeof fileSchema>

export const iconSchema = v
  .string()
  .assert<NonNullable<keyof typeof icons>>(s => Object.keys(icons).includes(s))

export const pageMetaDataSchema = v.object({
  slug: v.string().optional(), // FIXME: make required
  pageTitle: v.string(),
  metaTitle: v.string().optional(),
  metaDescription: v.string().optional(),
  openGraphImage: imageSchema.optional(),
  withZendesk: v.boolean().optional(),
  includeInSitemapXml: v.boolean().optional(), // TODO: rename and migrate to isCrawelable
  isCacheable: v.boolean().optional(),
  canonicalLink: v.string().optional(),
})
export type PageMetaData = v.Infer<typeof pageMetaDataSchema>

export const videoSchema = v.object({
  title: v.string(),
  file: v.object({
    url: v.string(),
    details: v.object({
      size: v.number(),
    }),
    fileName: v.string(),
    contentType: v.string(),
  }),
})
export type ContentfulVideo = v.Infer<typeof videoSchema>

export const contentfulColor = v.object({
  contentTypeId: v.string().assert<'toniesColors'>(s => s === 'toniesColors'),
  colorName: v.string(),
})
export type ContentfulColor = v.Infer<typeof contentfulColor>

export const skuEntrySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'commercetoolsSkuEntry'>(s => s === 'commercetoolsSkuEntry'),
  sku: v.string(),
})
export type SkuEntry = v.Infer<typeof skuEntrySchema>

const mediaCopyPrimaryActions = ['link', 'add-2-cart', 'download'] as const
type MediaCopyPrimaryActionType = (typeof mediaCopyPrimaryActions)[number]
const isMediaCopyPrimaryAction = (s: string): s is MediaCopyPrimaryActionType =>
  mediaCopyPrimaryActions.includes(s as MediaCopyPrimaryActionType)

export const mediaCopySchema = v.object({
  contentTypeId: v.string().assert<'mediaCopy'>(s => s === 'mediaCopy'),
  referenceTitle: v.string(),
  media: v.union(imageSchema, videoSchema),
  mediaLink: v.string().optional(),
  headline: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string().optional(),
  primaryCtaLabel: v.string().optional(),
  primaryCtaAction: v.string().assert(isMediaCopyPrimaryAction).optional(),
  primaryCtaUrl: v.string().optional(),
  primaryCtaProduct: skuEntrySchema.optional(),
  primaryCtaDownload: fileSchema.optional(),
  secondaryCtaLabel: v.string().optional(),
  secondaryCtaUrl: v.string().optional(),
})
export type MediaCopySchema = v.Infer<typeof mediaCopySchema>

export const textHeroSchema = v.object({
  contentTypeId: v.string().assert<'textHero'>(s => s === 'textHero'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string().optional(),
  backgroundColor: contentfulColor,
  primaryCtaText: v.string().optional(),
  primaryCtaUrl: v.string().optional(),
  secondaryCtaText: v.string().optional(),
  secondaryCtaUrl: v.string().optional(),
  hasCurve: v.boolean().optional(),
  id: v.string().optional(),
})
export type TextHeroSchema = v.Infer<typeof textHeroSchema>

export const isRichTextDocument = (s: unknown): s is RichTextDocument =>
  typeof s === 'object' &&
  s !== null &&
  (s as RichTextDocument).nodeType === 'document' &&
  Array.isArray((s as RichTextDocument).content)
export const richTextDocumentSchema = v.unknown().assert(isRichTextDocument)
export type RichTextDocumentSchema = v.Infer<typeof richTextDocumentSchema>

export const richTextBlockSchema = v.object({
  richText: richTextDocumentSchema,
})
export type RichTextBlockSchema = v.Infer<typeof richTextBlockSchema>

export const isRichTextBlockSchema = (u: unknown): u is RichTextBlockSchema =>
  typeof u === 'object' &&
  u !== null &&
  typeof (u as RichTextBlockSchema).richText === 'object' &&
  (u as RichTextBlockSchema).richText !== null

const blackOrWhite = ['black', 'white'] as const
type BlackOrWhite = (typeof blackOrWhite)[number]
export const isBlackOrWhite = (s: unknown): s is BlackOrWhite =>
  blackOrWhite.includes(s as BlackOrWhite)
export const blackOrWhiteSchema = v.string().assert(isBlackOrWhite)

export const primaryOrSecondary = ['primary', 'secondary'] as const
export type PrimaryOrSecondary = (typeof primaryOrSecondary)[number]
export const isPrimaryOrSecondary = (s: unknown): s is PrimaryOrSecondary =>
  primaryOrSecondary.includes(s as PrimaryOrSecondary)
export const primaryOrSecondarySchema = v.string().assert(isPrimaryOrSecondary)

export const brazeContentCardSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'brazeContentCard'>(s => s === 'brazeContentCard'),
  referenceTitle: v.string(),
  contentfulReference: v.string(),
  feedType: v.string().optional(),
  backgroundColor: contentfulColor.optional(),
})
export type BrazeContentCardSchema = v.Infer<typeof brazeContentCardSchema>

export const editorialTeaserEntrySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'editorialTeaserEntry'>(s => s === 'editorialTeaserEntry'),
  text: v.string().optional(),
  textStyling: blackOrWhiteSchema.optional(),
  ctaText: v.string(),
  ctaUrl: v.string(),
  ctaStyling: primaryOrSecondarySchema.optional(),
  backgroundImage: imageSchema,
  backgroundColor: contentfulColor.optional(),
})
export type EditorialTeaserEntrySchema = v.Infer<
  typeof editorialTeaserEntrySchema
>

export const editorialTeaserEntryListSchema = v.object({
  referenceTitle: v.string(),
  contentTypeId: v
    .string()
    .assert<'editorialTeaserEntryList'>(s => s === 'editorialTeaserEntryList'),
  entries: v.array(editorialTeaserEntrySchema),
})
export type EditorialTeaserEntryListSchema = v.Infer<
  typeof editorialTeaserEntryListSchema
>

export const variantsSchema = ['list', 'slider'] as const
export type VariantsSchema = (typeof variantsSchema)[number]
export const isVariantSchema = (s: unknown): s is VariantsSchema =>
  variantsSchema.includes(s as VariantsSchema)

export const editorialTeaserListSchema = v.object({
  referenceTitle: v.string(),
  contentTypeId: v
    .string()
    .assert<'editorialTeaserList'>(s => s === 'editorialTeaserList'),
  title: v.string().optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string().optional(),
  entries: v.array(v.union(brazeContentCardSchema, editorialTeaserEntrySchema)),
  primaryCtaText: v.string().optional(),
  primaryCtaUrl: v.string().optional(),
  secondaryCtaText: v.string().optional(),
  secondaryCtaUrl: v.string().optional(),
  variant: v.string().assert(isVariantSchema).optional(),
  columns: v.number().optional(),
  backgroundColor: contentfulColor.optional(),
  id: v.string().optional(),
})
export type EditorialTeaserListSchema = v.Infer<
  typeof editorialTeaserListSchema
>

export const leftOrRight = ['left', 'right'] as const
export type LeftOrRight = (typeof leftOrRight)[number]
export const isLeftOrRight = (s: unknown): s is LeftOrRight =>
  leftOrRight.includes(s as LeftOrRight)
export const leftOrRightSchema = v.string().assert(isLeftOrRight)

export const imageTextSchema = v.object({
  contentTypeId: v.string().assert<'imageText'>(s => s === 'imageText'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: richTextDocumentSchema,
  ctaUrl: v.string().optional(),
  ctaText: v.string().optional(),
  contentPositionMobile: topOrBottomSchema.optional(),
  contentPositionDesktop: leftOrRightSchema.optional(),
  imageMobile: imageSchema,
  imageDesktop: imageSchema,
  backgroundColor: contentfulColor,
  imageWidthMobile: v.string().optional(),
  imageWidthDesktop: v.string().optional(),
  stopper: imageSchema.optional(),
  id: v.string().optional(),
})
export type ImageTextSchema = v.Infer<typeof imageTextSchema>

export const imageTextCarouselSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'imageTextCarousel'>(s => s === 'imageTextCarousel'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  items: v.array(imageTextSchema),
  id: v.string().optional(),
})
export type ImageTextCarouselSchema = v.Infer<typeof imageTextCarouselSchema>

export const videoTextSchema = v.object({
  contentTypeId: v.string().assert<'videoText'>(s => s === 'videoText'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: richTextDocumentSchema,
  ctaUrl: v.string().optional(),
  ctaText: v.string().optional(),
  videoAlt: v.string(),
  videoMobile: videoSchema,
  videoDesktop: videoSchema,
  posterMobile: imageSchema.optional(),
  posterDesktop: imageSchema.optional(),
  id: v.string().optional(),
})
export type VideoTextSchema = v.Infer<typeof videoTextSchema>

export const videoTextCarouselSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'videoTextCarousel'>(s => s === 'videoTextCarousel'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  items: v.array(videoTextSchema),
  id: v.string().optional(),
})
export type VideoTextCarouselSchema = v.Infer<typeof videoTextCarouselSchema>

export const stageVideoSchema = v.object({
  contentTypeId: v.string().assert<'stageVideo'>(s => s === 'stageVideo'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: richTextDocumentSchema,
  ctaUrl: v.string().optional(),
  ctaText: v.string().optional(),
  videoMobile: videoSchema,
  videoDesktop: videoSchema,
  id: v.string().optional(),
})
export type StageVideoSchema = v.Infer<typeof stageVideoSchema>

export const seoTextSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'seoTextComponent'>(s => s === 'seoTextComponent'),
  referenceTitle: v.string(),
  content: richTextDocumentSchema,
})

const infoTeaserIcons = ['note', 'basket', 'info'] as const
type InfoTeaserIcon = (typeof infoTeaserIcons)[number]
const isInfoTeaserIcon = (s: unknown): s is InfoTeaserIcon =>
  infoTeaserIcons.includes(s as InfoTeaserIcon)
const infoTeaserIconSchema = v.string().assert(isInfoTeaserIcon)

// CHECK ME
// is this ever used?
export const infoTeaserEntrySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'infoTeaserEntry'>(s => s === 'infoTeaserEntry'),
  referenceTitle: v.string(),
  iconType: infoTeaserIconSchema,
  iconBackgroundColor: contentfulColor,
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string(),
  ctaUrl: v.string(),
  ctaText: v.string(),
})
// CHECK ME
// is this ever used?
export const infoTeaserCollectionSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'infoTeaserList'>(s => s === 'infoTeaserList'),
  referenceTitle: v.string(),
  entries: v.array(infoTeaserEntrySchema),
})

export const deliveryInfoIconTypeSchema = v
  .string()
  .assert(isDeliveryInfoIconType)

export const deliveryInfoTeaserSchema = v.object({
  referenceTitle: v.string(),
  iconType: deliveryInfoIconTypeSchema,
  title: v.string(),
  text: v.string().optional(),
})
export type DeliveryInfoTeaser = v.Infer<typeof deliveryInfoTeaserSchema>
// CHECK ME
// is this ever used?
export const deliveryInfoTeaserCollectionSchema = v.object({
  entries: v.array(deliveryInfoTeaserSchema),
})
export type DeliveryInfoTeaserCollection = v.Infer<
  typeof deliveryInfoTeaserCollectionSchema
>

export const productListSchema = v.object({
  contentTypeId: v.string().assert<'productList'>(s => s === 'productList'),
  referenceTitle: v.string(),
  categoryKey: v.string(),
  headline: v.string(),
})
export type ProductList = v.Infer<typeof productListSchema>

export const tooltipSchema = v.object({
  contentTypeId: v.string().assert<'tooltip'>(s => s === 'tooltip'),
  referenceTitle: v.string(),
  headline: v.string().optional(),
  text: v.string().optional(),
  image: imageSchema.optional(),
  ctaText: v.string().optional(),
  ctaUrl: v.string().optional(),
})
export type TooltipSchema = v.Infer<typeof tooltipSchema>

export const testimonialSchema = v.object({
  contentTypeId: v.string().assert<'testimonial'>(s => s === 'testimonial'),
  statement: v.string(),
  name: v.string(),
  occupation: v.string().optional(),
  backgroundColor: contentfulColor.optional(),
})
export type TestimonialSchema = v.Infer<typeof testimonialSchema>

export const testimonialListSchema = v.array(testimonialSchema)
export type TestimonialList = v.Infer<typeof testimonialListSchema>

export const linkTargets = ['_parent', '_blank'] as const
export type LinkTarget = (typeof linkTargets)[number]
export const isLinkTarget = (s: unknown): s is LinkTarget =>
  linkTargets.includes(s as LinkTarget)
export const linkTargetSchema = v.string().assert(isLinkTarget)

export const imageWithOptionalLinkSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'imageWithOptionalLink'>(s => s === 'imageWithOptionalLink'),
  image: imageSchema,
  link: v
    .object({
      href: v.string(),
      target: linkTargetSchema.default('_parent'),
    })
    .optional(),
  caption: v.string().optional(),
})
export type ImageWithOptionalLink = v.Infer<typeof imageWithOptionalLinkSchema>

export const imageWithOptionalLinkEntryListSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'imageWithOptionalLinkEntryList'>(
      s => s === 'imageWithOptionalLinkEntryList'
    ),
  entries: v.array(imageWithOptionalLinkSchema),
})
export type ImageWithOptionalLinkEntryListSchema = v.Infer<
  typeof imageWithOptionalLinkEntryListSchema
>

export const gallerySchema = v.object({
  contentTypeId: v.string().assert<'gallery'>(s => s === 'gallery'),
  headline: v.string().optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  entries: v.array(imageWithOptionalLinkSchema),
  labels: v.array(v.string()).optional(),
  id: v.string().optional(),
})
export type GallerySchema = v.Infer<typeof gallerySchema>

export const communitySchema = v.object({
  contentTypeId: v.string().assert<'community'>(s => s === 'community'),
  title: v.string().optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string().optional(),
  hasSocialLinks: v.boolean().default(true).optional(),
  entries: v.array(imageWithOptionalLinkSchema),
  backgroundColor: contentfulColor.optional(),
  variant: v.string().assert(isVariantSchema).optional(),
  columnsDesktop: v.number().optional(),
  id: v.string().optional(),
})
export type CommunitySchema = v.Infer<typeof communitySchema>

export const productSelectorCategoryKeys = [
  'car-organizer',
  'carrier',
  'deco-foils',
  'headphones',
  'tonieboxes',
  'tonieboxes-refurbished',
] as const
export type ProductSelectorCategoryKey =
  (typeof productSelectorCategoryKeys)[number]
export const isProductSelectorCategoryKey = (
  s: unknown
): s is ProductSelectorCategoryKey =>
  productSelectorCategoryKeys.includes(s as ProductSelectorCategoryKey)
export const productSelectorCategoryKeySchema = v
  .string()
  .assert(isProductSelectorCategoryKey)

export const tabNavigationEntrySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'tabNavigationEntry'>(s => s === 'tabNavigationEntry'),
  referenceTitle: v.string(),
  name: v.string(),
  url: v.string(),
  backgroundImageMobile: imageSchema.optional(),
  backgroundImageDesktop: imageSchema.optional(),
})
export type TabNavigationEntry = v.Infer<typeof tabNavigationEntrySchema>

export const tabNavigationSchema = v.object({
  contentTypeId: v.string().assert<'tabNavigation'>(s => s === 'tabNavigation'),
  referenceTitle: v.string(),
  entries: v.array(tabNavigationEntrySchema),
})
export type TabNavigation = v.Infer<typeof tabNavigationSchema>

export const faqQuestionAnswerSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'faqQuestionAnswer'>(s => s === 'faqQuestionAnswer'),
  question: v.string(),
  answer: richTextDocumentSchema,
})
export type FaqQuestionAnswerSchema = v.Infer<typeof faqQuestionAnswerSchema>

export const accordionSchema = v.object({
  contentTypeId: v.string().assert<'accordion'>(s => s === 'accordion'),
  referenceTitle: v.string(),
  entries: v.array(faqQuestionAnswerSchema),
  backgroundColor: contentfulColor.optional(),
})
export type AccordionSchema = v.Infer<typeof accordionSchema>

export const frequentlyAskedQuestionsSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'frequentlyAskedQuestions'>(s => s === 'frequentlyAskedQuestions'),
  headline: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  subheadline: v.string(),
  backgroundColor: contentfulColor,
  backgroundImage: imageSchema,
  entries: v.array(faqQuestionAnswerSchema),
  id: v.string().optional(),
})
export type frequentlyAskedQuestions = v.Infer<
  typeof frequentlyAskedQuestionsSchema
>

export const marketLaunchSelectorSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'marketEntrySelector'>(s => s === 'marketEntrySelector'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  subTitle: v.string(),
  text: richTextDocumentSchema,
  id: v.string().optional(),
})
export type marketLaunchSelector = v.Infer<typeof marketLaunchSelectorSchema>

export const skuListSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'commerceToolsSkuList'>(s => s === 'commerceToolsSkuList'),
  referenceTitle: v.string(),
  skuEntries: v.array(v.string()),
  headline: v.string().optional(),
  // TODO: make category key optional in Product Overview Layout
  categoryKey: v.string().optional(),
})
export type SkuList = v.Infer<typeof skuListSchema>

export const tonieboxBundlesPageEntrySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'tonieboxBundlesList'>(s => s === 'tonieboxBundlesList'),
  referenceTitle: v.string(),
  headline: v.string().optional(),
  bundleContent: v.array(v.string()).optional(),
  ctaText: v.string().optional(),
  attributeValue: v.string(),
})
export type TonieboxBundlesPageEntry = v.Infer<
  typeof tonieboxBundlesPageEntrySchema
>

export const productOverviewPromotionItemSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'productOverviewPromotionItem'>(
      s => s === 'productOverviewPromotionItem'
    ),
  referenceTitle: v.string(),
  ctaText: v.string(),
  ctaUrl: v.string(),
  promotionImage: imageSchema,
  position: v
    .unknown()
    .map(parseNumber) // This field was created as "shortText" in Contentful but should be processed as "number"
    .assert<number>((n: number | undefined) => n !== undefined),
  showCTAButton: v.boolean(),
})

export type ProductOverviewPromotionItemSchema = v.Infer<
  typeof productOverviewPromotionItemSchema
>

export const productItemTeaserCarouselSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'productItemTeaserCarousel'>(
      s => s === 'productItemTeaserCarousel'
    ),
  referenceTitle: v.string().optional(),
  headline: v.string().optional(),
  discoverAllUrl: v.string().optional(),
  skuList: skuListSchema,
  useRecommendationsAPI: v.boolean().default(true).optional(),
  promotions: v.array(productOverviewPromotionItemSchema).optional(),
  tooltip: tooltipSchema.optional(),

  // TODO: delete
  title: v.string().optional(),
  // TODO: delete
  primaryCtaUrl: v.string().optional(),
  // TODO: delete
  primaryCtaText: v.string().optional(),
  // TODO: delete
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  // TODO: delete
  text: v.string().optional(),
  backgroundColor: contentfulColor.optional(),
  id: v.string().optional(),
})
export type ProductItemTeaserCarouselSchema = v.Infer<
  typeof productItemTeaserCarouselSchema
>

export const productItemTeaserListSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'productItemTeaserList'>(s => s === 'productItemTeaserList'),
  title: v.string().optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string().optional(),
  skuList: skuListSchema,
  primaryCtaText: v.string().optional(),
  primaryCtaUrl: v.string().optional(),
  secondaryCtaText: v.string().optional(),
  secondaryCtaUrl: v.string().optional(),
  id: v.string().optional(),
})
export type ProductItemTeaserListSchema = v.Infer<
  typeof productItemTeaserListSchema
>

export const downloadTeaserEntryListSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'downloadTeaserEntryList'>(s => s === 'downloadTeaserEntryList'),
  entries: v.array(mediaCopySchema),
})
export type DownloadTeaserEntryListSchema = v.Infer<
  typeof downloadTeaserEntryListSchema
>

export const audioPlayerItemSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'audioPlayerItem'>(s => s === 'audioPlayerItem'),
  audioFile: fileSchema,
  image: imageSchema,
  title: v.string(),
})
export type AudioPlayerItem = v.Infer<typeof audioPlayerItemSchema>

export const audioPlayerSchema = v.object({
  contentTypeId: v.string().assert<'audioPlayer'>(s => s === 'audioPlayer'),
  backgroundColor: contentfulColor.optional(),
  entries: v.array(audioPlayerItemSchema).optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  skuList: skuListSchema.optional(),
  text: richTextDocumentSchema,
  title: v.string(),
  id: v.string().optional(),
})
export type audioPlayer = v.Infer<typeof audioPlayerSchema>

export const socialMediaIconTypeSchema = v
  .string()
  .assert(isSocialMediaIconType)

export const linkWithOptionalIconSchema = v.object({
  icon: socialMediaIconTypeSchema.optional(),
  link: v.string(),
  text: v.string(),
})
export type LinkWithOptionalIcon = v.Infer<typeof linkWithOptionalIconSchema>

export const footerSchema = v.object({
  contentTypeId: v.string().assert<'footer'>(s => s === 'footer'),
  referenceTitle: v.string(),
  linksList1Title: v.string().optional(),
  linksList1Entries: v.array(linkWithOptionalIconSchema).optional(),
  linksList2Title: v.string().optional(),
  linksList2Entries: v.array(linkWithOptionalIconSchema).optional(),
  legalLinksAdditionalEntries: v.array(linkWithOptionalIconSchema),
  addressTitle: v.string().optional(),
  addressColumn1: richTextDocumentSchema.optional(),
  addressColumn2: richTextDocumentSchema.optional(),
  socialMediaLinksEntries: v.array(linkWithOptionalIconSchema).optional(),
  hasNewsletterSignup: v.boolean().default(true).optional(),
  closingText: richTextDocumentSchema.optional(),
})
export type Footer = v.Infer<typeof footerSchema>

export const stageContentCtaTextSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'stageContentCtaText'>(s => s === 'stageContentCtaText'),
  ctaLabel: v.string().optional(),
  ctaLink: v.string().optional(),
  headline: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: richTextDocumentSchema,
})
export type StageContentCtaTextSchema = v.Infer<
  typeof stageContentCtaTextSchema
>

export const orientationSchema = ['horizontal', 'vertical'] as const
export type OrientationSchema = (typeof orientationSchema)[number]
export const isOrientationSchema = (s: unknown): s is OrientationSchema =>
  orientationSchema.includes(s as OrientationSchema)

export const mediaCopyCollectionSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'mediaCopyCollection'>(s => s === 'mediaCopyCollection'),
  referenceTitle: v.string(),
  headline: v.string().optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string().optional(),
  entries: v.array(mediaCopySchema),
  variant: v.string().assert(isVariantSchema).optional(),
  orientation: v.string().assert(isOrientationSchema).optional(),
  columnsMobile: v.number().optional(),
  columnsDesktop: v.number().optional(),
  backgroundColor: contentfulColor.optional(),
  id: v.string().optional(),
})
export type MediaCopyCollectionSchema = v.Infer<
  typeof mediaCopyCollectionSchema
>

export const sectionProductCopySchema = v.object({
  referenceTitle: v.string(),
  contentTypeId: v
    .string()
    .assert<'sectionProductCopy'>(s => s === 'sectionProductCopy'),
  badgeDesktop: imageSchema.optional(),
  badgeMobile: imageSchema.optional(),
  text: richTextDocumentSchema.optional(),
  licenseLogo: imageSchema.optional(),
  title: v.string().optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  backgroundColor: contentfulColor.optional(),
  contentPositionDesktop: leftOrRightSchema.default('left').optional(),
  sku: v.string(),
  id: v.string().optional(),
})
export type SectionProductCopySchema = v.Infer<typeof sectionProductCopySchema>

export const promotionToniesLayoutSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'promotionToniesLayout'>(s => s === 'promotionToniesLayout'),
  title: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  text: v.string().optional(),
  entries: v.array(sectionProductCopySchema),
})
export type PromotionToniesLayoutSchema = v.Infer<
  typeof promotionToniesLayoutSchema
>

const curveLayouts = [
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
] as const
type CurveLayout = (typeof curveLayouts)[number]
const isCurveLayout = (s: unknown): s is CurveLayout =>
  curveLayouts.includes(s as CurveLayout)
const curveLayoutSchema = v.string().assert(isCurveLayout)

/**
 * TODO
 * Migrate
 * contentTypeId -> 'fullWidthMedia'
 * imageMobile -> mediaMobile
 * imageDesktop -> mediaDesktop
 */
export const fullWidthMediaSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'fullWidthImage'>(s => s === 'fullWidthImage'),
  imageMobile: v.union(imageSchema, videoSchema),
  imageDesktop: v.union(imageSchema, videoSchema),
  hasCurve: v.boolean().optional(),
  curveLayout: curveLayoutSchema.optional(),
  curveColor: contentfulColor.optional(),
  id: v.string().optional(),
})
export type FullWidthMediaSchema = v.Infer<typeof fullWidthMediaSchema>

export const videoPlayerSchema = v.object({
  contentTypeId: v.string().assert<'videoPlayer'>(s => s === 'videoPlayer'),
  referenceTitle: v.string(),
  desktopVideo: videoSchema,
  desktopPosterImage: imageSchema,
  mobileVideo: videoSchema,
  mobilePosterImage: imageSchema,
  altText: v.string(),
})
export type VideoPlayerSchema = v.Infer<typeof videoPlayerSchema>

export const videoPlayerCloudinarySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'videoPlayerCloudinary'>(s => s === 'videoPlayerCloudinary'),
  referenceTitle: v.string(),
  cloudinaryVideo: v
    .array(cloudinaryVideoSchema)
    .assert(a => a.length === 1, 'Must have exactly 1 element'),
  videoAlt: v.string(),
})
export type VideoPlayerCloudinarySchema = v.Infer<
  typeof videoPlayerCloudinarySchema
>

export const podcastEntrySchema = imageTextSchema.extend({
  contentTypeId: v.string().assert<'podcastEntry'>(s => s === 'podcastEntry'),
  episodeUrl: v.string(),
  toniesInEpisode: productItemTeaserCarouselSchema,
})
export type PodcastEntrySchema = v.Infer<typeof podcastEntrySchema>

export const referralSectionSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'referralSection'>(s => s === 'referralSection'),
  contentHeadline: v.string(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  copy: richTextDocumentSchema,
  referralBoxHeadlineLoggedIn: v.string(),
  referralBoxHeadlineNotLoggedIn: v.string(),
  referralBoxSubHeadline: v.string(),
})
export type ReferralSectionSchema = v.Infer<typeof referralSectionSchema>

export const lottieSchema = v.object({
  title: v.string().optional(),
  description: v.string().optional(),
  file: v.object({
    contentType: v.string(),
    url: v.string(),
  }),
})

export type LottieSchema = v.Infer<typeof lottieSchema>

export const mediaGroupSchema = v.object({
  contentTypeId: v.string().assert<'mediaGroup'>(s => s === 'mediaGroup'),
  referenceTitle: v.string(),
  desktop: v.union(imageSchema, videoSchema, lottieSchema),
  mobile: v.union(imageSchema, videoSchema, lottieSchema),
  alt: v.string(),
  positionX: v
    .string()
    .assert<
      NonNullable<NonNullable<MediaCopyComponentProps['media']>['positionX']>
    >(s => ['left', 'center', 'right'].includes(s))
    .optional(),
  positionY: v
    .string()
    .assert<
      NonNullable<NonNullable<MediaCopyComponentProps['media']>['positionY']>
    >(s => ['start', 'center', 'end'].includes(s))
    .optional(),
})
export type MediaGroupSchema = v.Infer<typeof mediaGroupSchema>

type BrazeFormProps = ComponentProps<typeof BrazeForm>
export const brazeFormSchema = v.object({
  contentTypeId: v.string().assert<'brazeForm'>(s => s === 'brazeForm'),
  referenceTitle: v.string(),
  variant: v
    .string()
    .assert<NonNullable<BrazeFormProps['variant']>>(s =>
      [
        'AI Story Requests',
        'Kids Birthdays',
        'Newsletter',
        'Sweepstake',
      ].includes(s)
    ),
  backgroundColor: contentfulColor.optional(),
  ctaText: v.string().optional(),
  customUserAttributes: v
    .array(
      v.object({
        key: v.string(),
        value: v.union(v.boolean(), v.number(), v.string()),
      })
    )
    .optional(),
  eventLabel: v.string().optional(),
  eventName: v.string().optional(),
  hasAgeSelect: v.boolean().optional(),
  hasCountrySelect: v.boolean(),
  hasFirstNameInput: v.boolean(),
  hasLastNameInput: v.boolean(),
  hasNewsletterSignupCheckbox: v.boolean().optional(),
  hasOptionalTonies: v.boolean().optional(),
  hasPhoneInput: v.boolean(),
  headline: v.string().optional(),
  headlineAsHtmlTag: headlineAsHTMLTagSchema.optional(),
  optinEventName: v.string().optional(),
  ppPageUrl: v.string().optional(),
  richtext: richTextDocumentSchema.optional(),
  successHeadline: v.string().optional(),
  successRichtext: richTextDocumentSchema,
  tcPageUrl: v.string().optional(),
  id: v.string().optional(),
  consentAppendedText: v.string().optional(),
})
export type BrazeFormSchema = v.Infer<typeof brazeFormSchema>

export const giftfinderSchema = v.object({
  contentTypeId: v.string().assert<'giftfinder'>(s => s === 'giftfinder'),
  referenceTitle: v.string(),
  headline: v.string(),
  text: v.string(),
  backgroundColor: contentfulColor.optional(),
  id: v.string().optional(),
})
export type GiftfinderSchema = v.Infer<typeof giftfinderSchema>

type MediaCopyComponentProps = ComponentProps<typeof MediaCopySection>

export const mediaCopySectionSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'mediaCopySection'>(s => s === 'mediaCopySection'),
  referenceTitle: v.string(),
  icon: iconSchema.optional(),
  headline: v.string(),
  headlineAsHtmlTag: headlineAsHTMLTagSchema.optional(),
  text: richTextDocumentSchema.optional(),
  primaryCtaText: v.string().optional(),
  primaryCtaUrl: v.string().optional(),
  secondaryCtaText: v.string().optional(),
  secondaryCtaUrl: v.string().optional(),
  backgroundColor: contentfulColor.optional(),
  layout: v
    .string()
    .assert<NonNullable<MediaCopyComponentProps['layout']>>(s =>
      ['half-grid', 'half-screen'].includes(s)
    ),
  direction: v
    .string()
    .assert<NonNullable<MediaCopyComponentProps['direction']>>(s =>
      ['ltr', 'rtl'].includes(s)
    ),
  content: v.array(v.union(accordionSchema, brazeFormSchema)).optional(),
  media: v.union(
    mediaGroupSchema,
    videoPlayerSchema,
    videoPlayerCloudinarySchema
  ),
  videoInModal: v.array(cloudinaryVideoSchema).optional(),
  mediaPositionMobile: topOrBottomSchema.optional(),
  hasCurve: v.boolean().optional(),
  id: v.string().optional(),
})
export type MediaCopySectionSchema = v.Infer<typeof mediaCopySectionSchema>

export const deliveryInfoTeaserListSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'deliveryInfoTeaserList'>(s => s === 'deliveryInfoTeaserList'),
  referenceTitle: v.string(),
})
export type DeliveryInfoTeaserListSchema = v.Infer<
  typeof deliveryInfoTeaserListSchema
>

export const carouselSchema = v.object({
  contentTypeId: v.string().assert<'carousel'>(s => s === 'carousel'),
  referenceTitle: v.string(),
  items: v.array(
    v.union(
      mediaCopySectionSchema,
      testimonialSchema,
      imageWithOptionalLinkSchema,
      sectionProductCopySchema
    )
  ),
  hasSidePadding: v.boolean(),
})
export type CarouselSchema = v.Infer<typeof carouselSchema>

export const primaryNavigationEntrySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'primaryNavigationEntry'>(s => s === 'primaryNavigationEntry'),
  referenceTitle: v.string(),
  title: v.string(),
  pageMetaData: pageMetaDataSchema,
  pageMetaDataAliases: v.array(pageMetaDataSchema).optional(),
  tagText: v.string().optional(),
})
export type PrimaryNavigationEntrySchema = v.Infer<
  typeof primaryNavigationEntrySchema
>

export const authorSchema = v.object({
  contentTypeId: v.string().assert<'authors'>(s => s === 'authors'),
  referenceTitle: v.string(),
  description: v.string(),
  image: imageSchema,
  name: v.string(),
})
export type AuthorSchema = v.Infer<typeof authorSchema>

export const blogCategorySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'blogCategories'>(s => s === 'blogCategories'),
  label: v.string(),
})
export type BlogCategorySchema = v.Infer<typeof blogCategorySchema>

export const blogTagSchema = v.object({
  contentTypeId: v.string().assert<'blogTags'>(s => s === 'blogTags'),
  label: v.string(),
})
export type BlogTagSchema = v.Infer<typeof blogTagSchema>

export const audioSampleEntry = v.object({
  contentTypeId: v
    .string()
    .assert<'audioSampleEntry'>(s => s === 'audioSampleEntry'),
  label: v.string().optional(),
  sku: v.string().optional(),
  audioFile: fileSchema.optional(),
})
export type AudioSampleEntry = v.Infer<typeof audioSampleEntry>

type ButtonProps = ComponentProps<typeof Button>
export const buttonSchema = v.object({
  contentTypeId: v.string().assert<'button'>(s => s === 'button'),
  href: v.string(),
  label: v.string(),
  variant: v
    .string()
    .assert<NonNullable<ButtonProps['variant']>>(s =>
      ['primary', 'secondary'].includes(s)
    ),
})
export type ButtonSchema = v.Infer<typeof buttonSchema>

export const productSelectorSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'productSelector'>(s => s === 'productSelector'),
  referenceTitle: v.string(),
  headline: v.string(),
  headlineAsHtmlTag: headlineAsHTMLTagSchema.optional(),
  categoryKey: productSelectorCategoryKeySchema,
  hasUrlReferralCodeSection: v.boolean().optional(),
  id: v.string().optional(),
})
export type ProductSelectorSchema = v.Infer<typeof productSelectorSchema>

export const osCustomQuerySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'openSearchQuery'>(s => s === 'openSearchQuery'),
  referenceTitle: v.string(),
  // TODO TWAS-4810
  // Should we be stricter here?
  json: v.array(v.record()),
})
export type OsCustomQuerySchema = v.Infer<typeof osCustomQuerySchema>

export const osQuerySchema = v.object({
  contentTypeId: v
    .string()
    .assert<'openSearchQuery'>(s => s === 'openSearchQuery'),
  referenceTitle: v.string(),
  ageMin: v.array(v.string()).optional(),
  categoryKey: v.array(v.string()).optional(),
  categoryKeyAdditional: v.array(v.string()).optional(),
  categorySlug: v.array(v.string()).optional(),
  flag: v.array(v.string()).optional(),
  genre: v.array(v.string()).optional(),
  language: v.array(v.string()).optional(),
  theme: v.array(v.string()).optional(),
})
export type OsQuerySchema = v.Infer<typeof osQuerySchema>

export const osSkuListSchema = v.object({
  contentTypeId: v
    .string()
    .assert<'openSearchSkuList'>(s => s === 'openSearchSkuList'),
  referenceTitle: v.string(),
  sku: v.array(v.string()),
})
export type OsSkuListSchema = v.Infer<typeof osSkuListSchema>

const productsOutOfStockSorting = [
  'in-sorting-order',
  'sorting-default',
  'to-the-end',
] as const
type ProductsOutOfStockSorting = (typeof productsOutOfStockSorting)[number]
const isProductsOutOfStockSorting = (
  s: unknown
): s is ProductsOutOfStockSorting =>
  productsOutOfStockSorting.includes(s as ProductsOutOfStockSorting)

export const productsSchema = v.object({
  contentTypeId: v.string().assert<'products'>(s => s === 'products'),
  referenceTitle: v.string(),
  backgroundColor: contentfulColor.optional(),
  filters: v.array(v.string().assert(isSupportedFilterKey)).optional(),
  hasFilters: v.boolean(),
  hasSearch: v.boolean(),
  hasSorting: v.boolean(),
  headline: v.string().optional(),
  headlineAsHTMLTag: headlineAsHTMLTagSchema.optional(),
  hideOutOfStock: v.boolean(),
  id: v.string().optional(),
  itemsPerPage: v.number(),
  openSearch: v.union(osCustomQuerySchema, osQuerySchema, osSkuListSchema),
  outOfStockSorting: v.string().assert(isProductsOutOfStockSorting),
  priceGte: v.number().optional(),
  priceLte: v.number().optional(),
  promotionTeasers: v.array(productOverviewPromotionItemSchema).optional(),
  showDiscountedOnly: v.boolean(),
  showMoreLabel: v.string().optional(),
  showMoreUrl: v.string().optional(),
  sorting: v.string().assert(isSupportedSortingId).optional(),
  text: v.string().optional(),
  tooltip: tooltipSchema.optional(),
  // TODO TWAS-4810
  // make type?
  topFilters: v
    .array(
      v.object({
        categories: v.array(v.string()),
        filterKey: v.string(),
        value: v.string(),
      })
    )
    .optional(),
  variant: v.string().assert(isVariantSchema),
})
export type ProductsSchema = v.Infer<typeof productsSchema>

export const modularContentSchema = v.union(
  audioPlayerSchema,
  communitySchema,
  editorialTeaserListSchema,
  frequentlyAskedQuestionsSchema,
  fullWidthMediaSchema,
  gallerySchema,
  imageTextSchema,
  imageTextCarouselSchema,
  marketLaunchSelectorSchema,
  productItemTeaserCarouselSchema,
  richTextBlockSchema,
  sectionProductCopySchema,
  testimonialSchema,
  textHeroSchema,
  videoTextCarouselSchema,
  videoTextSchema,
  stageVideoSchema,
  mediaCopySectionSchema,
  productItemTeaserListSchema,
  deliveryInfoTeaserListSchema,
  carouselSchema,
  mediaCopyCollectionSchema,
  brazeFormSchema,
  giftfinderSchema,
  productSelectorSchema,
  productsSchema
)
export type ModularContent = v.Infer<typeof modularContentSchema>

export const isOfSchema = <T>(u: unknown, s: v.Type<T>): u is T => s.try(u).ok
