import React from 'react'
import styled, { css } from 'styled-components'
import FocusLock from 'react-focus-lock'
import { FocusStyles, Icon } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { ColorContextProvider } from '@/tonies-ui/index'

const StyledFocusLock = styled(FocusLock)`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: grid;
  padding-bottom: 1rem;
`

const LocaleSwitchWrapper = styled.div`
  align-items: center;
  justify-items: center;
  text-align: center;
`

const LocaleList = styled.ul`
  display: grid;
  grid-gap: 3rem;
  list-style: none;
  margin: 0;
  padding: 0;
`

const LocaleListItem = styled.li`
  font-size: 1.25rem;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`

const LocaleSwitchOptionButton = styled.button<{ isActive?: boolean }>`
  background: none;
  border: none;
  color: ${props => props.theme.colors.darkergrey};
  font-family: ${props => props.theme.fonts.serif};
  font-size: inherit;
  font-weight: 300;

  ${FocusStyles}

  &:focus,
  &:hover {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.primary};
      position: relative;
      pointer-events: none;

      &::after {
        background-color: ${props => props.theme.colors.primary};
        border-radius: 0.125rem;
        bottom: -0.375rem;
        content: '';
        display: block;
        height: 0.125rem;
        left: -1rem;
        position: absolute;
        right: -1rem;
      }
    `}
`

export interface LocaleOption {
  isActive: boolean
  lcCC: string
  text: string
}

export interface LocaleSwitchProps {
  /**
   * Array of the available locales which will be displayed in the viewport-wide overlay when it's opened.
   */
  availableLocales: LocaleOption[]

  /**
   * ID of the overlay to reference it via "aria-controls". This should always be provided but may be undefined at the first render (thus it's technically optional).
   */
  id?: string

  /**
   * Callback triggered when the user selects a language.
   */
  onSelectLocale: (lcCC: string) => void

  /**
   * When the language switch is opened via the keyboard, this should be true to
   * auto-focus the first element (expected behavior for keyboard users).
   */
  shouldFocusFirstElement: boolean
}

export const LocaleSwitch = ({
  availableLocales,
  id,
  onSelectLocale,
  shouldFocusFirstElement,
}: LocaleSwitchProps & Styleable) => {
  // useCloseOnEscape(onClose)

  return (
    <ColorContextProvider backgroundColor="lightblue-40">
      {/* eslint-disable jsx-a11y/no-autofocus */}
      <StyledFocusLock autoFocus={shouldFocusFirstElement} returnFocus={true}>
        <LocaleSwitchWrapper id={id}>
          <LocaleList data-testid="locale-switch-lccc-list">
            {availableLocales.map(locale => (
              <LocaleListItem key={locale.lcCC}>
                <LocaleSwitchOptionButton
                  data-testid="locale-switch-option-button"
                  isActive={locale.isActive}
                  onClick={() => onSelectLocale(locale.lcCC)}
                  disabled={locale.isActive}
                >
                  {locale.isActive ? (
                    <>
                      <Icon type={icons.place} height="1rem" width="1rem" />
                      {' ' + locale.text}
                    </>
                  ) : (
                    locale.text
                  )}
                </LocaleSwitchOptionButton>
              </LocaleListItem>
            ))}
          </LocaleList>
        </LocaleSwitchWrapper>
      </StyledFocusLock>
    </ColorContextProvider>
  )
}

LocaleSwitch.defaultProps = {
  id: undefined,
}
