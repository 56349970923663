import { media } from '@boxine/tonies-ui'
import React from 'react'
import styled from 'styled-components'
import { StyledHeartsReversed, StyledHeart } from './Doodles'
import { Image } from '@/tonies-ui/atoms/Image'

const Wrapper = styled.div`
  position: relative;
  margin-right: auto;
  height: 9.5rem;
  width: 9.5rem;
  left: 0;
  top: 1.5rem;

  ${media.mobileM`
    left: 1rem;
  `}

  ${media.tablet`
    top: unset;
    margin-right: unset;
    margin-left: auto;
    height: 13.75rem;
    width: 13.75rem;
  `}
`

const Badge = styled.div`
  position: absolute;
  left: 0;
  bottom: 1.5rem;
  height: 6rem;
  width: 6rem;

  ${media.tablet`
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 11.75rem;
    width: 11.75rem;
  `}
`

type Props = {
  src: string
  alt: string
}

export const BadgeWithHearts = ({ src, alt }: Props) => {
  return (
    <Wrapper>
      <StyledHeartsReversed />
      <Badge>
        <Image src={src} alt={alt} ratio={[1, 1]} responsive={[100, 200]} />
      </Badge>
      <StyledHeart />
    </Wrapper>
  )
}
