import { Paragraph, media } from '@boxine/tonies-ui'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { motion } from 'framer-motion'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { NumberColumn } from '@/tonies-ui/atoms/CountIndicator/components/NumberColumn'

export const DIAMETER_INDICATOR_DESKTOP = '1.25rem'
export const DIAMETER_INDICATOR_MOBILE = '1rem'

const MotionBubble = styled(motion.div)`
  background-color: ${props => props.theme.colors.primary};
  border-radius: ${DIAMETER_INDICATOR_MOBILE};
  line-height: ${DIAMETER_INDICATOR_MOBILE};
  padding: 0 0.25rem;
  overflow: hidden;
  min-width: ${DIAMETER_INDICATOR_MOBILE};
  min-height: ${DIAMETER_INDICATOR_MOBILE};
  user-select: none; /* prevent selection & text cursor on hover */
  font-size: 0.5rem;

  ${media.laptop`
    font-size: 0.8125rem;
    padding: 0 0.25rem;
    border-radius: ${DIAMETER_INDICATOR_DESKTOP};
    line-height: ${DIAMETER_INDICATOR_DESKTOP};
    min-width: ${DIAMETER_INDICATOR_DESKTOP};
    min-height: ${DIAMETER_INDICATOR_DESKTOP};
  `}
`

export interface CountIndicatorProps {
  count: number
  dataTestId?: string
  isAnimated?: boolean
  statusMessage: string
}

export const CountIndicator: FunctionComponent<
  CountIndicatorProps & Styleable
> = ({ className, count, dataTestId, isAnimated = false, statusMessage }) => {
  const [lastCount, setLastCount] = useState<number | undefined>()
  const [isZooming, setIsZooming] = useState(false)

  useEffect(() => {
    if (count !== lastCount) {
      setIsZooming(true)
      setLastCount(count)
    }
  }, [count, lastCount])

  const handleAnimationEnd = useCallback(() => {
    setIsZooming(false)
  }, [])

  return (
    <Paragraph asHTMLTag="span" size={3} weight={700} color="white">
      <MotionBubble
        aria-label={statusMessage}
        className={className + ' count-indicator-selector'}
        initial={{ opacity: count === 0 ? 0 : 1 }}
        animate={
          isAnimated && {
            scale: isZooming ? [1, 1.5, 1] : undefined,
            opacity: count === 0 ? 0 : 1,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
        exit={{ opacity: 0 }}
        onAnimationComplete={handleAnimationEnd}
        role="status"
      >
        <NumberColumn dataTestId={dataTestId} count={count} />
      </MotionBubble>
    </Paragraph>
  )
}
