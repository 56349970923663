import { useEcomLocale } from '../providers/locale'
import { useTranslation } from 'react-i18next'
import {
  bundleBase,
  isOrderableSupportedBundlerProduct,
} from '../config/bundles/getCustomBundlerConfig'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

export const useCustomBundler = ({
  template,
  products,
  useBestTemplate = false,
}: {
  template?: string | number
  products: NormalizedProduct[] | undefined
  useBestTemplate?: boolean
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const lcCC = useEcomLocale()

  const base = bundleBase[lcCC]

  const supportedProducts = useMemo(
    () => products?.filter(p => isOrderableSupportedBundlerProduct(p, lcCC)),
    [lcCC, products]
  )

  const unsupportedProducts = useMemo(
    () => products?.filter(p => !supportedProducts?.includes(p)),
    [supportedProducts, products]
  )

  const toniebox = useMemo(
    () => supportedProducts?.find(p => p.shopCategory === 'tonieboxes'),
    [supportedProducts]
  )

  const creativeTonie = useMemo(
    () => supportedProducts?.find(p => p.shopCategory === 'creative-tonies'),
    [supportedProducts]
  )

  const tonies = useMemo(
    () => supportedProducts?.filter(p => p.shopCategory === 'tonies') || [],
    [supportedProducts]
  )

  const bestFittingTemplateId = useMemo(() => {
    const templateBase = base.find(t => t.id === template)
    const reversedBase = [...base].reverse()

    // get best fitting template for count of tonies
    const bestFittingTemplate = reversedBase.reduce((prev, curr) => {
      return curr.tonies.quantity >= tonies.length ? curr : prev
    }, reversedBase[0])

    // get best fitting template for count of tonies
    if (
      // tonies are passed
      tonies.length &&
      // no template is passed and we want to use the best fitting template
      ((!templateBase && useBestTemplate) ||
        // template is passed but is to small for passed tonie count
        (templateBase && templateBase.tonies.quantity < tonies.length))
    ) {
      return bestFittingTemplate.id
    }

    return templateBase?.id
  }, [base, template, tonies.length, useBestTemplate])

  const link = useMemo(() => {
    const searchParams = new URLSearchParams()

    if (bestFittingTemplateId) {
      searchParams.append('cbTemplate', bestFittingTemplateId)
    }

    if (toniebox) {
      searchParams.append('cbTonieboxes', toniebox.sku)
    }

    if (creativeTonie) {
      searchParams.append('cbCreativeTonies', creativeTonie.sku)
    }

    if (tonies.length) {
      tonies.forEach(tonie => searchParams.append('cbTonies', tonie.sku))
    }

    return router.basePath + t('bundler:url') + '?' + searchParams.toString()
  }, [
    bestFittingTemplateId,
    toniebox,
    creativeTonie,
    tonies,
    router.basePath,
    t,
  ])

  return {
    isSupported: !!supportedProducts?.length,
    areAllProductsSupported: !!unsupportedProducts?.length,
    supportedProducts,
    unsupportedProducts,
    link: supportedProducts?.length ? link : undefined,
    baseConfig: base,
  }
}
