import React, { ComponentProps } from 'react'
import { Icon } from '@boxine/tonies-ui'
import { useTheme } from 'styled-components'

export const IconThemed = (
  props: Exclude<ComponentProps<typeof Icon>, 'fillAll' | 'fill'>
) => {
  const { colorContext, colors } = useTheme()

  return (
    <Icon
      {...props}
      fillAll={colors[colorContext === 'dark' ? 'white' : 'darkergrey']}
    />
  )
}
