import React from 'react'
import Head from 'next/head'

export const Icons = () => (
  <Head>
    <link
      rel="icon"
      href="https://assets.ctfassets.net/l6vo76cztcax/2ncZmRwmx6PEDvry7GiaCM/2ff240625d3763f8a6125c30d070944c/favicon.ico"
      sizes="32x32"
    />
    <link
      rel="icon"
      href="https://images.ctfassets.net/l6vo76cztcax/KQ8KQn4DI127oelftKjL4/eecdfedbd47f53495abdf83542f61e1c/icon.svg"
      type="image/svg+xml"
    />
    <link
      rel="apple-touch-icon"
      href="https://images.ctfassets.net/l6vo76cztcax/3GqREuwG72lmcQ1xRik4Mh/884dc1b1e20230951e7ca07bcd25638c/apple-touch-icon.png"
    />
    <link
      rel="apple-touch-startup-image"
      href="https://images.ctfassets.net/l6vo76cztcax/1OChtqfYfYLZe3p8ZbI3mB/6a60f75397b50c43a25fcf19fbb69d47/640x1136.png"
      media="(device-width: 320px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="https://images.ctfassets.net/l6vo76cztcax/3GvR6ZdbI9FKRAWQ0zZNwx/d7b4dcb518ff56a7c9a05af0727338f4/480x320.png"
      media="(device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="https://images.ctfassets.net/l6vo76cztcax/6d55AArDgWOLnBQGBuXqk5/6ad9c42753e32ecfbe0bdb5ce3b124ee/1536x2048.png"
      media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
    />
  </Head>
)
