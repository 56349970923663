import {
  useIsViewportMobile,
  Spacing,
  Headline1,
  AudioPlayer,
} from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import React, { FunctionComponent, useCallback } from 'react'
import { SectionProductCopySchema } from '../../../lib/contentful/datamodel/schemas'
import { Column } from '../ImageText/components/Column'
import { Container } from '../ImageText/components/Container'
import { Layout } from '../ImageText/components/Layout'
import { AudioPlayerWrapper } from './components/AudioPlayerWrapper'
import { CallToAction } from './components/CallToAction'
import { StyledHearts } from './components/Doodles'
import { LicenseLogo } from './components/LicenseLogo'
import { Image } from '@/tonies-ui/atoms/Image'

import {
  ProductImageContainer,
  ProductImageWrapper,
} from './components/ProductImage'
import { BadgeWithHearts } from './components/BadgeWithHearts'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { RichText } from '../../molecules/RichText'
import { FreeHtml } from '../../molecules/FreeHtml'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { handleOnPlay } from '../AudioPlayerSection/utils'
import { useRecommendations } from '../../../providers/recommendations'

export type SectionProductCopyProps = Aggregated<SectionProductCopySchema> & {
  dataTestId?: string
  normalizedProduct: NormalizedProduct &
    Pick<NormalizedProductExtended, 'description'>
}

const SectionProductCopyContainer: FunctionComponent<
  SectionProductCopyProps
> = ({
  backgroundColor = 'transparent',
  badgeDesktop,
  badgeMobile,
  contentPositionDesktop = 'left',
  dataTestId,
  licenseLogo,
  normalizedProduct,
  text,
  title,
  headlineAsHTMLTag,
  id,
}) => {
  const isMobile = useIsViewportMobile()
  const { handleSetLastSeenSku } = useRecommendations()

  const { pushGtmV4Event } = useGtmV4()
  const handleAudioSampleOnPlay = useCallback(() => {
    handleSetLastSeenSku(normalizedProduct.sku)
    handleOnPlay(normalizedProduct, pushGtmV4Event)
  }, [pushGtmV4Event, normalizedProduct, handleSetLastSeenSku])

  return (
    <ColorContextProvider backgroundColor={backgroundColor}>
      <Container
        backgroundColor={backgroundColor}
        data-testid={dataTestId}
        id={id}
      >
        <Layout contentPositionMobile="bottom">
          <Column
            textAlignMobile="center"
            contentPositionDesktop={contentPositionDesktop}
          >
            {badgeDesktop && !isMobile && (
              <>
                <StyledHearts />
                <BadgeWithHearts
                  src={badgeDesktop.file.url}
                  alt={badgeDesktop.title}
                />
              </>
            )}
            <Spacing mb="spacing-l" mt={isMobile ? 'spacing-xl' : 'spacing-l'}>
              <Headline1 asHTMLTag={headlineAsHTMLTag}>
                <Accent text={title || normalizedProduct.name} />
              </Headline1>
              <Spacing my="spacing-xs">
                {text ? (
                  <RichText
                    document={text}
                    size={1}
                    align={isMobile ? 'center' : 'left'}
                  />
                ) : (
                  <FreeHtml
                    html={normalizedProduct.description || ''}
                    align={isMobile ? 'center' : 'left'}
                  />
                )}
              </Spacing>
            </Spacing>
            <CallToAction product={normalizedProduct} />
          </Column>
          <Column>
            {licenseLogo && (
              <LicenseLogo
                src={licenseLogo.file.url}
                alt={licenseLogo.title}
                contentPositionDesktop={contentPositionDesktop}
              />
            )}
            {badgeMobile && isMobile && (
              <BadgeWithHearts
                src={badgeMobile.file.url}
                alt={badgeMobile.title}
              />
            )}
            <ProductImageWrapper>
              <ProductImageContainer
                contentPositionDesktop={contentPositionDesktop}
              >
                <Image
                  src={normalizedProduct.image.src}
                  alt={normalizedProduct.name}
                  ratio={[4, 3]}
                  responsive={[200, 300, 400, 500, 600]}
                  crop="fill"
                />
              </ProductImageContainer>
              {normalizedProduct.audioSampleUrl && (
                <AudioPlayerWrapper
                  contentPositionDesktop={contentPositionDesktop}
                >
                  <AudioPlayer
                    hasButtonBoxed
                    src={normalizedProduct.audioSampleUrl}
                    loading="lazy"
                    onPlay={handleAudioSampleOnPlay}
                  />
                </AudioPlayerWrapper>
              )}
            </ProductImageWrapper>
          </Column>
        </Layout>
      </Container>
    </ColorContextProvider>
  )
}

export const SectionProductCopy = withGTMV4Context(
  SectionProductCopyContainer,
  'SectionProductCopy'
)
