import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  SectionHeadline,
  SectionHeadlineProps,
} from '../../molecules/SectionHeadline'
import { Grid, Spacing } from '@boxine/tonies-ui'
import { HorizontalScrollList } from '@/tonies-ui/index'
import { EditorialTeaser } from '../../molecules/EditorialTeaser'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { useViewportConfig } from '../../../tonies-ui/hooks/useViewport'
import { motion } from 'framer-motion'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import {
  BrazeContentCardSchema,
  EditorialTeaserEntrySchema,
} from '../../../lib/contentful/datamodel/schemas'
import { contentfulImage } from '../../../utils/contentful-helpers'
import { Card } from '@braze/web-sdk'
import {
  logContentCardImpressions,
  requestContentCardsRefresh,
} from '../../../lib/braze'
import {
  isBxContentCard,
  isMatchingBxContentCard,
  parseBxContentCard,
} from '../../../lib/braze/schemas'
import { useAuth } from '../../../providers/auth'

export type EditorialTeaserCollectionProps = {
  entries: Aggregated<BrazeContentCardSchema | EditorialTeaserEntrySchema>[]
  variant?: 'list' | 'slider'
  columns?: number
} & Partial<SectionHeadlineProps>

type AggregatedEditorialTeaser = Aggregated<
  Omit<EditorialTeaserEntrySchema, 'contentTypeId'>
> & {
  contentCard?: Card
}

const EditorialTeaserCollectionContainer: FunctionComponent<
  EditorialTeaserCollectionProps
> = ({
  title,
  headlineAsHTMLTag,
  text,
  entries,
  variant = 'list',
  columns = 3,
  ...rest
}) => {
  const isSlider = variant === 'slider'
  const VariantComp = isSlider ? HorizontalScrollList : Grid
  const cols = useViewportConfig({
    mobile: isSlider ? 1.5 : 1,
    tablet: isSlider ? 3 : 2,
    desktop: columns,
  })

  const { isBrazePending } = useAuth()

  const initialTeasers = entries.filter(
    entry => entry.contentTypeId === 'editorialTeaserEntry'
  ) as AggregatedEditorialTeaser[]

  const [teasers, setTeasers] = useState(initialTeasers)

  const hasContentfulContentCards = entries.some(
    entry => entry.contentTypeId === 'brazeContentCard'
  )

  useEffect(() => {
    if (hasContentfulContentCards && !isBrazePending) {
      if (typeof window === 'undefined' || !window.__BRAZE__) {
        return
      }

      requestContentCardsRefresh()
      const contentCards = window.__BRAZE__.getCachedContentCards().cards

      if (contentCards.length > 0) {
        const newTeasers: typeof initialTeasers = []
        let hasNewTeasers = false

        entries.forEach(entry => {
          if (entry.contentTypeId === 'editorialTeaserEntry') {
            newTeasers.push(entry)
          } else if (entry.contentTypeId === 'brazeContentCard') {
            const matchingContentCard = contentCards
              .filter(card => isBxContentCard(card))
              .find(card => isMatchingBxContentCard(card, entry))

            if (matchingContentCard) {
              const bxContentCard = parseBxContentCard(matchingContentCard)

              newTeasers.push({
                text: bxContentCard.title,
                ctaText: bxContentCard.linkText,
                ctaUrl: bxContentCard.url,
                backgroundColor: entry.backgroundColor,
                backgroundImage: contentfulImage(bxContentCard.imageUrl),
                contentCard: matchingContentCard,
              })

              logContentCardImpressions([matchingContentCard])

              hasNewTeasers = true
            }
          }
        })

        if (hasNewTeasers) {
          setTeasers(newTeasers)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrazePending])

  return (
    // The wrapper div makes sure that we don't
    // need `noGaps` on surrounding sections.
    <div>
      <Spacing mx="spacing-s">
        <SectionHeadline
          title={title}
          headlineAsHTMLTag={headlineAsHTMLTag}
          text={text}
          {...rest}
        />
      </Spacing>

      <motion.div
        variants={{
          show: {
            transition: {
              staggerChildren: 0.15,
              delayChildren: 0.05,
            },
          },
        }}
        whileInView="show"
        /**
         * [BUG] Conditionally rendered children components not staggering
         * https://github.com/framer/motion/issues/776
         */
        initial={hasContentfulContentCards ? false : 'hide'}
        viewport={{
          once: true,
          amount: 0.15,
        }}
      >
        <VariantComp columns={cols} hasGapH hasGapV scrollByOne>
          {teasers.map(
            (
              {
                text,
                textStyling,
                ctaUrl,
                ctaStyling = 'primary',
                ctaText,
                backgroundImage,
                backgroundColor,
                contentCard,
              },
              index
            ) => (
              <EditorialTeaser
                key={ctaUrl + backgroundImage.file.url + text + index}
                text={text}
                textStyling={textStyling}
                ctaUrl={ctaUrl}
                ctaStyling={ctaStyling}
                ctaText={ctaText}
                backgroundImageUrl={backgroundImage.file.url}
                backgroundColor={backgroundColor || 'transparent'}
                contentCard={contentCard}
              />
            )
          )}
        </VariantComp>
      </motion.div>
    </div>
  )
}

export const EditorialTeaserCollection = withGTMV4Context(
  EditorialTeaserCollectionContainer,
  'EditorialTeaserCollection'
)
