import { CarouselContext } from 'pure-react-carousel'
import { useCallback, useContext } from 'react'

export const usePreviousSlide = () => {
  const carouselContext = useContext(CarouselContext)

  return useCallback(() => {
    const { currentSlide, totalSlides, visibleSlides } =
      carouselContext.getStoreState()

    carouselContext.setStoreState({
      currentSlide:
        currentSlide === 0 ? totalSlides - visibleSlides : currentSlide - 1,
    })
  }, [carouselContext])
}
