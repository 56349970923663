import { useModalNotifications } from '../providers/ModalNotifications'
import { useAuth } from '../providers/auth'
import React, { useCallback, useState } from 'react'
import { useCartActions } from './useCartActions'
import { useTunesInCart } from './useCartState'
import { AlreadyBoughtTunesModal } from '../components/organisms/AlreadyBoughtTunesModal'
import { useShopLocaleConfig } from './useShopLocaleConfig'

export const useRemoveAlreadyBoughtTunesFromCart = () => {
  const { setModal } = useModalNotifications()
  const { authenticated } = useAuth()
  const { removeLineItemsFromCart } = useCartActions()
  const { alreadyMyTuneLineItems } = useTunesInCart()
  const [isPending, setIsPending] = useState(false)
  const shopGlobalLocaleConfig = useShopLocaleConfig()

  return useCallback(() => {
    if (
      authenticated &&
      alreadyMyTuneLineItems.length !== 0 &&
      !isPending &&
      shopGlobalLocaleConfig?.showTunes
    ) {
      setIsPending(true)
      setModal(
        <AlreadyBoughtTunesModal
          products={alreadyMyTuneLineItems}
          onClose={() => setModal(undefined)}
        />
      )

      removeLineItemsFromCart(alreadyMyTuneLineItems)?.finally(() =>
        setIsPending(false)
      )
    }
  }, [
    alreadyMyTuneLineItems,
    authenticated,
    isPending,
    removeLineItemsFromCart,
    setModal,
    shopGlobalLocaleConfig,
  ])
}
