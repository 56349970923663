export const deliveryInfoIconTypes = [
  'circular-arrow',
  'padlock',
  'parcel',
  'stopwatch',
] as const

export type DeliveryInfoIconType = (typeof deliveryInfoIconTypes)[number]

export const isDeliveryInfoIconType = (s: unknown): s is DeliveryInfoIconType =>
  deliveryInfoIconTypes.includes(s as DeliveryInfoIconType)

export type DeliveryInfoIconProps = {
  iconType: DeliveryInfoIconType
  width?: string
}
