import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FocusEvent,
  forwardRef,
  KeyboardEventHandler,
  useCallback,
  useState,
  ReactNode,
} from 'react'
import { useTheme } from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import { Label } from '../components/Label'
import { Icon } from '@boxine/tonies-ui'
import { Message, MessageProps } from '../components/Message'
import { COLOR } from '../variables'
import { Styling, Status } from '../types'
import {
  DisabledWrapper,
  FieldWrapper,
  StatusWrapperInputField,
} from '../styles'

export type Type = 'text' | 'email' | 'password' | 'tel' | 'url'

export type Props = {
  /**
   * Component is disabled
   */
  isDisabled?: boolean

  /**
   * Component is optional
   */
  optionalHint?: string

  /**
   * Label for the input field. If you don't want to see the label visually,
   * set the label to a meaningful value nevertheless and use
   * `isLabelHidden={true}` so that assistive technologies (e.g. screen
   * readers) are still able to identify this input.
   */
  label: string

  /**
   * Message text (input-dependent) to be displayed below the input field.
   */
  message?: MessageProps

  /**
   * Should be unique. Allows to identify this unambiguously
   */
  name: string

  /**
   * Gets called with the event object when the blur event occurs
   */
  onBlur?: FocusEventHandler<HTMLInputElement>

  /**
   * Gets called with the event object when the change event occurs
   */
  onChange?: ChangeEventHandler<HTMLInputElement>

  /**
   * Gets called with the event object when the focus event occurs
   */
  onFocus?: FocusEventHandler<HTMLInputElement>

  /**
   * The keydown event fires when the user presses a keyboard key.
   */
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>

  /**
   * Status (input-dependent) which modifies the color and the indicator icon.
   */
  status?: Status

  /**
   * Autocomplete function (see all supported values here: https://devdocs.io/html/attributes/autocomplete)
   */
  autoComplete?: 'on' | 'off' | 'new-password'

  /**
   * Overwrite the internal data-testid of the input component for testing purposes.
   */
  dataTestIdInput?: string

  /**
   * Overwrite the internal data-testid of the label for testing purposes.
   */
  dataTestIdLabel?: string

  /**
   * Overwrite the internal data-testid of the message for testing purposes.
   */
  dataTestIdMessage?: string

  /**
   * Maximum length of characters allowed
   */
  maxLength?: number

  /**
   * Type of the input
   */
  type?: Type

  /**
   * Input value
   */
  value: string

  /**
   * Hint text that could be used to explain status of the input field (error, success).
   */
  hint?: string

  /**
   * Customizable Message, please use a Paragraph, optional a link within the paragraph
   * and optional an Icon as children. Mentioned children are styled automatically with selectors
   * of their parent wrapper.
   */
  fieldInfo?: ReactNode

  /**
   * Color scheme; default "primary", use "secondary" on vivid backgrounds
   */
  styling?: Styling

  /**
   * Placeholder of the component.
   * Please don't use this as a label replacement.
   * If you don't want to have a visible label, you should still set a label (e.g. for assistive technologies) and set `isLabelHidden={true}`.
   */
  placeholder?: string

  /**
   * Component label is only shown for assistive technologies (e.g. screen readers).
   */
  isLabelHidden?: boolean

  /**
   * Hides border on bottom
   */
  isBorderless?: boolean
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'autoFocus'>

export const InputField = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoComplete = 'on',
      autoFocus,
      dataTestIdInput = 'input-field',
      dataTestIdLabel = 'input-field-label',
      dataTestIdMessage = 'input-field-message',
      fieldInfo,
      hint,
      isBorderless = false,
      isDisabled = false,
      isLabelHidden = false,
      label,
      maxLength,
      message,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      optionalHint = undefined,
      placeholder,
      status,
      styling: stylingProp,
      type = 'text',
      value,
    },
    ref
  ) => {
    const { colorContext } = useTheme()

    const inputId = `input-id-${name}`
    const messageId = `message-id-${name}`
    const hintId = `hint-id-${name}`
    const [hasFocus, setHasFocus] = useState(false)
    const isEmptyValue = value === '' || value === undefined || value === null
    const styling =
      stylingProp || (colorContext === 'dark' ? 'secondary' : 'primary')

    const handleLabelOnFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setHasFocus(true)

        if (onFocus) {
          onFocus(event)
        }
      },
      [setHasFocus, onFocus]
    )

    const handleLabelOnBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setHasFocus(false)

        if (onBlur) {
          onBlur(event)
        }
      },
      [setHasFocus, onBlur]
    )

    return (
      <DisabledWrapper
        data-testid="input-field-disabled-wrapper"
        isDisabled={isDisabled}
      >
        <Label
          data-testid={dataTestIdLabel}
          htmlFor={inputId}
          isFloating={isEmptyValue && !hasFocus}
          styling={styling}
          isLabelHidden={isLabelHidden}
        >
          {label}
          {optionalHint && (
            <span data-testid="input-field-label-optional">
              {' '}
              ({optionalHint})
            </span>
          )}
          {(hint || fieldInfo) && (
            <span data-testid="input-field-label-hint">*</span>
          )}
        </Label>
        <FieldWrapper
          data-testid="input-field-field-wrapper"
          isDisabled={isDisabled}
          status={status}
          styling={styling}
          isBorderless={isBorderless}
        >
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            aria-describedby={
              message?.text && !message.ariaLive ? messageId : ''
            }
            aria-invalid={status === 'error'}
            autoComplete={autoComplete}
            data-testid={dataTestIdInput}
            disabled={isDisabled}
            id={inputId}
            name={name}
            maxLength={maxLength}
            onBlur={handleLabelOnBlur}
            onChange={onChange}
            onFocus={handleLabelOnFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            ref={ref}
            required={!optionalHint}
            type={type}
            value={value}
          />
        </FieldWrapper>
        {(status || message) && (
          <StatusWrapperInputField>
            {message && (
              <Message
                styling={styling}
                message={message}
                status={status}
                dataTestId={dataTestIdMessage}
                id={messageId}
              />
            )}
            {status === 'error' && (
              <Icon type={icons.exclamationMark} fill={COLOR.ERROR} />
            )}
            {status === 'ok' && (
              <Icon type={icons.checkmark} fill={COLOR.SUCCESS} />
            )}
          </StatusWrapperInputField>
        )}
        {hint && (
          <StatusWrapperInputField>
            {hint && (
              <Message
                message={{ text: '* ' + hint }}
                dataTestId="input-field-hint"
                id={hintId}
              />
            )}
          </StatusWrapperInputField>
        )}
        {fieldInfo && (
          <StatusWrapperInputField>{fieldInfo}</StatusWrapperInputField>
        )}
      </DisabledWrapper>
    )
  }
)

InputField.displayName = 'InputField'
