import React, { ComponentProps } from 'react'
import { GiftfinderSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { Giftfinder } from '../../../../../../components/organisms/Giftfinder'

export const CM_GiftfinderSchema = ({
  componentProps,
  ...rest
}: ContentModelType<ComponentProps<typeof Giftfinder>, GiftfinderSchema>) => {
  return <Giftfinder {...rest} {...componentProps} />
}
