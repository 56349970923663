import React from 'react'
import { ComponentProps } from 'react'
import { AccordionSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { Accordion } from '@boxine/tonies-ui'
import { AccordionItem } from '@/tonies-ui/index'
import { RichText } from '../../../../../../components/molecules/RichText'

export const CM_Accordion = ({
  componentProps,
  entries,
  ...rest
}: ContentModelType<ComponentProps<typeof Accordion>, AccordionSchema>) => (
  <Accordion {...rest} {...componentProps}>
    {entries.map(({ question, answer }) => (
      <AccordionItem
        key={question}
        title={question}
        data-testid="accordion-item"
      >
        <RichText document={answer} />
      </AccordionItem>
    ))}
  </Accordion>
)
