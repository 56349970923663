import React, { FunctionComponent, ReactNode } from 'react'
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import {
  Accent,
  AudioSample,
  Button,
  Grid,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  List,
  ListItem,
  media,
  Paragraph,
  useIsViewportMobile,
  Spacing,
  ThemeSpacing,
} from '@boxine/tonies-ui'
import {
  SupportedAlignments,
  SupportedSizes,
} from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { Strong } from './components/Strong'
import { Link } from './components/Link'
import { ImageWithOptionalLink as ImageWithOptionalLinkComp } from './components/ImageWithOptionalLink'
import styled from 'styled-components'
import { useGtmV4 } from '../../../providers/gtmV4'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import {
  AudioSampleEntry,
  ButtonSchema,
  ContentfulFile,
  DownloadTeaserEntryListSchema,
  EditorialTeaserEntryListSchema,
  ImageWithOptionalLink,
  ImageWithOptionalLinkEntryListSchema,
  SkuList,
  SkuEntry,
  VideoPlayerCloudinarySchema,
} from '../../../lib/contentful/datamodel/schemas'
import { NormalizedProductCard } from '../NormalizedProductCard'
import { EditorialTeaser } from '../EditorialTeaser'
import { ProductCardCarousel } from '../../organisms/ProductCardCarousel'
import { CloudinaryVideoPlayer } from '../CloudinaryVideoPlayer'
import { ContentModels } from '../../../lib/contentful/datamodel/contentModels'

const RichTextWrapper = styled.div`
  > div::after {
    content: ' ';
    display: table;
    clear: left;
  }

  p,
  ul,
  ol,
  li {
    em {
      font: inherit;
      color: inherit;
      hyphens: inherit;
      word-wrap: inherit;
    }
  }
`

const StyledListItem = styled(ListItem)`
  > div {
    padding-bottom: 0;
  }
`

const FigCaption = styled(Paragraph)`
  display: block;
`

const StyledInlineEntry = styled.span`
  display: block;
  padding-bottom: 1.5rem;

  ${media.tablet`
    float: left;
    width: calc(33.3333% + 1rem);
    padding-right: 2rem;
    padding-bottom: 0;
  `}
`

interface RichTextProps {
  document: Document
  options?: Options
  size?: SupportedSizes
  linksInheritColor?: boolean
  align?: SupportedAlignments
  dataTestId?: string
  childrenPaddingBottom?: ThemeSpacing
}

const RichText: FunctionComponent<RichTextProps & Styleable> = ({
  document,
  options,
  className,
  size = 2,
  linksInheritColor,
  align = 'left',
  dataTestId = 'richtext',
  childrenPaddingBottom = 'spacing-s',
}) => {
  const isMobile = useIsViewportMobile()
  const { pushGtmV4Event } = useGtmV4()

  const OPTIONS: Options = {
    renderMark: {
      [MARKS.BOLD]: children => <Strong>{children}</Strong>,
      /**
       * FIXME
       * Make Accent only available for Headlines
       * See CSS overrides in RichTextWrapper above
       */
      [MARKS.ITALIC]: children => <Accent>{children}</Accent>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_node: unknown, children: ReactNode) => (
        <Spacing pb={childrenPaddingBottom}>
          <Headline1 align={align}>{children}</Headline1>
        </Spacing>
      ),
      [BLOCKS.HEADING_2]: (_node: unknown, children: ReactNode) => (
        <Spacing pb={childrenPaddingBottom}>
          <Headline2 align={align}>{children}</Headline2>
        </Spacing>
      ),
      [BLOCKS.HEADING_3]: (_node: unknown, children: ReactNode) => (
        <Spacing pb={childrenPaddingBottom}>
          <Headline3 align={align}>{children}</Headline3>
        </Spacing>
      ),
      [BLOCKS.HEADING_4]: (_node: unknown, children: ReactNode) => (
        <Spacing pb={childrenPaddingBottom}>
          <Headline4 align={align}>{children}</Headline4>
        </Spacing>
      ),
      [BLOCKS.HEADING_5]: (_node: unknown, children: ReactNode) => (
        <Spacing pb={childrenPaddingBottom}>
          <Headline5 align={align}>{children}</Headline5>
        </Spacing>
      ),
      [BLOCKS.PARAGRAPH]: (_node: unknown, children: ReactNode) => {
        // don't render empty paragraphs
        if (children?.toString().trim() !== '') {
          return (
            <Spacing pb={childrenPaddingBottom}>
              <Paragraph align={align} size={size} whiteSpace="pre-line">
                {children}
              </Paragraph>
            </Spacing>
          )
        }
      },
      [BLOCKS.UL_LIST]: (_node: unknown, children: ReactNode) => (
        <Spacing pb={childrenPaddingBottom}>
          <List size={size}>{children}</List>
        </Spacing>
      ),
      [BLOCKS.OL_LIST]: (_node: unknown, children: ReactNode) => (
        <Spacing pb={childrenPaddingBottom}>
          <List type="ordered" size={size}>
            {children}
          </List>
        </Spacing>
      ),
      [BLOCKS.LIST_ITEM]: (_node: unknown, children: ReactNode) => (
        <StyledListItem>{children}</StyledListItem>
      ),
      [INLINES.HYPERLINK]: (node, content) => {
        return (
          <Link
            inheritColor={linksInheritColor}
            to={node.data.uri}
            onClick={() =>
              pushGtmV4Event({
                eventType: 'selectContent',
                ctaLabel: content?.toString() || '',
                ctaLink: node.data.uri || '',
                type: 'text-link',
              })
            }
          >
            {content}
          </Link>
        )
      },
      [INLINES.ASSET_HYPERLINK]: (node, content) => {
        const {
          file: { url },
          title,
        }: Aggregated<ContentfulFile> = node.data.target

        return (
          <Link
            inheritColor={linksInheritColor}
            target="_blank"
            to={url}
            onClick={() =>
              pushGtmV4Event({
                eventType: 'selectContent',
                ctaLabel: title,
                ctaLink: url,
                type: 'text-link',
              })
            }
          >
            {content}
          </Link>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const data: Aggregated<
          | AudioSampleEntry
          | ButtonSchema
          | DownloadTeaserEntryListSchema
          | EditorialTeaserEntryListSchema
          | ImageWithOptionalLinkEntryListSchema
          | ImageWithOptionalLink
          | SkuList
          | VideoPlayerCloudinarySchema
        > = node.data.target

        switch (data.contentTypeId) {
          case 'audioSampleEntry':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <AudioSample url={data.audioSampleUrl} copy={data.label} />
              </Spacing>
            )
          case 'button':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <Button as="a" href={data.href} variant={data.variant}>
                  {data.label}
                </Button>
              </Spacing>
            )
          case 'commerceToolsSkuList':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <ProductCardCarousel
                  items={data.normalizedProducts}
                  columns={{ mobile: 1.5, tablet: 3, desktop: 3 }}
                />
              </Spacing>
            )
          case 'downloadTeaserEntryList':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <ContentModels.DownloadTeaserEntryList {...data} />
              </Spacing>
            )
          case 'editorialTeaserEntryList':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <Grid columns={isMobile ? 1 : 3} hasGapH hasGapV>
                  {data.entries.map(
                    (
                      {
                        text,
                        textStyling,
                        ctaUrl,
                        ctaStyling = 'primary',
                        ctaText,
                        backgroundImage,
                        backgroundColor,
                      },
                      index
                    ) => (
                      <EditorialTeaser
                        key={ctaUrl + backgroundImage?.file.url + text + index}
                        text={text}
                        textStyling={textStyling}
                        ctaUrl={ctaUrl}
                        ctaStyling={ctaStyling}
                        ctaText={ctaText}
                        backgroundImageUrl={backgroundImage.file.url}
                        backgroundColor={backgroundColor || 'transparent'}
                      />
                    )
                  )}
                </Grid>
              </Spacing>
            )
          case 'imageWithOptionalLink':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <ImageWithOptionalLinkComp image={data} />
                {data.caption && (
                  <Spacing pt="spacing-xxs">
                    <FigCaption size={3} asHTMLTag="span">
                      {data.caption}
                    </FigCaption>
                  </Spacing>
                )}
              </Spacing>
            )
          case 'imageWithOptionalLinkEntryList':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <Grid columns={isMobile ? 1 : 2} hasGapH hasGapV>
                  {data.entries.map((e, index) => (
                    <div key={e.image.file.url + index}>
                      <ImageWithOptionalLinkComp image={e} />
                      {e.caption && (
                        <Spacing pt="spacing-xxs">
                          <FigCaption size={3} asHTMLTag="span">
                            {e.caption}
                          </FigCaption>
                        </Spacing>
                      )}
                    </div>
                  ))}
                </Grid>
              </Spacing>
            )
          case 'videoPlayerCloudinary':
            return (
              <Spacing pb={childrenPaddingBottom}>
                <CloudinaryVideoPlayer
                  cloudinaryPublicId={data.cloudinaryVideo[0].public_id}
                  videoAlt={data.videoAlt}
                />
              </Spacing>
            )
        }
      },
      [INLINES.EMBEDDED_ENTRY]: node => {
        const data: Aggregated<
          ImageWithOptionalLink | SkuEntry | VideoPlayerCloudinarySchema
        > = node.data.target

        switch (data.contentTypeId) {
          case 'commercetoolsSkuEntry':
            return (
              <StyledInlineEntry>
                <NormalizedProductCard
                  product={data.normalizedProduct}
                  orientation="portrait"
                  action="addToCart"
                />
              </StyledInlineEntry>
            )
          case 'imageWithOptionalLink':
            return (
              <StyledInlineEntry>
                <ImageWithOptionalLinkComp image={data} />
                {data.caption && (
                  <Spacing pt="spacing-xxs">
                    <FigCaption size={3} asHTMLTag="span">
                      {data.caption}
                    </FigCaption>
                  </Spacing>
                )}
              </StyledInlineEntry>
            )
          case 'videoPlayerCloudinary':
            return (
              <StyledInlineEntry>
                <CloudinaryVideoPlayer
                  cloudinaryPublicId={data.cloudinaryVideo[0].public_id}
                  videoAlt={data.videoAlt}
                />
              </StyledInlineEntry>
            )
        }
      },
    },
  }

  return (
    <RichTextWrapper data-testid={dataTestId} className={className}>
      {documentToReactComponents(document, options ? options : OPTIONS)}
    </RichTextWrapper>
  )
}

export { RichText }
