import React from 'react'
import styled from 'styled-components'
import IconCircularArrow from './assets/iconCircularArrow.svg'
import IconPadlock from './assets/iconPadlock.svg'
import IconParcel from './assets/iconParcel.svg'
import IconStopwatch from './assets/iconStopwatch.svg'
import { DeliveryInfoIconProps } from './types'

const icons = {
  'circular-arrow': IconCircularArrow,
  padlock: IconPadlock,
  parcel: IconParcel,
  stopwatch: IconStopwatch,
}

const Wrapper = styled.div<{ size: string }>`
  height: ${p => p.size};
  width: ${p => p.size};
  z-index: 1;

  svg {
    width: inherit;
    height: inherit;
  }
`

export const DeliveryInfoIcon = ({
  iconType,
  width = '100%',
}: DeliveryInfoIconProps) => {
  const Icon = icons[iconType]

  return (
    <Wrapper size={width}>
      <Icon />
    </Wrapper>
  )
}
