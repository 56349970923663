import React, { ComponentProps } from 'react'
import { ProductCardCarousel } from '../../../../../../components/organisms/ProductCardCarousel'
import { ProductItemTeaserCarouselSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { Box } from '@boxine/tonies-ui'

export const CM_ProductItemTeaserCarouselSchema = ({
  componentProps,
  skuList: { normalizedProducts, referenceTitle: referenceTitleSkuList },
  useRecommendationsAPI,
  headline,
  title,
  primaryCtaUrl,
  referenceTitle,
  discoverAllUrl,
  backgroundColor,
  promotions,
  tooltip,
}: ContentModelType<
  ComponentProps<typeof ProductCardCarousel>,
  ProductItemTeaserCarouselSchema
>) => {
  return (
    <Box backgroundColor={backgroundColor}>
      <ProductCardCarousel
        getAPIRecommendations={useRecommendationsAPI}
        referenceTitle={referenceTitle || referenceTitleSkuList}
        items={normalizedProducts}
        headline={headline || title}
        showAllLink={discoverAllUrl || primaryCtaUrl}
        promotions={promotions}
        tooltip={tooltip}
        {...componentProps}
      />
    </Box>
  )
}
