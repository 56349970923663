import { useEcomLocale } from '../providers/locale'
import { useEffect, useState } from 'react'
import { useMoreProductsFromAttributeValueList } from './useMoreProductsFromAttributeValueList'
import { getCompatibleContentTonieSalesIds } from '../lib/cloudservices/requests/rest/getCompatibleContentTonieSalesIds'

type CompatibleToniesState = 'initial' | 'idle' | 'processing' | 'error'

export const useCompatibleContentTonies = (salesId: string) => {
  const lcCC = useEcomLocale()
  const [compatibleTonieSalesIds, setCompatibleTonieSalesIds] = useState<
    string[]
  >([])
  const [state, setState] = useState<CompatibleToniesState>('initial')
  const compatibleTonies = useMoreProductsFromAttributeValueList(
    'Artikelnummer',
    compatibleTonieSalesIds,
    10
  )

  /** wait for compatible tonies to be retrieved before changing back to reset state */
  useEffect(() => {
    if (compatibleTonies?.length) {
      setState('idle')
    }
  }, [compatibleTonies, state])

  useEffect(() => {
    const getSalesIds = async () => {
      try {
        const { data, result } = await getCompatibleContentTonieSalesIds(
          lcCC,
          salesId
        )
        if (result === 'successful') {
          setCompatibleTonieSalesIds(data as unknown as string[])
        } else {
          setState('error')
        }
      } catch (error) {
        // sentry error handling is already happening inside fetch
        console.error(error)
        setState('error')
      }
    }
    if (salesId) {
      setState('processing')
      getSalesIds()
    }
  }, [lcCC, salesId])

  return {
    compatibleTonies,
    compatibleTonieSalesIds,
    state,
  }
}
