import { Button, Paragraph } from '@boxine/tonies-ui'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import React from 'react'
import styled from 'styled-components'
import { ColorContextProvider } from '@/tonies-ui/index'
import { ToniesMotions } from '@/tonies-ui/motions'
import { toniesSpringTransition } from '@/tonies-ui/motions/constants'
import { motion } from 'framer-motion'
import { theme } from '@/tonies-ui/themes/theme'

export interface EditorialTeaserProps {
  text?: string
  textStyling?: 'black' | 'white'
  ctaText: string
  ctaUrl: string
  ctaStyling: 'primary' | 'secondary'
  backgroundImageUrl: string
  backgroundColor?: string
  isContainingSmallCenteredBackgroundImage?: boolean
  onClick?: (
    // must match GtmV4SelectContentEvent['type']
    type: 'image' | 'primary'
  ) => void
  aspectRatio?: 'inherit' | [number, number]
  showCTAButton?: boolean
}

export const EditorialTeaser = ({
  text,
  textStyling,
  ctaText,
  ctaUrl,
  ctaStyling,
  backgroundImageUrl,
  backgroundColor = 'transparent',
  onClick = () => undefined,
  aspectRatio = [1, 1],
  showCTAButton = true,
  isContainingSmallCenteredBackgroundImage = undefined,
}: EditorialTeaserProps) => {
  return (
    <ColorContextProvider backgroundColor={backgroundColor}>
      <ToniesMotions.WobbleIt>
        <Container
          data-testid="editorial-teaser-container"
          $backgroundImageUrl={backgroundImageUrl}
          $backgroundColor={backgroundColor}
          $isContainingSmallCenteredBackgroundImage={
            isContainingSmallCenteredBackgroundImage
          }
          variants={{
            show: {
              x: 0,
            },
            hide: {
              x: '100vw',
            },
          }}
          transition={toniesSpringTransition}
        >
          <Teaser $aspectRatio={aspectRatio}>
            {(text || showCTAButton) && (
              <TeaserContent>
                {text && (
                  <Paragraph
                    weight={500}
                    color={
                      backgroundColor === 'transparent'
                        ? textStyling === 'black'
                          ? 'darkergrey'
                          : textStyling
                        : undefined
                    }
                  >
                    {text}
                  </Paragraph>
                )}
                {showCTAButton && (
                  <ButtonContainer>
                    <Button
                      as="a"
                      href={ctaUrl}
                      data-testid="editorial-teaser-button"
                      onClick={() => onClick('primary')}
                      backgroundColor={
                        !backgroundColor
                          ? ctaStyling === 'secondary'
                            ? 'white'
                            : ctaStyling
                          : undefined
                      }
                      target={
                        ctaUrl.startsWith('https://') ? '_bank' : undefined
                      }
                      rel={
                        ctaUrl.startsWith('https://')
                          ? 'noopener noreferrer'
                          : undefined
                      }
                    >
                      {ctaText}
                    </Button>
                  </ButtonContainer>
                )}
              </TeaserContent>
            )}
            {!showCTAButton && (
              <FullsizeAnchor
                href={ctaUrl}
                data-testid="editorial-teaser-fullsize-anchor"
                onClick={() => onClick('image')}
              >
                <span>{ctaText}</span>
              </FullsizeAnchor>
            )}
          </Teaser>
        </Container>
      </ToniesMotions.WobbleIt>
    </ColorContextProvider>
  )
}

const Container = styled(motion.div)<{
  $backgroundImageUrl: EditorialTeaserProps['backgroundImageUrl']
  $backgroundColor: EditorialTeaserProps['backgroundColor']
  $isContainingSmallCenteredBackgroundImage?: EditorialTeaserProps['isContainingSmallCenteredBackgroundImage']
}>`
  border-radius: 1rem;
  background-color: ${(props): string =>
    props.$backgroundColor || props.theme.colors.middlegrey};
  background-image: ${({ $backgroundImageUrl, $backgroundColor }) =>
    `url(${cloudinaryUrl({
      src: $backgroundImageUrl,
      background: $backgroundColor,
      width: theme.screenMobileXL,
    })}
    )`};
  background-size: ${props =>
    props.$isContainingSmallCenteredBackgroundImage ? '75%' : 'cover'};
  background-position: ${props =>
    props.$isContainingSmallCenteredBackgroundImage
      ? 'top 15% center'
      : 'center'};
  background-repeat: no-repeat;
  height: 100%;
`

const Teaser = styled.div<{
  $aspectRatio: EditorialTeaserProps['aspectRatio']
}>`
  position: relative;
  aspect-ratio: ${(props): string =>
    props.$aspectRatio === 'inherit'
      ? 'inherit'
      : props.$aspectRatio
      ? props.$aspectRatio.join(' / ')
      : '1 / 1'};
  height: 100%;
`

const TeaserContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem 1.25rem 1rem;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: block;
  margin-top: 0.75rem;
`

const FullsizeAnchor = styled.a`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  span {
    visibility: hidden;
  }
`
