import { Icon } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { EXPANDABLE_ANIMATION_DURATION_MILLIS } from '../constants'

// FIXME: avoid overwriting internal component styles
export const ArrowUp = styled(Icon)<{ $isOpen?: boolean }>`
  height: 1rem;
  width: 1rem;
  position: absolute;
  right: 0;
  top: 0.125rem;
  transform: translate3d(0, ${props => (props.$isOpen ? 0 : '250%')}, 0);
  transition: all ${EXPANDABLE_ANIMATION_DURATION_MILLIS}ms;
`
