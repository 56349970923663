import { useSidebarOverlayProvider } from '../../../../providers/SidebarOverlay'
import React from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Box, Button, Headline3, Icon, Paragraph } from '@boxine/tonies-ui'
import { useReferralCode } from '../../../../hooks/useReferralCode'
import { ComponentSpinner } from '@/tonies-ui/atoms/ComponentSpinner'
import { useAuth } from '../../../../providers/auth'
import { TextTeaser } from '@/tonies-ui/molecules/TextTeaser'
import styled, { useTheme } from 'styled-components'
import { shopConfig } from '../../../../config/shop'
import { IconButton } from '@/tonies-ui/atoms/IconButton'
import { Avatar } from '@/tonies-ui/atoms/Avatar'
import { SidebarOverlay } from '../../SidebarOverlay'
import { useAggregatedShopLocale } from '../../../../providers/aggregatedShopLocale'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { useOwnAudioContentContext } from '../../../../providers/ownAudioContent/OwnAudioContext'
import { useLocale } from '../../../../providers/locale'
import { isEcomLocale } from '../../../../config/shopAPI'

const StyledBox = styled(Box)`
  display: grid;
  gap: 1.5rem;
  overflow: initial;
  grid-auto-flow: row;
`

const MyAccountWrapper = styled(StyledBox)`
  align-items: center;
  grid-template-columns: auto 4rem;
  position: relative;
  margin-top: -1rem;
`
const ReferralWrapper = styled(StyledBox)`
  justify-items: center;
`

const NavigationWrapper = styled(StyledBox)`
  align-content: space-between;
`

const StyledSidebarOverlay = styled(SidebarOverlay)`
  grid-template-rows: auto auto auto 1fr;
`

const ShareButton = styled(Button)`
  color: ${props => props.theme.colors.darkergrey};
  text-decoration: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: nowrap;

  svg {
    margin-right: 0.25rem;
  }
`

const AccountNavigation = () => {
  const lcCC = useLocale()
  const { t } = useTranslation()
  const {
    content: { accountSidebarLinkTeaserList },
  } = useAggregatedShopLocale()
  const { closeOverlay } = useSidebarOverlayProvider()
  const { isRegionWithAudioContent, ownAudioContentCollection } =
    useOwnAudioContentContext()
  const {
    hasOwnAudioContentInAccount,
    isDirectOwnAudioContentAssignmentEnabled,
  } = useFeatureFlags()
  const showOwnAudioContentLink =
    hasOwnAudioContentInAccount &&
    isDirectOwnAudioContentAssignmentEnabled &&
    (isRegionWithAudioContent ||
      (ownAudioContentCollection && ownAudioContentCollection.length > 0))

  return (
    <nav>
      <ul>
        <li>
          <TextTeaser
            as="a"
            href={t('header:AccountLink')}
            mb="spacing-s"
            headline={t('accountNavigationSidebar:myProfile:headline')}
            icon={<IconButton as="span" icon="arrowRight" cursor="pointer" />}
            onClick={closeOverlay}
            data-testid="account-navigation-my-profile-link"
          >
            {t('accountNavigationSidebar:myProfile:text')}
          </TextTeaser>
        </li>
        {isEcomLocale(lcCC) && (
          <li>
            <TextTeaser
              as="a"
              href={t('header:AccountLink') + '#history'}
              mb="spacing-s"
              headline={t('accountNavigationSidebar:orderHistory:headline')}
              icon={<IconButton as="span" icon="arrowRight" cursor="pointer" />}
              onClick={closeOverlay}
              data-testid="account-navigation-order-history-link"
            >
              {t('accountNavigationSidebar:orderHistory:text')}
            </TextTeaser>
          </li>
        )}
        {showOwnAudioContentLink && (
          <li>
            <TextTeaser
              as="a"
              href={`${t('header:AccountLink')}${t(
                'ownAudioContent:AccountNavigationOwnAudioContentSlug'
              )}/`}
              mb="spacing-s"
              headline={t(
                'ownAudioContent:AccountNavigationOwnContentHeadline'
              )}
              icon={<IconButton as="span" icon="arrowRight" cursor="pointer" />}
              onClick={closeOverlay}
            >
              {t('ownAudioContent:AccountNavigationOwnContentCopy')}
            </TextTeaser>
          </li>
        )}
        {accountSidebarLinkTeaserList &&
          accountSidebarLinkTeaserList.map((teaser, i) => (
            <li key={i + teaser.url}>
              <TextTeaser
                as="a"
                href={teaser.url}
                mb="spacing-s"
                headline={teaser.headline}
                icon={
                  <IconButton as="span" icon="arrowRight" cursor="pointer" />
                }
              >
                {teaser.text}
              </TextTeaser>
            </li>
          ))}
      </ul>
    </nav>
  )
}

export const AccountNavigationSidebar = () => {
  const lcCC = useLocale()
  const { t } = useTranslation()
  const theme = useTheme()
  const { user, logout, isPending: isAuthPending } = useAuth()
  const {
    code: referralCode,
    handleCopyToClipboard,
    handleShare,
    isPending,
  } = useReferralCode()
  const { closeOverlay } = useSidebarOverlayProvider()

  const MyAccountBox = () => (
    <StyledBox mb="spacing-s" px="spacing-s">
      <MyAccountWrapper>
        <Paragraph size={2} asHTMLTag="div">
          <Headline3 asHTMLTag="h2">
            {t('account:Greeting', {
              name: user?.firstName ? ` ${user.firstName}` : '',
            })}
          </Headline3>
          {user?.customerNumber &&
            t('account:CustomerId') + ' ' + user.customerNumber}
          <br />
          {user?.authCode && t('account:supportId') + ' ' + user?.authCode}
        </Paragraph>
        <Avatar src={shopConfig.assets.toniesAvatar} alt="Avatar" />
      </MyAccountWrapper>

      <Button
        as="a"
        variant="secondary"
        href={process.env.NEXT_PUBLIC_MY_TONIES_URL}
      >
        {t('accountNavigationSidebar:toMyTonies:text')}
      </Button>
    </StyledBox>
  )

  const ReferralBox = () => (
    <>
      {!isPending ? (
        referralCode &&
        isEcomLocale(lcCC) && (
          <ReferralWrapper p="spacing-s" backgroundColor="lightblue-20">
            <Paragraph align="center" size={2}>
              <Trans
                i18nKey="accountNavigationSidebar:referral:text"
                components={{
                  a: (
                    <Button
                      as="a"
                      variant="secondary"
                      href={t('referral:url')}
                    />
                  ),
                }}
              />
            </Paragraph>
            <Box backgroundColor="white" p="spacing-xs" borderRadius={['xs']}>
              <ShareButton variant="secondary" onClick={handleCopyToClipboard}>
                <Icon
                  type="copyToClipboard"
                  height={16}
                  width={16}
                  fill={theme.colors.primary}
                />{' '}
                {referralCode}
              </ShareButton>
            </Box>
            <Button icon="share" onClick={handleShare}>
              {t('referralSection:shareButtonLabel')}
            </Button>
          </ReferralWrapper>
        )
      ) : (
        <ComponentSpinner height="10.5rem" />
      )}
    </>
  )

  const NavigationBox = () => (
    <NavigationWrapper backgroundColor="white" p="spacing-xs" pb="spacing-m">
      <AccountNavigation />
      <Button
        data-testid="account-navigation-sidebar__logout"
        style={{ justifySelf: 'center' }}
        onClick={() => logout()}
      >
        {t('header:Logout')}
      </Button>
    </NavigationWrapper>
  )

  return (
    <StyledSidebarOverlay
      headline={t('header:YourProfile')}
      onClose={closeOverlay}
      id="AccountNavigationSidebar"
      referenceTitle="[coded] AccountNavigationSidebar"
      backgroundColor="lightblue-40"
      isPending={isAuthPending}
      isHeadlineHidden
    >
      <MyAccountBox />
      <ReferralBox />
      <NavigationBox />
    </StyledSidebarOverlay>
  )
}
