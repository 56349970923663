import React from 'react'
import { useTranslation } from 'next-i18next'
import { useProductOverview } from '../../../../providers/productOverview'
import { Select } from '../../../atoms/FormElements/Select'
import { useSortingOptions } from '../hooks/useSortingOptions'

export type SortingOptionType = {
  id: string
  label: string
}

export const Sorting = () => {
  const { t } = useTranslation()
  const { sorting, search } = useProductOverview()
  const sortingOptions = useSortingOptions()

  if (!sorting) {
    return null
  }

  return (
    <Select
      data-testid="product-sorting-accordion"
      label={t('sorting:headline')}
      name="sorting"
      isDisabled={search?.value !== ''}
      value={sorting.id ?? ''}
      options={sortingOptions.map(o => ({ label: o.label, value: o.id }))}
      onChange={({ target: { value } }) => sorting.onChange(value)}
    />
  )
}
