import { useContext, useEffect, useState } from 'react'
import { CarouselContext } from 'pure-react-carousel'

/**
 * The CarouselContext doesn't automatically trigger re-renders
 * when the current slide changes unless we explicitly subscribe.
 *
 * We need these notifications to reflect the current state in
 * the pagination and buttons.
 */
export const useRenderOnContextChange = () => {
  const [, setCounter] = useState(0)
  const carouselContext = useContext(CarouselContext)

  useEffect(() => {
    const forceRender = () => setCounter(c => c + 1)
    carouselContext.subscribe(forceRender)

    return () => {
      carouselContext.unsubscribe(forceRender)
    }
  }, [carouselContext])
}
