import { toast } from '@/tonies-ui/atoms/Toast'
import { useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { getCode } from '../lib/commercetools/requests/referral/getCode'
import { useAuth } from '../providers/auth'
import { useEcomLocale } from '../providers/locale'
import { useAsyncEffect } from './useAsyncEffect'
import { sentryWithExtras } from './useSentry'
import { useAggregatedShopLocale } from '../providers/aggregatedShopLocale'
import { useGtmV4 } from '../providers/gtmV4'
import { fullUrl } from '../utils/urlHelpers'

export const useReferralCode = () => {
  const { user } = useAuth()
  const lcCC = useEcomLocale()
  const { t } = useTranslation()
  const {
    commonPages: { tonieboxPage },
  } = useAggregatedShopLocale()
  const { pushGtmV4Event } = useGtmV4()
  const [referralCode, setReferralCode] = useState<string | undefined>(
    undefined
  )
  const [isPending, setIsPending] = useState(true)

  useAsyncEffect(async () => {
    if (user) {
      await getCode(lcCC)
        .then(res => {
          if (res.result === 'successful') {
            if (res.data && res.data.code) {
              setReferralCode(res.data.code)
            }
            setIsPending(false)
          } else if (res.result === 'request-failed') {
            const toastMessage = res.httpStatus
              ? t('default:TOASTSomethingWentWrongWithErrorCode', {
                  errorCode: res.httpStatus,
                })
              : t('default:TOASTSomethingWentWrong')
            toast(toastMessage, 'error', {
              autoClose: false,
              toastId: 'referral-code-outage',
            })
            sentryWithExtras('ReferralCode', res.error)
            return undefined
          } else {
            toast(t('default:TOASTSomethingWentWrong'), 'error', {
              autoClose: false,
              toastId: 'referral-code-unexpected-response-format',
            })
            sentryWithExtras('ReferralCode', res.error)
          }
        })
        .catch(e => {
          toast(
            t('default:TOASTSomethingWentWrongWithErrorCode', {
              errorCode: 403,
            }),
            'error',
            {
              autoClose: false,
              toastId: 'referral-code-outage',
            }
          )
          console.error('ERROR', e)
        })
    }
  }, [user, lcCC])

  const handleShare = useCallback(async () => {
    const referralUrl = `${fullUrl(
      tonieboxPage?.slug || ''
    )}/?referral-code=${referralCode}`

    const text = t('referral:shareCodeMessage', {
      code: referralCode,
      tonieboxPageWithCode: referralUrl,
    })

    if (navigator.share) {
      // When device supports the share API
      try {
        await navigator.share({ text })
      } catch (error) {
        // Fallback to copy to clipboard on error, e.g. missing permissions
        navigator.clipboard.writeText(text)
        toast(t('referral:copyCodeToClipboardSuccessMessage'), 'success')
        console.error(error)
      }
    } else {
      // When device doesn't support share API
      navigator.clipboard.writeText(text)
      toast(t('referral:copyCodeToClipboardSuccessMessage'), 'success')
    }

    pushGtmV4Event({
      eventType: 'referral',
      step: 'share_code',
    })
  }, [tonieboxPage?.slug, referralCode, t, pushGtmV4Event])

  const handleCopyToClipboard = useCallback(async () => {
    navigator.clipboard.writeText(referralCode || '')
    toast(t('referral:copyCodeToClipboardSuccessMessage'), 'success')

    pushGtmV4Event({
      eventType: 'referral',
      step: 'copy_code',
    })
  }, [pushGtmV4Event, referralCode, t])

  return {
    code: isPending ? undefined : referralCode,
    handleCopyToClipboard,
    handleShare,
    isPending,
  }
}
