import React, { FunctionComponent } from 'react'
import { AuthContextInterface } from '../../../../providers/auth'
import { CartLink } from './CartLink'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import {
  MobileOnlyNavigation as ToniesUIMobileOnlyNavigation,
  MobileOnlyNavigationItem,
} from '@/tonies-ui/index'
import { WishlistLink } from './WishlistLink'
import { useWishlist } from '../../../../providers/Wishlist'
import { AccountLink } from './AccountLink'
import styled from 'styled-components'

const StyledNav = styled.nav`
  ul {
    grid-gap: 1.5rem;
    margin-left: 0;
    margin-right: 0.5rem;
  }
`

interface MobileOnlyNavigationProps {
  auth: AuthContextInterface
  isClient: boolean
  isMobile: boolean
  isMobileMenuOpen: boolean
  hasCart: boolean
}

export const MobileOnlyNavigation: FunctionComponent<
  MobileOnlyNavigationProps
> = ({ isClient, hasCart }) => {
  const { hasKeycloak } = useFeatureFlags()
  const { hasWishlist } = useWishlist()

  if (!isClient && process.env.NODE_ENV !== 'development') {
    return null
  }

  return (
    <StyledNav>
      <ToniesUIMobileOnlyNavigation>
        {hasWishlist && (
          <MobileOnlyNavigationItem>
            <WishlistLink />
          </MobileOnlyNavigationItem>
        )}
        {hasCart && (
          <MobileOnlyNavigationItem>
            <CartLink />
          </MobileOnlyNavigationItem>
        )}
        {hasKeycloak && (
          <MobileOnlyNavigationItem>
            <AccountLink />
          </MobileOnlyNavigationItem>
        )}
      </ToniesUIMobileOnlyNavigation>
    </StyledNav>
  )
}
