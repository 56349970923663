import { cssReset } from '@/tonies-ui/helpers/css-helper'
import { Button, FocusStyles, Icon } from '@boxine/tonies-ui'
import React, { Fragment, FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'

const StyledPagination = styled.ul`
  display: flex;
  justify-content: center;
  align-content: center;
  grid-gap: 0.5rem;
  * {
    line-height: 1rem;
    align-self: center;
  }
`

const StyledDots = styled.span`
  display: inline-block;
`

const StyledButton = styled(Button)`
  // FIXME: implement focusstyle in button
  ${FocusStyles}
  height: 3rem;
  width: 3rem;
  ${({ theme: { colors } }) => css`
    &:disabled {
      color: ${colors.darkergrey};
      text-decoration: none;
    }
  `}
`

const StyledArrowButton = styled.button`
  ${cssReset}
  height: 3rem;
  width: 3rem;
  cursor: pointer;
`

type Props = {
  onPageChange: (newPage: number) => void
  current: number
  max: number
  isDisabled?: boolean
}

const Pagination: FunctionComponent<Props> = ({
  onPageChange,
  current,
  max,
}) => {
  const { colors } = useTheme()
  const isPrevDisabled = !(current - 1)
  const isNextDisabled = !(max - current)

  // Create an array of numbers from 1 to max
  const numbers = Array.from(Array(max).keys()).map(number => number + 1)

  // Define a function to determine if a page number should be included in the final array
  const shouldIncludePageNumber = (pageNumber: number) => {
    // Always include the first and last page numbers
    if (pageNumber === 1 || pageNumber === max) {
      return true
    }

    // If we're on the first page, also include the third page number
    if (current === 1 && pageNumber === current + 2) {
      return true
    }

    // If we're on the last page, also include the third-to-last page number
    if (current === max && pageNumber === max - 2) {
      return true
    }

    // Include the current page number, as well as the page numbers immediately before and after it
    return pageNumber >= current - 1 && pageNumber <= current + 1
  }

  // Filter the array of numbers using the function defined above
  const entryArray = numbers.filter(shouldIncludePageNumber)

  return (
    <nav aria-label="pagination" data-testid="pagination">
      <StyledPagination>
        {!isPrevDisabled && (
          <li key="pagination-page-prev">
            <StyledArrowButton
              onClick={() => onPageChange(current - 1)}
              data-testid="arrow-left-button__pagination"
            >
              <Icon
                type="arrowLeft"
                width="1rem"
                height="1rem"
                fill={colors.primary}
              />
            </StyledArrowButton>
          </li>
        )}

        {entryArray.map((pageNumber, index) => {
          const isNextEntryNoDirectSibling =
            entryArray[index + 1] - pageNumber > 1

          return (
            <Fragment key={`pagination-page-${current}-${pageNumber}`}>
              <li>
                <StyledButton
                  data-testid="page-button__pagination"
                  aria-label={`page ${pageNumber}`}
                  aria-current={pageNumber === current ? 'page' : undefined}
                  variant="secondary"
                  onClick={() => onPageChange(pageNumber)}
                  disabled={pageNumber === current}
                >
                  {pageNumber}
                </StyledButton>
              </li>
              {isNextEntryNoDirectSibling && (
                <li>
                  <StyledDots>...</StyledDots>
                </li>
              )}
            </Fragment>
          )
        })}

        {!isNextDisabled && (
          <li key="pagination-page-next">
            <StyledArrowButton
              disabled={isNextDisabled}
              onClick={() => onPageChange(current + 1)}
              data-testid="arrow-right-button__pagination"
            >
              <Icon
                type="arrowRight"
                width="1rem"
                height="1rem"
                fill={colors.primary}
              />
            </StyledArrowButton>
          </li>
        )}
      </StyledPagination>
    </nav>
  )
}

export default Pagination
