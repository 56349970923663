import React from 'react'
import {
  Headline3,
  Paragraph,
  Button,
  Spacing,
  CTARow,
} from '@boxine/tonies-ui'
import { GenericImageText, GenericImageTextProps } from '..'
import { MediaCopySchema } from '../../../../lib/contentful/datamodel/schemas'
import { useGtmV4, withGTMV4Context } from '../../../../providers/gtmV4'
import { AddToCart } from '../../../../components/atoms/AddToCart'
import { Aggregated } from '../../../../lib/transformation/aggregatePage/aggregate'
import { useTranslation } from 'next-i18next'

export type ImageTextTeaserProps = GenericImageTextProps &
  Omit<
    Aggregated<MediaCopySchema>,
    'contentTypeId' | 'referenceTitle' | 'media'
  >

export const MediaCopyContainer = ({
  headline,
  headlineAsHTMLTag,
  media,
  mediaLink,
  orientation,
  primaryCtaAction,
  primaryCtaDownload,
  primaryCtaLabel,
  primaryCtaProduct,
  primaryCtaUrl,
  secondaryCtaLabel,
  secondaryCtaUrl,
  text,
}: ImageTextTeaserProps) => {
  const { pushGtmV4Event } = useGtmV4()
  const { t } = useTranslation()

  return (
    <GenericImageText
      media={{
        width: '35%',
        link: mediaLink ? { href: mediaLink, target: '_blank' } : undefined,
        ...media,
      }}
      orientation={orientation}
    >
      <Spacing mb="spacing-s">
        <Headline3
          asHTMLTag={headlineAsHTMLTag}
          align={orientation === 'vertical' ? 'center' : undefined}
        >
          {headline}
        </Headline3>
      </Spacing>
      {text && (
        <Spacing mb="spacing-s">
          <Paragraph
            size={2}
            align={orientation === 'vertical' ? 'center' : undefined}
          >
            {text}
          </Paragraph>
        </Spacing>
      )}
      <CTARow justifyContent={orientation === 'vertical' ? 'center' : 'start'}>
        {primaryCtaAction === 'link' && primaryCtaUrl && primaryCtaLabel && (
          <Button
            as="a"
            href={primaryCtaUrl}
            onClick={() =>
              pushGtmV4Event({
                eventType: 'selectContent',
                ctaLabel: primaryCtaLabel || '',
                ctaLink: primaryCtaUrl || '',
                type: 'primary',
              })
            }
          >
            {primaryCtaLabel}
          </Button>
        )}
        {primaryCtaAction === 'download' && primaryCtaDownload && (
          <Button as="a" icon="download" href={primaryCtaDownload.file.url}>
            {t('downloadTeaser:buttonLabel')}
          </Button>
        )}
        {primaryCtaAction === 'add-2-cart' && primaryCtaProduct && (
          <AddToCart
            product={primaryCtaProduct.normalizedProduct}
            renderPrice
            type="iconButton"
          />
        )}
        {secondaryCtaLabel && secondaryCtaUrl && (
          <Button
            as="a"
            variant="secondary"
            href={secondaryCtaUrl}
            onClick={() =>
              pushGtmV4Event({
                eventType: 'selectContent',
                ctaLabel: secondaryCtaLabel || '',
                ctaLink: secondaryCtaUrl || '',
                type: 'secondary',
              })
            }
          >
            {secondaryCtaLabel}
          </Button>
        )}
      </CTARow>
    </GenericImageText>
  )
}

export const MediaCopy = withGTMV4Context(MediaCopyContainer, 'MediaCopy')
