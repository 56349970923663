import { Box, FocusStyles, Headline4, Icon, Paragraph } from '@boxine/tonies-ui'
import { BoxProps } from '@boxine/tonies-ui/dest/types/src/atoms/Box'
import {
  CTAGeneralTypes,
  WithAsProperties,
} from '@boxine/tonies-ui/dest/types/src/atoms/Button/types'
import * as React from 'react'
import styled, { ThemeConsumer } from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { cssReset } from '@/tonies-ui/helpers/css-helper'

const StyledButton = styled.button`
  ${cssReset}
  width: 100%;
  display: block;
  cursor: pointer;

  ${FocusStyles}
`

const StyledBox = styled(Box)`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr auto;
  z-index: 1;
  width: 100%;
  align-items: center;
  overflow: clip;
`

const StyledIcon = styled(Icon)`
  pointer-events: none;
`

const InnerText = styled.span`
  display: grid;
  justify-items: baseline;
`

export type TextTeaserProps = Partial<Pick<CTAGeneralTypes, 'as'>> & {
  icon?: keyof typeof icons | React.ReactElement
  children?: React.ReactNode
  'data-testid'?: string
  headline?: string
} & BoxProps

export const TextTeaser = <T extends React.ElementType = 'button'>({
  // @ts-expect-error: FIXME `as` error
  as = 'button' as T,
  backgroundColor = 'transparent',
  borderColor = 'lightgrey',
  borderRadius = ['s'],
  children,
  headline,
  icon,
  px = 'spacing-s',
  py = 'spacing-s',
  'data-testid': dataTestId = 'text-teaser--' + backgroundColor,
  ...props
}: Partial<WithAsProperties<T, CTAGeneralTypes<T>>> &
  Partial<TextTeaserProps>) => {
  return (
    <StyledButton as={as} {...props}>
      <StyledBox
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        data-testid={dataTestId}
        asHtmlTag="span"
        px={px}
        py={py}
        borderColor={borderColor}
        {...props}
      >
        <InnerText>
          {headline && (
            <Headline4 asHTMLTag="span" weight={500}>
              <Accent text={headline} />
            </Headline4>
          )}
          {children && (
            <Paragraph asHTMLTag="span" size={2}>
              {children}
            </Paragraph>
          )}
        </InnerText>
        {icon && (
          <ThemeConsumer>
            {({ colors, colorContext }) =>
              typeof icon === 'string' && icons[icon] ? (
                <StyledIcon
                  type={icons[icon]}
                  width="1.5em"
                  height="1.5em"
                  fillAll={
                    colors[colorContext === 'light' ? 'darkergrey' : 'white']
                  }
                />
              ) : (
                icon
              )
            }
          </ThemeConsumer>
        )}
      </StyledBox>
    </StyledButton>
  )
}
