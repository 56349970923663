import React from 'react'
import { useCardNote } from '../../NormalizedProductCard/hooks/useCardNote'
import { GenericProductCardViewProps } from '../../GenericProductCard/types'
import styled, { css, useTheme } from 'styled-components'
import { useModalNotifications } from '../../../../providers/ModalNotifications'
import { TunesValidationModal } from '../../../../components/organisms/TunesValidationModal'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import { Trans, useTranslation } from 'next-i18next'
import { useCartState } from '../../../../hooks/useCartState'

const StyledButton = styled.button`
  ${({ theme: { colors, colorContext } }) =>
    css`
      ${cssReset}
      color: ${colors[colorContext === 'dark' ? 'white' : 'primary']};
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    `}
`

export const useLineItemCardNote = (
  product: NormalizedProduct
):
  | (GenericProductCardViewProps['note'] & {
      state:
        | 'invalid'
        | 'bought'
        | 'compatible'
        | NormalizedProduct['availability']['state']
    })
  | undefined => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const tunesStatus = useCartState().allTunesStatus?.getTuneStatus(product)
  const availabilityNote = useCardNote(product.availability)
  const { setModal } = useModalNotifications()

  if (tunesStatus === 'bought') {
    return {
      text: t('lineItem:tunes:alreadyBoughtNote'),
      fillColor: colors['green-20'],
      textColor: 'darkergrey',
      allowMultiline: true,
      state: tunesStatus,
    }
  }

  if (tunesStatus === 'invalid') {
    return {
      text: (
        <>
          <Trans
            i18nKey="lineItem:tunes:noCompatibleTonieNote"
            components={{
              em: <em />,
              button: (
                <StyledButton
                  data-testid="open-compatibilty-tonies-modal-button"
                  onClick={() => {
                    setModal(
                      <TunesValidationModal
                        product={product}
                        onClose={() => setModal(undefined)}
                      />
                    )
                  }}
                />
              ),
            }}
          />
        </>
      ),
      fillColor: colors['lightblue-20'],
      textColor: 'darkergrey',
      allowMultiline: true,
      state: tunesStatus,
    }
  }

  return availabilityNote
    ? { ...availabilityNote, state: product.availability.state }
    : undefined
}
