import React, { useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { media } from '@boxine/tonies-ui'
import { ProductsSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { ProductCardCarousel } from '../../../../../../components/organisms/ProductCardCarousel'
import { ProductOverviewProvider } from '../../../../../../providers/productOverview/ProductOverviewProvider'
import { ProductOverview } from '../../../../../../components/organisms/ProductOverview'
import { Button, Paragraph } from '@boxine/tonies-ui'
import { SectionTitle } from '@/tonies-ui/molecules/SectionTitle'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { IconButton } from '@/tonies-ui/atoms/IconButton'
import { useModalNotifications } from '../../../../../../providers/ModalNotifications'
import { TooltipModal } from '../../../../../../components/organisms/TooltipModal'
import { useMeasurements } from '../../../../../../providers/measurements'

const ProductCardCarouselWrapper = styled.div`
  margin: 0 -1rem;

  ${media.tablet`
    margin: 0;
  `}
`

export const CM_ProductsSchema = ({
  aggregations,
  hasFilters,
  hasSearch,
  hasSorting,
  headline,
  itemsPerPage,
  products,
  parameter,
  promotionTeasers,
  showMoreLabel,
  showMoreOnClick,
  showMoreUrl,
  stickyOffset,
  text,
  tooltip,
  total,
  topFilters,
  variant,
}: ContentModelType<unknown, ProductsSchema> & {
  showMoreOnClick?: () => void
  stickyOffset?: number
}) => {
  const { t } = useTranslation()
  const { setModal } = useModalNotifications()
  const getCardAction = useCallback((_sku: string) => 'addToCart' as const, [])
  const { mainNavigationHeight } = useMeasurements()

  const header = (headline ||
    showMoreOnClick ||
    showMoreUrl ||
    text ||
    tooltip) && (
    <>
      <SectionTitle
        title={<Accent text={headline || ''} />}
        infoButton={
          tooltip && (
            <IconButton
              icon="questionMarkFill"
              onClick={() =>
                setModal(
                  <TooltipModal
                    {...tooltip}
                    onClose={() => setModal(undefined)}
                  />
                )
              }
              iconColor="grey"
              fillColor="transparent"
              borderColor="transparent"
            />
          )
        }
        showMoreLink={
          showMoreUrl ? (
            <Button variant="secondary" as="a" href={showMoreUrl}>
              {showMoreLabel || t('Carousel:showAll')}
            </Button>
          ) : showMoreOnClick ? (
            <Button variant="secondary" onClick={showMoreOnClick}>
              {showMoreLabel || t('Carousel:showAll')}
            </Button>
          ) : undefined
        }
      >
        {text && <Paragraph>{text}</Paragraph>}
      </SectionTitle>
    </>
  )

  if (variant === 'slider') {
    return (
      <>
        {header}
        <ProductCardCarouselWrapper>
          <ProductCardCarousel
            items={products}
            promotions={promotionTeasers}
            showOutOfStock={true}
          />
        </ProductCardCarouselWrapper>
      </>
    )
  } else {
    return (
      <>
        {header}
        <ProductOverviewProvider
          productsFetchConfig={{
            api: 'openSearch',
            initialState: {
              aggregations,
              parameter,
              products,
              total,
            },
          }}
          productsPerPage={itemsPerPage}
          promotions={promotionTeasers}
          hasScrollBehaviorOnChange
        >
          <ProductOverview
            getCardAction={getCardAction}
            hasPrices
            hasFilters={hasFilters}
            hasSearch={hasSearch}
            hasSorting={hasSorting}
            /**
             * Temporary workaround until we can really use topFilters from products.
             * Until then, the TopFilters from shopLocaleConfig are used.
             */
            hasTopFilters={!!topFilters}
            stickyFilter={{
              isActive: hasFilters || hasSearch || hasSorting,
              stickyOffset: stickyOffset || mainNavigationHeight,
            }}
          />
        </ProductOverviewProvider>
      </>
    )
  }
}
