import {
  Box,
  Spacing,
  useIsViewportMobile,
  useResizeObserver,
} from '@boxine/tonies-ui'
import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import {
  ImageWithOptionalLink,
  SectionProductCopySchema,
  MediaCopySectionSchema,
  TestimonialSchema,
} from '../../../lib/contentful/datamodel/schemas'
import { Aggregated } from '../../../lib/transformation/aggregatePage/aggregate'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { WithReferenceTitle } from '../../../providers/gtmV4/types'
import { Section } from '../../atoms/Section'
import { Carousel as CarouselComp } from '../../molecules/Carousel'
import { Testimonial } from '../../molecules/Testimonial'
import styled from 'styled-components'
import { css } from 'styled-components'
import { SectionProductCopy } from '../SectionProductCopy'
import { ContentModels } from '../../../lib/contentful/datamodel/contentModels'

export type CarouselProps = {
  dataTestId?: string
  items: Aggregated<
    | MediaCopySectionSchema
    | TestimonialSchema
    | ImageWithOptionalLink
    | SectionProductCopySchema
  >[]
  hasSidePadding?: boolean
  hasMarginTop?: boolean
  isContentWidth?: boolean
  id?: string
}

const Wrapper = styled.div<{ isContentWidth: boolean }>`
  margin: 0 auto;

  ${({ isContentWidth }) =>
    isContentWidth &&
    css`
      max-width: 75rem;
    `}
`

const CarouselContainer: FunctionComponent<
  WithReferenceTitle<CarouselProps>
> = ({
  dataTestId = 'carousel',
  referenceTitle,
  items,
  hasSidePadding = false,
  hasMarginTop = false,
  isContentWidth = false,
  id,
}) => {
  const isMobile = useIsViewportMobile()
  const carouselRef = useRef<HTMLDivElement>(null)
  const [containerDimentions, setContainerDimentions] = useState<{
    width: number
    height: number
  }>()

  const backgroundColor = (
    items.find(i => 'backgroundColor' in i) as {
      backgroundColor: string
    }
  )?.backgroundColor

  const handleResize = useCallback(() => {
    if (carouselRef.current) {
      setContainerDimentions({
        height: carouselRef.current.clientHeight,
        width: carouselRef.current.clientWidth,
      })
    }
  }, [])

  useResizeObserver(handleResize, carouselRef)

  return (
    <Wrapper
      isContentWidth={isContentWidth}
      ref={carouselRef}
      data-testid={dataTestId}
      id={id}
    >
      <Box backgroundColor={backgroundColor}>
        <CarouselComp
          ariaTitle={referenceTitle || ''}
          naturalSlideWidth={320}
          visibleSlides={1}
          buttons={isMobile ? 'none' : 'center'}
          hasPagination
          isInfinite
          isIntrinsicHeight
          removeSidePadding={!hasSidePadding}
        >
          {items.map((item, i) => (
            <div key={i}>
              {item.contentTypeId === 'mediaCopySection' ? (
                <Box
                  pt={hasMarginTop ? 'spacing-xxl' : undefined}
                  key={item.contentTypeId + i}
                >
                  <ContentModels.MediaCopySection
                    {...item}
                    backgroundColor={backgroundColor}
                    componentProps={{ hasMarginTop: hasMarginTop }}
                  />
                </Box>
              ) : item.contentTypeId === 'testimonial' ? (
                <Section asHTMLTag="div" key={item.contentTypeId + i}>
                  <Spacing px={isMobile ? undefined : 'spacing-xxl'}>
                    <Testimonial {...item} />
                  </Spacing>
                </Section>
              ) : item.contentTypeId === 'sectionProductCopy' ? (
                <SectionProductCopy
                  key={item.contentTypeId + i}
                  {...item}
                  backgroundColor={undefined}
                />
              ) : item.contentTypeId === 'imageWithOptionalLink' &&
                containerDimentions ? (
                <Section
                  key={item.contentTypeId + i}
                  backgroundImage={{
                    image: item.image.file.url,
                    height: containerDimentions.height,
                  }}
                  aspectRatio={{
                    ratio:
                      (containerDimentions.height / containerDimentions.width) *
                      100,
                    alignContent: 'bottom',
                  }}
                />
              ) : null}
            </div>
          ))}
        </CarouselComp>
      </Box>
    </Wrapper>
  )
}

export const Carousel = withGTMV4Context(CarouselContainer, 'Carousel')
