import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import {
  SocialMediaLink,
  ProfileSocialNetwork,
} from '../../atoms/SocialMediaLink'

const socialMediaNetworks: ProfileSocialNetwork[] = [
  'facebook',
  'instagram',
  'pinterest',
  'twitter',
  'youtube',
  'tiktok',
]

const Wrapper = styled.div<{ gapInEm: number }>`
  display: grid;
  grid-auto-columns: repeat(auto-fill, 1fr);
  gap: ${p => p.gapInEm}em;

  > * {
    display: inline-flex;
    grid-row: 1;
  }
`

export const SocialMediaLinks: FunctionComponent<{
  iconSizeInEm: number
  iconGapInEm: number
}> = ({ iconSizeInEm, iconGapInEm }) => {
  const { t } = useTranslation()

  return (
    <Wrapper gapInEm={iconGapInEm}>
      {socialMediaNetworks
        // only display links where an URL was specified in translations
        .filter(
          socialMediaNetworkKey =>
            t(`socialmedia:${socialMediaNetworkKey}:profileUrl`).length > 0
        )
        .map(socialMediaNetworkKey => (
          <SocialMediaLink
            key={socialMediaNetworkKey}
            type="profile"
            socialNetwork={socialMediaNetworkKey}
            sizeInEm={iconSizeInEm}
          />
        ))}
    </Wrapper>
  )
}
