import React, { ComponentProps } from 'react'
import { MediaCopyCollectionSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { MediaCopyCollection } from '../../../../../../components/organisms/MediaCopyCollection'
import { CM_MediaCopySchema } from '../molecules/MediaCopySchema'

type Type = ComponentProps<typeof MediaCopyCollection>

export const CM_MediaCopyCollectionSchema = ({
  componentProps,
  headline,
  entries,
  ...rest
}: ContentModelType<Type, MediaCopyCollectionSchema>) => {
  return (
    <MediaCopyCollection
      title={headline}
      {...rest}
      entries={entries.map(entry => CM_MediaCopySchema(entry))}
      {...componentProps}
    />
  )
}
