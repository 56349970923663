import React, { useState } from 'react'
import {
  InputRow,
  FilterItem as FilterItemUiType,
  media,
  Spacing,
  Spinner,
} from '@boxine/tonies-ui'
import { useProductOverview } from '../../../providers/productOverview'
import { SupportedFilterKeys } from '../../../hooks/useProductFilter'
import { Expandable } from '../../../components/atoms/Expandable'
import styled, { useTheme } from 'styled-components'
import { CountIndicator } from '@/tonies-ui/atoms/CountIndicator'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'

const Wrapper = styled.div<{ isDisabled: boolean }>`
  max-height: 15rem;
  overflow-y: auto;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`

const StyledCoundIndicator = styled(CountIndicator)`
  scale: 1.5;

  ${media.tablet`
    scale: 1;
  `}
`

export type FilterItemType = FilterItemUiType & {
  label: string
  disabled: boolean
  // TODO: remove optional after legacy code was removed
  doc_count?: number | undefined
  options?: FilterItemType[]
}

type FilterAccordionTypes = {
  heading: string
  'data-testid': string
  'data-trackingid': string
  filterItems: FilterItemType[]
  // eslint-disable-next-line react/require-default-props
  isFilterOpen?: boolean
  filterKey: SupportedFilterKeys
  activeFilterCount?: number
}

const FilterExpandableContainer = ({
  heading,
  'data-testid': dataTestId,
  'data-trackingid': dataTrackingId,
  filterItems,
  isFilterOpen = false,
  filterKey,
  activeFilterCount = 0,
}: FilterAccordionTypes): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(isFilterOpen)
  const { filter, search, isPending } = useProductOverview()
  const { colors } = useTheme()
  const { pushGtmV4Event } = useGtmV4()

  return (
    <Expandable
      headline={heading}
      data-testid={dataTestId}
      isExpanded={isOpen}
      borderColor={isOpen ? colors['green-100'] : undefined}
      onToggle={() => setIsOpen(curr => !curr)}
      rightSlot={
        <StyledCoundIndicator
          count={activeFilterCount}
          statusMessage={`${activeFilterCount} filter active`}
          isAnimated
          dataTestId="filter-expandable-count"
        />
      }
      stateSlot={isPending && <Spinner height={16} />}
    >
      <Wrapper isDisabled={isPending}>
        {filterItems.map(
          lvl1 =>
            !lvl1.disabled && (
              <>
                <InputRow
                  key={`lvl1-${lvl1.id}-${lvl1.name}`}
                  id={lvl1.id}
                  isChecked={lvl1.value}
                  onChange={() => {
                    const newFilters = filter?.onChangeAll(
                      filterKey,
                      !lvl1.value,
                      lvl1.id
                    )

                    if (newFilters) {
                      pushGtmV4Event({
                        eventType: 'search',
                        filters: newFilters,
                        searchTerm: search?.value || '',
                      })
                    }
                  }}
                  dataTestId={`${dataTestId}-item`}
                  data-trackingid={dataTrackingId}
                >
                  {lvl1.label}
                  {lvl1.doc_count && !filter?.isFilterActive
                    ? ` (${lvl1.doc_count})`
                    : null}

                  {isPending && (
                    <Spacing ml="spacing-xxs" asHtmlTag="span">
                      <Spinner height={12} />
                    </Spacing>
                  )}
                </InputRow>
                {lvl1.options?.map(lvl2 => (
                  <Spacing key={`lvl2-${lvl2.id}-${lvl2.name}`} ml="spacing-s">
                    <InputRow
                      id={lvl2.id}
                      isChecked={lvl2.value}
                      onChange={() => {
                        const newFilters = filter?.onChangeAll(
                          filterKey,
                          !lvl2.value,
                          lvl2.id
                        )

                        if (newFilters) {
                          pushGtmV4Event({
                            eventType: 'search',
                            filters: newFilters,
                            searchTerm: search?.value || '',
                          })
                        }
                      }}
                      dataTestId={`${dataTestId}-item`}
                      data-trackingid={dataTrackingId}
                    >
                      {lvl2.label}
                      {lvl2.doc_count && !filter?.isFilterActive
                        ? ` (${lvl2.doc_count})`
                        : null}
                    </InputRow>
                  </Spacing>
                ))}
              </>
            )
        )}
      </Wrapper>
    </Expandable>
  )
}

export const FilterExpandable = withGTMV4Context(
  FilterExpandableContainer,
  'FilterExpandable'
)
