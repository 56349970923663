import { cart, checkmark } from '@boxine/tonies-ui/icons'
import { useCallback, useState } from 'react'
import { useTheme } from 'styled-components'
import { useCartActions } from '../../../../hooks/useCartActions'
import { addProductAction } from '../../../../providers/cartActions/actions/addProductAction'
import { useGtmV4 } from '../../../../providers/gtmV4'
import { GenericProductCardProps } from '../../GenericProductCard/types'
import { NormalizedProductCardProps } from '../types'
import { useCartState } from '../../../../hooks/useCartState'
import { useProductConfirmation } from '../../../../hooks/useProductConfirmation'
import { useProductValidation } from '../../../../hooks/useProductValidation'
import { useHouseholdTunesData } from '../../../../providers/auth'
import { useShopLocaleConfig } from '../../../../hooks/useShopLocaleConfig'
import { useTranslation } from 'next-i18next'
import { toast } from '@/tonies-ui/atoms/Toast'
import { useRecommendations } from '../../../../providers/recommendations'

export const useNormalizedProductCardAction = ({
  action,
  actionAdd2CartCallback = () => Promise.resolve(),
  product,
  validate = true,
}: Pick<
  NormalizedProductCardProps,
  'action' | 'isSelected' | 'product' | 'actionAdd2CartCallback' | 'validate'
>): GenericProductCardProps['action'] => {
  const { push: queue } = useCartActions()
  const shopConfig = useShopLocaleConfig()
  const { isMyTune } = useHouseholdTunesData(product.salesId)
  const { isItemInCart } = useCartState()
  const confirmProduct = useProductConfirmation()
  const validateProduct = useProductValidation()
  const [isPending, setIsPending] = useState(false)
  const {
    colors,
    colors: { white, primary },
  } = useTheme()
  const { pushGtmV4Event } = useGtmV4()
  const { t } = useTranslation()
  const { handleSetLastSeenSku } = useRecommendations()

  const isProductAvailable =
    product.availability.state === 'orderable' ||
    product.availability.state === 'preorderable'

  const isInCart = isItemInCart(product.sku)

  const handleAddToCart = useCallback(() => {
    return confirmProduct(product).then(({ product, custom }) => {
      setIsPending(true)
      handleSetLastSeenSku(product.sku)
      queue(
        addProductAction({
          product,
          quantity: 1,
          custom,
          pushGtmV4Event,
        })
      ).then(isSuccessful => {
        if (isSuccessful) {
          if (validate) {
            validateProduct(product)
          }

          toast(t('cart:toast.addedToCart'), 'success')

          actionAdd2CartCallback().finally(() => {
            setIsPending(false)
          })
        } else {
          setIsPending(false)
        }
      })
    })
  }, [
    product,
    handleSetLastSeenSku,
    queue,
    pushGtmV4Event,
    validate,
    validateProduct,
    actionAdd2CartCallback,
    confirmProduct,
    t,
  ])

  if (!action) {
    // no action available
    return undefined
  } else if (isMyTune) {
    return undefined
  } else if (action === 'toggleSelection') {
    if (isProductAvailable) {
      return action
    } else {
      // hide toggleSelection if product is unavailable
      return undefined
    }
  } else if (isInCart === undefined && product.maxQuantity === 1) {
    return {
      borderColor: colors['normalgrey'],
      dataTestId: 'add-to-cart-button--pending',
      fillColor: colors['normalgrey'],
      iconColor: white,
      icon: checkmark,
      isPending: true,
    }
  } else if (isInCart && product.maxQuantity === 1) {
    return {
      borderColor: colors['green-100'],
      dataTestId: 'already-in-cart',
      fillColor: colors['green-100'],
      iconColor: white,
      icon: checkmark,
      isPending,
    }
  } else if (action === 'addToCart') {
    if (isProductAvailable) {
      // FIXME: remove after Audio-Content golive
      const isDisabled =
        product.shopCategory === 'tunes' && !shopConfig?.showTunes

      return {
        borderColor: primary,
        dataTestId: 'add-to-cart-button',
        fillColor: primary,
        iconColor: white,
        icon: cart,
        isPending,
        onClick: isDisabled ? () => null : handleAddToCart,
        isDisabled,
      }
    } else {
      // hide addToCart if product is unavailable
      return undefined
    }
  } else {
    // custom action
    return {
      dataTestId: 'custom-card-action',
      isPending,
      ...action,
    }
  }
}
