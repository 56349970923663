import styled from 'styled-components'

export const Content = styled.div`
  align-content: center;
  display: grid;
  gap: 1rem;
`

export const IconWrapper = styled.div`
  height: 1.25rem;
`

export const StyledButtonWrapper = styled.div`
  display: grid;
`
