import React, { FunctionComponent } from 'react'
import Lottie from 'lottie-react'
import styled, { css, useTheme } from 'styled-components'
import {
  useMedia,
  media as mediaTheme,
  useIsViewportMobile,
  Spacing,
  Grid,
  GridArea,
  CloudinaryVideoPlayer,
  useIsViewportTablet,
} from '@boxine/tonies-ui'
import { MediaCopyViewProps } from './types'
import {
  ButtonArea,
  VideoArea,
  VideoPlayer,
  VideoWrapper,
} from '@/tonies-ui/molecules/VideoPlayer'
import { VideoArea as CloundinaryVideoArea } from '@/tonies-ui/molecules/CloudinaryVideoPlayer'
import { VideoInModal } from '@/tonies-ui/molecules/VideoInModal'
import { ColorContextProvider } from '@/tonies-ui/index'
import { motion } from 'framer-motion'
import { Curve } from '@/tonies-ui/atoms/Curve'
import { ToniesMotions } from '@/tonies-ui/motions'
import { Image } from '@/tonies-ui/atoms/Image'

const STAGE_CONTENT_MIN_HEIGHT = '32rem'
const STAGE_HEADER_PADDING = '90px'
const STAGE_INNER_PADDING = '5rem'

const StyledAutoplayVideo = styled.video``

const BelowHeader = styled.div<
  Pick<
    MediaCopyViewProps,
    'hasMarginTop' | 'paddingTop' | 'mediaPositionMobile' | 'layout'
  >
>`
  ${({ hasMarginTop, paddingTop, mediaPositionMobile, layout }) => {
    const padding = hasMarginTop ? paddingTop || STAGE_HEADER_PADDING : '0rem'
    const height = `calc(${STAGE_CONTENT_MIN_HEIGHT} + ${padding})`

    return css`
      position: relative;
      max-width: 200rem;
      margin: 0 auto;

      ${hasMarginTop &&
      css`
        margin-top: -${padding};
        padding-top: ${mediaPositionMobile === 'top' && layout === 'half-screen'
          ? 0
          : padding};
      `}

      ${mediaTheme.tabletL`
        min-height: ${height};
        display: grid;
      `}
    `
  }}
`
const BackgroundWrapper = styled(motion.div)<{
  $backgroundColor: MediaCopyViewProps['backgroundColor']
}>`
  ${({ $backgroundColor = 'transparent' }) => {
    return css`
      background-color: ${$backgroundColor};
    `
  }}
`

const AbsoluteMediaWrapper = styled.div<MediaCopyViewProps>`
  ${({ layout = 'half-grid', media, direction }) => {
    return css`
      align-items: center;
      display: flex;
      height: 100%;
      margin: 0 auto;
      max-height: 100%;
      max-width: 80rem;
      position: relative;
      overflow: hidden;
      justify-content: ${!media?.positionX
        ? layout === 'half-grid'
          ? direction === 'ltr'
            ? 'right'
            : 'left'
          : 'center'
        : media.positionX};

      ${StyledAutoplayVideo},
      /* TODO: Temporary, dirty work-around!! 
      Reuse <StyledImage /> Image as soon as the component is migrated */
      [data-testid="media--image__image"] {
        max-width: fit-content;
        max-height: 100%;
        height: auto;
        width: 100%;
        align-self: ${media?.positionY || 'center'};

        ${mediaTheme.tabletL`
          width: auto;

          ${
            layout === 'half-grid' &&
            css`
              max-width: ${media?.scaling || 100}%;
            `
          }
        `}
        ${layout === 'half-screen' &&
        css`
          height: 100%;
          max-width: none;

          ${direction === 'ltr'
            ? css`
                margin-left: auto;
                right: 0;
              `
            : css`
                margin-right: auto;
              `}
        `}
      }

      /* VIDEO PLAYER SUPPORT OVERWRITES */
      ${VideoWrapper} {
        margin-bottom: -1.5rem;
        width: 100%;
      }

      ${layout === 'half-screen' &&
      css`
        ${VideoArea}, ${VideoWrapper}, ${CloundinaryVideoArea} {
          position: static;
        }

        ${ButtonArea} {
          bottom: -2rem;
        }
      `}

      ${mediaTheme.tabletL`
        position: absolute;
        width: 50%;
        bottom:0;
        top: 0;

        ${
          direction === 'rtl'
            ? css`
                left: 0;
              `
            : css`
                right: 0;
              `
        }

        // IMAGE SUPPORT OVERWRITE
        // @FIXME: workaround - need fix class for NextJS build
        .image__container {
          height: 100%;
          display: flex;
          justify-content: ${
            !media?.positionX
              ? layout === 'half-grid'
                ? direction === 'ltr'
                  ? 'right'
                  : 'left'
                : 'center'
              : media.positionX
          };
          align-items: ${!media?.positionY ? 'end' : media?.positionY}
        }
      `}
    `
  }}
`

const Wrapper = styled.div<{
  isFullWidth: boolean
  isCentered?: boolean
  mediaPositionMobile: 'top' | 'bottom'
}>`
  ${({ isFullWidth, isCentered, mediaPositionMobile }) => {
    return css`
      margin: 0 auto;
      width: 100%;
      display: grid;
      max-width: 75rem;
      padding: ${mediaPositionMobile === 'top' ? '0' : '2rem'} 0
        ${mediaPositionMobile === 'bottom' ? '0' : '2rem'};

      ${mediaTheme.tabletL`
        min-height: calc(${STAGE_CONTENT_MIN_HEIGHT} - ${STAGE_INNER_PADDING});
        padding: 2rem 0;
      `}

      ${!isFullWidth && `position: relative;`}
      ${isCentered && `text-align: center;`}
    `
  }}
`

// choose Media
export const Media = ({ media }: Pick<MediaCopyViewProps, 'media'>) => {
  const isMobile = useIsViewportMobile()
  const isTablet = useIsViewportTablet()
  if (!media) return null

  const currentMedia = isMobile ? media.mobile : media.desktop

  // SupportedMedia: add any favorite MediaComponent confguration here
  switch (currentMedia.variant) {
    case 'image':
      return (
        <Image
          // needed for CSS overwrite (@FIXME temporary, dirty work-around!!)
          // please dont change this data-testid!
          dataTestId="media--image"
          crop={currentMedia.crop || 'fill'}
          responsive={[400, 600, 1000, 2000]}
          {...currentMedia}
        />
      )
    case 'video--autoplay':
      return (
        <StyledAutoplayVideo
          data-testid="media--video--autoplay"
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          src={currentMedia.src}
        />
      )
    case 'videoPlayer':
      return (
        <VideoPlayer
          data-testid="media--videoPlayer"
          srcDesktop={currentMedia.src}
          crop={currentMedia.crop}
          {...currentMedia}
        />
      )
    case 'videoPlayer--cloudinary':
      return (
        <CloudinaryVideoPlayer
          {...currentMedia}
          videoAlt={currentMedia.alt}
          cloudinaryPublicId={currentMedia.src}
        />
      )
    case 'lottie':
      return (
        <Lottie
          {...currentMedia}
          // FIXME: Styled(Lottie) do not work
          style={{
            display: 'grid',
            width: '100%',
            maxWidth: isMobile || isTablet ? '100%' : '37.5rem',
            maxHeight: '100%',
            height: 'auto',
          }}
        />
      )
    default:
      return null
  }
}

export const MediaCopyView: FunctionComponent<MediaCopyViewProps> = ({
  children,
  backgroundColor,
  media,
  layout = 'half-grid',
  hasMarginTop,
  mediaPositionMobile = 'bottom',
  'data-testid': dataTestId = 'MediaCopy',
  direction = 'ltr',
  paddingTop,
  isContentCentered,
  videoInModal,
  hasCurve = false,
  nextSectionColor = 'transparent',
  id,
}) => {
  const theme = useTheme()
  const isMobile = useMedia({ maxWidth: theme.screenTabletL })
  const isFullWidth = layout === 'half-screen'

  return (
    <ColorContextProvider backgroundColor={backgroundColor}>
      <BackgroundWrapper
        data-testid={dataTestId}
        $backgroundColor={backgroundColor}
        id={id}
      >
        <BelowHeader
          hasMarginTop={hasMarginTop}
          paddingTop={paddingTop}
          mediaPositionMobile={mediaPositionMobile}
        >
          <Wrapper
            data-testid={`${dataTestId}__wrapper--${layout}`}
            isFullWidth={isFullWidth}
            isCentered={isContentCentered}
            mediaPositionMobile={mediaPositionMobile}
          >
            <Grid hasGapH columns={isMobile ? 1 : 2}>
              <GridArea
                colStart={isMobile ? undefined : direction === 'ltr' ? 0 : 1}
                rowStart={
                  !isMobile ? undefined : mediaPositionMobile === 'top' ? 1 : 0
                }
                contentAlignV="center"
              >
                <Spacing
                  mx="spacing-s"
                  mt={
                    isMobile && mediaPositionMobile === 'top'
                      ? 'spacing-m'
                      : undefined
                  }
                  mb={
                    isMobile && mediaPositionMobile === 'bottom'
                      ? 'spacing-m'
                      : undefined
                  }
                >
                  {children}
                </Spacing>
              </GridArea>
              <GridArea
                colStart={isMobile ? undefined : direction === 'ltr' ? 1 : 0}
                rowStart={
                  !isMobile ? undefined : mediaPositionMobile === 'top' ? 0 : 1
                }
              >
                {media && (
                  <AbsoluteMediaWrapper
                    layout={layout}
                    media={media}
                    direction={direction}
                  >
                    <ToniesMotions.ZoomIt
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      initial={isFullWidth ? 'zoomIn' : 'zoomOut'}
                      viewport={{ amount: 0.4 }}
                    >
                      <Media media={media} />
                    </ToniesMotions.ZoomIt>
                    {videoInModal && (
                      <VideoInModal direction={direction} {...videoInModal} />
                    )}
                  </AbsoluteMediaWrapper>
                )}
              </GridArea>
            </Grid>
          </Wrapper>
        </BelowHeader>
        {hasCurve && (
          <Curve
            color={backgroundColor}
            backgroundColor={nextSectionColor}
            layout={direction === 'ltr' ? 'bottomLeft' : 'bottomRight'}
            size="small"
            isInverted={
              !!videoInModal ||
              (isMobile && mediaPositionMobile === 'bottom') ||
              layout === 'half-screen'
            }
          />
        )}
      </BackgroundWrapper>
    </ColorContextProvider>
  )
}
