import React, { FunctionComponent } from 'react'
import { Icon } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { useTheme } from 'styled-components'
import { ProcessStep } from '../types'

export type StateIconProps = {
  state: ProcessStep['state']
}

const SIZE = '1.25rem'

export const StateIcon: FunctionComponent<StateIconProps> = ({ state }) => {
  const theme = useTheme()

  if (state === 'complete') {
    return (
      <Icon
        type={icons.checkmark}
        fill={theme.colors['green-100']}
        height={SIZE}
        width={SIZE}
      />
    )
  } else if (state === 'error') {
    return (
      <Icon
        type={icons.exclamationMark}
        fill={theme.colors.primary}
        height={SIZE}
        width={SIZE}
      />
    )
  } else {
    return null
  }
}
