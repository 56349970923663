import {
  Box,
  Button,
  CTARow,
  Headline2,
  ImageV2,
  Modal,
  Paragraph,
  Spacing,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import { toast } from '@/tonies-ui/atoms/Toast'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { IconButtonProps } from '@/tonies-ui/atoms/IconButton'
import { HorizontalScrollList } from '@/tonies-ui/molecules/HorizontalScrollList'
import { GenericProductCard } from '../../../../../components/molecules/GenericProductCard'
import { CompatibleTonie } from '../../../../../lib/cloudservices/requests/rest/checkTuneStatus'
import { handleAssignAudioContent } from '../../../../../lib/cloudservices/requests/rest/handleAssignAudioContent'
import { ImageWrapper } from '../../../../../layouts/AccountLayout/styles'
import { useOwnAudioContentContext } from '../../../../../providers/ownAudioContent/OwnAudioContext'
import { sentryWithExtras } from '../../../../../hooks/useSentry'
import { useGtmV4, withGTMV4Context } from '../../../../../providers/gtmV4'

const ScrollListWrapper = styled.div`
  ul {
    height: 100%;
    padding-bottom: 1.5rem;
  }
`

const AssignModalContentContainer = ({
  tonies,
  tune,
  tuneId,
  setIsAssignModalOpen,
  setHasAssignSuccess,
}: {
  tonies: CompatibleTonie[]

  tune: NormalizedProduct
  tuneId: string
  setIsAssignModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setHasAssignSuccess: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const [isResetWarningModalOpen, setIsResetWarningModalOpen] = useState(false)
  const theme = useTheme()
  const [isTuneAssigned, setIsTuneAssigned] = useState(false)
  const [currentlySelectedTonie, setCurrentlySelectedTonie] = useState<
    CompatibleTonie | undefined
  >(undefined)
  const { pushGtmV4Event } = useGtmV4()
  const { updateAssignedContentList, assignStatus } =
    useOwnAudioContentContext()
  const cloudServicesApiUrl = process.env.NEXT_PUBLIC_CLOUDSERVICES_API_URL
  const assignUrl =
    currentlySelectedTonie &&
    `${cloudServicesApiUrl}/households/${currentlySelectedTonie.householdId}/tonie/${currentlySelectedTonie.id}/tune/${tuneId}`

  const onAssignSuccess = () => {
    setIsTuneAssigned(true)
    setHasAssignSuccess(true)
    setIsResetWarningModalOpen(false)
    pushGtmV4Event({
      eventType: 'audioContent',
      item: tune,
      step: 'assign_succeeded',
    })
    toast(t('ownAudioContent:AssignSuccessHeadline'), 'success', {
      toastId: 'assign-success-message',
    })
  }
  const isMobile = useIsViewportMobile()

  const onAssignError = (error?: never) => {
    toast(`${t('default:TOASTSomethingWentWrong')}`, 'error', {
      autoClose: false,
      toastId: 'assignment-error',
    })
    sentryWithExtras(
      'assignment-error',
      error ? error : new Error('Assignment call to cloudservices API failed.')
    )
    console.log(error)
  }

  function handleClose() {
    setIsTuneAssigned(false)
    setHasAssignSuccess(false)
    setIsAssignModalOpen(false)
    setIsResetWarningModalOpen(false)
  }
  const topRightIconSlot = (tonie: CompatibleTonie) => {
    // FIXME: This is a placeholder to avoid smaller spacing to top if no tune is assigned
    let iconSlot: IconButtonProps | undefined = {
      icon: 'tuneFill',
      iconColor: theme.colors.white,
      size: 'large',
    }
    if (
      assignStatus &&
      assignStatus.length > 0 &&
      assignStatus.some(item => item.assignedTonieId === tonie.id)
    ) {
      iconSlot = {
        icon: 'tuneFill',
        iconColor: theme.colors['green-100'],
        size: 'large',
      }
    }
    return iconSlot
  }

  function handleAssignClick(tonie: CompatibleTonie) {
    if (
      assignStatus &&
      assignStatus.length > 0 &&
      assignStatus.some(item => item.assignedTonieId === tonie.id)
    ) {
      setCurrentlySelectedTonie(tonie)
      setIsResetWarningModalOpen(true)
      pushGtmV4Event({
        eventType: 'audioContent',
        item: tune,
        step: 'tonie_chosen',
      })
      return
    }

    setCurrentlySelectedTonie(tonie)
    pushGtmV4Event({
      eventType: 'audioContent',
      item: tune,
      step: 'tonie_chosen',
    })
    handleAssignAudioContent({
      assignUrl: `${cloudServicesApiUrl}/households/${tonie.householdId}/tonie/${tonie.id}/tune/${tuneId}`,
      onSuccess: onAssignSuccess,
      onError: onAssignError,
    })
    if (updateAssignedContentList && tune) {
      updateAssignedContentList(tune.sku, tonie.id, tune.name)
    }
  }

  return (
    <>
      <div data-testid="assign-modal-inner">
        <div style={{ minWidth: 0 }}>
          <Spacing p="spacing-s">
            {!isTuneAssigned && (
              <ScrollListWrapper>
                <HorizontalScrollList
                  columns={isMobile ? 1.5 : 2.75}
                  scrollByOne
                >
                  {tonies &&
                    tonies.map(tonie => (
                      <GenericProductCard
                        key={tonie.id}
                        topRightIconSlot={topRightIconSlot(tonie)}
                        image={{
                          src: tonie.imageUrl,
                          alt: tonie.title,
                          variant: 'coverCard',
                        }}
                        name={tonie.title}
                        orientation="portrait"
                        action={{
                          borderColor: theme.colors.primary,
                          dataTestId: 'assign-tune-to-tonie-button',
                          fillColor: theme.colors.primary,
                          icon: 'assign',
                          iconColor: 'white',
                          cursor: 'pointer',
                          onClick: () => {
                            handleAssignClick(tonie)
                          },
                          isDisabled: assignStatus.some(
                            item =>
                              item.assignedTonieId === tonie.id &&
                              item.sku === tune.sku
                          ),
                        }}
                        note={
                          assignStatus.some(
                            item =>
                              item.assignedTonieId === tonie.id &&
                              item.sku === tune.sku
                          )
                            ? {
                                fillColor: theme.colors['berry-20'],
                                text: t(
                                  'ownAudioContent:AlreadyAssignedToSameTonieHint'
                                ),
                              }
                            : undefined
                        }
                      />
                    ))}
                </HorizontalScrollList>
              </ScrollListWrapper>
            )}
            {isTuneAssigned && (
              <div>
                <Box mb="spacing-s">
                  <Headline2>
                    {t('ownAudioContent:AssignSuccessHeadline')}
                  </Headline2>
                  <ImageWrapper>
                    <ImageV2
                      src="https://images.ctfassets.net/l6vo76cztcax/6kQdKh3WfOrvg5t1m2uNve/619414c64d308b90dfb50a5023f66674/toniebox-refresh.svg"
                      alt="doodle-toniebox-refresh"
                    />
                  </ImageWrapper>
                  <Paragraph>
                    {t('ownAudioContent:AssignSuccessCopy')}
                  </Paragraph>
                </Box>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleClose}
                  data-testid="assign-success-close-modal"
                >
                  {t('ownAudioContent:AssignModalContinue')}
                </Button>
              </div>
            )}
          </Spacing>
        </div>
      </div>
      <Modal
        isOpen={isResetWarningModalOpen}
        onClose={() => setIsResetWarningModalOpen(false)}
        headline={t(
          'ownAudioContent:TonieIsCurrentlyHoldingOtherContentHeadline'
        )}
        footer={
          <Spacing mt="spacing-xxl">
            <CTARow justifyContent="space-around" flexDirection="row-reverse">
              <Button
                variant="secondary"
                onClick={() => setIsResetWarningModalOpen(false)}
              >
                {t('navigation:goBack')}
              </Button>
              <Button
                data-testid="change-tonie-confirm"
                variant="primary"
                onClick={() => {
                  if (!currentlySelectedTonie) {
                    return
                  }
                  handleAssignAudioContent({
                    assignUrl: assignUrl,
                    onSuccess: onAssignSuccess,
                    onError: onAssignError,
                  })
                  if (
                    updateAssignedContentList &&
                    tune &&
                    currentlySelectedTonie
                  ) {
                    updateAssignedContentList(
                      tune.sku,
                      currentlySelectedTonie.id,
                      tune.name
                    )
                  }
                }}
              >
                {t('ownAudioContent:AssignModalContinue')}
              </Button>
            </CTARow>
          </Spacing>
        }
      >
        <Paragraph data-testid="change-tonie-warning">
          <Trans
            i18nKey="ownAudioContent:TonieIsCurrentlyHoldingOtherContentCopy"
            components={{ b: <b /> }}
            values={{
              tonieTitle: currentlySelectedTonie?.title || '',
              episodeName:
                assignStatus.find(
                  item => item.assignedTonieId === currentlySelectedTonie?.id
                )?.assignedTuneTitle || '',
            }}
          />
        </Paragraph>
      </Modal>
    </>
  )
}

export const AssignModalContent = withGTMV4Context(
  AssignModalContentContainer,
  'AssignModal'
)
