import { toniesSpringTransition } from '@/tonies-ui/motions/constants'
import { motion } from 'framer-motion'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

export type TranslucentOverlayProps = {
  onClick?: () => void
}

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const StyledDiv = styled(motion.div)`
  backdrop-filter: blur(0.5rem);
  background: ${({ theme: { colors } }) =>
    colors['darkgrey-50']}; // translucent
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`

export const TranslucentOverlay = ({ onClick }: TranslucentOverlayProps) => {
  return (
    <>
      <GlobalStyle />
      <StyledDiv
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={toniesSpringTransition}
        onClick={onClick}
      />
    </>
  )
}
