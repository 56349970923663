import React, { useEffect, useRef, useState, useContext } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { ImageProps } from './types'
import { StyledContainer, StyledImage } from './styles'
import {
  ERROR_SRC,
  IMAGE_SIZES,
  IMAGE_WIDTH,
  getHeightByRatioWidth,
  getActualValue,
  throttle,
} from './lib'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import { useInView } from 'framer-motion'

export const ImageCtx = React.createContext({
  backend: cloudinaryUrl,
})

export const Image = ({
  dataTestId = 'image',
  src,
  ratio,
  alt,
  title,
  responsive = IMAGE_SIZES,
  background,
  crop,
  placeholder = true,
  imageBackend: imageOverwriteBackend,
  onLoad,
  onError,
  borderRadius,
}: ImageProps) => {
  // Allow users to overwrite the backend on an image basis. The majority
  // is expected to rely on a global backend provider that's set
  // via context.
  const imageCtx = useContext(ImageCtx)
  const imageBackend = imageOverwriteBackend || imageCtx.backend

  const refImage = useRef<HTMLImageElement>(null)
  const refContainer = useRef<HTMLDivElement>(null)

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(placeholder)
  const [didLoad, setDidLoad] = useState(!placeholder)

  const [cssImageWidth, setCssImageWidth] = useState(IMAGE_WIDTH)

  const width = responsive
    ? getActualValue(cssImageWidth, responsive)
    : cssImageWidth
  const height = getHeightByRatioWidth({ width, ratio })

  const imgPlaceholder = imageBackend({
    src,
    background,
    crop,
    width: IMAGE_WIDTH,
    height: getHeightByRatioWidth({
      width: IMAGE_WIDTH,
      ratio,
    }),
    quality: 30,
    isPlaceholder: true,
  })

  const imgNormal = imageBackend({
    src,
    background,
    crop,
    width,
    height,
    quality: 'auto',
  })

  let imageSource = placeholder ? imgPlaceholder : imgNormal

  const inViewport = useInView(refImage)
  const showHighRes = !isError && (didLoad || inViewport)

  useEffect(() => {
    // Once we've loaded the highres version we'll keep displaying
    // that instead of falling back to the placeholder version when
    // the image moves out of the viewport again.
    if (showHighRes && !isLoading) {
      setDidLoad(true)
    }
  }, [showHighRes, isLoading])

  useEffect(() => {
    let isMounted = true
    const container = refContainer.current
    if (!container) return

    function updateContainer() {
      // Use `isMounted` check to avoid state updates after
      // the component is destroyed
      if (!container || !isMounted) return

      const cssWidth = container.offsetWidth
      // Ignore if size is 0 in tests
      if (cssWidth > 0) {
        setCssImageWidth(cssWidth)
      }
    }

    // Trigger initial size calculation
    updateContainer()

    const observer = new ResizeObserver(throttle(updateContainer, 200))
    observer.observe(container)

    return () => {
      isMounted = false
      if (container) observer.unobserve(container)
    }
  }, [ratio, responsive])

  useEffect(() => {
    if (!src || !/^(?:https?:)?\/\/|\.svg$/.test(src)) {
      setIsError(true)

      if (process.env.NODE_ENV === 'development') {
        console.warn(
          `Image: the src '${src}' is not a valid image src. Image src must start with 'https://', 'http://' or '//`
        )
      }
    }
  }, [src])

  // An image may be done loading before the load event is attached.
  // This can be checked via `img.complete`.
  // See https://stackoverflow.com/questions/39777833/
  useEffect(() => {
    const img = refImage.current
    if (!img) return

    if (!isError && showHighRes && img.complete) {
      setIsLoading(false)
    }
  }, [isError, showHighRes])

  let srcSet: string | undefined = undefined

  if (showHighRes) {
    imageSource = imgNormal
    const x2 = imageBackend({
      src,
      background,
      crop,
      width: Math.round(width * 2),
      height: height ? Math.round(height * 2) : undefined,
      quality: 60,
    })
    srcSet = `${imageSource} 1x, ${x2} 1.49x`
  }

  return (
    <StyledContainer
      /**
       * Workaround className
       *
       * needed to reference in MediaCopyView
       * import/usage like ${StyledContainer} {} doesn't work after NextJS builds
       */
      className="image__container"
      data-testid={`${dataTestId}__container`}
      isLoading={!isError && !didLoad}
      isError={isError}
      ref={refContainer}
    >
      <StyledImage
        data-testid={`${dataTestId}__image`}
        ref={refImage}
        src={isError ? ERROR_SRC : imageSource}
        title={title}
        srcSet={srcSet}
        onLoad={e => {
          if (
            !placeholder ||
            (showHighRes && e.currentTarget.srcset === srcSet)
          ) {
            setIsLoading(false)
            if (onLoad) onLoad()
          }
        }}
        onError={() => {
          setIsError(true)
          if (onError) onError()
        }}
        alt={alt}
        width={width}
        height={height}
        borderRadius={borderRadius}
      />
    </StyledContainer>
  )
}
