import React, { ComponentProps, FunctionComponent, useCallback } from 'react'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { MediaCopySection as MediaCopySectionUI } from '@/tonies-ui/organisms/MediaCopySection'
import { useTranslation } from 'next-i18next'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { useLocale } from '../../../providers/locale'
import { homepageUrl } from '../../../utils/urlHelpers'

const MediaCopySectionComponent: FunctionComponent<
  ComponentProps<typeof MediaCopySectionUI>
> = ({ children, actions, videoInModal, headline, media, ...rest }) => {
  const { pushGtmV4Event } = useGtmV4()
  const { t } = useTranslation()
  const lcCC = useLocale()

  const handlePrimaryClick = useCallback(() => {
    pushGtmV4Event({
      eventType: 'selectContent',
      ctaLabel: actions?.primary?.text || '',
      ctaLink: actions?.primary?.url || '',
      type: 'primary',
    })

    if (actions?.primary?.onClick) actions?.primary?.onClick()
  }, [actions, pushGtmV4Event])

  const handleSecondaryClick = useCallback(() => {
    pushGtmV4Event({
      eventType: 'selectContent',
      ctaLabel: actions?.secondary?.text || '',
      ctaLink: actions?.secondary?.url || '',
      type: 'secondary',
    })

    if (actions?.secondary?.onClick) actions?.secondary?.onClick()
  }, [actions, pushGtmV4Event])

  const handleVideoInModalClick = useCallback(() => {
    pushGtmV4Event({
      eventType: 'selectContent',
      ctaLabel: t('videoInModal:text'),
      ctaLink: '/',
      type: 'open-modal',
    })

    if (videoInModal?.onClick) videoInModal?.onClick()
  }, [videoInModal, t, pushGtmV4Event])

  return (
    <MediaCopySectionUI
      {...rest}
      media={
        media
          ? {
              ...media,
              desktop:
                media.desktop.variant === 'videoPlayer--cloudinary'
                  ? { ...media.desktop, logoOnclickUrl: homepageUrl(lcCC) }
                  : { ...media.desktop },
              mobile:
                media.mobile.variant === 'videoPlayer--cloudinary'
                  ? { ...media.mobile, logoOnclickUrl: homepageUrl(lcCC) }
                  : { ...media.mobile },
            }
          : undefined
      }
      headline={
        headline?.children
          ? {
              ...headline,
              children:
                typeof headline.children === 'string' ? (
                  <Accent text={headline.children} />
                ) : (
                  headline.children
                ),
            }
          : undefined
      }
      actions={{
        primary: { ...actions?.primary, onClick: handlePrimaryClick },
        secondary: { ...actions?.secondary, onClick: handleSecondaryClick },
      }}
      videoInModal={
        videoInModal
          ? {
              ...videoInModal,
              onClick: handleVideoInModalClick,
              videoPlayer: {
                ...videoInModal.videoPlayer,
                logoOnclickUrl: homepageUrl(lcCC),
              },
            }
          : undefined
      }
    >
      {children}
    </MediaCopySectionUI>
  )
}

export const MediaCopySection = withGTMV4Context(
  MediaCopySectionComponent,
  'MediaCopySection'
)
