import { useTheme } from 'styled-components'
import React, { FunctionComponent } from 'react'
import {
  CTARow,
  useMedia,
  Paragraph,
  Spacing,
  Button,
  HeadlineChooser,
} from '@boxine/tonies-ui'
import { MediaCopyView } from './MediaCopySectionView'
import { MediaCopySectionProps } from './types'
import { IconThemed } from '@/tonies-ui/atoms/Icon'

export const MediaCopySection: FunctionComponent<MediaCopySectionProps> = ({
  children,
  actions,
  headline,
  text,
  isContentCentered,
  icon,
  ...props
}) => {
  const { screenTabletL } = useTheme()
  const isMobile = useMedia({ maxWidth: screenTabletL })
  const isCentered = isContentCentered ?? isMobile
  const { mediaPositionMobile } = props

  return (
    <MediaCopyView isContentCentered={isCentered} {...props}>
      {icon && (
        <Spacing mb="spacing-xs">
          <CTARow justifyContent={isCentered ? 'center' : undefined}>
            <IconThemed type={icon} width="1.25rem" height="1.25rem" />
          </CTARow>
        </Spacing>
      )}
      {headline?.children && (
        <Spacing mb="spacing-xs">
          <HeadlineChooser
            data-testid={`mediaCopy__Headline--${headline.variant}`}
            align={isCentered ? 'center' : undefined}
            {...headline}
          />
        </Spacing>
      )}
      {text?.children && (
        <Spacing mb="spacing-xs">
          <Paragraph
            asHTMLTag="div"
            align={isCentered ? 'center' : undefined}
            {...text}
          />
        </Spacing>
      )}
      {children}
      {actions &&
        ((actions.primary?.text && actions.primary?.url) ||
          (actions.secondary?.text && actions.secondary?.url)) && (
          <Spacing
            mb={
              isMobile && mediaPositionMobile === 'bottom'
                ? 'spacing-xs'
                : undefined
            }
            mt="spacing-m"
          >
            <CTARow flexDirection={isCentered ? 'column' : 'row'}>
              {Object.entries(actions).map(
                ([variant, { url, text, onClick, dataTestId }], i) => {
                  return url && text ? (
                    <Button
                      key={`${i}-${text}`}
                      variant={
                        variant as keyof MediaCopySectionProps['actions']
                      }
                      as="a"
                      href={url}
                      onClick={onClick}
                      data-testid={dataTestId}
                    >
                      {text}
                    </Button>
                  ) : null
                }
              )}
            </CTARow>
          </Spacing>
        )}
    </MediaCopyView>
  )
}
