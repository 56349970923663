import React, { ReactNode } from 'react'
import { Accordion } from '@boxine/tonies-ui'
import { AccordionItem } from '@/tonies-ui/index'
import { MediaCopySection } from '@/tonies-ui/organisms/MediaCopySection'
import { MediaCopySectionProps } from '@/tonies-ui/organisms/MediaCopySection/types'
import { ToniesMotions } from '@/tonies-ui/motions'

export type Props = Omit<
  MediaCopySectionProps,
  'headline' | 'text' | 'media'
> & {
  headline: ReactNode
  headlineAsHTMLTag?: SupportedHTMLTags
  layout?: 'half-grid' | 'half-screen'
  subheadline: string
  entries: {
    question: string
    answer: ReactNode
    trackingId?: string
  }[]
  imageMobile: string
  imageDesktop: string
  scaling?: number
}

// Reuse from external tonies-ui as soon as more components are exported
type SupportedHTMLTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'p'
  | 'span'
  | 'div'
  | 'em'
  | 'strong'

export const FrequentlyAskedQuestions = ({
  headline,
  headlineAsHTMLTag,
  subheadline,
  entries,
  imageDesktop,
  imageMobile,
  layout,
  scaling,
  ...rest
}: Props) => {
  return (
    <>
      <ToniesMotions.ScrollingTonieHeads
        firstColor="1-color-6-shades-of-secondary-shaded-blue"
        secondColor={rest.backgroundColor || 'white'}
      />
      <MediaCopySection
        headline={{
          variant: 'Headline1',
          asHTMLTag: headlineAsHTMLTag,
          children: headline,
        }}
        isContentCentered={false}
        text={{ children: subheadline }}
        direction="rtl"
        media={{
          positionX: 'center',
          positionY: 'end',
          scaling: scaling,
          mobile: {
            variant: 'image',
            alt: '',
            src: imageMobile,
          },
          desktop: {
            variant: 'image',
            alt: '',
            src: imageDesktop,
          },
        }}
        layout={layout}
        {...rest}
      >
        <Accordion>
          {entries.map(({ question, answer, trackingId }) => (
            <AccordionItem
              key={question}
              title={question}
              data-trackingid={trackingId}
              data-testid="faq-item"
            >
              {answer}
            </AccordionItem>
          ))}
        </Accordion>
      </MediaCopySection>
    </>
  )
}

FrequentlyAskedQuestions.defaultProps = {
  headlineAsHTMLTag: 'h2',
  layout: 'half-grid',
  scaling: 80,
}
