import React, { FunctionComponent, useCallback } from 'react'
import { useGtmV4 } from '../../../../providers/gtmV4'
import { ButtonLink, Icon } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { useAccountNavigationSidebar } from '../hooks/useAccountNavigationSidebar'
import { useAuth } from '../../../../providers/auth'

const StyledButtonLink = styled(ButtonLink)`
  display: inline-block;

  svg {
    fill: currentColor;
    height: auto;
    width: 1.5rem;
  }
`

export const AccountLink: FunctionComponent = () => {
  const { pushGtmV4Event } = useGtmV4()
  const { authenticated, isTokenPresent, login } = useAuth()
  const [isOpen, setIsOpen] = useAccountNavigationSidebar()

  const handleAcount = useCallback(() => {
    setIsOpen(!isOpen)

    pushGtmV4Event({
      eventType: 'click',
      type: 'onClick',
      label: '[coded] Account',
      action: 'account',
    })
  }, [isOpen, pushGtmV4Event, setIsOpen])

  const handleLogin = useCallback(() => {
    login()

    pushGtmV4Event({
      eventType: 'click',
      type: 'onClick',
      label: '[coded] Login',
      action: 'login',
    })
  }, [login, pushGtmV4Event])

  // we use `isTokenPresent` because we already want to display the logged in state before API calls to Keycloak have finished
  // this prevents an ugly flash for most users (who are almost certainly logged in)
  const showLoggedInState = isTokenPresent || authenticated

  return showLoggedInState ? (
    <StyledButtonLink
      dataTestId="header-account-link"
      onClick={() => {
        handleAcount()
      }}
    >
      <Icon type="account" />
    </StyledButtonLink>
  ) : (
    <StyledButtonLink
      dataTestId="header-login-link"
      onClick={() => {
        handleLogin()
      }}
    >
      <Icon type="accountLogin" />
    </StyledButtonLink>
  )
}
