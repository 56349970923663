import { ToniesColor } from '@boxine/tonies-ui'
import { SupportedProductFlag } from '../types/normalize/product'

const productFlagMapping: {
  [key in SupportedProductFlag]: ToniesColor
} = {
  bestseller: 'lightblue-20',
  favourite: 'lightblue-20',
  exclusive: 'lightblue-20',
  seenOnTv: 'green-20',
  new: 'green-20',
  sale: 'pink-20',
  endOfLife: 'pink-20',
  xmas: '1-color-7-campaigns-christmas-2022-20',
  easter: 'berry-20',
  blackFriday: 'darkergrey',
  backInStock: 'anthracite-20',
} as const

export const useProductFlag = (p: Pick<NormalizedProduct, 'flag'>) => {
  return p.flag
    ? {
        key: p.flag.key,
        backgroundColor: productFlagMapping[p.flag.key],
        text: p.flag.label,
      }
    : undefined
}
