import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ImageWithOptionalLink as ImageWithOptionalLinkType } from '../../../../lib/contentful/datamodel/schemas'
import { Image } from '@/tonies-ui/atoms/Image'

const ImageWrapper = styled.a`
  display: inline;
`

export const ImageWithOptionalLink: FunctionComponent<{
  image: ImageWithOptionalLinkType
}> = ({ image }) => (
  <ImageWrapper as={image.link?.href ? 'a' : 'span'} {...image.link}>
    <Image
      src={image.image.file.url}
      alt={image.caption || image.image.file.url}
    />
  </ImageWrapper>
)
