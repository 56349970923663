import styled from 'styled-components'
import { media } from '@boxine/tonies-ui'
import { mainFirstChildPaddingTop } from '@/tonies-ui/helpers/css-helper'
import { Section } from '../../../atoms/Section'

export const BadgeWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 10;
`

export const ColorSelectorWrapper = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: 1.5rem;
`

export const StyledSection = styled(Section)`
  padding: 2rem 0;

  main > &:first-child > div {
    ${mainFirstChildPaddingTop}
  }
`

export const ProductSelectorSection = styled.section<{
  backgroundColor: string
}>`
  position: relative;
  overflow: hidden;
  transition: background-color 1s;
  background-color: ${props => props.backgroundColor};

  main > &:first-child {
    ${mainFirstChildPaddingTop}
  }
`

export const Lane = styled.div`
  margin: 0 auto;
  max-width: 75rem;
  padding: 2.5rem ${props => props.theme.section.column.padding};

  ${media.tablet`
      padding: 0 ${(props: {
        theme: { section: { column: { padding: string } } }
      }) => props.theme.section.column.padding};
      height: 65vw;
    `}

  @media (min-width: 67.75rem) {
    height: 44rem;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet`
      flex-direction: row;
      height: 100%;
    `}
`

export const Column = styled.div<{
  order: number
  tabletAlignContent?: string
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  hyphens: none;

  ${media.tablet`
      width: 50%;
      order: ${(props: { order: number }) => props.order};
  
      ${(props: { tabletAlignContent?: string }) => {
        if (props.tabletAlignContent === 'left') {
          return `align-items: flex-start;`
        }
        if (props.tabletAlignContent === 'right') {
          return `align-items: flex-end;`
        }
        return `align-items: center;`
      }}
  
    `}

  &.idx-1 {
    z-index: 5;
  }
`

export const ProductImage = styled.div`
  position: relative;
  line-height: 0;
`

export const ProductImageContainer = styled.div<{
  isImageLoaded?: boolean
  hasRelativeProductImage?: boolean
  hasLargerProductImage?: boolean
}>`
  ${props =>
    props.hasRelativeProductImage &&
    `
      z-index: 4;
      position: relative;
      `}

  ${props =>
    props.hasLargerProductImage &&
    media.laptop`
        transform: scale(1.1);
      `}
  
    width: 100%;
  min-width: 320px;
  max-width: 100%;
  height: auto;
  transition: opacity 0.5s;
  opacity: ${props => (props.isImageLoaded ? '1' : '0')};

  ${media.tablet`
      min-width: 560px;
    `}

  ${media.laptop`
      min-width: 640px;
    `}
`

export const ProductImageLink = styled.a`
  position: absolute;
  left: 12.5%;
  top: 0;
  width: 75%;
  height: 100%;
  border-radius: 10%;
  transform: scale(0.8);

  span {
    display: none;
  }

  ${media.tablet`
      a {
        transform: scale(1.2);
      }
    `}
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
