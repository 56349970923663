import * as v from '@badrap/valita'
import { Card } from '@braze/web-sdk'
import { BrazeContentCardSchema } from '../contentful/datamodel/schemas'

const bxContentCardSchema = v.object({
  title: v.string(),
  linkText: v.string(),
  url: v.string(),
  imageUrl: v.string(),
  extras: v.object({
    contentful_component: v.string(),
    contentful_reference: v.string(),
    feed_type: v.string().optional(),
  }),
})
export type BxContentCard = v.Infer<typeof bxContentCardSchema>

const isBxContentCardSchema = (card: Card): boolean =>
  bxContentCardSchema.try(card, { mode: 'strip' }).ok

export const parseBxContentCard = (card: Card): BxContentCard =>
  bxContentCardSchema.parse(card, { mode: 'strip' })

export const isBxContentCard = (
  card: Card,
  contentTypeId = 'editorialTeaserEntry'
): boolean =>
  isBxContentCardSchema(card) &&
  parseBxContentCard(card).extras.contentful_component === contentTypeId

export const isMatchingBxContentCard = (
  card: Card,
  entry: Pick<BrazeContentCardSchema, 'contentfulReference' | 'feedType'>
): boolean => {
  if (isBxContentCardSchema(card)) {
    const extras = parseBxContentCard(card).extras

    let isMatch = entry.contentfulReference === extras.contentful_reference

    if (isMatch && entry.feedType) {
      isMatch = entry.feedType === extras.feed_type
    }

    return isMatch
  }

  return false
}
