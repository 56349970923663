import React from 'react'
import HandSVG from './hand.svg'
import ThumbSVG from './thumb.svg'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { media } from '@boxine/tonies-ui'

const HandComponent = styled(motion.span)`
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 100%;
  top: -1rem;
  right: -5rem;
  scale: 0.8;

  ${media.tablet`
    right: -8rem;
  `}

  ${media.laptop`
    right: -11rem;
    top: -5rem;
    scale: 1;
  `}
`

export const Hand: NormalizedMotionComponent = ({ children }) => {
  return (
    <span
      style={{
        display: 'inline-block',
        width: '100%',
        height: '100%',
      }}
    >
      <HandComponent
        data-hand-motion="hand"
        initial={{ x: '150%', y: '-150%' }}
        style={{
          zIndex: -1,
        }}
      >
        <ThumbSVG />
      </HandComponent>

      <motion.span
        data-hand-motion="children"
        initial={{ x: '-50%' }}
        style={{ display: 'grid' }}
      >
        {children}
      </motion.span>
      <HandComponent
        data-hand-motion="hand"
        initial={{ x: '150%', y: '-150%' }}
      >
        <HandSVG />
      </HandComponent>
    </span>
  )
}
