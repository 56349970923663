import React, { FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Button, Spacing, useColorContext } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { useTranslation } from 'next-i18next'
import { OmniButtonProps } from '@boxine/tonies-ui/dest/types/components/OmniButton/types'
import { Price } from '../Price'
import { useCartActions } from '../../../hooks/useCartActions'
import { addProductAction } from '../../../providers/cartActions/actions/addProductAction'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { IconButton } from '@/tonies-ui/atoms/IconButton'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { toast } from '@/tonies-ui/atoms/Toast'
import { useProductConfirmation } from '../../../hooks/useProductConfirmation'
import { useProductValidation } from '../../../hooks/useProductValidation'
import { useRecommendations } from '../../../providers/recommendations'

export type AddToCartProps = {
  gtmActionField?: string
  renderDisabledOnAllCartActions?: boolean
  renderPrice?: boolean
  renderRetailerSearchWhenSoldOut?: boolean
  product: NormalizedProduct
  type: 'iconButton' | 'omniButton'
  isDisabled?: boolean
  validate?: boolean
  'data-testid'?: string
}

const IconButtonWrapper = styled.div`
  align-items: center;
  display: inline-grid;
  grid-template-columns: auto auto;
`

// eslint-disable-next-line sonarjs/cognitive-complexity
const AddToCartContainer: FunctionComponent<AddToCartProps> = props => {
  const {
    product,
    product: { availability },
    renderDisabledOnAllCartActions = false,
    renderPrice = false,
    renderRetailerSearchWhenSoldOut = false,
    type,
    isDisabled: isDisabledProp,
    validate = true,
  } = props
  const dataTestId = props['data-testid'] ? `${props['data-testid']}--` : ''
  const { t } = useTranslation()
  const [isHandlerPending, setIsHandlerPending] = useState(false)
  const { push: queue, state: cartActionState } = useCartActions()
  const confirmProduct = useProductConfirmation()
  const validateProduct = useProductValidation()
  const { pushGtmV4Event } = useGtmV4()
  const { hasPredefinedBundleAddToCart } = useFeatureFlags()
  const colorContext = useColorContext()
  const { handleSetLastSeenSku } = useRecommendations()

  const handleAddToCart = useCallback(() => {
    if (product.sku) {
      if (
        product.sku.includes('predefined_bundle') &&
        !hasPredefinedBundleAddToCart
      ) {
        toast(
          "This is a test product. You're not allowed to add this to your cart.",
          'error'
        )
        return false
      }

      confirmProduct(product).then(({ product, custom }) => {
        setIsHandlerPending(true)
        handleSetLastSeenSku(product.sku)
        queue(
          addProductAction({
            product,
            quantity: 1,
            custom,
            pushGtmV4Event,
          })
        )
          .then(isSuccessful => {
            if (isSuccessful) {
              if (validate) {
                validateProduct(product)
              }

              toast(t('cart:toast.addedToCart'), 'success')
            }
          })
          .finally(() => {
            setIsHandlerPending(false)
          })
      })
    }
  }, [
    hasPredefinedBundleAddToCart,
    product,
    handleSetLastSeenSku,
    pushGtmV4Event,
    queue,
    validate,
    confirmProduct,
    validateProduct,
    t,
  ])

  const retailerSearchUrl =
    availability.state === 'sold-out' &&
    renderRetailerSearchWhenSoldOut &&
    t('products:retailerSearchUrl').trim()

  const isDisabled =
    isDisabledProp ||
    (availability.state !== 'orderable' &&
      availability.state !== 'preorderable')

  const price = renderPrice ? product.price : undefined
  const strikePrice = renderPrice ? product.strikePrice : undefined

  const isPending =
    isHandlerPending ||
    (renderDisabledOnAllCartActions && cartActionState === 'processing')

  if (type === 'omniButton') {
    let omniButtonStyling: OmniButtonProps['styling'] =
      colorContext === 'dark' ? 'secondary' : 'primary'

    if (omniButtonStyling === 'secondary') {
      // map "secondary" to the white OmniButton version
      omniButtonStyling = 'secondaryWhite'
    }

    if (retailerSearchUrl) {
      return (
        <Button
          as="a"
          data-testid={`${dataTestId}retailer-search-button`}
          icon="search"
          href={retailerSearchUrl}
        >
          {t('products:retailerSearch')}
        </Button>
      )
    }

    let content

    if (availability.state === 'preorderable') {
      content = t('addToCart:preOrder')
    } else if (price) {
      content = (
        <Price
          price={price}
          strikePrice={strikePrice}
          size="s"
          color={
            omniButtonStyling === 'primary'
              ? isDisabled
                ? 'normalgrey'
                : 'white'
              : isDisabled
              ? 'normalgrey'
              : 'primary'
          }
        />
      )
    } else {
      content = t('addToCart:label')
    }

    return (
      <Button
        data-testid={`${dataTestId}add-to-cart-button${
          isDisabled ? '--disabled' : ''
        }`}
        disabled={isDisabled}
        isPending={isPending}
        title={t('addToCart:label')}
        icon="cart"
        onClick={handleAddToCart}
      >
        {content}
      </Button>
    )
  } else {
    return retailerSearchUrl ? (
      <Button
        as="a"
        data-testid={`${dataTestId}retailer-search-button`}
        icon="search"
        href={retailerSearchUrl}
      >
        {t('products:retailerSearch')}
      </Button>
    ) : (
      <IconButtonWrapper data-testid="add-to-cart-button-wrapper">
        {price && (
          <Spacing pr="spacing-xs">
            <Price
              color={colorContext === 'dark' ? 'white' : 'darkergrey'}
              price={price}
              strikePrice={strikePrice}
              size="s"
            />
          </Spacing>
        )}
        <IconButton
          icon={icons.cart}
          data-testid={`${dataTestId}add-to-cart-button${
            isDisabled ? '--disabled' : ''
          }`}
          dataTestExtra={product.sku}
          isDisabled={isDisabled}
          isPending={isPending}
          onClick={handleAddToCart}
        />
      </IconButtonWrapper>
    )
  }
}

export const AddToCart = withGTMV4Context(AddToCartContainer, 'AddToCart')
