import React, { FunctionComponent } from 'react'
import { CarouselProvider } from 'pure-react-carousel'
import { CarouselProps } from './types'
import { CarouselWrapper } from './components/CarouselWrapper'

export const Carousel: FunctionComponent<CarouselProps> = ({
  ariaTitle = '',
  buttons = 'center',
  children,
  hasIntroAnimation = false,
  hasPagination = false,
  isInfinite = false,
  naturalSlideWidth,
  naturalSlideHeight = 0,
  onChange,
  visibleSlides = 1,
  isIntrinsicHeight = naturalSlideHeight === 0,
  removeSidePadding = false,
}) => (
  <CarouselProvider
    naturalSlideWidth={naturalSlideWidth}
    naturalSlideHeight={naturalSlideHeight}
    visibleSlides={visibleSlides}
    totalSlides={children.length}
    infinite={isInfinite}
    isIntrinsicHeight={isIntrinsicHeight}
  >
    <CarouselWrapper
      ariaTitle={ariaTitle}
      buttons={buttons}
      hasIntroAnimation={hasIntroAnimation}
      hasPagination={hasPagination}
      isInfinite={isInfinite}
      onChange={onChange}
      hasPadding={!removeSidePadding}
    >
      {children}
    </CarouselWrapper>
  </CarouselProvider>
)
