import abTestsConfig from '../../../configcat.config.json'

export type ABTestsType = {
  [key: string]: {
    abtests: {
      percentage?: number
      pathname?: string
    }[]
    key: string
    type: string
    pathnames: string[]
  }
}

export type DefaultTextValue = string
export type TextValue = string

export const cookiePrefix = 'ab-test-'
const abTests: ABTestsType = abTestsConfig

export const getAbTest = (key: string) => abTests[key]
export const abTestsArray = Object.entries(abTests).map(
  ([, testAttributes]) => ({
    ...testAttributes,
    key: testAttributes.key,
  })
)
export const getAbTestInArray = (key: string) =>
  abTestsArray.find(test => test.key === key)

export const isRewriteValue = (
  settingTypeValue: unknown
): settingTypeValue is 'rewrite' =>
  typeof settingTypeValue === 'string' && settingTypeValue === 'rewrite'

export function chooseAbTest(key: string): string | undefined {
  const { type, abtests } = getAbTest(key)

  if (!isRewriteValue(type)) {
    return undefined
  }

  let n = Math.random() * 100

  const item = abtests.find(({ percentage }) => {
    n -= percentage || 0
    return n <= 0
  })

  return item?.pathname
}
