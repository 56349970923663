import { FocusShadow, media } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { gapRem, sizeMobileRem, sizeOthersRem } from '../constants'

export const Radio = styled.div<{
  color: string
}>`
  overflow: hidden;
  position: relative;
  height: ${sizeMobileRem}rem;
  width: ${sizeMobileRem}rem;
  margin: ${gapRem}rem 0 0 ${gapRem}rem;

  input {
    opacity: 0;
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    font-size: 100%;
    cursor: pointer;
    user-select: none;

    :before {
      font-size: 0;
      position: absolute;
      content: '';
      left: ${gapRem / 2}rem;
      right: ${gapRem / 2}rem;
      top: ${gapRem / 2}rem;
      bottom: ${gapRem / 2}rem;
      border-radius: 50%;
      background-color: ${props => props.color};
    }
  }

  input:checked + label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid ${props => props.color};
  }

  @supports not (selector(:focus-visible)) {
    input.focus-visible + label:after,
    input:focus + label:after {
      ${FocusShadow}
    }
  }

  @supports selector(:focus-visible) {
    input:focus-visible + label:after {
      ${FocusShadow}
    }
  }

  ${media.tablet`
    width: ${sizeOthersRem}rem;
    height: ${sizeOthersRem}rem;
  `}
`
