import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Paragraph } from '@boxine/tonies-ui'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import { CONTENT_IMAGE_HEIGHT_PX, CONTENT_IMAGE_WIDTH_PX } from '../variables'

export type ContentProps = {
  name: string
  image: {
    alt: string
    src: string
  }
}

export type BundleContentProps = ContentProps & {
  subtitle: string
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const BundleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`
const BundleLabelWrapper = styled.div`
  align-self: center;
`

export const Content: FunctionComponent<ContentProps> = ({ name, image }) => (
  <Wrapper>
    <img
      alt={image.alt}
      height={CONTENT_IMAGE_HEIGHT_PX}
      src={image.src}
      width={CONTENT_IMAGE_WIDTH_PX}
    />
    <Paragraph align="center" size={5} weight={300}>
      {name}
    </Paragraph>
  </Wrapper>
)

export const BundleContent: FunctionComponent<BundleContentProps> = ({
  name,
  subtitle,
  image,
}) => (
  <BundleWrapper>
    <img
      alt={image.alt}
      height={CONTENT_IMAGE_HEIGHT_PX}
      src={cloudinaryUrl({
        src: image.src,
        width: CONTENT_IMAGE_WIDTH_PX,
        background: 'white',
      })}
      width={CONTENT_IMAGE_WIDTH_PX}
    />
    <BundleLabelWrapper>
      <Paragraph align="left" size={4} weight={700}>
        {name}
      </Paragraph>
      <Paragraph align="left" size={5} weight={300}>
        {subtitle}
      </Paragraph>
    </BundleLabelWrapper>
  </BundleWrapper>
)
