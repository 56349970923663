import styled from 'styled-components'

export const Contents = styled.div<{ align: 'left' | 'center' }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: ${p => (p.align === 'left' ? 'start' : 'center')};
  margin-bottom: -0.5rem;

  > * {
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
  }
`
