import { lineClampCss, Paragraph, ParagraphProps } from '@boxine/tonies-ui'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export type TextProps = {
  name: string
  description1?: string
  description2?: string
  align: ParagraphProps['align']
}

const LineClamp = styled.span<{ maxLines: number }>`
  ${p => lineClampCss(p.maxLines)}
  hyphens: none;
`

export const Text: FunctionComponent<TextProps> = ({
  name,
  description1,
  description2,
  align,
}) => (
  <div>
    <Paragraph align={align} data-testid="name" size={2} weight={700}>
      <LineClamp maxLines={2}>{name}</LineClamp>
    </Paragraph>
    {description1 && (
      <Paragraph align={align} data-testid="description1" size={3} weight={300}>
        <LineClamp maxLines={1}>{description1}</LineClamp>
      </Paragraph>
    )}
    {description2 && (
      <Paragraph align={align} data-testid="description2" size={3} weight={300}>
        <LineClamp maxLines={1}>{description2}</LineClamp>
      </Paragraph>
    )}
  </div>
)
