import React, { ComponentProps } from 'react'
import { EditorialTeaserCollection } from '../../../../../../components/organisms/EditorialTeaserCollection'
import { EditorialTeaserListSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'

export const CM_EditorialTeaserListSchema = ({
  componentProps,
  ...rest
}: ContentModelType<
  ComponentProps<typeof EditorialTeaserCollection>,
  EditorialTeaserListSchema
>) => {
  return <EditorialTeaserCollection {...rest} {...componentProps} />
}
