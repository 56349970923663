import React, { ComponentProps } from 'react'
import { Headline1, Spacing, useIsViewportMobile } from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { ProductSelectorSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { ProductSelector } from '../../../../../../components/organisms/ProductSelector'
import { generateId } from '../../../../../../utils'

export const CM_ProductSelectorSchema = ({
  componentProps,
  ...rest
}: ContentModelType<
  ComponentProps<typeof ProductSelector>,
  ProductSelectorSchema
>) => {
  const isMobile = useIsViewportMobile()

  return (
    <ProductSelector
      referenceTitle={rest.referenceTitle}
      uniqueInputName={generateId(rest.categoryKey)}
      productList={rest}
      hasProductDetails
      hasAddToCart
      headline={
        <Spacing pb={isMobile ? 'spacing-xs' : 'spacing-xl'}>
          <Headline1 asHTMLTag={rest.headlineAsHtmlTag} align="center">
            <Accent text={rest.headline} />
          </Headline1>
        </Spacing>
      }
      hasDetailLink
      isImageLink
      hasUrlReferralCodeSection
      id={rest.id}
    />
  )
}
