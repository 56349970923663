import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { List, ListItem, Spacing } from '@boxine/tonies-ui'
import { GenericProductCard } from '../GenericProductCard'
import { useCardNote } from './hooks/useCardNote'
import { useNormalizedProductCardAction } from './hooks/useNormalizedProductCardAction'
import { NormalizedProductCardProps } from './types'
import { useGtmV4 } from '../../../providers/gtmV4'
import { useProductFlag } from '../../../hooks/useProductFlag'
import { LabelSelector } from '../../atoms/LabelSelector'
import { ColorSelector } from '../../atoms/ColorSelector'
import { useProductVariants } from '../../../hooks/useProductVariants'
import { useCartState } from '../../../hooks/useCartState'
import { useTranslation } from 'next-i18next'
import { useNativeApp } from '../../../providers/nativeApp'
import { useHouseholdTunesData } from '../../../providers/auth'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useAssignTune } from '../../../hooks/useAssignTune'
import { handleOnPlay } from '../../../components/organisms/AudioPlayerSection/utils'
import { useWishlist } from '../../../providers/Wishlist'
import { useOwnAudioContentContext } from '../../../providers/ownAudioContent/OwnAudioContext'
import { OwnAudioContentCard } from '../GenericProductCard/variants/OwnAudioContentCard'
import { useToggleWishlistIconProps } from '../../../components/atoms/ToggleFromWishlist'
import { useRecommendations } from '../../../providers/recommendations'

const Wrapper = styled.div<{ isCentered: boolean }>`
  ${({ isCentered }) => css`
    display: grid;
    align-content: space-between;
    justify-items: ${isCentered ? 'center' : 'start'};
  `}
`

export const NormalizedProductCard: FunctionComponent<
  NormalizedProductCardProps & PropsWithChildren
  // eslint-disable-next-line sonarjs/cognitive-complexity
> = props => {
  const {
    dataTestExtra,
    dataTestId = 'normalized-product-card',
    dataTrackingId,
    hasBorder,
    hasPrices = true,
    isSelected,
    isGreyToned,
    onClick,
    orientation,
    product: initialProduct,
    children,
    listEntries,
    variants,
    action: actionProp,
    validate = true,
    actionAdd2CartCallback,
    preventWishlist,
    hasNote = true,
  } = props

  const {
    activeProduct,
    colorSelectorVariants,
    labelSelectorVariants,
    setActiveProductId,
  } = useProductVariants<typeof initialProduct>(initialProduct, variants || [])
  const product = activeProduct || initialProduct
  const { isMyTune } = useHouseholdTunesData(product.salesId)

  const action = useNormalizedProductCardAction({
    product,
    action: actionProp,
    actionAdd2CartCallback,
    validate,
  })

  // Wishlist Icon
  const { hasWhishlistSurvey } = useFeatureFlags()
  const wishlistIconProps = useToggleWishlistIconProps(product)

  /**
   * Wishlist
   */
  const { hasWishlist } = useWishlist()

  const hasWhishlistIcon =
    (hasWhishlistSurvey || hasWishlist) &&
    action !== 'toggleSelection' &&
    !preventWishlist

  const { t } = useTranslation()
  const { colors } = useTheme()
  const { isItemInCart } = useCartState()
  const { isApp } = useNativeApp()
  const {
    isDirectOwnAudioContentAssignmentEnabled,
    ownAudioContentCollection,
    isAllOwnAudioContentReady,
  } = useOwnAudioContentContext()
  const availabiltyNote = useCardNote(product.availability)
  const note = isMyTune
    ? {
        text: t('products:tunes:alreadyBoughtHint'),
        fillColor: colors['green-20'],
      }
    : isItemInCart(product.sku) && product.maxQuantity === 1
    ? {
        text: t('products:alreadyInCart'),
        fillColor: colors['green-20'],
      }
    : availabiltyNote
  const notch = useProductFlag(product)
  const { pushGtmV4Event } = useGtmV4()
  const { handleSetLastSeenSku } = useRecommendations()

  const handlePdpClick = useCallback(() => {
    pushGtmV4Event({ eventType: 'selectItem', item: product })
  }, [product, pushGtmV4Event])

  const handleAudioSampleOnPlay = useCallback(() => {
    handleSetLastSeenSku(product.sku)
    handleOnPlay(product, pushGtmV4Event)
  }, [product, pushGtmV4Event, handleSetLastSeenSku])

  const { handleClickAssign } = useAssignTune(product)

  // evaluate if product.path is an empty string to remove the `navigateToPdp` onClick action
  const productPath = product?.path?.length ? product.path : undefined

  const isOwnAudioContent =
    isDirectOwnAudioContentAssignmentEnabled &&
    isAllOwnAudioContentReady &&
    ownAudioContentCollection &&
    product.sku &&
    ownAudioContentCollection.some(tune => tune.sku === product.sku)

  return isOwnAudioContent && isAllOwnAudioContentReady ? (
    <OwnAudioContentCard tune={product} hasNote={hasNote} />
  ) : product.sku ? (
    <GenericProductCard
      action={action}
      id={product.sku}
      notch={notch}
      audioSample={
        product.audioSampleUrl
          ? {
              url: product.audioSampleUrl,
              onPlay: handleAudioSampleOnPlay,
            }
          : undefined
      }
      audioLibraryAssignUrl={
        isMyTune ? product.audioLibraryAssignUrl : undefined
      }
      onClickAssign={handleClickAssign}
      dataTestExtra={product.sku || dataTestExtra}
      dataTestId={dataTestId}
      dataTrackingId={dataTrackingId}
      description1={product.subName}
      hasBorder={hasBorder}
      image={{
        src: product.image.src,
        alt: product.image.alt,
        variant: product.shopCategory === 'tunes' ? 'coverCard' : 'image',
      }}
      isSelected={isSelected}
      isGreyToned={isGreyToned}
      name={product.name}
      note={note}
      orientation={orientation}
      onClick={
        isApp
          ? undefined // TWAS-4571
          : onClick === 'navigateToPdp' && productPath
          ? {
              href: productPath,
              onClick: handlePdpClick,
            }
          : onClick !== 'navigateToPdp'
          ? onClick
          : undefined
      }
      topRightIconSlot={
        hasWhishlistIcon
          ? wishlistIconProps
          : productPath && !isApp // TWAS-4571
          ? {
              href: productPath,
              onClick: handlePdpClick,
            }
          : undefined
      }
      price={!isMyTune && hasPrices ? product.price : undefined}
      strikePrice={hasPrices ? product.strikePrice : undefined}
    >
      <Wrapper isCentered={orientation === 'portrait'}>
        {product.bundledProducts && (
          <Spacing mt="spacing-s">
            <List size={3} weight={300}>
              {product.bundledProducts.map((p, i) => (
                <ListItem key={`${p.name}-${i}`}>{p.name}</ListItem>
              ))}
            </List>
          </Spacing>
        )}
        {/*
        TODO
        listEntries can be removed (here and also in contentful) after the
        switch to predefined bundles
        */}
        {listEntries && (
          <Spacing mt="spacing-s">
            <List size={3} weight={300}>
              {listEntries.map((string, i) => (
                <ListItem key={`${string}-${i}`}>{string}</ListItem>
              ))}
            </List>
          </Spacing>
        )}
        {variants && (
          <>
            {labelSelectorVariants && labelSelectorVariants.length > 1 && (
              <Spacing pt="spacing-s">
                <LabelSelector
                  activeVariantId={product.id}
                  uniqueInputName={initialProduct.id + '--label'}
                  variants={labelSelectorVariants}
                  onChange={setActiveProductId}
                />
              </Spacing>
            )}
            {colorSelectorVariants && colorSelectorVariants.length > 1 && (
              <Spacing my="spacing-s">
                <ColorSelector
                  uniqueInputName={initialProduct.id + '--color'}
                  variants={colorSelectorVariants}
                  onChange={setActiveProductId}
                  activeVariantId={product.id}
                />
              </Spacing>
            )}
          </>
        )}
        {children}
      </Wrapper>
    </GenericProductCard>
  ) : null
}
