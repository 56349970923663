import { spacings } from '@/tonies-ui/themes/spacings'

export const borderRadiuses = {
  xxs: spacings['spacing-xxs'],
  xs: spacings['spacing-xs'],
  s: spacings['spacing-s'],
  m: spacings['spacing-m'],
  l: spacings['spacing-l'],
  xl: spacings['spacing-xl'],
  xxl: spacings['spacing-xxl'],
} as const

export type borderRadiusKeys = keyof typeof borderRadiuses | 0

export type ImageBackendOptions = {
  width?: number
  height?: number
  quality?: 30 | 60 | 'auto'
  isPlaceholder?: boolean
} & Pick<ImageProps, 'src' | 'background' | 'crop'>

export type ImageBackend = (options: ImageBackendOptions) => string

export type ImageProps = {
  /**
   * testId prefix for '-container' and '-image'.
   */
  dataTestId?: string
  /**
   * Image Source that starts with '//' or 'https://' or 'http://' or ends with '.svg'. If the Image Source ends with '.svg' the component will skip cloudinary.
   */
  src: string
  /**
   * Image Ratio like 4:3 or 16:9 in Array format like [4, 3] or [16, 9] to calculate width and heights.
   */
  ratio?: [number, number]
  /**
   * Alternative Image text.
   */
  alt: string
  /**
   * Responsive Image width in pixel as number or Array of numbers. The Component will choose the best fitting Image by the parent container width. If the parent container width is greater than the greatest number given, the image stick to the greatest number given.
   *
   * For images with a static/fixed desired size, provide a single number for the width to render
   *
   * If not specified, this is the default list used: [128, 256, 450, 640, 768, 1024, 1600]
   */
  responsive?: Array<number> | number
  /**
   * Change the Background Color of a (transparent) Image to Tonies Colors, a given HEX Color or a given rgb() Color.
   */
  background?: string
  /**
   * Crop, Fill or AutoGravity the Image.
   */
  crop?: 'crop' | 'fill' | 'autoGravity'
  /**
   * Show a Placeholder of the Image itself. If false, lazy loading is disabled.
   */
  placeholder?: boolean
  /**
   * Image backend to use when building the url (e.g. cloudinary, octonie, ...)
   */
  imageBackend?: ImageBackend
  /**
   * Set HTML `title`-attribute
   */
  title?: string
  /**
   * onLoad callback. The callback is executed when the actual (not the placeholder) image has loaded.
   */
  onLoad?: () => void
  /**
   * onError callback. The callback is executed when the placeholder or the actual image loading fail.
   */
  onError?: () => void
  /**
   * Border radius
   */
  borderRadius?: [
    borderRadiusKeys,
    borderRadiusKeys?,
    borderRadiusKeys?,
    borderRadiusKeys?
  ]
}
