import React from 'react'
import { media } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { Image } from '@/tonies-ui/atoms/Image'

const Container = styled.div<Pick<Props, 'contentPositionDesktop'>>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 5rem;

  ${media.mobileM`
    height: 6rem;
  `}

  ${media.tablet`
    height: 10.5rem;

    ${(props: { contentPositionDesktop: 'left' | 'right' }) =>
      props.contentPositionDesktop === 'right' &&
      `
          right: unset;
          left: 0;
        `}
  `}
`

type Props = {
  src: string
  alt: string
  contentPositionDesktop?: 'left' | 'right'
}

export const LicenseLogo = ({ src, alt, contentPositionDesktop }: Props) => {
  return (
    <Container contentPositionDesktop={contentPositionDesktop}>
      <Image src={src} alt={alt} ratio={[1, 1]} responsive={[100, 200]} />
    </Container>
  )
}
