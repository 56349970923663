import styled from 'styled-components'
import { AspectRatio } from '../types'
import { media } from '@boxine/tonies-ui'
import { css } from 'styled-components'

const AspectRatioAlignContentMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

export const StyledLaneWrapper = styled.div<{
  aspectRatio?: AspectRatio
  removePaddingY?: boolean
}>`
  ${({ aspectRatio, removePaddingY }): string | 0 | undefined =>
    aspectRatio?.ratio &&
    `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: ${
      aspectRatio.alignContent &&
      AspectRatioAlignContentMap[aspectRatio.alignContent]
        ? AspectRatioAlignContentMap[aspectRatio.alignContent]
        : AspectRatioAlignContentMap['top']
    };
      
    ${
      !removePaddingY &&
      css`
        padding: 2.5rem 0;

        ${media.laptop`
          padding: 5rem 0;
        `}
      `
    }
  `}
`
