import { FocusShadow, Paragraph, Spacing } from '@boxine/tonies-ui'
import React from 'react'
import styled from 'styled-components'
import { Image } from '@/tonies-ui/atoms/Image'

const Selector = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`

const RadioWrapper = styled.div`
  overflow: hidden;
  hyphens: auto;
  padding: 0.375rem;
`

const Radio = styled.div`
  position: relative;

  height: 0;
  padding-bottom: 100%;

  img {
    position: relative;
    top: 0;
  }

  input {
    opacity: 0;
  }

  label {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;
    background: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input:checked + label {
    box-shadow: 0 0 0 0.125rem ${props => props.theme.colors.primary},
      0 0 0 0.3rem ${props => props.theme.colors.white};
  }

  @supports not (selector(:focus-visible)) {
    input.focus-visible + label:after,
    input:focus + label:after {
      ${FocusShadow}
    }
  }

  @supports selector(:focus-visible) {
    input:focus-visible + label:after {
      ${FocusShadow}
    }
  }
`

export interface ItemProps {
  items: Array<{
    src: string
    alt: string
    title: string
  }>
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function Items({ items, onChange, value }: ItemProps) {
  return (
    <Selector onChange={onChange}>
      {items.map((item, i) => (
        <RadioWrapper key={item.alt + i}>
          <Radio>
            <input
              type="radio"
              name="listaudiosample-input"
              id={`radio-${i}`}
              value={i}
              defaultChecked={value === `${i}`}
            />
            <label htmlFor={`radio-${i}`}>
              <Image
                src={item.src}
                alt={item.alt}
                responsive={[65, 90, 165]}
                ratio={[1, 1]}
                crop="fill"
              />
            </label>
          </Radio>
          <Spacing mt="spacing-s">
            <Paragraph weight={500} align="center" size={4}>
              {item.title}
            </Paragraph>
          </Spacing>
        </RadioWrapper>
      ))}
    </Selector>
  )
}
