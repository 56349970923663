import styled from 'styled-components'
import { css } from 'styled-components'

export const Wrapper = styled.div<{
  onClick?: () => void
  isGreyToned: boolean
}>`
  ${({ onClick, isGreyToned }) => css`
    display: grid;
    grid-template-rows: 1fr auto;
    cursor: ${onClick && 'pointer'};
    position: relative; // required for z-indexed children
    user-select: ${onClick && 'none'};
    z-index: 0; // create a new stacking context, required for z-indexed children
    height: 100%; // make cards the same height in grid rows

    ${isGreyToned &&
    css`
      filter: grayscale(1) opacity(0.7);
    `}
  `}
`
