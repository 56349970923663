import React, { ReactNode } from 'react'
import {
  Headline1,
  Button,
  AssistiveTechnologyOnly,
  Paragraph,
  Spacing,
  CTARow,
} from '@boxine/tonies-ui'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useNativeApp } from '../../../providers/nativeApp'
import { useIsAudioLibraryCheckout } from '../../../hooks/useIsAudioLibraryCheckout'
import { shopConfig } from '../../../config/shop'
import { useLocale } from '../../../providers/locale'
import { ProductCardCarousel } from '../ProductCardCarousel'
import { Image } from '@/tonies-ui/atoms/Image'

const ImageContainer = styled.div`
  max-width: 10.5rem;
  margin: 0 auto;
`

// FIXME: avoid overwriting internal component styles
const HiddenHeadline1 = styled(Headline1)`
  ${AssistiveTechnologyOnly}
`

const StyledProductCardCarousel = styled(ProductCardCarousel)`
  margin-left: 0;
  margin-right: 0;
`

export type EmptyStateViewProps = {
  isInSidebar?: boolean
  onShopNow?: () => void
  staticRecommendations?: NormalizedProduct[]
  primaryCtaHref?: string
  primaryCtaText?: string
  secondaryCtaHref?: string
  secondaryCtaText?: string
  headline: ReactNode
  subheadline: string | ReactNode
}

export const ShopNowButton = ({
  onClick,
  primaryCtaHref = undefined,
  primaryCtaText = undefined,
}: {
  onClick?: EmptyStateViewProps['onShopNow']
  primaryCtaHref?: EmptyStateViewProps['primaryCtaHref']
  primaryCtaText?: EmptyStateViewProps['primaryCtaText']
}) => {
  const { t } = useTranslation()
  const lcCC = useLocale()
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()

  const audioLibraryUrl = process.env.NEXT_PUBLIC_MY_TONIES_URL
  // TODO: Extract this URL from the aggregated shop locale instead of hard coding
  const shopToniesUrl = `/${lcCC.toLowerCase()}/tonies/`
  const createCtaHref = () => {
    if (primaryCtaHref) {
      return primaryCtaHref
    }
    if (isAudioLibraryCheckout && audioLibraryUrl) {
      return audioLibraryUrl
    }
    return shopToniesUrl
  }

  return onClick ? (
    <Button onClick={onClick}>
      {primaryCtaText ? primaryCtaText : t('cart:empty:buttonText')}
    </Button>
  ) : (
    <Button as="a" href={createCtaHref()}>
      {primaryCtaText ? primaryCtaText : t('cart:empty:buttonText')}
    </Button>
  )
}

export const EmptyStateView: NormalizedFunctionComponent<
  EmptyStateViewProps
> = ({
  isInSidebar = false,
  onShopNow,
  staticRecommendations,
  'data-testid': dataTestId = 'cart-empty',
  headline,
  subheadline,
  children,
  primaryCtaHref = undefined,
  primaryCtaText = undefined,
  secondaryCtaHref = undefined,
  secondaryCtaText = undefined,
}) => {
  const { isApp } = useNativeApp()
  const { colors } = useTheme()
  const hasSecondaryCta = secondaryCtaHref && secondaryCtaText
  return (
    <div data-testid={dataTestId}>
      <Spacing my="spacing-l">
        {isInSidebar && <HiddenHeadline1>{headline}</HiddenHeadline1>}
        <ImageContainer>
          <Image
            src={shopConfig.assets.emptyState}
            alt={typeof headline === 'string' ? headline : 'empty state'}
            background={colors['lightblue-20']}
          />
        </ImageContainer>
      </Spacing>
      <Spacing mb="spacing-l">
        {isInSidebar ? (
          <Paragraph align="center">{subheadline}</Paragraph>
        ) : (
          <>
            {typeof subheadline === 'string' ? (
              <Headline1 asHTMLTag="h2" align="center">
                {subheadline}
              </Headline1>
            ) : (
              [subheadline]
            )}
          </>
        )}
        {children && <Spacing mt="spacing-s">{children}</Spacing>}
      </Spacing>

      {!isApp && !isInSidebar && (
        <CTARow justifyContent="center" flexDirection="row">
          <ShopNowButton
            onClick={onShopNow}
            primaryCtaHref={primaryCtaHref}
            primaryCtaText={primaryCtaText}
          />
          {hasSecondaryCta && (
            <Button as="a" href={secondaryCtaHref} variant="secondary">
              {secondaryCtaText}
            </Button>
          )}
        </CTARow>
      )}

      {staticRecommendations && staticRecommendations.length > 0 && (
        <Spacing my="spacing-xl">
          <StyledProductCardCarousel
            items={staticRecommendations}
            getAPIRecommendations
            columns={{
              desktop: isInSidebar ? 1.5 : 4,
              tablet: isInSidebar ? 1.5 : 3,
            }}
            hideSliderButtons={isInSidebar}
            hideCartLineItems
            referenceTitle="[coded] Recommendations Cart"
          />
        </Spacing>
      )}
    </div>
  )
}
