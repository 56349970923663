import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  TikTokIcon,
  TwitterIcon,
  YouTubeIcon,
} from '@boxine/tonies-ui'

export const socialMediaIconTypes = [
  'facebook',
  'facebookV2',
  'instagram',
  'instagramV2',
  'youtube',
  'youtubeV2',
  'twitter',
  'twitterV2',
  'pinterest',
  'pinterestV2',
  'tikTokV2',
] as const

export type SocialMediaIconType = (typeof socialMediaIconTypes)[number]
export const isSocialMediaIconType = (s: unknown): s is SocialMediaIconType =>
  socialMediaIconTypes.includes(s as SocialMediaIconType)

export const SocialMediaIcons: Record<SocialMediaIconType, () => JSX.Element> =
  {
    facebook: FacebookIcon,
    facebookV2: FacebookIcon,
    instagram: InstagramIcon,
    instagramV2: InstagramIcon,
    youtube: YouTubeIcon,
    youtubeV2: YouTubeIcon,
    twitter: TwitterIcon,
    twitterV2: TwitterIcon,
    pinterest: PinterestIcon,
    pinterestV2: PinterestIcon,
    tikTokV2: TikTokIcon,
  } as const
