import React, { FunctionComponent, forwardRef } from 'react'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { SectionProps } from './types'
import { StyledSection } from './components/StyledSection'
import { StyledLaneWrapper } from './components/StyledLaneWrapper'
import { StyledLane } from './components/StyledLane'
import { useMeasurements } from '../../../providers/measurements'
import { ColorContextProvider } from '@/tonies-ui/index'
import { Curve } from '@/tonies-ui/atoms/Curve'

export const Section: FunctionComponent<SectionProps & Styleable> = forwardRef(
  (
    {
      'data-testid': dataTestId,
      'data-testextra': dataTestExtra,
      className,
      curves: [curveTop, curveBottom] = [],
      backgroundColor,
      backgroundImage,
      children,
      lane = 'normal',
      aspectRatio,
      removePaddingX = false,
      removePaddingY = false,
      noGaps,
      asHTMLTag = 'section',
      isStageSection,
      id,
    },
    ref
  ) => {
    const { mainNavigationHeight } = useMeasurements()

    return (
      <ColorContextProvider backgroundColor={backgroundColor}>
        {curveTop && (
          <Curve
            color={backgroundColor}
            {...curveTop}
            backgroundColor="white"
          />
        )}
        <StyledSection
          data-testid={dataTestId}
          data-testextra={dataTestExtra}
          className={className}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          aspectRatio={aspectRatio}
          as={asHTMLTag}
          offset={isStageSection ? mainNavigationHeight : undefined}
          removePaddingY={removePaddingY}
          ref={ref}
          id={id}
        >
          <StyledLaneWrapper
            aspectRatio={aspectRatio}
            removePaddingY={removePaddingY}
          >
            <StyledLane
              removePaddingX={removePaddingX}
              className={lane}
              noGaps={noGaps}
            >
              {children}
            </StyledLane>
          </StyledLaneWrapper>
        </StyledSection>
        {curveBottom && (
          <Curve
            color={backgroundColor}
            backgroundColor="white"
            {...curveBottom}
          />
        )}
      </ColorContextProvider>
    )
  }
)

Section.displayName = 'Section'
