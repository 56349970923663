import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  FormEvent,
  ChangeEventHandler,
} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { InputField, OmniButton, Spacing } from '@boxine/tonies-ui'
import { useCurrencyFormatter } from '../../../hooks/useCurrencyFormatter'
import { useCartActions } from '../../../hooks/useCartActions'
import {
  addDiscountAction,
  AddDiscountCodeResult,
} from '../../../providers/cartActions/actions/addDiscountAction'
import { useCartState } from '../../../hooks/useCartState'
import { IconWrapper } from '../styles'
import { RemoveItem } from './RemoveItem'
import { removeDiscountAction } from '../../../providers/cartActions/actions/removeDiscountAction'
import { localizeDiscountState } from '../../../utils/localizeDiscountState'
import { useDiscountCodeInput } from '../../../providers/discountCodeInput'
import { useGtmV4 } from '../../../providers/gtmV4'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.25rem;
`

const StyledForm = styled.form`
  width: 100%;
  margin-bottom: 0.25rem;
`

export const Coupon = () => {
  const { t } = useTranslation()
  const { cart } = useCartState()
  const { discountCodeInput, setDiscountCodeInput } = useDiscountCodeInput()
  const { push: queue, state: cartActionState } = useCartActions()
  const [addDiscountCodeResult, setAddDiscountCodeResult] =
    useState<AddDiscountCodeResult>()
  const format = useCurrencyFormatter()
  const focusTimeout = useRef<number | undefined>()
  const inputRef = useRef<HTMLInputElement>(null)
  const hasDiscountCode = !!cart?.cartDiscount?.byDiscountCode?.code
  const isMatchingCart =
    cart?.cartDiscount?.byDiscountCode?.state === 'MatchesCart'
  const { pushGtmV4Event } = useGtmV4()

  useEffect(() => {
    if (cart?.cartDiscount?.byDiscountCode?.code) {
      setDiscountCodeInput(cart?.cartDiscount?.byDiscountCode?.code)
    }
  }, [cart, setDiscountCodeInput, setAddDiscountCodeResult])

  const handleOpen = useCallback(() => {
    const openCb = () => {
      clearTimeout(focusTimeout.current)
      setDiscountCodeInput('')
      setAddDiscountCodeResult(undefined)
      focusTimeout.current = setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 1) as unknown as number // see https://stackoverflow.com/questions/45802988/typescript-use-correct-version-of-settimeout-node-vs-window
    }
    openCb()
  }, [setDiscountCodeInput])

  const handleRemoveDiscount = useCallback(() => {
    queue(removeDiscountAction(pushGtmV4Event)).then(() =>
      setDiscountCodeInput(undefined)
    )
  }, [queue, setDiscountCodeInput, pushGtmV4Event])

  const updateCoupon = useCallback(
    (value: string | undefined) => {
      const updateCouponCb = () => {
        if (value) {
          queue(addDiscountAction(value, pushGtmV4Event)).then(
            setAddDiscountCodeResult
          )
        }
      }
      updateCouponCb()
    },
    [queue, setAddDiscountCodeResult, pushGtmV4Event]
  )

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      updateCoupon(discountCodeInput)
    },
    [discountCodeInput, updateCoupon]
  )

  const handleBlur = useCallback(() => {
    updateCoupon(discountCodeInput)
  }, [discountCodeInput, updateCoupon])

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    setDiscountCodeInput(value)
  }

  // set status color & message
  let couponStatus: 'ok' | 'error' | undefined
  let couponMessage

  if (hasDiscountCode) {
    // we have successfully added a discountCode
    couponStatus = isMatchingCart ? 'ok' : 'error'
    couponMessage = t(
      localizeDiscountState(cart.cartDiscount?.byDiscountCode?.state || ''),
      {
        discount: format(
          cart?.cartDiscount?.byDiscountCode?.savings?.amount || 0
        ),
      }
    )
  } else if (addDiscountCodeResult) {
    // we tried to add a discountCode, but it failed for some reason
    couponStatus = 'error'
    couponMessage = t(localizeDiscountState(addDiscountCodeResult))
  }

  return (
    <>
      {discountCodeInput === undefined ? (
        <Spacing mx="spacing-s">
          <Wrapper>
            {/* @TODO: third cta variant */}
            <OmniButton
              isCoupon
              onClick={handleOpen}
              dataTestId="coupon-add-button"
            >
              {t('cart:coupon:addCouponButtonText')}
            </OmniButton>
          </Wrapper>
        </Spacing>
      ) : (
        <Spacing mx="spacing-s">
          <Wrapper>
            <Spacing mr="spacing-xs" mt="spacing-xxs">
              <IconWrapper>
                <RemoveItem
                  disabled={!cart?.cartDiscount?.byDiscountCode?.code}
                  onClick={handleRemoveDiscount}
                  text="remove coupon"
                  aria-label="remove coupon"
                  data-testid="summary-coupon-remove"
                />
              </IconWrapper>
            </Spacing>
            <StyledForm onSubmit={handleSubmit} data-testid="coupon-form">
              <InputField
                isDisabled={
                  cartActionState === 'processing' ||
                  !!cart?.cartDiscount?.byDiscountCode?.code
                }
                isOptional
                label={t('cart:coupon:inputLabel')}
                message={{
                  text: couponMessage || '',
                }}
                name="coupon"
                onBlur={handleBlur}
                onChange={handleChange}
                ref={inputRef}
                status={couponStatus}
                value={discountCodeInput ?? ''}
                dataTestIdMessage={couponStatus}
                maxLength={100}
              />
            </StyledForm>
          </Wrapper>
        </Spacing>
      )}
    </>
  )
}
