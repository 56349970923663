import { useTheme } from 'styled-components'

export const useCardBorderColor = (hasBorder: boolean, isSelected: boolean) => {
  const { colors } = useTheme()

  if (hasBorder) {
    if (isSelected) {
      return colors['green-100']
    } else {
      return colors.lightgrey
    }
  } else {
    return colors.white
  }
}
