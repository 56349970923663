import React, { FunctionComponent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import type { Option } from '../../../providers/localeSelector'
import PaperplaneSVG from '../GeoIpModal/assets/doodles-md-erweitert-papierflieger.svg'
import { useLocaleSelectorContext } from '../../../providers/localeSelector'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { SidebarOverlay } from '../SidebarOverlay'
import {
  useSidebarOverlay,
  useSidebarOverlayProvider,
} from '../../../providers/SidebarOverlay'
import { LocaleSwitch } from './LocaleSwitch'

const PaperplaneWrapper = styled(motion.div)`
  justify-self: center;
  rotate: -20deg;
`

const RedirectContainer = styled.div`
  display: inline-grid;
  grid-template-rows: 1fr 5fr;
  gap: 1rem;
  align-items: center;
  text-align: center;
  justify-items: center;
`
const RedirectLabel = styled.span`
  font-size: 1.5rem;
  align-self: start;
`

type LocaleSelectorContainerProps = {
  handleHasSelectedOption: (hasSelectedOption: boolean) => void
}
export const LocaleSelectorContainer: FunctionComponent<
  LocaleSelectorContainerProps
> = ({ handleHasSelectedOption }) => {
  const { pushGtmV4Event } = useGtmV4()
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<Option | undefined>()
  const { lcccOptions, lcccSelectedOption, wasTriggeredByKeyboard } =
    useLocaleSelectorContext()

  const options = lcccOptions
    .map(({ lccc, label }) => ({
      lcCC: lccc,
      text: label,
      isActive: lcccSelectedOption.lccc === lccc,
    }))
    // Sort by label
    .sort((a, b) => a.text.localeCompare(b.text))
    // Move active element to the top
    .sort((a, b) => Number(b.isActive) - Number(a.isActive))

  const openLandingPage = useCallback(
    (lccc: string) => {
      const lcccOption = lcccOptions.find(o => o.lccc === lccc)
      setSelectedOption(lcccOption)
      if (lcccOption) {
        pushGtmV4Event({
          eventType: 'click',
          type: 'link',
          label: lcccOption.label,
          link: lcccOption.url,
        })

        handleHasSelectedOption(true)
        window.location.href = lcccOption.url
      }
    },
    [lcccOptions, pushGtmV4Event, handleHasSelectedOption]
  )

  const redirectLabelArticle = selectedOption
    ? t(`localeSelector:option:${selectedOption.lccc.toLowerCase()}:article`)
    : ''

  const redirectLabelFull = selectedOption
    ? t('localeSelector:redirectTo', {
        region:
          redirectLabelArticle +
          (!redirectLabelArticle.endsWith("'") ? ' ' : '') +
          selectedOption.label,
      })
    : ''

  return selectedOption ? (
    <RedirectContainer>
      <PaperplaneWrapper
        animate={{
          rotate: [20, -20, 20],
          translateX: [0, 30, 0],
          translateY: [10, -20, 10],
        }}
        transition={{
          duration: 4,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      >
        <PaperplaneSVG />
      </PaperplaneWrapper>
      <RedirectLabel>{redirectLabelFull}</RedirectLabel>
    </RedirectContainer>
  ) : (
    <LocaleSwitch
      availableLocales={options}
      id="country-selector"
      onSelectLocale={openLandingPage}
      shouldFocusFirstElement={wasTriggeredByKeyboard}
    />
  )
}

export const LocaleSelector = withGTMV4Context(
  LocaleSelectorContainer,
  'LocaleSelector'
)
export const useLocaleSelectorSidebar = () => {
  const { closeOverlay } = useSidebarOverlayProvider()
  const { t } = useTranslation()
  const [hasSelectedOption, setHasSelectedOption] = useState(false)

  return useSidebarOverlay(
    <SidebarOverlay
      headline={t('localeSelector:selector:label')}
      onClose={closeOverlay}
      id="LocaleSelectorSidebar"
      referenceTitle="[coded] LocaleSelectorSidebar"
      backgroundColor="lightblue-30"
      isHeadlineHidden
      isCloseButtonHidden={hasSelectedOption ? true : false}
    >
      <LocaleSelector handleHasSelectedOption={setHasSelectedOption} />
    </SidebarOverlay>
  )
}
