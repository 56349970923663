import styled from 'styled-components'

export type LayoutProps = {
  contentPositionMobile?: 'top' | 'bottom'
}

export const Layout = styled.div<LayoutProps>`
  position: relative;
  color: ${props =>
    props.theme.colorContext === 'light'
      ? 'inherit'
      : props.theme.colors.white};
  display: flex;
  flex-direction: ${props =>
    props.contentPositionMobile === 'bottom' ? 'column-reverse' : 'column'};
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoint.screenTablet}) {
    max-width: 75rem;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  picture {
    img {
      max-width: 100%;
    }
  }
`
