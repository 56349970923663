import React, { FunctionComponent, MouseEventHandler, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useGtmV4 } from '../../../../providers/gtmV4'
import { IconWithCount } from '@/tonies-ui/atoms/IconWithCountIndicator'
import styled from 'styled-components'
import { useWishlist } from '../../../../providers/Wishlist'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import { useAuth } from '../../../../providers/auth'
import { useModalNotifications } from '../../../../providers/ModalNotifications'
import { LoginModal } from '../../../../components/organisms/LoginModal'
import { fullUrl } from '../../../../utils/urlHelpers'

const StyledButtonLink = styled.a`
  ${cssReset}
  display: inline-block;
  cursor: pointer;
`

export const WishlistLink: FunctionComponent = () => {
  const { pushGtmV4Event } = useGtmV4()
  const { authenticated } = useAuth()
  const { t } = useTranslation()
  const { myDefaultWishlist, isPending } = useWishlist()
  const { setModal } = useModalNotifications()
  const count = myDefaultWishlist?.count || 0

  const handleClickWishlist: MouseEventHandler = useCallback(
    e => {
      if (isPending) {
        e.preventDefault()
      } else if (!authenticated) {
        e.preventDefault()
        setModal(
          <LoginModal
            headline={t('wishlist:loginModal:headline')}
            onClose={() => {
              setModal(undefined)
            }}
            redirectUri={
              myDefaultWishlist?.url || fullUrl(t('header:wishlistLink'))
            }
          >
            {t('wishlist:loginModal:text')}
          </LoginModal>
        )
      }

      pushGtmV4Event({
        eventType: 'click',
        type: 'onClick',
        label: '[coded] Wishlist Navigation Item',
        action: authenticated ? 'wishlist_show' : 'wishlist_access_denied',
      })
    },
    [
      authenticated,
      isPending,
      myDefaultWishlist?.url,
      pushGtmV4Event,
      setModal,
      t,
    ]
  )

  return (
    <StyledButtonLink
      href={myDefaultWishlist?.url || fullUrl(t('header:wishlistLink'))}
      data-testid="wishlist-link"
      onClick={handleClickWishlist}
    >
      <IconWithCount
        dataTestIdCount="wishlist-count"
        count={count}
        iconType="heart"
        isAnimated
        statusMessage={t('header:WishlistStatus', {
          count,
        })}
      />
    </StyledButtonLink>
  )
}
