/* eslint-disable react/jsx-pascal-case */
import React, { ComponentProps } from 'react'
import {
  MediaCopySectionSchema,
  isOfSchema,
  richTextDocumentSchema,
} from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { Aggregated } from '../../../../../../lib/transformation/aggregatePage/aggregate'
import { CM_BrazeFormSchema } from './BrazeFormSchema'
import { MediaCopySection } from '../../../../../../components/organisms/MediaCopySection'
import { CM_Accordion } from '../molecules/AccordionSchema'
import { Spacing } from '@boxine/tonies-ui'
import { RichText } from '../../../../../../components/molecules/RichText'
import { useMedia } from 'use-media'
import { useTheme } from 'styled-components'
import { useLottieAnimationData } from '../../../../../../hooks/useLottieAnimationData'

const Children = (
  isMobile: boolean,
  props?: Aggregated<NonNullable<MediaCopySectionSchema['content']>[0]>
) => {
  switch (props?.contentTypeId) {
    case 'brazeForm':
      return (
        <Spacing mb="spacing-l">
          <CM_BrazeFormSchema
            {...props}
            componentProps={{
              align: isMobile ? 'center' : 'left',
            }}
          />
        </Spacing>
      )
    case 'accordion':
      return (
        <Spacing mb="spacing-l">
          <CM_Accordion {...props} />
        </Spacing>
      )
    default:
      return null
  }
}

export const CM_MediaCopySectionSchema = ({
  componentProps,
  media,
  content,
  headlineAsHtmlTag,
  headline,
  text,
  primaryCtaText,
  primaryCtaUrl,
  secondaryCtaText,
  secondaryCtaUrl,
  videoInModal,
  ...rest
}: ContentModelType<
  ComponentProps<typeof MediaCopySection>,
  MediaCopySectionSchema
>) => {
  const theme = useTheme()
  const isMobile = useMedia({
    maxWidth: theme.screenTabletL,
  })

  const currentMediaGroup =
    media.contentTypeId === 'mediaGroup'
      ? isMobile
        ? media.mobile
        : media.desktop
      : undefined

  const isLottie = currentMediaGroup?.file.url.endsWith('.json')
  const animationData = useLottieAnimationData(
    isLottie ? currentMediaGroup?.file.url : undefined
  )

  if (!currentMediaGroup) {
    return null
  }
  return (
    <MediaCopySection
      hasMarginTop
      {...rest}
      {...componentProps}
      headline={{
        variant: 'Headline1',
        children: headline,
        asHTMLTag: headlineAsHtmlTag,
      }}
      text={{
        children:
          typeof text === 'string' ? (
            text
          ) : isOfSchema(text, richTextDocumentSchema) ? (
            <RichText document={text} align={isMobile ? 'center' : undefined} />
          ) : (
            text
          ),
      }}
      media={
        media.contentTypeId === 'mediaGroup'
          ? {
              positionX: media.positionX,
              positionY: media.positionY,
              desktop: {
                alt: media.alt,
                variant: currentMediaGroup.file.contentType.includes('video')
                  ? 'video--autoplay'
                  : isLottie
                  ? 'lottie'
                  : 'image',
                animationData: animationData,
                src: currentMediaGroup.file.url,
              },
              mobile: {
                alt: media.alt,
                variant: currentMediaGroup.file.contentType.includes('video')
                  ? 'video--autoplay'
                  : isLottie
                  ? 'lottie'
                  : 'image',
                animationData: animationData,
                src: currentMediaGroup.file.url,
                ratio: undefined,
              },
            }
          : media.contentTypeId === 'videoPlayer'
          ? {
              desktop: {
                alt: media.altText,
                variant: 'videoPlayer',
                src: media.desktopVideo.file.url,
                posterDesktop: media.desktopPosterImage.file.url,
              },
              mobile: {
                alt: media.altText,
                variant: 'videoPlayer',
                src: media.mobileVideo.file.url,
                posterMobile: media.mobilePosterImage.file.url,
              },
            }
          : {
              desktop: {
                alt: media.videoAlt,
                variant: 'videoPlayer--cloudinary',
                src: media.cloudinaryVideo[0].public_id,
                logoOnclickUrl: 'FIXME: DONT NEEDED - WILL BE OVERWRITTEN',
              },
              mobile: {
                alt: media.videoAlt,
                variant: 'videoPlayer--cloudinary',
                src: media.cloudinaryVideo[0].public_id,
                logoOnclickUrl: 'FIXME: DONT NEEDED - WILL BE OVERWRITTEN',
              },
            }
      }
      actions={{
        primary: {
          text: primaryCtaText,
          url: primaryCtaUrl,
        },
        secondary: {
          text: secondaryCtaText,
          url: secondaryCtaUrl,
        },
        ...componentProps?.actions,
      }}
      videoInModal={
        videoInModal && videoInModal[0]
          ? {
              ...componentProps?.videoInModal,
              videoPlayer: {
                cloudinaryPublicId: videoInModal[0].public_id,
                videoAlt: '',
                logoOnclickUrl: 'FIXME: DONT NEEDED - WILL BE OVERWRITTEN',
              },
            }
          : undefined
      }
    >
      {content?.map(props => Children(isMobile, props))}
    </MediaCopySection>
  )
}
