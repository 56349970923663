import React, { ComponentProps } from 'react'
import { TextHero } from '../../../../../../components/organisms/TextHero'
import { TextHeroSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'

export const CM_TextHeroSchema = ({
  componentProps,
  ...rest
}: ContentModelType<ComponentProps<typeof TextHero>, TextHeroSchema>) => {
  return <TextHero {...rest} {...componentProps} />
}
