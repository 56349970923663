import styled, { css } from 'styled-components'
import { PRODUCT_IMAGE_WIDTH_PX } from '../variables'

export const GridLayout = styled.div<{
  hasAction: boolean
  hasAudioSample: boolean
  hasBundleContents: boolean
  hasContents: boolean
  hasTopRightIconSlot: boolean
  hasAssignLink: boolean
  hasImage: boolean
  hasPrices: boolean
  hasQuantity: boolean
  orientation: 'portrait' | 'landscape'
}>`
  display: grid;
  gap: 0.5rem;
  width: 100%;

  ${
    // eslint-disable-next-line sonarjs/cognitive-complexity
    p => {
      const areas: string[] = []
      let columns: string

      if (p.orientation === 'portrait') {
        areas.push('audiosample audiosample top-right-icon-slot')
        p.hasImage && areas.push('image image image')
        areas.push('text text text')

        if (p.hasAssignLink) {
          areas.push('assign-link assign-link assign-link')
        }
        if (p.hasBundleContents) {
          areas.push('bundle-contents bundle-contents bundle-contents')
        }
        if (p.hasContents) {
          areas.push('contents contents contents')
        }

        if (p.hasQuantity) {
          areas.push('quantity quantity quantity')
        }
        if (p.hasPrices || p.hasAction) {
          areas.push('prices prices ' + (p.hasAction ? 'action' : 'prices'))
        }

        columns = 'max-content 1fr max-content'
      } else {
        areas.push('audiosample audiosample audiosample top-right-icon-slot')
        areas.push(`${p.hasImage ? 'image' : 'text'} text text text`)
        if (p.hasAssignLink) {
          areas.push(
            `${
              p.hasImage ? 'image' : 'assign-link'
            }  assign-link assign-link assign-link`
          )
        }
        if (p.hasBundleContents) {
          areas.push(
            `${
              p.hasImage ? 'image' : 'bundle-contents'
            } bundle-contents bundle-contents bundle-contents`
          )
        }
        if (p.hasContents) {
          areas.push(
            `${p.hasImage ? 'image' : 'contents'} contents contents contents`
          )
        }

        if (p.hasQuantity || p.hasPrices || p.hasAction) {
          areas.push(
            'quantity prices prices ' + (p.hasAction ? 'action' : 'prices')
          )
        }

        columns = `${PRODUCT_IMAGE_WIDTH_PX}px 1fr max-content auto`
      }

      return css`
        grid-template-areas: ${areas.map(line => "'" + line + "'").join(' ')};
        grid-template-columns: ${columns};
        grid-template-rows: max-content max-content 1fr;
      `
    }
  }
`
