import React, { FunctionComponent, ReactNode } from 'react'
import {
  Box,
  lineClampCss,
  Paragraph,
  ToniesColor,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import styled, { useTheme } from 'styled-components'
import { useProductFlag } from '../../../hooks/useProductFlag'

const Wrapper = styled.span`
  display: inline-block;
`

const LineClamp = styled.span`
  ${lineClampCss(2)}
`

export type NotchTypes = {
  text: ReactNode
  backgroundColor: ToniesColor
}

export const Notch = ({
  backgroundColor = 'lightergrey',
  text,
}: NotchTypes) => {
  const { colors } = useTheme()
  const isMobile = useIsViewportMobile()

  return (
    <Wrapper>
      <Box
        px={isMobile ? 'spacing-xxs' : 'spacing-s'}
        py="spacing-xxs"
        backgroundColor={colors[backgroundColor]}
        borderRadius={[0, 0, 'xs', 'xs']}
      >
        <Paragraph asHTMLTag="span" size={5} align="center" weight={700}>
          <LineClamp>{text}</LineClamp>
        </Paragraph>
      </Box>
    </Wrapper>
  )
}

export const NotchByProductFlag: FunctionComponent<{
  product: Pick<NormalizedProduct, 'flag'>
}> = ({ product }) => {
  const productFlag = useProductFlag(product)

  return productFlag ? (
    <Notch
      backgroundColor={productFlag.backgroundColor}
      text={productFlag.text}
    />
  ) : null
}
