import styled from 'styled-components'
import { GridArea, PriceProps } from '../types'

export const Container = styled.div<PriceProps>`
  display: grid;
  align-items: baseline;
  gap: 0 0.5rem;
  justify-content: ${p =>
    p.align === 'right' ? 'end' : p.align === 'center' ? 'center' : 'start'};
  text-align: ${p => p.align || 'left'};

  grid-template-areas: ${p => {
    const areas: GridArea[][] = []
    const { strikePrice, price, ecoTax } = 'product' in p ? p.product : p

    if (!price) {
      return undefined
    }

    if (strikePrice) {
      areas[0] = ['strike-price', 'price']
    } else {
      areas[0] = ['price']
    }

    if (p.vatHint?.position === 'inline') {
      areas[0].push('vat-hint')
    } else if (p.vatHint?.position === 'new-line') {
      if (areas[0].length === 2) {
        areas[1] = ['vat-hint', 'vat-hint']
      } else {
        areas[1] = ['vat-hint']
      }
    }

    if (ecoTax) {
      if (areas[0].length === 3) {
        areas.push(['eco-tax-hint', 'eco-tax-hint', 'eco-tax-hint'])
      } else if (areas[0].length === 2) {
        areas.push(['eco-tax-hint', 'eco-tax-hint'])
      } else {
        areas.push(['eco-tax-hint'])
      }
    }

    return areas.map(areas => JSON.stringify(areas.join(' '))).join(' ')
  }};
`
