import React from 'react'
import Head from 'next/head'

type CanonicalProps = {
  pageUrl: string
}

export const Canonical = ({ pageUrl }: CanonicalProps) => (
  <Head>
    <link rel="canonical" href={pageUrl} />
  </Head>
)
