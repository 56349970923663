import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import {
  Footer as FooterOrigin,
  FooterMetaNavigation,
  FooterMetaNavigationItem,
  FooterList,
  FooterListLink,
  FooterListLinkExternal,
  FooterCollapse,
  FooterInput,
  FooterInputButton,
  FooterInputIcon,
  ContactLink,
  AssistiveTechnologyOnly,
  FacebookIcon,
  InstagramIcon,
  YouTubeIcon,
  TwitterIcon,
  PinterestIcon,
  TikTokIcon,
  media,
  useIsViewportDesktop,
  Paragraph,
  Spacing,
} from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import * as icons from '@boxine/tonies-ui/icons'
import { useRouter } from 'next/router'
import { LinkWithOptionalIcon } from '../../../lib/contentful/datamodel/schemas'
import { Document } from '@contentful/rich-text-types'
import { RichText } from '../../molecules/RichText'
import { PaymentIconList } from '../../molecules/PaymentIconList'
import { useMetaItems } from '../../../hooks/useHeaderData'
import { useLocale } from '../../../providers/locale'
import { useBrazeFormik } from '../../../hooks/useBrazeFormik'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { isEcomLocale } from '../../../config/shopAPI'
import { shopConfig } from '../../../config/shop'
import { useConsent } from '../../../hooks/useConsent'
import { ToniesMotions } from '@/tonies-ui/motions'
import { LocaleSelectorButton } from '../MetaNavigation/components/LocaleSelectorButton'
import { useWishlist } from '../../../providers/Wishlist'

export type FooterProps = {
  addressColumn1?: Document
  addressColumn2?: Document
  addressTitle?: string
  closingText?: Document
  hasNewsletterSignup?: boolean
  hasScollingHeads?: boolean
  legalLinksAdditionalEntries?: LinkWithOptionalIcon[]
  linksList1Entries?: LinkWithOptionalIcon[]
  linksList1Title?: string
  linksList2Entries?: LinkWithOptionalIcon[]
  linksList2Title?: string
  socialMediaLinksEntries?: LinkWithOptionalIcon[]
}

const socialMediaLinkIcon = {
  facebookV2: FacebookIcon,
  instagramV2: InstagramIcon,
  youtubeV2: YouTubeIcon,
  twitterV2: TwitterIcon,
  pinterestV2: PinterestIcon,
  tikTokV2: TikTokIcon,
} as const

const Wrapper = styled.div`
  /* Reduces the hardcoded bottom padding in the (not styleable) Footer */
  margin-bottom: -2rem;
  overflow: hidden;
`

// The footer needs some global styles that are not included in the tonies-ui package
const NewsletterWrapper = styled.div`
  p {
    font-size: inherit;
    line-height: 1.4;
    margin-bottom: 0.75rem;
    text-rendering: optimizeSpeed;
  }

  form p {
    font-size: 0.75rem;
    margin-bottom: 0;
  }

  button {
    line-height: 1;
  }

  input::placeholder {
    color: #9b9999;
  }

  [type='email'] {
    font-size: 16px;
    font-size: 1rem;
  }

  ${media.laptop`
    grid-column: 4;
  `}
`

const AddressWrapper = styled.div`
  section > div {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 0.75rem;
  }

  p {
    line-height: 1.8;
  }

  a {
    text-decoration: none;
  }

  ${media.laptop`
    grid-column: 1 / span 2;
  `}
`

const PaymentsWrapper = styled.div`
  padding-top: 3.75rem;

  ${media.laptop`
    padding-top: 2.625rem;
  `}
`

const PaymentIconListWrapper = styled.span`
  display: block;
  padding-top: 0.5rem;
`

const StyledSpan = styled.span`
  strong {
    font-weight: 700;
  }
`

const VisuallyHiddenText = styled.p`
  ${AssistiveTechnologyOnly};
`

export const FooterContainer: FunctionComponent<FooterProps> = ({
  addressColumn1,
  addressColumn2,
  addressTitle,
  closingText,
  hasNewsletterSignup,
  hasScollingHeads,
  legalLinksAdditionalEntries,
  linksList1Entries,
  linksList1Title,
  linksList2Entries,
  linksList2Title,
  socialMediaLinksEntries,
}) => {
  const lcCC = useLocale()
  const { t } = useTranslation()
  const { hasWishlist } = useWishlist()

  const { showSecondUcUILayer } = useConsent()
  const { legalLinks } = shopConfig
  const { pushGtmV4Event } = useGtmV4()
  const asPath = useRouter()?.asPath || 'undefined'
  const metaNavigationItems = useMetaItems()

  const isDesktop = useIsViewportDesktop()

  const socialMediaLinkItemsComponents = socialMediaLinksEntries
    ? socialMediaLinksEntries.map(item => ({
        to: item.link,
        title: item.text,
        component:
          socialMediaLinkIcon[item.icon as keyof typeof socialMediaLinkIcon] ||
          (() => null),
        onClick: () =>
          pushGtmV4Event({
            eventType: 'click',
            type: 'link',
            label: item.text,
            link: item.link,
          }),
      }))
    : []

  const formik = useBrazeFormik({
    variant: 'Newsletter',
    formLocation: 'footer',
  })

  return (
    <ColorContextProvider backgroundColor="#000">
      <ToniesMotions.TonieEars fill="darkergrey" />
      {hasScollingHeads && (
        <ToniesMotions.ScrollingTonieHeads
          firstColor="1-color-7-campaigns-christmas-2022-20"
          secondColor="darkergrey"
          hasCurve
        />
      )}
      <Wrapper>
        <FooterOrigin
          topLinks={
            <>
              <ContactLink
                to={t('footer:contact.url')}
                onClick={() =>
                  pushGtmV4Event({
                    eventType: 'click',
                    type: 'link',
                    label: t('footer:contact.label'),
                    link: t('footer:contact.url'),
                  })
                }
              >
                {t('footer:contact.label')}
              </ContactLink>
              {hasWishlist && <LocaleSelectorButton position="footer" />}
            </>
          }
          copyright={`© ${new Date().getFullYear()} tonies GmbH`}
          metaNavigation={
            <FooterMetaNavigation>
              {metaNavigationItems.map(item => (
                <FooterMetaNavigationItem
                  key={item.link}
                  to={item.link}
                  isActive={asPath.startsWith(item.link)}
                  onClick={() =>
                    pushGtmV4Event({
                      eventType: 'click',
                      type: 'link',
                      label: item.text.replace(/(<([^>]+)>)/gi, ''),
                      link: item.link,
                    })
                  }
                >
                  <StyledSpan dangerouslySetInnerHTML={{ __html: item.text }} />
                </FooterMetaNavigationItem>
              ))}
            </FooterMetaNavigation>
          }
          socialMediaLinks={socialMediaLinkItemsComponents}
          legalNavigation={null} // We don't use the `legalNavigation`, but the prop is required
          closingText={
            closingText ? (
              <RichText document={closingText} size={3} />
            ) : undefined
          }
        >
          {linksList1Title && linksList1Entries && (
            <FooterCollapse title={linksList1Title}>
              <FooterList>
                {linksList1Entries.map(item => (
                  <FooterListLinkExternal
                    key={item.link}
                    href={item.link}
                    onClick={() =>
                      pushGtmV4Event({
                        eventType: 'click',
                        type: 'link',
                        label: item.text,
                        link: item.link,
                      })
                    }
                  >
                    {item.text}
                  </FooterListLinkExternal>
                ))}
              </FooterList>
            </FooterCollapse>
          )}
          {linksList2Title && linksList2Entries && (
            <FooterCollapse title={linksList2Title}>
              <FooterList>
                {linksList2Entries.map(item => (
                  <FooterListLinkExternal
                    key={item.link}
                    href={item.link}
                    onClick={() =>
                      pushGtmV4Event({
                        eventType: 'click',
                        type: 'link',
                        label: item.text,
                        link: item.link,
                      })
                    }
                  >
                    {item.text}
                  </FooterListLinkExternal>
                ))}
              </FooterList>
            </FooterCollapse>
          )}
          <FooterCollapse title={t('footer:legal.title')}>
            <FooterList>
              <FooterListLinkExternal
                href={legalLinks.imprint}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  pushGtmV4Event({
                    eventType: 'click',
                    type: 'link',
                    label: t('footer:legal.imprint.label'),
                    link: legalLinks.imprint,
                  })
                }
              >
                {t('footer:legal.imprint.label')}
              </FooterListLinkExternal>
              <FooterListLinkExternal
                href={legalLinks.termsOfService}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  pushGtmV4Event({
                    eventType: 'click',
                    type: 'link',
                    label: t('footer:legal.termsOfService.label'),
                    link: legalLinks.termsOfService,
                  })
                }
              >
                {t('footer:legal.termsOfService.label')}
              </FooterListLinkExternal>
              <FooterListLinkExternal
                href={legalLinks.privacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  pushGtmV4Event({
                    eventType: 'click',
                    type: 'link',
                    label: t('footer:legal.privacyPolicy.label'),
                    link: legalLinks.privacyPolicy,
                  })
                }
              >
                {t('footer:legal.privacyPolicy.label')}
              </FooterListLinkExternal>
              {legalLinksAdditionalEntries &&
                legalLinksAdditionalEntries.map(item => (
                  <FooterListLinkExternal
                    key={item.link}
                    href={item.link}
                    onClick={() =>
                      pushGtmV4Event({
                        eventType: 'click',
                        type: 'link',
                        label: item.text,
                        link: item.link,
                      })
                    }
                  >
                    {item.text}
                  </FooterListLinkExternal>
                ))}
              <FooterListLink
                data-testid="footer-cmp-button"
                href="#"
                onClick={(e: { preventDefault: () => void }) => {
                  e.preventDefault()
                  showSecondUcUILayer()
                }}
              >
                {t('footer:legal.cookieSettings.label')}
              </FooterListLink>
            </FooterList>
          </FooterCollapse>
          {hasNewsletterSignup && (
            <NewsletterWrapper>
              <FooterCollapse
                title={t('footer:newsletter.title')}
                isOpenByDefault
              >
                {formik.status === 'SUCCESS' && (
                  <p>{t('newsletter:success:text')}</p>
                )}
                {formik.status === 'IDLE' && (
                  <>
                    <Spacing mb="spacing-s">
                      <p>{t('footer:newsletter.text')}</p>
                    </Spacing>
                    <form onSubmit={formik.handleSubmit} noValidate>
                      <FooterInput
                        label={t('footer:newsletter.input.label')}
                        placeholder={t('footer:newsletter.input.placeholder')}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        name="email"
                        type="email"
                        isRequired
                        autoComplete="off"
                        className={
                          formik.errors.email && formik.touched.email
                            ? 'error'
                            : undefined
                        }
                      >
                        <FooterInputButton type="submit">
                          <VisuallyHiddenText>
                            {t('brazeForm:label:submit')}
                          </VisuallyHiddenText>
                          {/* title={null} prevent screenreader to show the wrong title */}
                          <FooterInputIcon
                            type={icons.arrowRight}
                            title={null}
                          />
                        </FooterInputButton>
                      </FooterInput>
                      {formik.errors.email && formik.touched.email && (
                        <p style={{ marginTop: '-1rem', marginBottom: '1rem' }}>
                          {formik.errors.email}
                        </p>
                      )}
                      <Paragraph size={4}>
                        {t('brazeForm:consent:withoutCtaLabel')}{' '}
                        <a
                          href={shopConfig.legalLinks.privacyPolicy}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('brazeForm:consent:privacyPolicy')}
                        </a>
                        .
                      </Paragraph>
                    </form>
                  </>
                )}
              </FooterCollapse>
            </NewsletterWrapper>
          )}
          {addressTitle && addressColumn1 && addressColumn2 && (
            <AddressWrapper>
              <FooterCollapse title={addressTitle}>
                <div>
                  <RichText document={addressColumn1} size={3} />
                  <RichText document={addressColumn2} size={3} />
                </div>
              </FooterCollapse>
            </AddressWrapper>
          )}
          {isEcomLocale(lcCC) && (
            <PaymentsWrapper>
              <Paragraph
                color="white"
                size={3}
                align={isDesktop ? 'left' : 'center'}
              >
                {t('footer:paymentMethods.title')}
                <PaymentIconListWrapper>
                  <PaymentIconList
                    iconAlignment={isDesktop ? 'left' : 'center'}
                    iconSize="s"
                    iconHasBorder={false}
                    context="global"
                  />
                </PaymentIconListWrapper>
              </Paragraph>
              {t('footer:paymentMethods.disclaimer') && (
                <Spacing mt="spacing-xs">
                  <Paragraph
                    color="white"
                    size={4}
                    align={isDesktop ? 'left' : 'center'}
                  >
                    {t('footer:paymentMethods.disclaimer')}
                  </Paragraph>
                </Spacing>
              )}
            </PaymentsWrapper>
          )}
        </FooterOrigin>
      </Wrapper>
    </ColorContextProvider>
  )
}

export const Footer = withGTMV4Context(FooterContainer, 'Footer')
