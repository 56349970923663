import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useToniesCookies } from '../../hooks/useToniesCookies'
import { useUrlQuery } from '../../providers/urlQuery'
import { useSideCartOverlay } from './hooks/useSideCartOverlay'

export type SideCartProps = ReturnType<typeof useSideCartOverlay>

export const sideCartContext = createContext<SideCartProps>([
  false,
  () => false,
])

export const SideCartProvider: FunctionComponentWithChildren<{
  autoExpandOnAddToCart?: boolean
}> = ({ autoExpandOnAddToCart = true, children }) => {
  const { cartItemCount } = useToniesCookies()
  const { getUrlParamAsSingleton, setUrlParam } = useUrlQuery()
  const urlExpandSideCart = getUrlParamAsSingleton('expandSideCart')
  const prevCartItemCount = useRef(cartItemCount)
  const [isOpen, setIsOpen] = useSideCartOverlay()

  // expand side-cart on change of cart item count
  useEffect(() => {
    if (cartItemCount > prevCartItemCount.current && autoExpandOnAddToCart) {
      setIsOpen(true)
    }
    prevCartItemCount.current = cartItemCount
  }, [autoExpandOnAddToCart, cartItemCount, prevCartItemCount, setIsOpen])

  // expand side-cart by query param
  useEffect(() => {
    if (urlExpandSideCart) {
      if (autoExpandOnAddToCart) {
        setIsOpen(true)
      }
      setUrlParam({ expandSideCart: undefined })
    }
  }, [autoExpandOnAddToCart, setUrlParam, setIsOpen, urlExpandSideCart])

  return (
    <sideCartContext.Provider value={[isOpen, setIsOpen]}>
      {children}
    </sideCartContext.Provider>
  )
}

export const useSideCart = () => useContext(sideCartContext)
