import Head from 'next/head'
import React from 'react'
import { shopConfig } from '../../../../config/shop'
import { localesInclExternal } from '../../../../config/shopAPI/types'
import { homepageUrl } from '../../../../utils/urlHelpers'

export const HomepageAlternates = () => {
  return (
    <Head>
      {localesInclExternal.map(lcCC => {
        const hrefLangs = shopConfig.locales[lcCC].hrefLangs || [
          `${lcCC.toLowerCase()}`,
        ]
        return hrefLangs.map(hrefLang => (
          <link
            key={lcCC + hrefLang}
            rel="alternate"
            hrefLang={hrefLang}
            href={homepageUrl(lcCC)}
          />
        ))
      })}
      <link rel="alternate" hrefLang="x-default" href={homepageUrl('en-EU')} />
    </Head>
  )
}
