import { FocusStyles, Icon, Paragraph } from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import styled, { css, useTheme } from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import React, { ComponentProps, FunctionComponent, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { CloudinaryVideoPlayer } from '@/tonies-ui/molecules/CloudinaryVideoPlayer'
import { cssReset } from '@/tonies-ui/helpers/css-helper'
import { motion } from 'framer-motion'
import { Modal } from '@/tonies-ui/molecules/Modal'

type VideoInModalButtonProps = {
  onClick?: () => void
  direction: 'ltr' | 'rtl'
}

const StyledButton = styled(motion.div)<{
  $direction: VideoInModalButtonProps['direction']
}>`
  ${({ $direction }) => css`
    ${FocusStyles}
    ${cssReset}
    background-color: rgba(255, 255, 255, 0.8);
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    bottom: 0;
    padding: 0.5rem 2rem;
    position: absolute;
    z-index: 1;
    cursor: pointer;

    ${$direction === 'ltr' && 'right: 1rem;'}
    ${$direction === 'rtl' && 'left: 1rem;'}
  `}
`

const ButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`

const VideoInModalButton: FunctionComponent<VideoInModalButtonProps> = ({
  onClick,
  direction,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <ColorContextProvider backgroundColor="#fff">
      <StyledButton $direction={direction} onClick={onClick}>
        <ButtonWrapper>
          <Icon
            type={icons.playFilled2}
            fill={theme.colors.primary}
            height="2rem"
            width="2rem"
          />
          <Paragraph asHTMLTag="span" size={2}>
            {t('videoInModal:text')}
          </Paragraph>
        </ButtonWrapper>
      </StyledButton>
    </ColorContextProvider>
  )
}

export const VideoInModal: FunctionComponent<
  VideoInModalButtonProps & {
    videoPlayer: ComponentProps<typeof CloudinaryVideoPlayer>
  }
> = ({ direction, videoPlayer, onClick = () => null }) => {
  const [showVideo, setShowVideo] = useState(false)

  return (
    <>
      <VideoInModalButton
        direction={direction}
        onClick={() => {
          onClick()
          setShowVideo(true)
        }}
      />
      <Modal
        isOpen={showVideo}
        onClose={() => setShowVideo(false)}
        size="large"
      >
        <CloudinaryVideoPlayer {...videoPlayer} />
      </Modal>
    </>
  )
}
