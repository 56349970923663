import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  SupportedFilterKeys,
  useOsProductFilters,
} from '../../../hooks/useProductFilter'
import {
  getProducts,
  getNewFilters as getNewFiltersFunc,
  isNoFilterActive as isNoFilterActiveFunc,
  getNewFiltersType,
} from '../actions'
import { ProductOverviewProviderProps } from '../ProductOverviewProvider'
import { ProductApiType } from '../../../providers/productOverview/types'
import { getSorting } from '../../../lib/opensearch/util/sorting'
import { SupportedSortingIds } from 'lib/opensearch/requests/products/types'

export const useStateProductOverview = ({
  products,
  aggregations,
  parameter,
  api,
  promotions,
  hasLoadMoreButton,
  productsPerPage = 24,
}: Pick<
  ProductOverviewProviderProps,
  'promotions' | 'hasLoadMoreButton' | 'productsPerPage' | 'shopCategory'
> &
  NormalizedOpenSearchProductsResponse & {
    api: ProductApiType
  }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const filterBase = useOsProductFilters({
    aggregations,
  })
  const [filters, setFilters] = useState(filterBase)
  /**
   * sorting
   */
  const [sortingId, setSortingId] = useState<string | undefined>(
    parameter?.sort?.id
  )

  const sorting = useMemo(
    () =>
      getSorting({
        ...parameter,
        sort: { ...parameter?.sort, id: sortingId as SupportedSortingIds },
      }),
    [parameter, sortingId]
  )

  const itemsMax = products.length + (promotions ? promotions.length : 0)
  const [showItemMax, setShowItemMax] = useState(productsPerPage)
  const [page, setPage] = useState(api === 'legacy' ? 0 : 1)
  const handleSetPage = useCallback((page: number) => {
    setPage(page)
  }, [])

  // set shown products by update
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    // we want to make sure that this useEffect does nothing in the first render cycle for
    // performance reasons - espacially when we want to show all products, where we show
    // a subset of all products first and show all products after the first render cycle
    if (!isFirstRender) {
      // show all products if we have no load more button in the second render cycle
      setShowItemMax(!hasLoadMoreButton ? itemsMax : productsPerPage)
    } else {
      setIsFirstRender(false)
    }
  }, [hasLoadMoreButton, itemsMax, isFirstRender, productsPerPage])

  const isNoFilterActive = useMemo(
    () => isNoFilterActiveFunc(filters),
    [filters]
  )

  const matchingProducts = useMemo(
    () =>
      api === 'openSearch'
        ? products
        : getProducts(products, filters, searchTerm),
    [api, products, filters, searchTerm]
  )

  const getNewFilters = useCallback(
    ({
      products: productProp = products,
      filters: filtersProp = filters,
      search = searchTerm,
      updateSingleFilter,
    }: Partial<getNewFiltersType>) =>
      getNewFiltersFunc({
        products: productProp,
        filters: filtersProp,
        search,
        updateSingleFilter,
      }),
    [filters, products, searchTerm]
  )

  const handleFilterChange = useCallback(
    (key: SupportedFilterKeys, value: boolean, id: string) => {
      setPage(1)
      const newFilters = getNewFilters({
        updateSingleFilter: {
          key,
          value,
          id,
        },
      })
      setFilters(newFilters)

      return newFilters
    },
    [getNewFilters]
  )

  const handleUpdateSearch = useCallback(
    (term: string) => {
      setPage(1)
      const newFilters = getNewFilters({
        search: term,
      })

      // update state
      setFilters(newFilters)
      setSearchTerm(term)
      return newFilters
    },
    [getNewFilters]
  )

  const resetAll = useCallback(
    (clearSearch = true) => {
      setPage(1)
      setFilters(filterBase)

      if (clearSearch) setSearchTerm('')
    },
    [filterBase]
  )

  const clearSearch = useCallback(() => {
    if (searchTerm !== '') {
      setPage(1)
      setSearchTerm('')
    }
  }, [searchTerm])

  return {
    search: {
      searchValue: searchTerm,
      setSearchTerm: handleUpdateSearch,
      clearSearch,
    },
    filters: {
      filters,
      isFilterActive: !isNoFilterActive,
      handleFilterChange,
    },
    sorting: { setSortingId, ...sorting },
    loadMore: {
      showItemMax,
      setShowItemMax,
    },
    matchingProducts,
    resetAll,
    pagination: {
      page: page,
      setPage: handleSetPage,
    },
  }
}
