import React, { ReactNode } from 'react'
import { useTheme } from 'styled-components'
import {
  Paragraph,
  ParagraphProps,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import { Content } from '@/tonies-ui/atoms/Card/components/Content'
import { Note } from '@/tonies-ui/atoms/Card/components/Note'
import { Wrapper } from '@/tonies-ui/atoms/Card/components/Wrapper'
import { ColorContextProvider } from '@/tonies-ui/index'
import { AnimatePresence } from 'framer-motion'
import {
  LowerIt,
  createLowerItAnimationWithCustomDelay,
} from '@/tonies-ui/motions/LowerIt'

export type CardProps = {
  borderColor?: string
  dataTestExtra?: string
  dataTestId?: string
  dataTrackingId?: string
  fillColor?: string
  isGreyToned?: boolean
  note?: {
    fillColor?: string
    text: ReactNode
    textColor?: ParagraphProps['color']
    allowMultiline?: boolean
    animationDelay?: number
  }
  onClick?: () => void
}

export const Card: NormalizedFunctionComponent<CardProps> = ({
  borderColor,
  children,
  dataTestExtra,
  dataTestId,
  dataTrackingId,
  fillColor,
  isGreyToned = false,
  note,
  onClick,
}) => {
  const { colors } = useTheme()
  const isMobile = useIsViewportMobile()

  return (
    <ColorContextProvider backgroundColor="#fff">
      <Wrapper
        data-testextra={dataTestExtra}
        data-testid={dataTestId}
        data-trackingid={dataTrackingId}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        isGreyToned={isGreyToned}
      >
        <Content
          borderColor={borderColor || colors.lightgrey}
          borderRadius={['s']}
          backgroundColor={fillColor || colors.white}
          p={isMobile ? 'spacing-xxs' : 'spacing-xs'}
        >
          {children}
        </Content>
        <AnimatePresence>
          {note && (
            <LowerIt
              animate={createLowerItAnimationWithCustomDelay(
                note?.animationDelay || 0.75
              )}
            >
              <Note
                backgroundColor={note.fillColor || colors['lightblue-30']}
                allowMultiline={note.allowMultiline || false}
                borderRadius={[0, 0, 's', 's']}
                px="spacing-s"
                data-testid={`${dataTestId}__card-note`}
              >
                <Paragraph
                  align="center"
                  color={note.textColor || 'darkergrey'}
                  size={4}
                  weight={500}
                >
                  {note.text}
                </Paragraph>
              </Note>
            </LowerIt>
          )}
        </AnimatePresence>
      </Wrapper>
    </ColorContextProvider>
  )
}
