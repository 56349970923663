import React, { useMemo } from 'react'
import {
  useSidebarOverlay,
  useSidebarOverlayProvider,
} from '../../../providers/SidebarOverlay'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'
import { useTranslation } from 'next-i18next'
import { Cart } from '../../../layouts/CartLayout/components/Cart'
import { SidebarOverlay } from '../../../components/organisms/SidebarOverlay'
import { useCartState } from '../../../hooks/useCartState'

const SideCartOverlay = () => {
  const { t } = useTranslation()
  const {
    content: { cartDeliveryInfo, cartDeliveryInfoTeaserList },
  } = useAggregatedShopLocale()
  const { closeOverlay } = useSidebarOverlayProvider()
  const {
    cart,
    isPendingState: [isPending],
  } = useCartState()

  const isEmpty = useMemo(() => {
    return (cart?.lineItems.length || 0) <= 0
  }, [cart?.lineItems.length])

  return (
    <SidebarOverlay
      headline={t('cart:headline')}
      onClose={closeOverlay}
      id="side-cart"
      referenceTitle="side-cart"
      backgroundColor={!isEmpty ? undefined : 'lightblue-20'}
      isPending={isPending}
    >
      <Cart
        layout="narrow"
        cartDeliveryInfo={cartDeliveryInfo}
        cartDeliveryInfoTeaserList={cartDeliveryInfoTeaserList}
        handleClose={closeOverlay}
      />
    </SidebarOverlay>
  )
}

export const useSideCartOverlay = () => useSidebarOverlay(<SideCartOverlay />)
