import React, { useRef } from 'react'
import Head from 'next/head'

export const Build = () => (
  <Head>
    <meta
      name="bx-commit-short-sha"
      content={process.env.NEXT_PUBLIC_COMMIT_SHORT_SHA}
    />
    <meta
      name="bx-datetime"
      content={useRef(new Date().toISOString()).current}
    />
    <meta name="bx-environment" content={process.env.NEXT_PUBLIC_ENVIRONMENT} />
    <meta name="bx-vercel-env" content={process.env.NEXT_PUBLIC_VERCEL_ENV} />
    <meta name="bx-node-env" content={process.env.NODE_ENV} />
  </Head>
)
