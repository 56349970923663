import React from 'react'
import styled from 'styled-components'
import { usePaymentTypes } from '../../../hooks/usePaymentTypes'
import { PaymentIcon, IconSize } from '../../atoms/PaymentIcon'
import type { PaymentIconName } from '../../../providers/payment/lib'
import { PaymentIconContext } from '../../../providers/paymentTypes'

type IconAlignment = 'left' | 'center' | 'right'

export type PaymentIconListProps = {
  iconAlignment?: IconAlignment
  iconSize?: IconSize
  iconHasBorder?: boolean
  iconsToMark?: PaymentIconName[]
  context: PaymentIconContext
}

const Wrapper = styled.span<{
  iconAlignment: IconAlignment
}>`
  display: flex;
  justify-content: ${props =>
    props.iconAlignment === 'center'
      ? 'center'
      : props.iconAlignment === 'right'
      ? 'flex-end'
      : 'flex-start'};
  gap: 0.75rem;
  flex-wrap: wrap;
`

const PaymentIconWrapper = styled.span`
  display: flex;
`

export const PaymentIconList = ({
  iconAlignment = 'left',
  iconSize = 'l',
  iconHasBorder = true,
  iconsToMark,
  context,
}: PaymentIconListProps) => {
  const { getPaymentIcons } = usePaymentTypes()
  const paymentIcons = getPaymentIcons(context)

  return (
    <>
      {paymentIcons.length && (
        <Wrapper iconAlignment={iconAlignment}>
          {paymentIcons.map((paymentType, i) => {
            return (
              <PaymentIconWrapper key={i}>
                <PaymentIcon
                  icon={paymentType.toLowerCase() as PaymentIconName}
                  hasBorder={iconHasBorder}
                  size={iconSize}
                  key={i}
                />
                {iconsToMark?.includes(paymentType) && <span>*</span>}
              </PaymentIconWrapper>
            )
          })}
        </Wrapper>
      )}
    </>
  )
}
