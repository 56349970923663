import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { HeadlineMapper } from '@boxine/tonies-ui'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import {
  SectionHeadlineView,
  SectionHeadlineViewProps,
} from './components/View'

export type SectionHeadlineProps = {
  colorContext?: DefaultTheme['colorContext']
  title?: string
  headlineAsHTMLTag?: HeadlineMapper['asHTMLTag']
  headlineAsStyleTag?: HeadlineMapper['variant']
  text?: string
  weight?: 500 | 700
} & Pick<
  SectionHeadlineViewProps,
  'primaryCtaText' | 'secondaryCtaText' | 'primaryCtaUrl' | 'secondaryCtaUrl'
>

const StyledWrapper = styled.div`
  margin-bottom: 2em;
`

export const SectionHeadline = ({
  className,
  title,
  headlineAsHTMLTag,
  text,
  primaryCtaText,
  primaryCtaUrl,
  secondaryCtaText,
  secondaryCtaUrl,
  headlineAsStyleTag = 'Headline2',
  weight = 500,
}: SectionHeadlineProps & Styleable) => {
  return title || text ? (
    <StyledWrapper className={className}>
      <SectionHeadlineView
        headline={{
          variant: headlineAsStyleTag,
          children: title,
          asHTMLTag: headlineAsHTMLTag,
          align: 'center',
          weight: weight,
        }}
        subheadline={{ children: text, align: 'center' }}
        primaryCtaText={primaryCtaText}
        primaryCtaUrl={primaryCtaUrl}
        secondaryCtaText={secondaryCtaText}
        secondaryCtaUrl={secondaryCtaUrl}
      />
    </StyledWrapper>
  ) : null
}
