import styled, { css } from 'styled-components'
import { theme } from '../../../tonies-ui/themes/theme'
import { Styling, Status } from './types'
import { COLOR } from './variables'

export const DisabledWrapper = styled.div<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    opacity: 66.66%;
    `}
`

export const FieldWrapper = styled.div<{
  isDisabled: boolean
  isBorderless?: boolean
  status?: Status
  styling?: Styling
  isSelect?: boolean
}>`
  display: inline-block;
  width: 100%;
  margin: 0.25rem 0;
  position: relative;

  > select {
    cursor: pointer;
  }

  > input,
  select {
    appearance: none;
    background-color: transparent;
    border-radius: 0;
    border-color: ${({ status, styling }) =>
      status === 'error'
        ? COLOR.ERROR
        : status === 'ok'
        ? COLOR.SUCCESS
        : styling === 'secondary'
        ? theme.colors.white
        : theme.colors.darkergrey};
    border-style: solid;
    border-width: 0 0 ${({ isBorderless }) => (isBorderless ? '0' : '1px')} 0;
    color: ${({ styling }) =>
      styling === 'secondary' ? theme.colors.white : theme.colors.darkergrey};
    display: block;
    font-family: ${props => props.theme.fonts.serif};
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.38;
    padding: 0.25rem 0;
    margin: 0;
    width: 100%;

    &::placeholder {
      opacity: 0;
    }

    &:focus {
      outline: none;
    }

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        cursor: not-allowed;
        text-overflow: ellipsis;
      `}
  }

  > svg {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
`
export const StatusWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  div {
    display: inline-flex;
    gap: 0.5rem;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`
export const StatusWrapperInputField = styled.div`
  display: flex;
  gap: 0.5rem;

  p {
    flex-grow: 1;
  }
  svg {
    width: 1rem;
    height: 1rem;
    justify-self: flex-end;
  }
  a {
    color: ${props => props.theme.colors.darkergrey};
  }
`
