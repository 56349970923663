import { useTranslation } from 'next-i18next'
import { useEcomLocale } from '../../../providers/locale'
import { homepageUrl } from '../../../utils/urlHelpers'
import { hasSeriesGroupAndSeriesCategoryKeys } from '../../../types/normalize/product'

type Breadcrumb = {
  label: string
  url: string
}

export const useBreadcrumbs = (product: NormalizedProduct) => {
  const lcCC = useEcomLocale()
  const { t } = useTranslation()

  const breadcrumbs: Breadcrumb[] = []

  /**
   * Breadcrumbs level 1
   * Homepage URL
   * "Home"
   */
  const lv1Url = homepageUrl(lcCC)
  breadcrumbs.push({
    label: t('productDetailPage:breadcrumbs.home'),
    url: lv1Url,
  })

  /**
   * Breadcrumbs level 2
   * Product category overview URL
   * "Home > Accessories"
   * "Home > Tonies"
   * ...
   * Prerequisite: The URL maintained in contentful for the overview page
   * will have to match the category's slug maintained in commercetools
   */
  const lv2Url = lv1Url + product.normalizedCategories[0].slug + '/'
  breadcrumbs.push({
    label: product.normalizedCategories[0].name,
    url: lv2Url,
  })

  /**
   * Breadcrumbs level 3
   * Product category specific URLs
   */

  /**
   * Accessory subcategory overview URL
   * "Home > Accessories > Electronic"
   * "Home > Accessories > Home & Deco"
   * ...
   * Prerequisite: The URL maintained in contentful for the overview page
   * will have to match the category's slug maintained in commercetools
   */
  if (product.normalizedCategories[0].key === 'accessories') {
    const attr = product.normalizedCategories[1]
    breadcrumbs.push({
      label: attr.name,
      url: lv2Url + attr.slug + '/',
    })
  }

  /**
   * Audio Content OR Tonies series (!) URL
   * "Home > Audio Content > Disney"
   * "Home > Tonies > Disney"
   * ...
   */
  if (
    hasSeriesGroupAndSeriesCategoryKeys.includes(
      product.normalizedCategories[0].key
    ) &&
    product.seriesGroup &&
    product.series
  ) {
    breadcrumbs.push({
      label: product.seriesGroup.label,
      url: `${lv2Url}?series=${product.seriesGroup.key}&series=${product.series.key}`,
    })
  }

  return breadcrumbs
}
