import { ToniesMotions } from '@/tonies-ui/motions'
import { media } from '@boxine/tonies-ui'
import styled from 'styled-components'

export const ProductImageWrapper = styled(ToniesMotions.ZoomIt)`
  margin: -3rem 0 -1rem;

  display: block;
  position: relative;

  ${media.tablet`
    margin: 0;
  `}
`

export const ProductImageContainer = styled.div<{
  contentPositionDesktop?: 'left' | 'right'
}>`
  position: relative;
  width: 100%;
  bottom: -1rem;

  ${media.tablet`
    bottom: -5rem;
    left: 0;
  `}

  ${media.laptop`
    ${(props: { contentPositionDesktop?: 'left' | 'right' }) =>
      props.contentPositionDesktop === 'left'
        ? `
          left: -6rem;
          `
        : `
            right: -2rem;
          `}
  `}
`
