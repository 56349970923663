import { ImageProps } from './types'

export const PLACEHOLDER_SRC =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
export const ERROR_SRC = PLACEHOLDER_SRC
export const FALLBACK_SRC = PLACEHOLDER_SRC
export const IMAGE_SIZES = [128, 256, 450, 640, 768, 1024, 1600]
export const IMAGE_WIDTH = 40

export function throttle(fn: () => void, ms: number) {
  let lastTime = 0
  return () => {
    const now = Date.now()
    if (now - lastTime >= ms) {
      fn()
      lastTime = now
    }
  }
}

export type GetHeightByRatioWidth = {
  width: number
  ratio: ImageProps['ratio']
}

export const getHeightByRatioWidth = ({
  width,
  ratio,
}: GetHeightByRatioWidth) => {
  return ratio ? Math.round((width / ratio[0]) * ratio[1]) : undefined
}

export const getActualValue = (
  value: number,
  actual: number[] | number
): number => {
  return Array.isArray(actual)
    ? actual.find(v => v >= value) ?? actual[actual.length - 1]
    : actual
}
