import React from 'react'
import { Coupon } from '../../CartLayout/components/Coupon'
import { Spacing } from '@boxine/tonies-ui'
import { Hr } from '../../../components/atoms/Hr'
import { Summary } from '../../../components/organisms/CartSummary'
import { useCheckoutConfig } from '../../../providers/checkout'
import { InfoBox } from '@/tonies-ui/atoms/InfoBox'
import { useTranslation } from 'next-i18next'

export const CouponSummary = ({
  cartDeliveryInfo,
  isFreeShipping = false,
}: {
  cartDeliveryInfo?: string
  isFreeShipping?: boolean
}) => {
  const config = useCheckoutConfig()
  const { t } = useTranslation()

  return (
    <>
      <Coupon />
      {cartDeliveryInfo && (
        <InfoBox type="info" borderRadius="none" width="fullwidth">
          {cartDeliveryInfo}
        </InfoBox>
      )}
      {config.orderReview.price.shouldShowShippingCosts && isFreeShipping && (
        <InfoBox type="success" borderRadius="none" width="fullwidth">
          {t('cart:freeshipping:reached')}
        </InfoBox>
      )}
      {(!cartDeliveryInfo ||
        (!config.orderReview.price.shouldShowShippingCosts &&
          !isFreeShipping)) && <Hr />}
      <Spacing mt="spacing-s" mx="spacing-s">
        <Summary
          hasShippingCostRows={config.orderReview.price.shouldShowShippingCosts}
        />
      </Spacing>
    </>
  )
}
