import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react'
import { useResizeObserver } from '@boxine/tonies-ui'
import styled, { CSSProperties } from 'styled-components'

export type StickyHeaderProps = {
  onResize: (width: number, height: number) => void
} & Pick<CSSProperties, 'top'>

type WrapperProps = Pick<CSSProperties, 'top'>

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  pointer-events: none;
  width: 100%;
  z-index: 10;

  > * {
    pointer-events: initial;
  }
`
type SpacerProps = { height: number }
const ColoredSpacer = styled.div<SpacerProps>`
  height: ${p => p.height + p.height / 2 + 'px'};
  margin-bottom: ${p => -(p.height / 2) + 'px'};
`

export const StickyHeader: FunctionComponent<
  StickyHeaderProps & PropsWithChildren
> = ({ children, onResize, top }) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (entries.length) {
        const { width, height } = entries[0].contentRect
        onResize(width, height)
      }
    },
    [onResize]
  )

  useResizeObserver(handleResize, ref)

  return (
    <Wrapper top={top} ref={ref}>
      <ColoredSpacer height={top as number} />
      {children}
    </Wrapper>
  )
}
