import React from 'react'
import {
  ProductCardCarousel,
  ProductCardCarouselProps,
} from '../../../../components/organisms/ProductCardCarousel'
import { useTranslation } from 'next-i18next'
import { sentryWithExtras } from '../../../../hooks/useSentry'
import { FlashMessage } from '@/tonies-ui/index'
import { Section } from '../../../../components/atoms/Section'
import { isDevEnvironment } from '../../../../utils/processUtils'

export type CompatibleToniesProductCardCarouselViewProps = {
  product: NormalizedProduct
  showHeadline?: boolean
  contentError: boolean
  products?: NormalizedProduct[]
  showSkeleton?: boolean
} & Pick<
  ProductCardCarouselProps,
  'actionAdd2CartCallback' | 'columns' | 'showOutOfStock' | 'showAllLink'
>

export const CompatibleToniesProductCardCarouselView = ({
  contentError,
  product: { salesId },
  products,
  showHeadline = true,
  columns,
  showOutOfStock,
  actionAdd2CartCallback,
  showSkeleton = true,
  showAllLink,
}: CompatibleToniesProductCardCarouselViewProps) => {
  const { t } = useTranslation()

  /**
   * a content Error is not expected on PROD as the data in octonie and commercetools should be aligned
   * if an error occurs on PROD, we are throwing a sentry error
   * if an error occurs on DEV, we do not throw, but show a `FlashMessage`
   */
  if (contentError && !isDevEnvironment) {
    sentryWithExtras(
      'CompatibleToniesNotFound',
      new Error(`compatible Tonies not found for salesId: ${salesId}`)
    )
  }

  return contentError && isDevEnvironment ? (
    <FlashMessage
      data-testid="compatible-tonies-content-error"
      type="error"
      isPersistent={true}
    >
      <Section noGaps removePaddingX removePaddingY>
        We could not get compatible Tonies for salesId: <pre>{salesId}</pre>
        This should not happen on prod and this error message is only shown on
        dev. On prod we throw an error to sentry: CompatibleToniesNotFound
      </Section>
    </FlashMessage>
  ) : showSkeleton || (products && products.length > 0) ? (
    <ProductCardCarousel
      showOutOfStock={showOutOfStock}
      columns={columns}
      headline={
        showHeadline ? t('products:tunes:compatibleToniesHeadline') : undefined
      }
      referenceTitle={`[coded] CompatibleToniesProductCardCarousel -- ${products
        ?.map(p => p.salesId)
        .join(',')}`}
      items={products?.filter(p => p.salesId !== salesId)}
      actionAdd2CartCallback={actionAdd2CartCallback}
      showAllLink={showAllLink}
    />
  ) : null
}
