import React from 'react'
import Head from 'next/head'
import { shopConfig } from '../../../../config/shop'

type StructuredDataProps = {
  baseUrl: string
}

export const StructuredData = ({ baseUrl }: StructuredDataProps) => {
  const structuredData = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: 'tonies',
      alternateName: ['tonies.com'],
      url: baseUrl,
      logo: shopConfig.assets.toniesLogo,
    },
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'tonies',
      alternateName: ['tonies.com'],
      url: baseUrl,
    },
  ]

  return (
    <Head>
      {structuredData.map((data, i) => (
        <script
          key={'d' + i}
          type="application/ld+json"
          data-testid={`structured-data__${i}`}
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(data),
          }}
        />
      ))}
    </Head>
  )
}
