import React, { ComponentProps } from 'react'
import { BrazeForm } from '../../../../../../components/organisms/BrazeForm'
import { BrazeFormSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'

export const CM_BrazeFormSchema = ({
  componentProps,
  ...rest
}: ContentModelType<ComponentProps<typeof BrazeForm>, BrazeFormSchema>) => {
  return <BrazeForm formLocation="component" {...rest} {...componentProps} />
}
