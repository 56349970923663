import {
  Button,
  Grid,
  Headline2,
  Headline3,
  Paragraph,
  Radio,
  Spacing,
  useIsViewportMobile,
} from '@boxine/tonies-ui'
import { Select } from '../../../components/atoms/FormElements/Select'
import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { GiftfinderModal } from './components/GiftfilterModal'
import { useProducts } from '../../../hooks/useProducts'
import { withGTMV4Context } from '../../../providers/gtmV4'
import { useEcomLocaleConfig } from '../../../hooks/useLocaleConfig'

/**
 * FIXME
 * The font size diverges here for run dev and run build
 */
import styled from 'styled-components'
const StyledLabel = styled.span`
  font-size: 0.8125rem;
`

const GiftfinderContainer = ({
  headline,
  text,
}: {
  headline: string
  text: string
}) => {
  const { t } = useTranslation()
  const isMobile = useIsViewportMobile()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const ThemeMapper = useEcomLocaleConfig({
    'de-DE': {
      activeAndWild: {
        themes: ['adventure', 'education-learning'],
      },
      musicAndDancing: {
        themes: ['activity-movement', 'childrens-music'],
      },
      bedtimeAndCalm: {
        themes: ['fairy-tales', 'falling-asleep', 'storytime'],
      },
    },
    'en-EU': {
      activeAndWild: {
        themes: ['adventure', 'education-learning'],
      },
      musicAndDancing: {
        themes: ['activity-movement', 'childrens-music'],
      },
      bedtimeAndCalm: {
        themes: ['fairy-tales', 'falling-asleep', 'storytime'],
      },
    },
    'en-GB': {
      activeAndWild: {
        themes: ['adventure', 'education-learning'],
      },
      musicAndDancing: {
        themes: ['activity-movement', 'childrens-music'],
      },
      bedtimeAndCalm: {
        themes: ['fairy-tales', 'falling-asleep', 'storytime'],
      },
    },
    'fr-FR': {
      activeAndWild: {
        themes: ['adventure', 'education-learning'],
      },
      musicAndDancing: {
        themes: ['activity-movement', 'childrens-music'],
      },
      bedtimeAndCalm: {
        themes: ['fairy-tales', 'falling-asleep', 'storytime'],
      },
    },
  })

  const [kidType, setKidType] = useState<keyof typeof ThemeMapper>()
  const [ageMinOptions, setAgeMinOptions] = useState<
    NormalizedAggregationOption[]
  >([])
  const [age, setAge] = useState<string>()

  const currentOption = useMemo(
    () => kidType && ThemeMapper[kidType],
    [ThemeMapper, kidType]
  )

  const { aggregations, products, isLoading } = useProducts({
    parameter: {
      limit: 3,
      categoryKey: ['tonies'],
      theme: currentOption?.themes,
      ageMin: age ? [age] : undefined,
      available: true,
      withBucketAggs: true,
    },
  })

  const handleSubmit: FormEventHandler = useCallback(
    e => {
      e.preventDefault()
      if (products) {
        setIsModalOpen(true)
      }
    },
    [products]
  )

  useEffect(() => {
    const options = aggregations?.find(agg => agg.key === 'ageMin')?.options

    if (!age && options?.length) {
      setAgeMinOptions(options)
    }
  }, [age, aggregations])

  return (
    <>
      <Grid columns={isMobile ? 1 : 2} hasGapH hasGapV>
        <div>
          <Headline2>{headline}</Headline2>
          <Paragraph>{text}</Paragraph>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <Spacing mb="spacing-s" applyToChildren>
              <Headline3>{t('giftfinder:forms:kidTypeHeadline')}</Headline3>
              <Spacing mb="spacing-xs" applyToChildren>
                {Object.keys(ThemeMapper).map(key => (
                  <Radio
                    key={key}
                    disabled={isLoading}
                    name="theme"
                    onChange={value => {
                      setKidType(value as typeof kidType)
                      setAge(undefined)
                    }}
                    value={key}
                    checked={kidType === key}
                  >
                    <StyledLabel>
                      {t(`giftfinder:forms:${key}Label`)}
                    </StyledLabel>
                  </Radio>
                ))}
              </Spacing>
              <Spacing mt="spacing-m" mb="spacing-xs">
                <Headline3>{t('giftfinder:forms:kidAgeHeadline')}</Headline3>
                <Select
                  label={t('giftfinder:forms:kidAgeLabel')}
                  isDisabled={!kidType || isLoading}
                  name="ages"
                  value={age || ''}
                  options={[
                    { label: '', value: '' },
                    ...ageMinOptions.map(age => ({
                      label:
                        age.key +
                        ' ' +
                        t('default:year', {
                          count: parseInt(age.key),
                        }),
                      value: age.key,
                    })),
                  ]}
                  onChange={e => setAge(e.target.value)}
                />
              </Spacing>
              <Button
                type="submit"
                disabled={isLoading || !kidType || !age}
                isPending={isLoading}
              >
                {t('giftfinder:forms:submit')}
              </Button>
            </Spacing>
          </form>
        </div>
      </Grid>
      <GiftfinderModal
        products={isModalOpen ? products : undefined}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
    </>
  )
}

export const Giftfinder = withGTMV4Context(GiftfinderContainer, 'Giftfinder')
