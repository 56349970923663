import styled from 'styled-components'

export type Props = {
  height: number
  placement: 'header' | 'footer'
}

export const StickySpacer = styled.div<Props>`
  background: ${p =>
    p.placement === 'footer' ? p.theme.colors.darkergrey : undefined};
  height: ${p => p.height}px;
`
