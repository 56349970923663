import React, { useEffect, useState } from 'react'
import { useAnimate } from 'framer-motion'
import styled from 'styled-components'
import { useIsViewportDesktop } from '@boxine/tonies-ui'
import { DIAMETER_INDICATOR_DESKTOP, DIAMETER_INDICATOR_MOBILE } from '..'

const CountDisplay = styled.span`
  display: block;
  height: 10%;
  text-align: center;
`
const NumberColumnWrapper = styled.span`
  display: block;
  position: relative;
`
const NumberPlaceholder = styled.span`
  visibility: hidden;
`
const NumberColumnContainer = styled.div`
  position: absolute;
  height: 1000%;
  bottom: 0;
`

type NumberColumnProps = { count: number; dataTestId?: string }

export const NumberColumn = ({ count = 99, dataTestId }: NumberColumnProps) => {
  const isDesktop = useIsViewportDesktop()
  const [lastCount, setLastCount] = useState(count)
  const heightInRem = parseFloat(
    (isDesktop
      ? DIAMETER_INDICATOR_DESKTOP
      : DIAMETER_INDICATOR_MOBILE
    ).replace('rem', '')
  )
  const [scope, animate] = useAnimate()
  useEffect(() => {
    animate(scope.current, {
      left: '50%',
      x: '-50%',
      y: `${(heightInRem * (9 - lastCount)).toFixed(1)}rem`,
    })
  }, [heightInRem, animate, lastCount, scope])

  useEffect(() => {
    // we are only showing a maximum count of 100 items = `99+`
    const newCount = Math.min(count, 100)
    if (newCount !== lastCount) {
      setLastCount(newCount)
    }
  }, [count, lastCount])

  const numbers = new Array(100).fill(1).map((_v, i) => i + 1)
  return (
    <NumberColumnWrapper>
      <NumberColumnContainer ref={scope}>
        {numbers.map((_v, i) => (
          <CountDisplay key={i}>
            <span data-testid={count === i ? dataTestId : undefined}>{i}</span>
          </CountDisplay>
        ))}
        <CountDisplay key={100} style={{ fontSize: 'smaller' }}>
          <span data-testid={count === 100 ? dataTestId : undefined}>99+</span>
        </CountDisplay>
      </NumberColumnContainer>
      <NumberPlaceholder>0</NumberPlaceholder>
    </NumberColumnWrapper>
  )
}
