import React, { ComponentProps } from 'react'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { RichText } from '../../../../../../components/molecules/RichText'
import { frequentlyAskedQuestions } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { FrequentlyAskedQuestions } from '@/tonies-ui/organisms/MediaCopySection/variants/FrequentlyAskedQuestions'

export const CM_FrequentlyAskedQuestions = ({
  componentProps,
  ...rest
}: ContentModelType<
  ComponentProps<typeof FrequentlyAskedQuestions>,
  frequentlyAskedQuestions
>) => {
  return (
    <FrequentlyAskedQuestions
      backgroundColor={rest.backgroundColor}
      headline={<Accent text={rest.headline} />}
      headlineAsHTMLTag={rest.headlineAsHTMLTag}
      subheadline={rest.subheadline}
      imageDesktop={rest.backgroundImage.file.url}
      imageMobile={rest.backgroundImage.file.url}
      entries={rest.entries.map(entry => ({
        question: entry.question,
        answer: <RichText document={entry.answer} />,
      }))}
      id={rest.id}
      {...componentProps}
    />
  )
}
