import {
  ContentfulImage,
  ContentfulVideo,
} from '../lib/contentful/datamodel/schemas'
import { Document as RichTextDocument } from '@contentful/rich-text-types'

export const contentfulImage = (
  url: string,
  width = 400,
  height = 300
): ContentfulImage => ({
  title: '#',
  file: {
    url,
    contentType: 'image',
    fileName: '#',
    details: {
      size: width,
      image: {
        width,
        height,
      },
    },
  },
})

export const contentfulVideo = (url: string) =>
  ({ file: { url } } as ContentfulVideo)

export const contentfulRichText = (text: string) =>
  ({
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value: text,
            marks: [],
            data: {},
          },
        ],
        data: {},
      },
    ],
  } as RichTextDocument)
