import React, { ComponentProps, useEffect, useMemo, useRef } from 'react'
import { useProductOverview } from '../../../../providers/productOverview'
import { useTranslation } from 'next-i18next'
import { InputField } from '../../../atoms/FormElements/Input'
import { useGtmV4 } from '../../../../providers/gtmV4'

export const SearchContent = ({
  hasAutoFocus = true,
}: {
  hasAutoFocus?: boolean
}) => {
  const { filter, search, entries } = useProductOverview()
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()

  const searchMessage:
    | Pick<ComponentProps<typeof InputField>, 'message' | 'status'>
    | undefined = useMemo(() => {
    if (!search?.value) return

    const entriesCount = entries.total

    return entriesCount > 0
      ? {
          status: 'ok',
          message: {
            text:
              entriesCount === 1
                ? t('products:productFoundHint.one')
                : t('products:productFoundHint.other', {
                    count: entriesCount,
                  }),
          },
        }
      : { status: 'error', message: { text: t('NoProductsBySearch:text') } }
  }, [entries.total, search?.value, t])

  // delay sending filters to GTM to avoid tracking every keystroke
  const gtmV4ActiveFiltersTimeout = useRef(0)

  useEffect(() => {
    let isMounted = true

    clearTimeout(gtmV4ActiveFiltersTimeout.current)
    gtmV4ActiveFiltersTimeout.current = window.setTimeout(() => {
      if (isMounted && search?.value.trim()) {
        pushGtmV4Event({
          eventType: 'search',
          filters: filter?.items || [],
          searchTerm: search?.value.trim() || '',
        })
      }
    }, 1000)

    return () => {
      isMounted = false
    }
  }, [pushGtmV4Event, search?.value, filter?.items])

  return search ? (
    <InputField
      label={t('MobileFilter:SearchPlaceholder')}
      name="search"
      value={search.value}
      onChange={({ target: { value } }) => {
        search.setValue(value)
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={hasAutoFocus}
      {...searchMessage}
    />
  ) : null
}
