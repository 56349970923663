import { getCurrencyCodeByLocale } from '../config/shopAPI'
import { EcomLocale } from '../config/shopAPI/types'
import { useEcomLocale } from '../providers/locale'

export const useCurrencyCode = (lcCC?: EcomLocale) => {
  const contextLcCC = useEcomLocale()
  return getCurrencyCodeByLocale(lcCC || contextLcCC)
}

export const useCurrencyFormatter = (lcCC?: EcomLocale) => {
  const contextLcCC = useEcomLocale()
  const locale = lcCC || contextLcCC
  const currency = useCurrencyCode(locale)

  return (amount: number) =>
    amount.toLocaleString(locale, {
      style: 'currency',
      currency,
    })
}
