import { media } from '@boxine/tonies-ui'
import styled from 'styled-components'

export const AudioPlayerWrapper = styled.span<{
  contentPositionDesktop: 'left' | 'right'
}>`
  position: absolute;
  width: 3.125rem;
  height: 3.125rem;
  bottom: -2rem;
  transform: translateX(-50%);
  left: 30%;

  ${media.mobileL`
    bottom: 0;
  `}

  ${media.tablet`
    bottom: -2rem;
    left: 21%;
    height: 3.75rem; 
    width: 3.75rem;
  `}


  ${({ contentPositionDesktop }) =>
    media.laptop`
      left: ${contentPositionDesktop === 'left' ? '0' : '20%'};
      transform: unset;
    `}
`
