// import { BundleTemplate } from '../../components/organisms/CustomBundler/types'
import { PaymentType } from '../payment/types'
import { BundlerProductSelection } from '../../components/organisms/CustomBundler/types'
import { BxAddress } from '../../lib/commercetools/requests/bxTypes'
import { useProductFilterReturnType } from '../../hooks/useProductFilter'
import { EcomLocale } from '../../config/shopAPI/types'

export type GtmV4Item = {
  item_id: string
  item_name: string
  currency: string
  discount?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  price: number
  quantity?: number
  availability: string
  is_tunes_available: boolean
  play_time?: number
}

export type GtmV4ViewItemEvent = {
  eventType: 'viewItem'
  item: NormalizedProduct
}

export type GtmV4ViewItemListEvent = {
  eventType: 'viewItemList'
  items: NormalizedProduct[]
}

export type GtmV4AddToCartEvent = {
  eventType: 'addToCart'
  item: NormalizedProduct & { quantity: number }
}

export type GtmV4RemoveFromCartEvent = {
  eventType: 'removeFromCart'
  value: number
  item: NormalizedProduct & { quantity: number }
}

export type GtmV4ViewWishlistEvent = {
  eventType: 'viewWishlist'
  items: NormalizedProduct[]
}

export type GtmV4WishlistEvent = {
  eventType: 'wishlist'
  step: 'share_link' | 'copy_link'
}

export type GtmV4AddToWishlistEvent = {
  eventType: 'addToWishlist'
  item: NormalizedProduct & { quantity: number }
}

export type GtmV4RemoveFromWishlistEvent = {
  eventType: 'removeFromWishlist'
  value: number
  item: NormalizedProduct & { quantity: number }
}

export type GtmV4CheckoutEvent = {
  eventType: 'checkout'
  event:
    | 'view_cart'
    | 'checkout_login_type'
    | 'begin_checkout'
    | 'add_address_info'
    | 'add_shipping_info'
    | 'review_order'
    | 'add_payment_info'
    | 'purchase'
  cart?: NormalizedCart
  paymentType?: PaymentType
  billingIsShipping?: boolean
  billingAddress?: BxAddress
  shippingAddress?: BxAddress
  orderNumber?: string
  newsletterSubscription?: boolean
  loginType?: 'guest' | 'register' | 'user'
  lcCC?: EcomLocale
  user?: NormalizedUser | null
}

export type GtmV4MagicLinkEvent = {
  eventType: 'magicLink'
  items?: NormalizedProduct[]
  coupon?: string
}

export type GtmV4CustomBundlerEvent = {
  eventType: 'customBundler'
  step:
    | 'select_bundle'
    | 'select_tonieboxes'
    | 'select_creative-tonies'
    | 'select_tonies'
    | 'select_creative-tonies_bipoc'
    | 'jump_to_bundle'
    | 'jump_to_tonieboxes'
    | 'jump_to_creative-tonies'
    | 'jump_to_tonies'
    | 'jump_to_creative-tonies_bipoc'
    | 'jump_to_summary'
    | 'add_bundle_to_cart'
  selection: BundlerProductSelection & {
    template: NonNullable<BundlerProductSelection['template']>
  }
}

export type GtmV4CouponEvent = {
  eventType: 'coupon'
  event: 'add' | 'remove'
  coupon: string
}

export type GtmV4ReferralEvent = {
  eventType: 'referral'
  step: 'copy_code' | 'share_code'
}

export type GtmV4AudioSampleEvent = {
  eventType: 'audioSample'
  step: 'play' | 'stop'
  item: NormalizedProduct
}

export type GtmV4AudioContentEvent = {
  eventType: 'audioContent'
  step:
    | 'start_assign'
    | 'tonie_chosen'
    | 'assign_succeeded'
    | 'reset_original'
    | 'upload_audio'
  item: NormalizedProduct
}

const gtmV4GenerateLeadEventleadTypes = [
  'ai_story_requests',
  'kids_birthdays',
  'newsletter',
  'sweepstake',
] as const
type GtmV4GenerateLeadEventLeadType =
  (typeof gtmV4GenerateLeadEventleadTypes)[number]
export const isGtmV4GenerateLeadEventLeadType = (
  s: string
): s is GtmV4GenerateLeadEventLeadType =>
  gtmV4GenerateLeadEventleadTypes.includes(s as GtmV4GenerateLeadEventLeadType)

export type GtmV4GenerateLeadEvent = {
  eventType: 'generateLead'
  leadType: GtmV4GenerateLeadEventLeadType
  formLocation: string
  email: string
}

export type GtmV4SelectItemEvent = {
  eventType: 'selectItem'
  item: NormalizedProduct
}

export type GtmV4SelectContentEvent = {
  eventType: 'selectContent'
  ctaLabel: string
  ctaLink: string
  type: 'primary' | 'secondary' | 'image' | 'text-link' | 'open-modal'
}

export type GtmV4SelectVariantEvent = {
  eventType: 'selectVariant'
  step: 'pageload' | 'select'
  item: NormalizedProduct
}

export type GtmV4SearchEvent = {
  eventType: 'search'
  filters: useProductFilterReturnType[]
  searchTerm: string
}

export type GtmV4ExperimentImpression = {
  eventType: 'experimentImpression'
  key: string
  value: boolean | string
}

export type GtmV4ClickEvent = {
  eventType: 'click'
  label: string
} & (
  | {
      type: 'link'
      link: string
      action?: never
    }
  | {
      type: 'onClick'
      link?: never
      action: string
    }
)

export type GtmV4Event =
  | GtmV4AddToCartEvent
  | GtmV4RemoveFromCartEvent
  | GtmV4ViewWishlistEvent
  | GtmV4WishlistEvent
  | GtmV4AddToWishlistEvent
  | GtmV4RemoveFromWishlistEvent
  | GtmV4ViewItemEvent
  | GtmV4ViewItemListEvent
  | GtmV4CheckoutEvent
  | GtmV4MagicLinkEvent
  | GtmV4CustomBundlerEvent
  | GtmV4CouponEvent
  | GtmV4ReferralEvent
  | GtmV4AudioSampleEvent
  | GtmV4AudioContentEvent
  | GtmV4GenerateLeadEvent
  | GtmV4SelectItemEvent
  | GtmV4SelectContentEvent
  | GtmV4SelectVariantEvent
  | GtmV4SearchEvent
  | GtmV4ExperimentImpression
  | GtmV4ClickEvent

export type GtmV4ContextProps = {
  componentId: string | undefined
  componentName: string | undefined
  pushGtmV4Event: (event: GtmV4Event) => void
  pushDataLayer: (dataLayer: object) => void
}

export type WithReferenceTitle<T> = T & { referenceTitle?: string | undefined }
