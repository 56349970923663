import React, { FunctionComponent } from 'react'
import { Icon } from '@boxine/tonies-ui'
import { IconJson } from '@boxine/tonies-ui/icons'
import * as icons from '@boxine/tonies-ui/icons'
import { useTranslation } from 'next-i18next'
import { useGtmV4 } from '../../../providers/gtmV4'
import styled from 'styled-components'

const StyledLink = styled.a`
  border-radius: 100%;
  border: 3px solid white;
`

/* Enumeration of social networks on which Tonies have a profile */
export const profileSocialNetworks = [
  'facebook',
  'instagram',
  'linkedin',
  'pinterest',
  'snapchat',
  'tiktok',
  'twitter',
  'youtube',
] as const

export type ProfileSocialNetwork = (typeof profileSocialNetworks)[number]

/** Enumeration of social networks on which we can share content */
export const shareSocialNetworks = [
  'facebook',
  'linkedin',
  'pinterest',
  'twitter',
] as const

export type ShareSocialNetwork = (typeof shareSocialNetworks)[number]

export type Props = {
  sizeInEm?: number
} & (
  | {
      type: 'profile'
      socialNetwork: ProfileSocialNetwork
    }
  | {
      type: 'share'
      socialNetwork: ShareSocialNetwork
      shareUrl: string
      shareTitle: string
    }
)

const mapIconType: Record<ProfileSocialNetwork | ShareSocialNetwork, IconJson> =
  {
    facebook: icons.snFacebook,
    instagram: icons.snInstagram,
    linkedin: icons.snLinkedIn,
    pinterest: icons.snPinterest,
    snapchat: icons.snSnapchat,
    tiktok: icons.snTikTok,
    twitter: icons.snTwitter,
    youtube: icons.snYoutube,
  }

const shareUrl: Record<
  ShareSocialNetwork,
  (url: string, title: string) => string
> = {
  facebook: url =>
    'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(url),

  linkedin: (url, title) =>
    'https://www.linkedin.com/shareArticle?url=' +
    encodeURIComponent(url) +
    '&title=' +
    encodeURIComponent(title),

  pinterest: (url, title) =>
    'https://pinterest.com/pin/create/bookmarklet/?url=' +
    encodeURIComponent(url) +
    '&description=' +
    encodeURIComponent(title),

  twitter: (url, title) =>
    'https://twitter.com/share?url=' +
    encodeURIComponent(url) +
    '&text=' +
    encodeURIComponent(title),
}

export const SocialMediaLink: FunctionComponent<Props> = props => {
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()
  const iconType = mapIconType[props.socialNetwork]
  const sizeStr = props.sizeInEm + 'em'
  let url: string, title: string

  if (props.type === 'share') {
    url = shareUrl[props.socialNetwork](props.shareUrl, props.shareTitle)
    title = t(`socialmedia:${props.socialNetwork}:shareTitle`)
  } else {
    url = t(`socialmedia:${props.socialNetwork}:profileUrl`)
    title = t(`socialmedia:${props.socialNetwork}:profileTitle`)
  }

  return (
    <StyledLink
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      onClick={() =>
        pushGtmV4Event({
          eventType: 'selectContent',
          ctaLabel: title || '',
          ctaLink: url || '',
          type: 'image',
        })
      }
    >
      <Icon type={iconType} title={title} height={sizeStr} width={sizeStr} />
    </StyledLink>
  )
}
