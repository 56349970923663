import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'

const StyledHr = styled.hr<{ colorKey: keyof DefaultTheme['colors'] }>`
  border: 0;
  border-top: 1px solid ${({ theme, colorKey }) => theme.colors[colorKey]};
  margin: 0;
  padding: 0;
`

export const Hr = ({
  className,
  color = 'anthracite-30',
}: { color?: keyof DefaultTheme['colors'] } & Styleable) => (
  <StyledHr className={className} colorKey={color} />
)
