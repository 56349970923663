import { FocusStyles, media } from '@boxine/tonies-ui'
import styled, { css } from 'styled-components'

export const cssReset = css`
  appearance: none;
  background: none;
  border: none;
  font: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;

  ${FocusStyles}
`

export const SimpleButton = styled.button`
  ${cssReset}

  &:hover {
    cursor: pointer;
  }
`

export const SimpleLink = styled.a`
  ${cssReset}
`

/**
 * TODO
 * Remove this everywhere after the header's "Schwung" is finally gone
 */
export const mainFirstChildPaddingTop = css`
  padding-top: 3.25rem;

  ${media.laptop`
    padding-top: 4.75rem;
  `}
`
