import React from 'react'
import Head from 'next/head'

export const FontPreload = () => (
  <Head>
    {/*
      Reduce the latency of cross-origin requests and prefetch fonts
      See: https://developer.mozilla.org/en-US/docs/Web/Performance/dns-prefetch#best_practices
    */}
    <link rel="preconnect" href="https://cdn.tonies.de/" />
    <link
      rel="dns-prefetch"
      href="https://cdn.tonies.de/"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href="https://cdn.tonies.de/fonts/EllaTonies-Script.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href="https://cdn.tonies.de/fonts/museo-300.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href="https://cdn.tonies.de/fonts/museo-500.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href="https://cdn.tonies.de/fonts/museo-700.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
  </Head>
)
