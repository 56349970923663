import React, { FunctionComponent, useCallback } from 'react'
import { useColorContext, ButtonLink, Icon } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { useLocaleSelectorContext } from '../../../../providers/localeSelector'
import { useTranslation } from 'next-i18next'
import { useGtmV4, withGTMV4Context } from '../../../../providers/gtmV4'
import { useLocaleSelectorSidebar } from '../../LocaleSelector'
import styled from 'styled-components'

export const isRelative = (parent: URL, child: URL) =>
  child.toString().startsWith(parent.toString())

const LocaleSelectorButtonWrapper = styled.div<{
  colorContext: 'dark' | 'light'
}>`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-left: 2rem;
  border-top: 0.125rem solid transparent;
  border-bottom: 0.125rem solid transparent;
`

const LabelWrapper = styled.span<{
  colorContext: 'dark' | 'light'
  position: 'footer' | 'metaNavigation'
}>`
  display: inline-flex;
  height: 100%;
  align-items: center;
  color: ${props =>
    props.colorContext === 'dark'
      ? props.theme.colors.white
      : props.theme.colors.darkergrey};
  text-decoration: none;
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => (props.position === 'footer' ? '0.9rem' : '0.75rem')};
  font-weight: 400;
  padding: 0;
  transition: color 0.3s linear;
  > svg {
    margin-right: 0.25rem;
  }
  &:hover {
    color: ${props =>
      props.position === 'footer' ? undefined : props.theme.colors.primary};
  }
`
type LocaleSelectorButtonComponentProps = {
  position: 'footer' | 'metaNavigation'
}
export const LocaleSelectorButtonComponent: FunctionComponent<
  LocaleSelectorButtonComponentProps
> = ({ position }: LocaleSelectorButtonComponentProps) => {
  const { pushGtmV4Event } = useGtmV4()
  const localeSelector = useLocaleSelectorContext()
  const colorContext = useColorContext()
  const [isOpen, handleIsOpen] = useLocaleSelectorSidebar()

  const { t } = useTranslation()

  const handleLocaleSelection = useCallback(() => {
    // Toggle Locale Selector
    handleIsOpen(!isOpen)
    pushGtmV4Event({
      eventType: 'click',
      type: 'onClick',
      label: '[coded] Locale Selector',
      action: localeSelector.isSelectorExpanded
        ? 'locale_selector_hide'
        : 'locale_selector_show',
    })
  }, [localeSelector, pushGtmV4Event, handleIsOpen, isOpen])

  return (
    <LocaleSelectorButtonWrapper colorContext={colorContext}>
      <ButtonLink
        onClick={handleLocaleSelection}
        dataTestId="locale-selection-link"
      >
        <LabelWrapper colorContext={colorContext} position={position}>
          <Icon type={icons.place} height="1rem" width="1rem" />
          <span>{t('localeSelector:selector:label')}</span>
        </LabelWrapper>
      </ButtonLink>
    </LocaleSelectorButtonWrapper>
  )
}

export const LocaleSelectorButton = withGTMV4Context(
  LocaleSelectorButtonComponent,
  'LocaleSelector'
)
