import React, { FunctionComponent } from 'react'
import { CoverCard } from '@boxine/tonies-ui'
import { GenericProductCardProps } from '../types'
import styled from 'styled-components'
import { Image } from '@/tonies-ui/atoms/Image'

const Wrapper = styled.div`
  padding: 0 0.5rem;
`

export const GenericProductCardImage: FunctionComponent<
  GenericProductCardProps['image']
> = ({ src, alt, placeholder = true, variant = 'image' }) => {
  return (
    <>
      {variant === 'image' && (
        <Image
          alt={alt}
          dataTestId="image"
          ratio={[4, 3]}
          src={src}
          imageBackend={
            // Default backend not working if original image is already hosted at Cloudinary
            src.includes('res.cloudinary.com') ? () => src : undefined
          }
          placeholder={placeholder}
          background="#ffffff"
        />
      )}
      {variant === 'coverCard' && (
        <Wrapper>
          <CoverCard src={src} alt={alt} placeholder={placeholder} />
        </Wrapper>
      )}
    </>
  )
}
