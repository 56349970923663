import React, { FunctionComponent } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { PageMetaData } from '../../../lib/contentful/datamodel/schemas'
import { useRouter } from 'next/router'
import { cloudinaryUrl } from '@/tonies-ui/atoms/Image/components/cloudinary'
import { OpenGraph } from './components/OpenGraph'
import { FontPreload } from './components/FontPreload'
import { Icons } from './components/Icons'
import { DomainVerification } from './components/DomainVerification'
import { Application } from './components/Application'
import { HomepageAlternates } from './components/HomepageAlternates'
import { PreventCache } from './components/PreventCache'
import { RobotsNoIndex } from './components/RobotsNoIndex'
import { Build } from './components/Build'
import { Canonical } from './components/Canonical'
import { StructuredData } from './components/StructuredData'
import { StructuredDataProduct } from './components/StructuredDataProduct'
import { StructuredDataBlogPosting } from './components/StructuredDataBlogPosting'
import useLocalizedEnv from '../../../hooks/useLocalizedEnv'
import { CmpPreload, CmpScript } from './components/Cmp'
import { BazaarvoiceScript } from './components/Bazaarvoice'
import * as urlHelper from '../../../utils/urlHelpers'
import { shopConfig } from '../../../config/shop'
import { useLocale } from '../../../providers/locale'
import { BlogPostLayoutSchema } from '../../../lib/contentful/datamodel/layouts'

export type MetaProps = PageMetaData & {
  product?: NormalizedProductExtended
  blogPost?: Pick<BlogPostLayoutSchema, 'author' | 'date'>
}

export const Meta: FunctionComponent<MetaProps> = ({
  isCacheable,
  pageTitle,
  metaTitle,
  metaDescription,
  openGraphImage,
  includeInSitemapXml,
  product,
  blogPost,
}) => {
  const { t } = useTranslation()
  const lcCC = useLocale()
  const baseUrl = urlHelper.baseUrl()
  const homepageUrl = urlHelper.homepageUrl(lcCC)

  /**
   * Use the path as shown in the browser so we don't have to handle internal rewrites, e.g. for our mainpages.
   * Also remove all query params that would change the pagePath in the client.
   */
  const pageUrl = urlHelper.fullUrl(useRouter().asPath, true)

  const isHomepageUrl = pageUrl === homepageUrl

  const imageUrl = cloudinaryUrl({
    src: openGraphImage?.file.url || shopConfig.assets.ogImage,
    width: 1920,
    height: 1080,
    background: 'white',
    crop: 'autoGravity',
  })

  /**
   * We are using _multiple_ <Head> elements to work around a bug (as of October 2021):
   * When next/head and next/dynamic are used together to render pages client-side,
   * the <Head>'s children are ignored if they are wrapped in React fragments or arrays,
   * quite contrary to the documentation: https://nextjs.org/docs/api-reference/next/head
   */

  return (
    <>
      <Head>
        <title>{pageTitle || t('metaDefaults:metaTitle')}</title>
      </Head>

      {isCacheable === false && <PreventCache />}

      <Application
        title={metaTitle || pageTitle || t('metaDefaults:metaTitle')}
        description={metaDescription || t('metaDefaults:metaDescription')}
        homepageUrl={homepageUrl}
      />

      <Build />

      <CmpPreload />

      <FontPreload />

      <OpenGraph
        title={metaTitle || pageTitle || t('metaDefaults:metaTitle')}
        description={metaDescription || t('metaDefaults:metaDescription')}
        pageUrl={pageUrl}
        imageUrl={imageUrl}
      />

      <DomainVerification
        facebookKey={t('metaDefaults:facebookDomainVerification')}
        pinterestKey={useLocalizedEnv(
          'NEXT_PUBLIC_PINTEREST_DOMAIN_VERIFICATION_KEY'
        )}
        googleKey={process.env.NEXT_PUBLIC_GOOGLE_DOMAIN_VERIFICATION_KEY}
        google2Key={process.env.NEXT_PUBLIC_GOOGLE2_DOMAIN_VERIFICATION_KEY}
      />

      <Icons />

      <Canonical pageUrl={pageUrl} />

      {isHomepageUrl && <HomepageAlternates />}

      {/* Check here for !== undefined for pages that have been created
       * in contentful prior to the introduction of this feature.
       */}
      {includeInSitemapXml !== undefined && !includeInSitemapXml && (
        <RobotsNoIndex />
      )}

      <CmpScript />

      <StructuredData baseUrl={baseUrl} />

      {product && (
        <>
          <BazaarvoiceScript lcCC={lcCC} />
          <StructuredDataProduct
            imageUrl={imageUrl}
            pageUrl={pageUrl}
            product={product}
          />
        </>
      )}

      {blogPost && (
        <StructuredDataBlogPosting
          description={metaDescription || t('metaDefaults:metaDescription')}
          imageUrl={imageUrl}
          post={blogPost}
          pageUrl={pageUrl}
          title={metaTitle || pageTitle || t('metaDefaults:metaTitle')}
        />
      )}
    </>
  )
}
