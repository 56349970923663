import React, {
  Children,
  FunctionComponent,
  ReactChild,
  useContext,
  useRef,
} from 'react'
import * as icons from '@boxine/tonies-ui/icons'
import { Slider, Slide, CarouselContext } from 'pure-react-carousel'
import styled, { css } from 'styled-components'
import { CarouselButton } from './CarouselButton'
import { Pagination } from './Pagination'
import { useIntroAnimation } from '../hooks/useIntroAnimation'
import { useRenderOnContextChange } from '../hooks/useRenderOnContextChange'
import { Buttons, CarouselChangeHandler } from '../types'
import { useNextSlide } from '../hooks/useNextSlide'
import { usePreviousSlide } from '../hooks/usePreviousSlide'
import { useNotifyOnChange } from '../hooks/useNotifyOnChange'
import { media, Spacing } from '@boxine/tonies-ui'

const Container = styled.div<{ hasPadding: boolean }>`
  ${({ hasPadding }) => css`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    ${hasPadding &&
    css`
      padding-bottom: 4rem;

      ${media.tablet`
        padding-bottom: 0;
      `}
    `}
  `}
`

export const CarouselWrapper: FunctionComponent<{
  ariaTitle: string
  buttons: Buttons
  children: ReactChild[]
  hasIntroAnimation: boolean
  hasPagination: boolean
  isInfinite: boolean
  onChange?: CarouselChangeHandler
  hasPadding: boolean
}> = ({
  ariaTitle,
  buttons,
  children,
  hasIntroAnimation,
  hasPagination,
  isInfinite,
  onChange,
  hasPadding,
}) => {
  const carouselContext = useContext(CarouselContext)
  const refCarousel = useRef<HTMLDivElement>(null)
  const refNextButton = useRef<HTMLButtonElement>(null)
  const refPrevButton = useRef<HTMLButtonElement>(null)
  const { totalSlides, currentSlide } = carouselContext.getStoreState()

  useRenderOnContextChange()
  useNotifyOnChange(onChange)

  useIntroAnimation(
    hasIntroAnimation,
    refCarousel,
    refPrevButton,
    refNextButton
  )

  const nextSlide = useNextSlide()
  const prevSlide = usePreviousSlide()

  return (
    /* Aria attributes based on https://www.w3.org/TR/wai-aria-practices/examples/carousel/carousel-1.html */
    <Container ref={refCarousel} hasPadding={hasPadding}>
      <Spacing
        px={buttons !== 'none' && hasPadding ? 'spacing-xxl' : undefined}
      >
        <Slider
          title={ariaTitle}
          role="region"
          aria-label={ariaTitle}
          aria-roledescription="carousel"
          style={{ width: '100%', maxWidth: '100%' }}
        >
          {Children.map(children, (child, index) => (
            <Slide
              index={index}
              role="listitem"
              aria-roledescription="slide"
              aria-label={`${index + 1} of ${children.length}`}
              style={{ display: 'grid', alignContent: 'center' }}
            >
              {child}
            </Slide>
          ))}
        </Slider>
      </Spacing>
      {buttons !== 'none' && (
        <>
          {(isInfinite || currentSlide > 0) && (
            <CarouselButton
              $x="left"
              $y={buttons}
              ref={refPrevButton}
              icon={icons.arrowLeft}
              onClick={prevSlide}
            />
          )}
          {(isInfinite || currentSlide < totalSlides - 1) && (
            <CarouselButton
              $x="right"
              $y={buttons}
              ref={refNextButton}
              icon={icons.arrowRight}
              onClick={nextSlide}
            />
          )}
        </>
      )}
      {hasPagination && <Pagination />}
    </Container>
  )
}
