import { useLocale } from '../providers/locale'

export const useDateFormatter = (lcCC?: string) => {
  const contextLcCC = useLocale()

  return (d: Date) =>
    d.toLocaleDateString(lcCC || contextLcCC, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
}
