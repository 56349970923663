import {
  useSidebarOverlay,
  useSidebarOverlayProvider,
} from '../../../../providers/SidebarOverlay'
import React from 'react'
import { Box, Button, CTARow, Spacing } from '@boxine/tonies-ui'
import { useProductOverview } from '../../../../providers/productOverview'
import { Sorting } from '../components/Sorting'
import styled from 'styled-components'
import { FilterExpandable } from '../../../molecules/FilterExpandable'
import { useTranslation } from 'next-i18next'
import { ActiveFilterTags } from '../components/ActiveFilterTags'
import { SearchContent } from './SearchContent'
import { SidebarOverlay } from '../../../../components/organisms/SidebarOverlay'

const Wrapper = styled(Box)`
  // FIXME: remove overflow hidden on BOX
  overflow-y: auto !important;
`

const Inner = styled(Box)`
  border-bottom: 1px solid ${props => props.theme.colors['anthracite-30']};
  :last-child {
    border-bottom: none;
  }
`

const ActiveFilterTagsWrapper = styled.div`
  :not(:empty) {
    margin-bottom: 1rem;
  }
`

const FilterContent = ({
  onApply,
  hasFilters,
  hasSearch,
  hasSorting,
}: {
  onApply: () => void
  hasFilters?: boolean
  hasSearch?: boolean
  hasSorting?: boolean
}) => {
  const { t } = useTranslation()
  const {
    filter,
    search,
    resetAll,
    entries: { total },
    isPending,
  } = useProductOverview()

  return (
    <>
      <Wrapper px="spacing-s">
        {hasSearch && (
          <Inner pb="spacing-s">
            <SearchContent />
          </Inner>
        )}
        {hasSorting && (
          <Inner pb="spacing-s">
            <Sorting />
          </Inner>
        )}
        {hasFilters && (
          <Inner pt="spacing-s">
            <ActiveFilterTagsWrapper>
              <ActiveFilterTags />
            </ActiveFilterTagsWrapper>
            {filter?.items.map(
              (item, i) =>
                item.filters.length !== 0 && (
                  <Spacing key={item.filterKey + i} mb="spacing-s">
                    <FilterExpandable
                      referenceTitle={`[coded] FilterOverlay - FilterExpandable ${item.filterKey}`}
                      heading={item.headline}
                      data-testid="filter-expandable"
                      data-trackingid="tracking-id"
                      filterItems={item.filters}
                      filterKey={item.filterKey}
                      activeFilterCount={item.activeFilters.length}
                    />
                  </Spacing>
                )
            )}
          </Inner>
        )}
      </Wrapper>
      <Box p="spacing-s" backgroundColor="middlegrey">
        <CTARow justifyContent="end">
          {(filter?.isFilterActive || search?.value) && (
            <Button
              variant="secondary"
              onClick={resetAll}
              data-testid="reset-all-filters"
            >
              {t('productFilter:reset')}
            </Button>
          )}
          <Button isPending={isPending} onClick={onApply}>{`${t(
            'productFilter:apply'
          )} (${isPending ? '??' : total})`}</Button>
        </CTARow>
      </Box>
    </>
  )
}

export const useFilterOverlay = (
  hasFilters = false,
  hasSearch = false,
  hasSorting = false
) => {
  const { closeOverlay } = useSidebarOverlayProvider()
  const { t } = useTranslation()

  return useSidebarOverlay(
    <SidebarOverlay
      headline={t('productFilter:filterSidebar:headline')}
      onClose={closeOverlay}
      id="FilterOverlay"
      referenceTitle="[coded] FilterOverlay"
    >
      <FilterContent
        onApply={closeOverlay}
        hasFilters={hasFilters}
        hasSearch={hasSearch}
        hasSorting={hasSorting}
      />
    </SidebarOverlay>
  )
}
