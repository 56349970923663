import { useCurrencyCode } from './useCurrencyFormatter'

/**
 * TODO
 * move this to NormalizedCart
 */
export const useCartProperties = (cart?: NormalizedCart) => {
  const currency = useCurrencyCode()

  const nullPrice: NormalizedAmount = {
    amount: 0,
    centAmount: 0,
    currency,
  }

  /**
   * SubTotal
   * LineItems incl. ProductDiscounts added up
   */
  const subTotal = cart?.price.original || nullPrice

  /**
   * Shipping
   */
  const shipping = cart?.shipping.price || nullPrice

  /**
   * Total
   * SubTotal + Shipping - CodeDiscounts - CartDiscounts
   */
  const total = cart?.price.total || nullPrice

  /**
   * Strike
   * SubTotal + Shipping
   */
  const strike: NormalizedAmount | undefined =
    total.amount < subTotal.amount + shipping.amount
      ? {
          amount: subTotal.amount + shipping.amount,
          centAmount: subTotal.centAmount + shipping.centAmount,
          currency,
        }
      : undefined

  /**
   * Goods Value
   * Total - Shipping
   */
  const goodsValue: NormalizedAmount = {
    amount: total.amount - shipping.amount,
    centAmount: total.centAmount - shipping.centAmount,
    currency,
  }

  /**
   * FreeShippingThreshold
   */
  const freeShippingThreshold = cart?.shipping.freeThreshold || nullPrice

  /**
   * IsFreeShipping
   * Shipping = 0
   */
  const isFreeShipping = shipping.amount === 0

  /**
   * CodeDisounts
   */
  const codeDiscounts = cart?.cartDiscount?.byDiscountCode && [
    cart?.cartDiscount?.byDiscountCode,
  ]

  /**
   * CartDiscounts
   */
  const cartDiscounts = cart?.cartDiscount?.others

  /**
   * ShippingFromDate
   */
  const shippingFromDate = cart?.shipping?.fromDate

  return {
    cartDiscounts,
    codeDiscounts,
    freeShippingThreshold,
    goodsValue,
    isFreeShipping,
    shipping,
    shippingFromDate,
    strike,
    subTotal,
    total,
  }
}
