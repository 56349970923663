import { useEffect } from 'react'

export const useScrollIntoView = (
  element: HTMLElement | null,
  isScrolledIntoView: boolean,
  delay = 0,
  offsetTop = 0
) => {
  useEffect(() => {
    if (element && isScrolledIntoView) {
      window.setTimeout(() => {
        window.scrollTo({
          behavior: 'smooth',
          top:
            element.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offsetTop,
        })
      }, delay)
    }
  }, [element, isScrolledIntoView, delay, offsetTop])
}
