import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'

const StyledStrong = styled.strong`
  font-weight: 500;
`

const Strong: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <StyledStrong>{children}</StyledStrong>
)

export { Strong }
