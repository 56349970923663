import { Button, CTARow, Paragraph, Spacing } from '@boxine/tonies-ui'
import { Accent } from '@/tonies-ui/atoms/Accent'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { CompatibleToniesExpandable } from '../../molecules/CompatibleToniesExpandable'
import { Modal } from '@/tonies-ui/molecules/Modal'

export const TunesValidationModal = ({
  hasCompatibleTonie = false,
  onClose = () => null,
  product,
}: {
  hasCompatibleTonie?: boolean
  onClose?: () => void
  product: NormalizedProduct
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen
      headline={<Accent text={`<em>${t('errorPage:headline:default')}</em>`} />}
      shouldFocusFirstElement
      size="large"
      onClose={onClose}
      data-testid="tunes-validation-modal"
      footer={
        <CTARow justifyContent="space-around" flexDirection="row-reverse">
          {product.salesId && (
            <Button
              data-testid="tunes-validation-modal-cancel-button"
              variant="secondary"
              onClick={onClose}
            >
              {t('products:tunes:validationModalCancel')}
            </Button>
          )}
        </CTARow>
      }
    >
      <Spacing mb="spacing-s">
        <Paragraph>{t('products:tunes:validationModalText')}</Paragraph>
      </Spacing>
      <CompatibleToniesExpandable
        product={product}
        actionAdd2CartCallback={() => Promise.resolve(onClose())}
        isValid={hasCompatibleTonie}
      />
    </Modal>
  )
}
