import React, { MouseEvent, HTMLAttributeAnchorTarget, forwardRef } from 'react'
import { Icon, ProgressIndicator } from '@boxine/tonies-ui'
import styled, { css, useTheme } from 'styled-components'
import { IconJson } from '@boxine/tonies-ui/icons'
import * as icons from '@boxine/tonies-ui/icons'

export type Styling = 'primary' | 'secondary'

export type IconButtonProps = WithAsProperty<
  {
    ariaLabel?: string
    borderColor?: string
    dataTestId?: string
    dataTestExtra?: string
    isDisabled?: boolean
    fillColor?: string
    icon: IconJson | keyof typeof icons
    iconColor?: string
    isPending?: boolean
    size?: 'small' | 'large'
    cursor?: 'not-allowed' | 'pointer'
    styling?: Styling
    onClick?: ((event: MouseEvent<HTMLElement>) => void) | undefined
  } & {
    href?: string
    target?: HTMLAttributeAnchorTarget
  }
>

const Wrapper = styled.div<
  Pick<
    IconButtonProps,
    | 'fillColor'
    | 'borderColor'
    | 'isDisabled'
    | 'isPending'
    | 'size'
    | 'onClick'
    | 'cursor'
  >
>`
  ${({
    onClick,
    cursor,
    isDisabled,
    isPending,
    fillColor,
    borderColor,
    size,
  }) => {
    const isClickable = Boolean(onClick && !isDisabled && !isPending)

    return css`
      align-items: center;
      background: ${fillColor};
      border: 2px solid ${borderColor};
      border-radius: 100%;
      cursor: ${cursor || isClickable
        ? 'pointer'
        : isDisabled || isPending
        ? 'not-allowed'
        : 'initial'};
      display: flex;
      position: relative;
      font-size: 0;
      height: ${size === 'large' ? 2.5 : 1.5}rem;
      justify-content: center;
      margin: 0;
      padding: ${size === 'large' ? 0.4 : 0.2}rem;
      width: ${size === 'large' ? 2.5 : 1.5}rem;

      ${isClickable &&
      css`
        &:hover ::after {
          // Darken on hover

          content: ' ';
          display: block;
          background-color: rgba(0, 0, 0, 0.2);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          border-radius: 100%;
        }
      `}
    `
  }}
`
/**
 * renders an `a` or `button` element depending on the presence
 * of `href` or `onClick` properties.
 *
 * Default values:
 *  - `styling` = `primary`
 *  - `styling` is not used when `fillColor`, `borderColor` or `iconColor` are set manually
 *  - `size` = "large"
 *  - `cursor` = "pointer"
 * @param {IconButtonProps} IconButtonProps
 * @returns {FunctionComponent} IconButton
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      as,
      ariaLabel,
      borderColor,
      dataTestId,
      dataTestExtra,
      isDisabled,
      fillColor,
      icon: iconProp,
      iconColor,
      styling: stylingProp,
      size = 'large',
      isPending,
      ...rest
    },
    ref
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const { colors, colorContext } = useTheme()
    const styling =
      stylingProp || (colorContext === 'dark' ? 'secondary' : 'primary')
    const icon = typeof iconProp === 'string' ? icons[iconProp] : iconProp

    if (!icon) return null

    /**
     * FIXME: handle primary/secondary color switch of multiPath Icons in tonies-ui
     *
     * Background: some icons that contain multiple SVG paths have to have different or the same
     * background-colors per path based on the design of the icon (see i.e. {@link(https://gitlab.boxine.de/tonies/tonies-ui/-/blob/master/components/Icon/index.tsx#L28)})
     */
    const iconNeedsFillAll = ['pause', 'toniesLogo', 'assign', 'share']
    const iconNeedsFillColorPrimary =
      icon.paths?.length > 1 &&
      icon.name &&
      !['visa', ...iconNeedsFillAll].includes(icon.name)

    const iconBackground = colors[isDisabled ? 'anthracite-40' : 'primary']
    const iconWrapperHighlight =
      colors[
        isDisabled ? (styling === 'primary' ? 'normalgrey' : 'white') : 'white'
      ]
    const iconHighlight =
      colors[
        isDisabled
          ? styling === 'primary'
            ? 'normalgrey'
            : 'anthracite-40'
          : iconNeedsFillColorPrimary
          ? 'primary'
          : 'white'
      ]

    const fillColorWrapper =
      fillColor && !isDisabled
        ? fillColor
        : styling === 'primary'
        ? iconBackground
        : iconWrapperHighlight

    const borderColorWrapper =
      borderColor && !isDisabled
        ? borderColor
        : styling === 'primary'
        ? iconBackground
        : iconWrapperHighlight

    const fillColorIcon =
      iconColor && !isDisabled
        ? iconColor
        : styling === 'primary'
        ? iconHighlight
        : iconBackground

    return (
      <Wrapper
        isDisabled={isDisabled}
        isPending={isPending}
        aria-label={ariaLabel}
        as={as || ('href' in rest ? 'a' : 'button')}
        borderColor={borderColorWrapper}
        data-testid={dataTestId}
        data-testextra={dataTestExtra}
        fillColor={fillColorWrapper}
        size={size}
        target={'target' in rest ? rest.target : undefined}
        title={ariaLabel}
        ref={ref}
        {...rest}
      >
        {isPending ? (
          <ProgressIndicator
            height="100%"
            width="100%"
            styling={styling}
            fillColor={
              styling === 'secondary' && isDisabled
                ? 'anthracite-40'
                : undefined
            }
          />
        ) : (
          <Icon
            type={icon}
            fill={fillColorIcon}
            fillAll={
              (icon.name &&
                iconNeedsFillAll.includes(icon.name) &&
                fillColorIcon) ||
              undefined
            }
            width="100%"
            height="100%"
            title={ariaLabel}
          />
        )}
      </Wrapper>
    )
  }
)

IconButton.displayName = 'IconButton'
