import { useCallback, useState } from 'react'
import { useCartActions } from '../../../../hooks/useCartActions'
import { changeQuantityAction } from '../../../../providers/cartActions/actions/changeQuantityAction'
import { LineItemProps } from '../types'
import {
  GtmV4AddToCartEvent,
  GtmV4RemoveFromCartEvent,
} from '../../../../providers/gtmV4/types'
import { useGtmV4 } from '../../../../providers/gtmV4'

export type Params = {
  lineItem: LineItemProps['lineItem']
  quantityChange: LineItemProps['quantityChange']
}

export const useQuantityChangeHandlers = ({
  quantityChange,
  lineItem,
}: Params) => {
  const [hasPendingActions, setHasPendingActions] = useState(false)
  const { push } = useCartActions()
  const { pushGtmV4Event } = useGtmV4()
  const { id: lineItemId, product, quantity, total } = lineItem

  const generateGtmV4Data = useCallback(
    (
      eventType: 'addToCart' | 'removeFromCart',
      quantity: number
    ): GtmV4AddToCartEvent | GtmV4RemoveFromCartEvent => ({
      eventType,
      value: (total.price.centAmount * quantity) / 100,
      item: { ...product, quantity },
    }),
    [product, total]
  )

  const handleChangeQuantity = useCallback(
    (
      quantity: number,
      gtmV4Data: GtmV4AddToCartEvent | GtmV4RemoveFromCartEvent
    ) => {
      const cq = () => {
        setHasPendingActions(true)
        push(
          changeQuantityAction(lineItemId, quantity, {
            data: gtmV4Data,
            pushGtmV4Event,
          })
        ).finally(() => {
          setHasPendingActions(false)
        })
      }
      return cq()
    },
    [push, lineItemId, setHasPendingActions, pushGtmV4Event]
  )

  const handleQuantityIncrease = useCallback(() => {
    if (!quantityChange) {
      return
    } else if (quantityChange.hasQuantityChange) {
      handleChangeQuantity(quantity + 1, generateGtmV4Data('addToCart', 1))
    }
  }, [quantityChange, handleChangeQuantity, quantity, generateGtmV4Data])

  const handleQuantityDecrease = useCallback(() => {
    if (!quantityChange) {
      return
    } else if (quantityChange.hasQuantityChange) {
      handleChangeQuantity(quantity - 1, generateGtmV4Data('removeFromCart', 1))
    }
  }, [quantityChange, handleChangeQuantity, quantity, generateGtmV4Data])

  const handleQuantityRemove = useCallback(() => {
    if (!quantityChange) {
      return
    } else if (quantityChange.hasRemove) {
      handleChangeQuantity(0, generateGtmV4Data('removeFromCart', quantity))
    }
  }, [quantityChange, handleChangeQuantity, quantity, generateGtmV4Data])

  return {
    handleQuantityIncrease,
    handleQuantityDecrease,
    handleQuantityRemove,
    hasPendingActions,
  }
}
