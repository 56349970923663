import styled from 'styled-components'

export type ColumnProps = {
  contentPositionDesktop?: 'left' | 'right'
  textAlignMobile?: 'center' | 'left' | 'right'
  imageWidthMobile?: string
  imageWidthDesktop?: string
}

export const Column = styled.div<ColumnProps>`
  padding: 1rem;
  align-self: center;
  text-align: ${props => props.textAlignMobile || 'center'};
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoint.screenTablet}) {
    ${props =>
      props.contentPositionDesktop === 'left' &&
      `
        grid-row: 1;
      `}

    text-align: left;
    &:last-child {
      grid-row: 1;
    }
  }
`
