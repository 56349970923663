import { ProductQueryResult } from './types'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { getCurrencyCodeByLocale } from '../../../../config/shopAPI'

/**
 * get products by list of values from variants attributes
 *
 * ❌ this API does not check public availability of products
 * therefore the data must always be matched to the products from the shopAPI
 * @param attributeName - name of the variant attribute to be queried (i.e `Artikelnummer`)
 * @param attributeValue - array of values to be queried
 * @param lcCC - lcCC
 * @param limit - result limit
 */
export const getProductsByAttributes = async (
  attributeName: string,
  attributeValue: string[],
  lcCC: EcomLocale,
  limit = 500
) => {
  if (!attributeName || !attributeValue) {
    throw new Error('Missing attributes')
  }

  const value = 'value in (' + attributeValue.map(v => `"${v}"`).join(',') + ')'

  let where = `masterData(current(masterVariant(attributes(name="${attributeName}" and ${value}))))`

  /**
   * TODO
   * after migration of commercetools UK in DE
   * UK currently has no prices(country= but "Any"
   */
  const priceCountry = lcCC.substring(3, 5)
  if (priceCountry !== 'GB') {
    where += ` AND masterData(current(masterVariant(prices(country="${priceCountry}"))))`
  }

  return fetch<Record<string, unknown>, ProductQueryResult>({
    query: '/products',
    lcCC,
    method: 'get',
    request: {
      limit,
      where,
      expand: 'masterData.current.categories[*].parent',
      priceCountry,
      priceCurrency: getCurrencyCodeByLocale(lcCC),
    },
  })
}
