import { ComponentProps } from 'react'
import { MediaCopySchema, imageSchema, isOfSchema } from '../../../schemas'
import { ContentModelType } from '../../types/types'
import { MediaCopy } from '../../../../../../components/molecules/GenericImageText/variants/MediaCopy'

export const CM_MediaCopySchema = ({
  media,
  ...rest
}: ContentModelType<
  ComponentProps<typeof MediaCopy>,
  MediaCopySchema
>): ComponentProps<typeof MediaCopy> => {
  return {
    media: isOfSchema(media, imageSchema)
      ? {
          variant: 'image',
          alt: media.description || '',
          src: media.file.url,
        }
      : {
          variant: 'video',
          src: media.file.url,
        },
    ...rest,
  }
}
