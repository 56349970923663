import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useState, useCallback } from 'react'
import {
  MainNavigation as ToniesUiMainNavigation,
  useIsMobileNavigation,
  FocusButton,
} from '@boxine/tonies-ui'
import useIsClient from '../../../hooks/useIsClient'
import { useAuth } from '../../../providers/auth'
import { LogoLink } from './components/LogoLink'
import { MobileOnlyNavigation } from './components/MobileOnlyNavigation'
import useMedia from 'use-media'
import { useTheme } from 'styled-components'
import { useGtmV4, withGTMV4Context } from '../../../providers/gtmV4'
import { PrimaryNavigation, PrimaryNavigationItem } from '@/tonies-ui/index'
import { useLocale } from '../../../providers/locale'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useTranslation, Trans } from 'next-i18next'
import { homepageUrl } from '../../../utils/urlHelpers'

const SecondaryNavigation = dynamic(
  () => import('./components/SecondaryNavigation'),
  {
    ssr: false,
  }
)

export interface PrimaryNavigationItemProps {
  link: string
  text: string
  activeAliasPaths?: string[]
  tagText?: string
}

interface MainNavigationProps {
  logoSrc: string
  logoAlt: string
  logoLink: string
  primaryNavigationItems?: PrimaryNavigationItemProps[]
  primaryNavigationBreakpointPx?: number
  primaryNavigationSmallerBreakpointRem?: number
  primaryNavigationLargerBreakpointRem?: number
  isMinimalVersion?: boolean // The "minimal" version contains only the logo and no navigation entries at all
  hasCart?: boolean
}

export const MainNavigationContainer: FunctionComponent<
  MainNavigationProps
> = ({
  logoAlt,
  logoSrc,
  logoLink,
  primaryNavigationItems,
  primaryNavigationBreakpointPx,
  primaryNavigationSmallerBreakpointRem,
  primaryNavigationLargerBreakpointRem,
  isMinimalVersion = false,
  hasCart = true,
}) => {
  const { t } = useTranslation()
  const lcCC = useLocale()
  const { pushGtmV4Event } = useGtmV4()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const isClient = useIsClient()
  const auth = useAuth()
  const isMobile = useIsMobileNavigation()
  const asPath = useRouter()?.asPath
  const hasKeycloak = useFeatureFlags()

  const { screenLaptopL } = useTheme()
  const primaryNavigationFontSize = useMedia({
    minWidth: primaryNavigationBreakpointPx || screenLaptopL,
  })
    ? primaryNavigationLargerBreakpointRem
    : primaryNavigationSmallerBreakpointRem

  const handleLogout = useCallback(() => {
    pushGtmV4Event({
      eventType: 'click',
      type: 'onClick',
      label: t('header:Logout'),
      action: 'logout',
    })
    auth.logout()
  }, [auth, pushGtmV4Event, t])

  const handleLogin = useCallback(() => {
    pushGtmV4Event({
      eventType: 'click',
      type: 'onClick',
      label: t('header:Login'),
      action: 'login',
    })
    auth.login()
  }, [auth, pushGtmV4Event, t])

  const primary = primaryNavigationItems &&
    primaryNavigationItems.length > 0 && (
      <PrimaryNavigation fontSizeDesktopInRem={primaryNavigationFontSize}>
        {/* Home page link for mobile only */}
        {isMobile && t('header:toniesOnlineshop') && (
          <PrimaryNavigationItem to={homepageUrl(lcCC)}>
            <Trans
              i18nKey="header:toniesOnlineshop"
              components={{
                b: <b />,
              }}
            />
          </PrimaryNavigationItem>
        )}
        {/* Main navigation items from contentful */}
        {primaryNavigationItems.map(item => (
          <PrimaryNavigationItem
            key={item.link + item.text}
            to={item.link}
            tagText={item.tagText}
            isActive={
              asPath.startsWith(item.link) ||
              (item.activeAliasPaths &&
                item.activeAliasPaths.filter(path => asPath.startsWith(path))
                  .length > 0)
            }
            onClick={() =>
              pushGtmV4Event({
                eventType: 'click',
                type: 'link',
                label: item.text,
                link: item.link,
              })
            }
          >
            {item.text}
          </PrimaryNavigationItem>
        ))}
      </PrimaryNavigation>
    )

  const secondary = (
    <SecondaryNavigation
      auth={auth}
      isClient={isClient}
      isMobile={isMobile}
      hasCart={hasCart}
    />
  )

  const mobile = (
    <MobileOnlyNavigation
      auth={auth}
      isClient={isClient}
      isMobile={isMobile}
      isMobileMenuOpen={isMobileMenuOpen}
      hasCart={hasCart}
    />
  )

  const logo = (
    <LogoLink
      logoLink={logoLink}
      logoAlt={logoAlt}
      logoSrc={logoSrc}
      isMobile={isMobile}
      onClick={() =>
        pushGtmV4Event({
          eventType: 'click',
          type: 'link',
          label: '[coded] Logo',
          link: logoLink,
        })
      }
    />
  )

  const hamburgerMenuLoginLogout = hasKeycloak ? (
    auth.authenticated ? (
      <FocusButton
        onClick={handleLogout}
        isMobile
        data-testid="hamburger-menu-logout-link"
      >
        {t('header:Logout')}
      </FocusButton>
    ) : (
      <FocusButton
        onClick={handleLogin}
        isMobile
        data-testid="hamburger-menu-login-link"
      >
        {t('header:Login')}
      </FocusButton>
    )
  ) : undefined

  return (
    <ToniesUiMainNavigation
      hasHamburgerMenu={!isMinimalVersion && !!primary}
      isLoggedIn={auth.authenticated}
      isLogoAnimated
      isMobileMenuOpen={isMobileMenuOpen}
      logo={logo}
      onChangeIsMobileMenuOpen={setIsMobileMenuOpen}
      primary={!isMinimalVersion && primary}
      secondary={!isMinimalVersion && secondary}
      mobile={!isMinimalVersion && mobile}
      hamburgerMenuLoginOut={hamburgerMenuLoginLogout}
    />
  )
}

export const MainNavigation = withGTMV4Context(
  MainNavigationContainer,
  'MainNavigation'
)
