import { Box } from '@boxine/tonies-ui'
import { CarouselContext } from 'pure-react-carousel'
import React, { FunctionComponent, useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { Dot } from './Dot'
import { motion, useAnimate, useScroll, useTransform } from 'framer-motion'

const Container = styled(Box)`
  display: inline-flex;
`

export const Pagination: FunctionComponent<unknown> = () => {
  const context = useContext(CarouselContext)
  const { colorContext } = useTheme()
  const { totalSlides } = context.getStoreState()
  const [paginationRef] = useAnimate()
  const { scrollYProgress } = useScroll({
    target: paginationRef,
  })

  return (
    <motion.div
      ref={paginationRef}
      style={{
        position: 'absolute',
        y: useTransform(scrollYProgress, [0, 0.2], ['-8rem', '-1rem']),
        scale: useTransform(scrollYProgress, [1, 0.8], [0.8, 1]),
        x: '-50%',
        bottom: 0,
        left: '50%',
      }}
      whileHover={{ scale: 1.1 }}
    >
      <Container
        backgroundColor={`rgba(255,255,255,${
          colorContext === 'light' ? '0.85' : '0.5'
        })`}
        borderRadius={['s']}
        p="spacing-xs"
      >
        {new Array(totalSlides).fill(0).map((_, i) => (
          <Dot key={i} index={i} />
        ))}
      </Container>
    </motion.div>
  )
}
